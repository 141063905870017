import ModalContent from "@components/shared/modal/ModalContent";
import { ModalBg } from "../ModalLayout/ModalBg";
import { ModalInnerCenter } from "../ModalLayout/ModalInnerCenter";
import useModal from "@hooks/useModal";

interface Props {
  message: string;
  confirmDoing: () => void;
  closeAlert?: () => void;
}

export const AlertConfirmAndClose = ({
  message,
  confirmDoing,
  closeAlert,
}: Props) => {
  const { closeModal } = useModal();
  const onClick = () => {
    confirmDoing();
  };
  return (
    <ModalContent>
      <ModalContent.ModalHead>알림</ModalContent.ModalHead>
      <hr className="mt-0 mb-4" />
      <div className="modal-body">
        <h4>{message}</h4>
        <div className="d-flex align-items-center justify-content-center gap-3 mt-4">
          <button
            className="cancel-btn"
            onClick={() => {
              closeAlert ? closeAlert() : closeModal();
            }}
          >
            닫기
          </button>
          <button className="cancel-btn save-btn" onClick={onClick}>
            확인
          </button>
        </div>
      </div>
    </ModalContent>
  );
};
