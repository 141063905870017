import useFetch from "@hooks/useFetch";
import { Banner } from "./Banner";
import banner1 from "@assets/images/banner/banner1.jpg";
import banner2 from "@assets/images/banner/banner2.jpg";
import { useRecoilState } from "recoil";
import { allttamCare } from "src/atom/atom";

export const HomeBanner = () => {
  const [bannerList] = useFetch(
    "banner",
    "https://center.allttam.com/api/banner"
  );
  const [isOpenCare, setIsOpenCare] = useRecoilState(allttamCare);

  return (
    <div className="col-md-6 col-12">
      <div className="slideBanner">
        {bannerList && <Banner bannerArray={bannerList.imgs} />}
        <div className="fixedBanner">
          <img
            onClick={() => {
              window.open("https://center.allttam.com", "_blank");
            }}
            src={banner1}
            className="smallbanner pointer"
          />
          <img
            onClick={(e) => {
              setIsOpenCare(true);
            }}
            src={banner2}
            className="smallbanner pointer"
          />
        </div>
      </div>
    </div>
  );
};
