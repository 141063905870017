import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { infoWindowState } from "src/atom/atom";
import useAnimation from "./useAnimation";

export const useInfoWindow = () => {
  const [isInfoWindow, setIsInfoWindow] = useRecoilState(infoWindowState);

  const windowOpen = (text: string) => {
    setIsInfoWindow({
      isOpen: true,
      message: text,
      animation: true,
    });
  };

  return windowOpen;
};
