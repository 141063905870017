import { GreenMiddleButton } from "@components/Button/allttamButton/GreenMiddleButton";
import { OrangeMiddleButton } from "@components/Button/allttamButton/OrangeMiddleButton";
import useToggle from "@hooks/useToggle";
import HTMLReactParser from "html-react-parser";
import styles from "../Inquiries.module.css";
import { FroalaEditor } from "@components/shared/Froala/Froala";
import axios from "axios";
import { useState } from "react";
import { useQueryClient } from "react-query";
import { AdditionalInquiryModal } from "./AdditionalInquiryModal";

interface Props {
  content: string;
  answer: string;
  isAdmin: boolean;
  id: string;
  parentId: string;
  isMore: boolean;
}

export const AdditionalContent = ({
  content,
  answer,
  isAdmin,
  id,
  parentId,
  isMore,
}: Props) => {
  const { state: isAdditionalModal, handleToggle: handleAdditionalModal } =
    useToggle();
  const { state: isAnswer, handleToggle: handleAnswer } = useToggle();
  const [model, setModel] = useState("");
  const queryClient = useQueryClient();

  const writeAdditional = async () => {
    const res = await axios.post(
      `https://center.allttam.com/api/allttam/qna/additional/answer/${id}`,
      {
        answer: model,
      }
    );

    if (res.status === 201) {
      queryClient.invalidateQueries(["additionalQna", parentId]);
      handleAnswer();
    }
  };

  return (
    <div>
      <p className="text-start">추가질문내용</p>
      <div className={`text-start ${styles["answer-container"]}`}>
        {HTMLReactParser(content)}
      </div>
      <p className="mt-5 textStart">답변</p>
      <div className={`mb-4 ${styles["answer-container"]}`}>
        {answer ? (
          <div className="text-start">{HTMLReactParser(answer)}</div>
        ) : (
          <p className="grey mt-5 mb-5">답변이 아직 없습니다.</p>
        )}
      </div>
      {isAdmin && !isAnswer && (
        <OrangeMiddleButton onClick={handleAnswer}>추가답변</OrangeMiddleButton>
      )}
      {isAnswer && (
        <div className="d-flex flex-column gap-3 align-items-center">
          <FroalaEditor
            placeholder=""
            model={model}
            setModel={setModel}
            height={300}
          />
          <GreenMiddleButton onClick={writeAdditional}>
            작성하기
          </GreenMiddleButton>
        </div>
      )}
      {!isAdmin && answer && isMore && (
        <OrangeMiddleButton onClick={handleAdditionalModal}>
          추가질문하기
        </OrangeMiddleButton>
      )}
      {isAdditionalModal && (
        <AdditionalInquiryModal
          id={parentId}
          closeModal={handleAdditionalModal}
        />
      )}
    </div>
  );
};
