import { ProductManageSearchingValue } from "@interface/productmanageinterface";

interface Props {
  onChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => void;
  value: ProductManageSearchingValue;
}

export const InputBoxUploadWorkingNumber = ({ onChange, value }: Props) => {
  return (
    <div className="product-label product-label-1 col-md-3 col-12 width-devide-1">
      <label htmlFor="uploadWorkNumber">업로드 작업번호</label>
      <input
        value={value.uploadWorkNumber}
        onChange={onChange}
        type="text"
        className="form-control"
        id="uploadWorkNumber"
        aria-describedby="emailHelp"
        name="uploadWorkNumber"
        placeholder="업로드 작업번호를 입력해주세요."
      />
    </div>
  );
};
