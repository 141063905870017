import { useState } from "react";
import { StarterMainMobile } from "./StarterMainMobile";
import { StarterSubMobile } from "./StarterSubMobile";

interface Props {
  isOffline: boolean;
}

export const StarterContentsMobile = ({ isOffline }: Props) => {
  const [component, setComponent] = useState<"main" | "sub">("main");
  return (
    <>
      {component === "main" ? (
        <StarterMainMobile setComponent={setComponent} isOffline={isOffline} />
      ) : (
        <StarterSubMobile setComponent={setComponent} />
      )}
    </>
  );
};
