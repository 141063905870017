import { CollectValue } from "@interface/collectInterface";
import { CostcoSearchingDuplicateSelect } from "./CostcoSearchingDuplicateSelect";
import { CostcoSearchingGroupName } from "./CostcoSearchingGroupName";
import { CostcoSearchingImgSelect } from "./CostcoSearchingImgSelect";
import { CostcoSearchingUrl } from "./CostcoSearchingUrl";
import { CostcoSearchingMemo } from "./CostcoSearchingMemo";
import { collect } from "@utils/functions/postApi";
import { useAlert } from "@hooks/useAlert";
import { ResetIcon } from "@components/svg/ResetIcon";
import { SearchingIcon } from "@components/svg/SeachingIcon";
import { AlertOnlyClose } from "@components/Alert/AlertOnlyClose";

interface Props {
  inputCollectValue: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => void;
  collectValue: CollectValue;
  resetValue: () => void;
  setColletValue: React.Dispatch<React.SetStateAction<CollectValue>>;
}

export const CostcoSearchingBoard = ({
  inputCollectValue,
  collectValue,
  resetValue,
  setColletValue,
}: Props) => {
  const { isAlert, handleAlert, alertMessage } = useAlert();
  const clickCollect = async () => {
    if (collectValue.groupName !== "") {
      const collectRes = await collect.collectCostco({
        duplicate: collectValue.duplicate,
        groupName: collectValue.groupName,
        imageIndex: collectValue.imageIndex,
        memo: collectValue.memo,
        url: collectValue.url,
      });
      if (collectRes.result) {
        handleAlert(collectRes.result);
      }
    }
  };

  return (
    <div className="row mt-5">
      <div className="col-12">
        <div className="tabs-col-btn ">
          <div className="tab-content">
            <div className="first-product-tab shadow">
              <div className="row">
                <CostcoSearchingGroupName
                  setColletValue={setColletValue}
                  inputCollectValue={inputCollectValue}
                  collectValue={collectValue}
                />
                <CostcoSearchingUrl
                  inputCollectValue={inputCollectValue}
                  collectValue={collectValue}
                />
              </div>
              <div className="row mt-3">
                <CostcoSearchingMemo
                  inputCollectValue={inputCollectValue}
                  collectValue={collectValue}
                />
                <CostcoSearchingImgSelect
                  inputCollectValue={inputCollectValue}
                  collectValue={collectValue}
                />
                <CostcoSearchingDuplicateSelect
                  inputCollectValue={inputCollectValue}
                  collectValue={collectValue}
                />
              </div>
              <hr className="hr1 mt-4" />
              <div className="row pt-3 pb-1 align-items-center justify-content-end">
                <div className="col-md-6 col-12">
                  <div className="search-btn d-flex justify-content-end gap-3 align-items-center">
                    <button
                      onClick={resetValue}
                      className="cancel-btn save-btn reset-btn"
                    >
                      <ResetIcon />
                      초기화
                    </button>
                    <button
                      onClick={clickCollect}
                      className="cancel-btn save-btn"
                    >
                      <SearchingIcon />
                      수집
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isAlert && (
        <AlertOnlyClose
          message={alertMessage}
          closeAlert={handleAlert as () => void}
        />
      )}
    </div>
  );
};
