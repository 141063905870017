import { LandingPrice } from "./component/Price/LandingPrice";
import { IndexNav } from "./LandingNav/IndexNav";
import backgroundViedo from "@assets/video/backgroundVideo.mp4";
import { useEffect, useRef, useState } from "react";
import { LandingCoummunity } from "./component/Community/LandingCommunity";
import { Footer } from "@components/Footer/Footer";
import "./IndexResponsible.css";
import "./Index.css";
import { LandingMain } from "./component/Main/LandingMain";
import { LandingSub } from "./component/Sub/LandingSub";
import { LandingSeo } from "./component/Seo/LandingSeo";
import { LandingEarth } from "./component/Eearth/LandingEarth";
import { LandingEarthMobile } from "./component/Eearth/LandingEarthMobile";
import { LandingComputer } from "./component/Computer/LandingComputer";
import { LandingLecture } from "./component/Lecture/LandingLecture";
import ChannelService from "@components/ChannelTalk/ChannelService.js";
import { useNavigate, useSearchParams } from "react-router-dom";

export const Index = () => {
  ChannelService.loadScript();
  ChannelService.boot({
    pluginKey: "261a0acd-6795-4dbd-8c62-556f5d642311",
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const scroll = searchParams.get("scroll");
  const navigate = useNavigate();
  const [isAtTop, setIsAtTop] = useState(true);
  let lastScrollTop = 0;
  const landingTopRef = useRef(null);
  const priceRef = useRef(null);
  const communityRef = useRef(null);
  const functionRef = useRef(null);

  const handleScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const isScrollingDown = scrollTop > lastScrollTop;

    lastScrollTop = scrollTop;

    setIsAtTop(scrollTop === 0);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleNavClick = (scroll: string) => {
    navigate(`?scroll=${scroll}`);
  };

  const handleNavButtonClick = (ref: any) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (scroll === "function") {
      (functionRef as any).current.scrollIntoView({ behavior: "smooth" });
    }
    if (scroll === "price") {
      (priceRef as any).current.scrollIntoView({ behavior: "smooth" });
    }
    if (scroll === "community") {
      (communityRef as any).current.scrollIntoView({ behavior: "smooth" });
    }
  }, [scroll]);

  return (
    <>
      <IndexNav
        handleNavClick={handleNavClick}
        isAtTop={isAtTop}
        handleNavButtonClick={handleNavButtonClick}
        priceRef={priceRef}
        communityRef={communityRef}
        functionRef={functionRef}
        landingTopRef={landingTopRef}
      />
      <div className="landing relative" ref={landingTopRef}>
        <video
          src={backgroundViedo}
          muted
          autoPlay
          loop
          playsInline
          className="section3video"
        />
        <LandingMain />
        <LandingSub />
        <div className="landingSpace"></div>
        <LandingSeo functionRef={functionRef} />
        <div className="landingSpaceSamll"></div>
        <LandingEarth />
        <LandingEarthMobile />
        <LandingComputer />
        <div className="landingSpaceSamll"></div>
        <LandingLecture />
        <div className="landingSpaceSamll"></div>
        {/* <LandingPrice priceRef={priceRef} /> */}
        <div className="landingSpaceSamll"></div>
        <LandingCoummunity communityRef={communityRef} />
        <Footer />
      </div>
    </>
  );
};
