export const CoinIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <path data-name="사각형 3925" fill="none" d="M0 0h30v30H0z" />
      <path
        data-name="패스 10538"
        d="M113.4 272.845A9.375 9.375 0 0 1 127 273a7.976 7.976 0 1 1-4.192 15.373 9.387 9.387 0 0 1-8.327-1.5c-.15.01-.3.015-.456.015a7.038 7.038 0 0 1-.622-14.048zm6.722-.965a7.507 7.507 0 1 1-7.507 7.507 7.51 7.51 0 0 1 7.509-7.507zm8.677 3.937a9.382 9.382 0 0 1-3.024 11.056 6.1 6.1 0 0 0 3.024-11.056zm-17.093-.576a5.164 5.164 0 0 0 .736 9.53 9.38 9.38 0 0 1-.736-9.53zm7.479.377a.938.938 0 0 1 1.875.012v.01a4.338 4.338 0 0 1 1.475.634.938.938 0 0 1-1.074 1.538 2.521 2.521 0 0 0-1.677-.4c-.314.044-.6.194-.6.571 0 .084.062.123.125.159a1.852 1.852 0 0 0 .344.145c.4.136.866.225 1.286.348 1.16.338 1.995.952 1.995 2.16a2.235 2.235 0 0 1-1.585 2.267 2.8 2.8 0 0 1-.29.084.938.938 0 0 1-1.875-.014v-.015a4.363 4.363 0 0 1-1.462-.607.937.937 0 1 1 1.048-1.554 2.616 2.616 0 0 0 1.694.4c.311-.041.594-.185.594-.559 0-.084-.062-.123-.125-.159a1.849 1.849 0 0 0-.344-.145c-.4-.135-.866-.225-1.286-.348-1.16-.338-1.995-.952-1.995-2.16a2.383 2.383 0 0 1 .455-1.461 2.475 2.475 0 0 1 1.424-.906z"
        transform="translate(-105.124 -264.381)"
        fill="#fff"
        fillRule="evenodd"
      />
    </svg>
  );
};
