import { useRecoilValue } from "recoil";
import { infoWindowState } from "src/atom/atom";

interface Props {
  message: string;
}

export const InfoWindow = ({ message }: Props) => {
  const isWindowOpen = useRecoilValue(infoWindowState);
  return (
    <div
      className={`information-window ${isWindowOpen.isOpen && "open"}
        ${!isWindowOpen.animation && "closed"}`}
    >
      <div>{message}</div>
    </div>
  );
};
