import { GreyMiddleButton } from "@components/Button/allttamButton/GreyMiddleButton";
import { ModalBg } from "@components/ModalLayout/ModalBg";
import { ModalInnerTop } from "@components/ModalLayout/ModalInnerTop";
import { Spinner } from "@components/Spinner/Spinner";

import styles from "./Loading.module.css";
import { useEffect, useState } from "react";

interface Props {
  children?: React.ReactNode;
  spinner?: boolean;
}

interface CloseButtonProps {
  closeModal: () => void;
}

const LoadingModal = ({ children, spinner = true }: Props) => {
  return (
    <ModalBg onClick={() => {}}>
      <ModalInnerTop>
        <div className="mt-5 mb-5 d-flex flex-col justify-content-center">
          <p className="text-center">호출중입니다.</p>
          <div>{spinner && <Spinner />}</div>
          {children}
        </div>
      </ModalInnerTop>
    </ModalBg>
  );
};

const LoadingModalCloseButton = ({ closeModal }: CloseButtonProps) => {
  return (
    <div className="mt-5 d-flex justify-content-center">
      <GreyMiddleButton onClick={closeModal}>닫기</GreyMiddleButton>
    </div>
  );
};

const LoadingProgressBar = ({ maxSecond }: { maxSecond: number }) => {
  const [second, setSecond] = useState(0);

  useEffect(() => {
    const timeout = setInterval(
      () =>
        setSecond((prev) => {
          if (maxSecond === prev) return prev;
          else return prev + 0.01;
        }),
      10
    );

    return () => {
      clearInterval(timeout);
    };
  }, []);
  return (
    <>
      <div className={styles["progress-bar-bg"]}>
        <div
          className={styles["progress-bar-inner"]}
          style={{
            width: `${(second / maxSecond) * 100}%`,
          }}
        />
      </div>
      <p className="h6 weight500 mt-3 margin0">잠시만 기다려주세요...</p>
    </>
  );
};

LoadingModal.LoadingModalCloseButton = LoadingModalCloseButton;
LoadingModal.LoadingProgressBar = LoadingProgressBar;

export default LoadingModal;
