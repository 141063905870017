import category from "@assets/images/helper/category.svg";
import { RightIcon } from "@components/svg/RightIcon";
import useFetch from "@hooks/useFetch";
import { FqaCategory, IndexType } from "@interface/fqa.interface";
import { useMemo } from "react";

interface Props {
  isViewAll: boolean;
  setIsViewAll: React.Dispatch<React.SetStateAction<boolean>>;
  selectComponent: (type: IndexType, id: string) => void;
}

export const CateogoryFqa = ({
  selectComponent,
  isViewAll,
  setIsViewAll,
}: Props) => {
  const [fqaCategory] = useFetch(
    "fqaCategory",
    "https://center.allttam.com/api/allttam/fqacategory"
  );

  const clickViewAll = () => {
    setIsViewAll(true);
  };

  const renderCategory = useMemo(() => {
    if (!fqaCategory) return [];
    if (isViewAll) return fqaCategory;
    if (!isViewAll) return fqaCategory.slice(0, 7);
  }, [fqaCategory, isViewAll]);

  return (
    <div className="mt-3">
      <div className="d-flex align-items-center gap-3">
        <img src={category} />
        <p className="margin0 text16 weight500 grey">유형별 FQA</p>
      </div>
      <div className="category-fqa-container">
        {renderCategory.map((item: FqaCategory) => (
          <button
            key={item._id}
            className="d-flex justify-content-between madebutton widthFull"
            onClick={() => {
              selectComponent("category", item._id);
            }}
          >
            <p className="margin0 weight500 text14 grey">{item.title}</p>
            <RightIcon />
          </button>
        ))}
        {/* {!isViewAll && (
          <div className="d-flex justify-content-center">
            <button onClick={clickViewAll} className="madebutton">
              <DownArrowIcon />
            </button>
          </div>
        )} */}
      </div>
    </div>
  );
};
