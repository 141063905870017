import dots from "../../../../../assets/images/landing/earth/threeWhiteDots.svg";
import svg1 from "../../../../../assets/images/landing/earth/svg/excel_icon.svg";
import svg4 from "../../../../../assets/images/landing/earth/svg/order_icon.svg";
import svg2 from "../../../../../assets/images/landing/earth/svg/identity_icon.svg";
import svg3 from "../../../../../assets/images/landing/earth/svg/invoice_icon.svg";

interface Props {
  style?: any;
}

export const EarthMobileOrderWindow = ({ style }: Props) => {
  return (
    <div className="landingMobileEarthBoard mt-5" style={style}>
      <div className="d-flex justify-content-end widthFull">
        <img src={dots} className="mb-2" />
      </div>
      <div className="landingMobileEarthBoardInner">
        <div className="d-flex justify-content-center">
          <p className="mobileEarthMainTitle">주문관리</p>
        </div>
        <ul className="moblieEarthTextBox">
          <li className="d-flex align-items-start gap-3">
            <img src={svg4} />
            <div>
              <p className="moblieEarthTextBoxMain">
                신규주문 조회와 자동발주확인
              </p>
              <p className="moblieEarthTextBoxSub">
                모든 오픈마켓의 주문 정보를{" "}
                <span className="moblieEarthTextBoxSubBold">한번에 조회</span>
                하는 기능
              </p>
            </div>
          </li>
          <li className="d-flex align-items-start gap-3">
            <img src={svg3} />
            <div>
              <p className="moblieEarthTextBoxMain">개인통관고유부호 검증</p>
              <p className="moblieEarthTextBoxSub">
                통관에 필요한 성함, 연락처, 통관고유부호를{" "}
                <span className="moblieEarthTextBoxSubBold">자동 검증</span>
              </p>
            </div>
          </li>
          <li className="d-flex align-items-start gap-3">
            <img src={svg2} />
            <div>
              <p className="moblieEarthTextBoxMain">송장입력과 발주처리</p>
              <p className="moblieEarthTextBoxSub">
                국내 운송장 번호 입력으로{" "}
                <span className="moblieEarthTextBoxSubBold">발주처리</span>
                하는 기능
              </p>
            </div>
          </li>
          <li className="d-flex align-items-start gap-3">
            <img src={svg1} />
            <div>
              <p className="moblieEarthTextBoxMain">
                주문내역, 엑셀로 내보내기
              </p>
              <p className="moblieEarthTextBoxSub">
                신규주문, 배송완료 등 구간별로{" "}
                <span className="moblieEarthTextBoxSubBold">엑셀 추출</span>
                하는 기능
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};
