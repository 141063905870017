export const CircleIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 30 30"
    >
      <path
        data-name="패스 10550"
        d="m-9834.936-7163.308 5.912 5.96 9.465-9.809"
        transform="translate(9842.247 7178.252)"
        stroke="#00b8ba"
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeWidth="3px"
        fill="none"
      />
      <path data-name="사각형 3994" fill="none" d="M0 0h30v30H0z" />
    </svg>
  );
};
