import { CollectWay } from "../../../interface/collectInterface";

interface Props {
  collectWay: CollectWay;
  setCollectWay: React.Dispatch<React.SetStateAction<CollectWay>>;
  clickReset: () => void;
}

export const SearchingTab = ({
  setCollectWay,
  collectWay,
  clickReset,
}: Props) => {
  const selectWay = (e: React.MouseEvent<HTMLButtonElement>) => {
    clickReset();
    setCollectWay(e.currentTarget.value as CollectWay);
  };
  return (
    <ul className="nav nav-tabs borderNone">
      <li className="nav-item">
        <button
          onClick={selectWay}
          value="multi"
          className={`nav-link ${collectWay === "multi" && "active"}`}
          disabled={collectWay === "multi"}
        >
          대량 수집
        </button>
      </li>
      <li className="nav-item">
        <button
          onClick={selectWay}
          value="one"
          className={`nav-link ${collectWay === "one" && "active"}`}
          disabled={collectWay === "one"}
        >
          단일 수집
        </button>
      </li>
    </ul>
  );
};
