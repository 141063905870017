import { CollectValue, CollectWay } from "@interface/collectInterface";
import { SearchingDuplicate1688 } from "./SearchingDuplicate1688";
import { SearchingGroupName1688 } from "./SearchingGroupName1688";
import { SearchingImgSelect1688 } from "./SearchingImgSelect1688";
import { SearchingKeyword1688 } from "./SearchingKeyword1688";
import { SearchingMemo1688 } from "./SearchingMemo1688";
import { SearchingTab } from "@components/Collect/CollectSearchingTab/SearchingTab";
import { ResetIcon } from "@components/svg/ResetIcon";
import { SearchingIcon } from "@components/svg/SeachingIcon";

interface Props {
  collectWay: CollectWay;
  setCollectWay: React.Dispatch<React.SetStateAction<CollectWay>>;
  inputCollectValue: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => void;
  collectValue: CollectValue;
  resetValue: () => void;
  clickCollect: (page: number) => Promise<void>;
  setCollectValue: React.Dispatch<React.SetStateAction<CollectValue>>;
}

export const SearchingBoard1688 = ({
  collectWay,
  setCollectWay,
  inputCollectValue,
  collectValue,
  resetValue,
  clickCollect,
  setCollectValue,
}: Props) => {
  return (
    <div className="row mt-5">
      <div className="col-12">
        <div className="tabs-col-btn">
          <SearchingTab
            clickReset={resetValue}
            setCollectWay={setCollectWay}
            collectWay={collectWay}
          />
          <div className="tab-content">
            <div className="first-product-tab serachingBoard shadow">
              <div className="row">
                <SearchingGroupName1688
                  setCollectValue={setCollectValue}
                  collectValue={collectValue}
                  inputCollectValue={inputCollectValue}
                />
                <SearchingKeyword1688
                  collectWay={collectWay}
                  collectValue={collectValue}
                  inputCollectValue={inputCollectValue}
                />
              </div>
              <div className="row mt-3">
                <SearchingMemo1688
                  collectValue={collectValue}
                  inputCollectValue={inputCollectValue}
                  setCollectValue={setCollectValue}
                />
                <SearchingImgSelect1688
                  collectValue={collectValue}
                  inputCollectValue={inputCollectValue}
                />
                <SearchingDuplicate1688
                  collectValue={collectValue}
                  inputCollectValue={inputCollectValue}
                />
              </div>
              <hr className="hr1 mt-4" />
              <div className="row pt-3 pb-1 align-items-center justify-content-end">
                <div className="col-md-6 col-12">
                  <div className="search-btn d-flex justify-content-end gap-3 align-items-center">
                    <button className="cancel-btn save-btn reset-btn">
                      <ResetIcon />
                      초기화
                    </button>
                    <button
                      onClick={() => clickCollect(1)}
                      className="cancel-btn save-btn"
                    >
                      <SearchingIcon />
                      {collectWay === "one" ? "수집" : "검색"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
