import { Link } from "react-router-dom";
import useFetch from "@hooks/useFetch";
import { HomeProgressBar } from "./components/HomeProgressBar";
import { RightArrowIcon } from "@components/svg/RightArrowIcon";

export const HomeProductManage = () => {
  const [uploadSummary] = useFetch(
    "uploadSummary",
    "/Log/GetUserUploadSummary"
  );
  return (
    <div className="heading-two heading-three home-middle-row widthHalf">
      <div className="heading-two-inner">
        <h6>상품관리</h6>
        <Link to="/productmanage" className="heading-two-inner-1">
          <p>전체보기</p>
          <RightArrowIcon />
        </Link>
      </div>
      {uploadSummary &&
        PROGRESS_DATA.map((progress) => (
          <HomeProgressBar
            key={progress.id}
            data={uploadSummary[progress.dataName]}
            progress={progress}
            total={uploadSummary?.totalCount}
          />
        ))}
    </div>
  );
};

const PROGRESS_DATA = [
  { id: 0, title: "스마트스토어", dataName: "smartStore", color: "" },
  { id: 1, title: "쿠팡", dataName: "coupang", color: "progress-col-1" },
  { id: 2, title: "지마켓", dataName: "gmarket", color: "progress-col-2" },
  { id: 3, title: "옥션", dataName: "auction", color: "progress-col-3" },
  { id: 4, title: "11번가", dataName: "elevenst", color: "progress-col-4" },
];
