import { useState } from "react";

export const useScroll = (height: number) => {
  const [count, setCount] = useState<number>(1);

  const scrollEvent = (e: React.UIEvent<HTMLElement>) => {
    const scrollTop = e.currentTarget.scrollTop;
    if (scrollTop > 90 * height * count) {
      setCount((prev) => prev + 1);
    }
    console.log(scrollTop);
  };

  return { count, scrollEvent };
};
