import { UploadLog } from "../../../../interface/defaultinterface";

interface Props {
  log: UploadLog;
}

export const UploadLogComponent = ({ log }: Props) => {
  return (
    <tr>
      <td>
        <div>{log.workNumber}</div>
      </td>
      <td>
        <div className="d-flex align-items-center">
          <span></span>
          {log.market}
        </div>
      </td>
      <td>
        <div>{log.uploadTotalCount}</div>
      </td>
      <td>
        <div>{log.uploadSuccessCount}</div>
      </td>
      <td>
        <div>{log.endTime}</div>
      </td>
    </tr>
  );
};
