interface Props {
  isSelect: boolean;
}

export const ItemCheckBox = ({ isSelect }: Props) => {
  return (
    <td>
      <div
        className={`collect-inner-1 div-collect ${isSelect && "selectedItem"}`}
      >
        <div className="form-group">
          <input
            onClick={(e) => e.stopPropagation()}
            type="checkbox"
            checked={isSelect}
            readOnly={true}
          />
          <label></label>
        </div>
      </div>
    </td>
  );
};
