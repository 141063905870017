import { useState } from "react";
import { AliItem, CollectValue, CollectWay } from "@interface/collectInterface";
import { collect } from "@utils/functions/postApi";
import { useAlert } from "@hooks/useAlert";
import chromeAPI from "@utils/functions/chromeAPI.js";
import { AliCollectItem } from "./component/AliCollectItem";
import { toScrollTop } from "@utils/functions/toScrollTop";
import { Spinner } from "@components/Spinner/Spinner";
import { MultiSelectedCollect } from "@components/MultiSelectedCollect/MultiSelectedCollect";
import { CollectMultiSubMenu } from "../CollectTaobao/component/TaobaoMultiSubMenu";
import { AlertOnlyClose } from "@components/Alert/AlertOnlyClose";
import { AliSearchingBoard } from "./component/AliSearchingBoard";
import { useInfoWindow } from "@hooks/useInfoWindow";

interface Props {
  collectWay: CollectWay;
  setCollectWay: React.Dispatch<React.SetStateAction<CollectWay>>;
  COLLECT_VALUE_DEFAULT: CollectValue;
}

export const CollectAli = ({
  collectWay,
  setCollectWay,
  COLLECT_VALUE_DEFAULT,
}: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { isAlert, handleAlert, alertMessage } = useAlert();
  const [collectValue, setCollectValue] = useState<CollectValue>(
    COLLECT_VALUE_DEFAULT
  );
  const [pageData, setPageData] = useState<any>();
  const [selectedCollectItem, setSelectedCollectItem] = useState<string[]>([]);
  const [collectItemList, setCollectItemList] = useState<AliItem[]>([]);

  const openWindow = useInfoWindow();

  const inputCollectValue = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setCollectValue({
      ...collectValue,
      [name]: value,
    });
  };

  const clickReset = () => {
    setCollectValue(COLLECT_VALUE_DEFAULT);
  };
  const clickCollect = async (page: number) => {
    if (collectValue.url === "") {
      return handleAlert("검색 url 혹은 키워드를 입력해주세요.");
    }
    if (collectValue.groupName === "") {
      return handleAlert("상품 그룹명을 입력해주세요.");
    }
    if (collectWay === "one") {
      try {
        const collectRes = await collect.collectAliUsingApi(collectValue);
        if (collectRes.result) {
          handleAlert(collectRes.result);
        }
      } catch (e: any) {
        handleAlert(e.response.data.result);
      }
    } else {
      setIsLoading(true);
      const collectRes = await chromeAPI.aliexpressItemSearch(
        collectValue.url,
        page
      );
      if (collectRes.responseMsg) {
        setCollectItemList(
          collectRes?.responseMsg?.data?.root?.fields?.mods?.itemList?.content
        );
        setPageData(collectRes?.responseMsg?.data?.root?.fields?.pageInfo);
        setIsLoading(false);
      } else {
        openWindow("검색결과가 없습니다.");
        setCollectItemList([]);
        setPageData(undefined);
        setIsLoading(false);
      }
    }
  };
  const clickCollectMulti = async () => {
    try {
      if (collectValue.groupName !== "") {
        const collectRes = await collect.collectAliUsingExtenstion({
          duplicate: collectValue.duplicate,
          groupName: collectValue.groupName,
          imageIndex: collectValue.imageIndex,
          itemIds: selectedCollectItem,
          memo: collectValue.memo,
          translate: collectValue.translate,
          url: collectValue.url,
        });
        if (collectRes.result) {
          handleAlert(collectRes.result);
          resetSelect();
        }
      } else {
        handleAlert("상품 그룹명을 입력해주세요.");
      }
    } catch (e: any) {
      handleAlert(e.response.data.result);
    }
  };

  const resetSelect = () => {
    setSelectedCollectItem([]);
  };

  const clickNextPage = async () => {
    clickCollect(pageData.page + 1);
    toScrollTop();
  };

  const clickPrevPage = async () => {
    clickCollect(pageData.page - 1);
    toScrollTop();
  };

  const clickPage = async (e: React.MouseEvent<HTMLButtonElement>) => {
    clickCollect(parseInt(e.currentTarget.value));
    toScrollTop();
  };

  return (
    <>
      <AliSearchingBoard
        inputCollectValue={inputCollectValue}
        collectValue={collectValue}
        collectWay={collectWay}
        setCollectWay={setCollectWay}
        clickCollect={clickCollect}
        clickReset={clickReset}
        setCollectValue={setCollectValue}
      />
      {isLoading && (
        <div className="spinnerContainer">
          <Spinner />
        </div>
      )}
      {!isLoading && collectWay === "multi" && collectItemList.length !== 0 && (
        <>
          <MultiSelectedCollect
            clickPage={clickPage}
            totalPages={Math.ceil(pageData.totalResults / pageData.pageSize)}
            currentPage={pageData?.page!}
            clickNextPage={clickNextPage}
            clickPrevPage={clickPrevPage}
            resetSelect={resetSelect}
            clickCollectMulti={clickCollectMulti}
            length={selectedCollectItem.length}
          />
          <CollectMultiSubMenu
            itemList={collectItemList}
            setSelectedCollectItem={setSelectedCollectItem}
            selectedCollectItem={selectedCollectItem}
            totalData={pageData?.totalResults}
            site="ali"
          />
          <div className="row justify-content-center">
            {collectItemList.map((item, idx) => (
              <AliCollectItem
                key={item.productId}
                item={item}
                setSelectedCollectItem={setSelectedCollectItem}
                selectedCollectItem={selectedCollectItem}
              />
            ))}
          </div>
        </>
      )}
      {isAlert && (
        <AlertOnlyClose
          message={alertMessage}
          closeAlert={handleAlert as () => void}
        />
      )}
    </>
  );
};
