import { NoticeSearchValues } from "@interface/notice";
import { useQuery } from "react-query";
import { noticeApi } from "src/apis/noticeApis";

interface Props {
  searchValues: NoticeSearchValues;
  enabled: boolean;
}

const useNotice = ({ searchValues, enabled }: Props) => {
  const values = Object.values(searchValues);
  return useQuery(
    ["notice", ...values],
    () => noticeApi.getNotice(searchValues),
    {
      enabled: enabled,
    }
  );
};

export default useNotice;
