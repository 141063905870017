import { useState } from "react";
import { orderManage, upload } from "@utils/functions/postApi";
import { decryptByDES } from "@utils/functions/encrypt";
import { AccountInfoType } from "@interface/settinginterface";
import { checkMarketLogin } from "@utils/functions/checkMarketLogin.js";
import { useAlert } from "@hooks/useAlert";
import { UseMutationResult } from "react-query";
import { CSSearchValue, Date } from "@interface/orderManageInterface";
import { SelectCS } from "../OrderManageCS";
import { CSPeriodBar } from "../component/CSPeriodBar";
import { AlertOnlyClose } from "@components/Alert/AlertOnlyClose";
import { SearchingIcon } from "@components/svg/SeachingIcon";
import { Spinner } from "@components/Spinner/Spinner";
import { UploadCompleteCheckAccount } from "src/pages/Upload/modal/component/uploadproduct/UploadCompleteCheckAccount";

interface Props {
  CSList: UseMutationResult<any, unknown, void, unknown>;
  date: Date;
  setDate: React.Dispatch<React.SetStateAction<Date>>;
  setSearchValue: React.Dispatch<React.SetStateAction<CSSearchValue>>;
  searchValue: CSSearchValue;
  setSelectedCS: React.Dispatch<React.SetStateAction<SelectCS | undefined>>;
}

export const CSSearchingBoard = ({
  setSelectedCS,
  setSearchValue,
  CSList,
  date,
  setDate,
  searchValue,
}: Props) => {
  const [isLoginLoading, setIsLoginLoading] = useState(false);
  const [isLogin, setIsLogin] = useState<boolean | null>(null);
  const { handleAlert, alertMessage, isAlert } = useAlert();
  const [accountList, setAccountList] = useState<AccountInfoType[]>([]);

  const selectMarket = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    setIsLogin(false);
    setSearchValue({
      market: e.target.value,
      marketAccount: "",
    });
    const res = await orderManage.getAccount(e.target.value);
    setAccountList(res);
  };

  const selectAccount = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    setIsLoginLoading(true);
    setSearchValue({
      ...searchValue,
      marketAccount: e.target.value,
    });
    const nowSelectAccount = accountList.filter(
      (item) => decryptByDES(item.account!) === e.target.value
    )[0];
    if (searchValue.market === "Coupang") {
      try {
        const res = await upload.checkCoupangLogin({
          account: decryptByDES(nowSelectAccount.account!),
          vendorId: decryptByDES(nowSelectAccount.vendorId!),
          accessKey: decryptByDES(nowSelectAccount.accessKey!),
          secretKey: decryptByDES(nowSelectAccount.secretKey!),
        });
        setIsLoginLoading(false);
        return setIsLogin(true);
      } catch {
        setIsLoginLoading(false);
        setIsLogin(false);
      }
    }
    if (searchValue.market === "Elevenst") {
      try {
        const res = await upload.checkElevenstLogin({
          account: decryptByDES(nowSelectAccount.account!),
          openApiKey: decryptByDES(nowSelectAccount.openApiKey!),
        });
        setIsLoginLoading(false);
        setIsLogin(true);
      } catch {
        setIsLoginLoading(false);
        setIsLogin(false);
      }
    } else {
      try {
        const res = await checkMarketLogin(
          decryptByDES(nowSelectAccount.account!),
          searchValue.market
        );
        if (res) {
          setIsLoginLoading(false);
          setIsLogin(true);
        } else {
          setIsLoginLoading(false);
          setIsLogin(false);
        }
      } catch {
        setIsLoginLoading(false);
        setIsLogin(false);
      }
    }
  };

  const clickSearch = () => {
    if (searchValue?.market !== "" && searchValue.marketAccount !== "") {
      setSelectedCS({ account: searchValue.marketAccount, cs: undefined });
      CSList.mutate();
    } else {
      handleAlert("마켓과 계정을 모두 선택해주세요.");
    }
  };

  return (
    <div className="row mt-5">
      <div className="col-12">
        <div className="first-product-tab">
          <div className="row">
            <div className="product-label col-md-3 col-12 p-name d-flex gap-4 widthFull">
              <div className="widthHalf">
                <label htmlFor="productName">오픈마켓</label>
                <div className="state-selection widthFull">
                  <select
                    onChange={selectMarket}
                    name="market"
                    value={searchValue.market}
                    className="form-select searchWaySelect"
                  >
                    <option value="">마켓을 선택해주세요</option>
                    <option value="SmartStore">스마트스토어</option>
                    <option value="Coupang">쿠팡</option>
                    <option value="Auction">옥션</option>
                    <option value="Gmarket">지마켓</option>
                    <option value="Elevenst">11번가</option>
                  </select>
                </div>
              </div>
              <div className="widthHalf">
                <label htmlFor="productName">오픈마켓</label>
                <div className="state-selection widthFull">
                  <select
                    onChange={selectAccount}
                    name="marketAccount"
                    value={searchValue.marketAccount}
                    className="form-select searchWaySelect"
                  >
                    {searchValue.market === "" ? (
                      <option value="">오픈마켓을 선택해주세요</option>
                    ) : (
                      <option value="">계정을 선택해주세요</option>
                    )}
                    {accountList.map((item, idx) => (
                      <option key={idx} value={decryptByDES(item?.account!)}>
                        {decryptByDES(item?.account!)}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end mt-3">
            {isLoginLoading && (
              <div className="widthFull d-flex justify-content-center">
                <Spinner />
              </div>
            )}
            {!isLoginLoading && isLogin && <UploadCompleteCheckAccount />}
            {!isLoginLoading && !isLogin && (
              <p className="textRed">로그인이 필요합니다.</p>
            )}
          </div>
          <hr className="hr1 mt-5" />
          <div className="col-12">
            <div className=" payment-detail-1 gap-0 justify-content-between">
              <CSPeriodBar setDate={setDate} date={date} />
              {/* <SearchingBoardPeriodComponent setDate={setDate} date={date} /> */}
              <div className="d-flex align-items-center gap-1 view-count-sec new-order-manage gap-4">
                <button onClick={clickSearch} className="cancel-btn save-btn">
                  <SearchingIcon />
                  검색
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isAlert && (
        <AlertOnlyClose
          message={alertMessage}
          closeAlert={handleAlert as () => void}
        />
      )}
    </div>
  );
};
