import { getKoreanTiemFromUTC } from "@components/AllttamCare/function/getKoreanTimeFromUTC";
import ModalContent from "@components/shared/modal/ModalContent";
import { Notice } from "@interface/notice";
import parse from "html-react-parser";

interface Props {
  notice: Notice;
}

export const NoticeModal = ({ notice }: Props) => {
  console.log(notice);
  return (
    <ModalContent width={1200}>
      <ModalContent.ModalHead>공지사항</ModalContent.ModalHead>
      <ModalContent.ModalBody>
        <div className="d-flex gap-3">
          <p className="h1 weigth500 grey">{notice.title}</p>
          {notice.isPinned && <span className="pinnedTag weight500">중요</span>}
        </div>
        <p className="h3 weigth500">{notice.category}</p>
        <p className="text16 weigth500">
          {getKoreanTiemFromUTC(notice.createdAt).replaceAll(".", ". ")}
        </p>
        <div>{parse(notice.content)}</div>
      </ModalContent.ModalBody>
    </ModalContent>
  );
};
