import dots from "../../../../../assets/images/landing/earth/threeWhiteDots.svg";

import svg5 from "../../../../../assets/images/landing/earth/svg/svg5.svg";
import svg6 from "../../../../../assets/images/landing/earth/svg/svg6.svg";

interface Props {
  style?: any;
}

export const EarthMobileCsWindow = ({ style }: Props) => {
  return (
    <div className="landingMobileEarthBoard mt-5" style={style}>
      <div className="d-flex justify-content-end widthFull">
        <img src={dots} className="mb-2" />
      </div>
      <div className="landingMobileEarthBoardInner">
        <div className="d-flex justify-content-center">
          <p className="mobileEarthMainTitle">CS 관리</p>
        </div>
        <ul className="moblieEarthTextBox">
          <li className="d-flex align-items-start gap-3">
            <img src={svg5} />
            <div>
              <p className="moblieEarthTextBoxMain">문의글 조회</p>
              <p className="moblieEarthTextBoxSub">
                모든 오픈마켓의 문의 정보를 한번에 조회하는 기능
              </p>
            </div>
          </li>
          <li className="d-flex align-items-start gap-3">
            <img src={svg6} />
            <div>
              <p className="moblieEarthTextBoxMain">문의글 답변</p>
              <p className="moblieEarthTextBoxSub">
                답변글 작성하는 기능
                <span className="moblieEarthTextBoxSubBold">자동 검증</span>
              </p>
            </div>
          </li>
        </ul>
        <p className="moblieEarthTextBoxSub">
          오픈마켓에서는 문의글 답변을
          <span className="moblieEarthTextBoxSubBold">의무화</span>
          하고 있습니다.
        </p>
        <p className="moblieEarthTextBoxSub">
          오픈마켓 판매자
          <span className="moblieEarthTextBoxSubBold"> 점수</span>를{" "}
          <span className="moblieEarthTextBoxSubBold">관리</span>할 수 있는 필수
          기능입니다.
        </p>
      </div>
    </div>
  );
};
