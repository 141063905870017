import saleUp from "@assets/images/landing/powerfultag.svg";
import yellowArrow from "@assets/images/landing/powerfultagArrow.png";

export const SeoKeywordSearching = () => {
  return (
    <div className="seoMainContentRightSide component-transition">
      <div className="textEnd">
        <p className="textContentMain">키워드 검색</p>
        <p className="seoSubText oneLine">검색 키워드의 경쟁강도 분석에서</p>
        <p className="seoSubText oneLine">
          연관키워드 검색수ㆍ클릭률ㆍ경쟁률까지
        </p>
      </div>
      <div className="seoMainSmallText mt-5">
        <div className="seoIconBox">
          <img src={saleUp} />
          <img src={yellowArrow} />
        </div>
        <div className="seoContentText">
          <p className="margin0 mt-4 oneLine">
            검색 키워드 노출로 Sales-up이 필요하신가요?
          </p>
          <p className="margin0 oneLine">
            검색키워드 노출 순위를 최상단으로 끌어올리고 싶나요?
          </p>
          <p className="margin0 oneLine">
            상품 노출에 직접적인
            <span className="seoContentTextBold">검색 키워드</span>설정은
            필수입니다.
          </p>
          <p className="margin0 oneLine">
            검색키워드
            <span className="seoContentTextBold">분석</span>에서 연관키워드까지{" "}
            <span className="seoContentTextBold">제공</span>해드려요.
          </p>
        </div>
      </div>
    </div>
  );
};
