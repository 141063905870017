import { QuestionMarkIcon } from "@components/svg/QuestionMarkIcon";
import { ExpectTableHead } from "./ExpectTableHead";
import { ExpectListType } from "@interface/uploadinterface";
import { ExpectMarginItem } from "./ExpectMarginItem";

interface Props {
  expectList: ExpectListType[];
}

export const ExpectMargin = ({ expectList }: Props) => {
  return (
    <div className="width60 relative">
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center gap-3">
          <p className="margin0 weight500 navy text18">마진 목록</p>
          <QuestionMarkIcon color="#39415c" />
        </div>
        <div className="weight500 text12 grey d-flex flex-col align-items-end">
          {/* <p className="margin0"></p> */}
          <p className="margin0">
            예상마진 = 설정 상품가 - (원가 * 환율) - 배송비
          </p>
          <p className="margin0">예상마진율(%) = 예상마진 / 설정 상품가</p>
        </div>
      </div>
      <div className="widthFull expect-table">
        <table width="100%" className="exchangeTable mt-3">
          <ExpectTableHead />
          <tbody>
            {!expectList.length ? (
              <NoneResult />
            ) : (
              expectList.map((item, idx) => (
                <ExpectMarginItem key={idx} item={item} />
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const NoneResult = () => {
  return (
    <tr>
      <td colSpan={6}>
        <div className="mt-5 pt-5 weight500 text14 grey">
          예상 마진 버튼을 눌러 결과를 출력해주세요.
        </div>
      </td>
    </tr>
  );
};
