import { useEffect, useState } from "react";
import { ImageTranslateIcon } from "../svg/ImageTranslateIcon";
import { usePost } from "@hooks/usePost";
import { useTooltip } from "@hooks/useTooltip";
import { HomeToolTip } from "../../Tooltip/HomeTooltip";
import { getKoreanTiemFromUTC } from "@components/AllttamCare/function/getKoreanTimeFromUTC";

export const UserInfoImageTranslate = () => {
  const [isTooltip, handleMouseEnter, handleMouseLeave] = useTooltip();

  const imageTranslate = usePost("/Log/SearchUserPoint", {
    startDate: "2022-01-01",
    endDate: getKoreanTiemFromUTC(new Date().toISOString())
      .replaceAll(".", "-")
      .slice(0, -1),
    page: 1,
    maxRow: 10,
    filter: "IT",
  });

  useEffect(() => {
    imageTranslate.mutate();
  }, []);

  return (
    <div className="col-6 col-md-3 tooltipRel">
      {isTooltip && <HomeToolTip>편집기 접속 1회당 3코인 차감</HomeToolTip>}
      <div
        className="calendar calendar-2"
        onMouseEnter={handleMouseEnter as () => void}
        onMouseLeave={handleMouseLeave as () => void}
      >
        <div className="calendar-inner d-flex justify-content-end">
          <ImageTranslateIcon />
        </div>
        <h4 className="textEnd oneLine">
          {imageTranslate?.data?.totalRows
            ? imageTranslate?.data?.totalRows.toLocaleString()
            : 0}{" "}
          건
        </h4>
        <p className="textEnd oneLine">이미지 번역</p>
      </div>
    </div>
  );
};
