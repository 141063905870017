import { useState } from "react";
import { fileRequest } from "@utils/functions/fileRequest";
import { PluseImgInput } from "../PlusImgInput";
import { XCircleIcon } from "@components/svg/XCircleIcon";

interface Props {
  imgList: string[];
  setImgList: React.Dispatch<React.SetStateAction<string[]>>;
  setCropImg: React.Dispatch<React.SetStateAction<string>>;
  setEditIndex: React.Dispatch<React.SetStateAction<number>>;
}

export const ThumbNailBar = ({
  imgList,
  setImgList,
  setCropImg,
  setEditIndex,
}: Props) => {
  const [draggingIndex, setDraggingIndex] = useState(-1);

  const handleDragStart = (index: any) => {
    setDraggingIndex(index);
  };

  const handleDragOver = (e: any, index: any) => {
    e.preventDefault();
    const draggedImage = imgList[draggingIndex];
    const newImages = [...imgList];
    newImages.splice(draggingIndex, 1);
    newImages.splice(index, 0, draggedImage);
    setImgList(newImages);
    setDraggingIndex(index);
  };

  const handleDrop = (index: any) => {
    setDraggingIndex(-1);
  };

  const selectImg = async (url: string) => {
    setCropImg(url);
    setEditIndex(imgList.indexOf(url));
  };

  const addImg = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files![0];
    const uploadRes = await fileRequest("/Image/UploadImage", file);
    setImgList([...imgList, uploadRes.link]);
  };

  const deleteImg = (index: number) => {
    const newArr = [...imgList];
    newArr.splice(index, 1);
    setImgList(newArr);
  };

  return (
    <div>
      <div className="d-flex widthFull align-items-center gap-3">
        <h3 className="h3 oneLine margin0">썸네일 패널</h3>
        <hr className="hr4 widthFull" />
      </div>

      <div className="mt-3 d-flex gap-3">
        <button className="madebutton paddingZero">
          <PluseImgInput onChange={addImg} />
        </button>
        {imgList.map((img, index) => (
          <div
            key={index}
            draggable
            onDragStart={() => handleDragStart(index)}
            onDragOver={(e) => handleDragOver(e, index)}
            onDrop={() => handleDrop(index)}
            onDragEnd={() => setDraggingIndex(-1)}
            className="imgWrapper relative"
          >
            <img
              onClick={() => selectImg(img)}
              src={img}
              className="defaultInfoImg"
            />
            <button
              onClick={() => deleteImg(index)}
              className="imgXIcon madebutton"
            >
              <XCircleIcon />
            </button>
            {index === 0 && (
              <h5 className="h5 textGreen oneLine mt-3">대표 썸네일</h5>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
