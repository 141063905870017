import { useEffect } from "react";
import { Revenue } from "../../../../interface/orderManageInterface";

interface Props {
  inputRevenueValue: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => void;
  revenueValue: Revenue;
  setRevenueValue: React.Dispatch<React.SetStateAction<Revenue>>;
}

export const RevenueOpenMarketFee = ({
  inputRevenueValue,
  revenueValue,
  setRevenueValue,
}: Props) => {
  return (
    <div className="d-flex align-items-center widthFull gap-3 textStart mt-2">
      <div className="d-flex align-items-center widthFull">
        <p className="ordererInfoTitle revenueModalLeft">오픈마켓 수수료</p>
        <div className="d-flex align-items-center revenueModalRight gap-5">
          <div className="d-flex align-items-center widthFull">
            <input
              value={revenueValue.openmarketFeePrice}
              name="openmarketFeePrice"
              className="orderInfoModalInput"
              disabled
            />
            <p className="margin0 pl-1">원</p>
          </div>
          <div className="d-flex align-items-center widthFull">
            <input
              onChange={inputRevenueValue}
              value={revenueValue.openmarketFee}
              name="openmarketFee"
              className="orderInfoModalInput"
              type="number"
              disabled
            />
            <p className="margin0 pl-1">%</p>
          </div>
        </div>
      </div>
    </div>
  );
};
