import { ProhibitWordButton } from "@components/Button/WordButton/ProhibitWordButton";
import { ReplaceWordButton } from "@components/Button/WordButton/ReplaceWordButton";
import { CheckBoxInput } from "@components/Input/CheckBoxInput";
import { QuestionMarkIcon } from "@components/svg/QuestionMarkIcon";
import { WordSettingType } from "./OptionNameSetting";

interface Props {
  wordSettingValue: WordSettingType;
  setWordSettingValue: React.Dispatch<React.SetStateAction<WordSettingType>>;
}

export const OptionWordSetting = ({
  wordSettingValue,
  setWordSettingValue,
}: Props) => {
  const clickCheckBox = (name: "isProhibit" | "isReplace") => {
    setWordSettingValue({
      ...wordSettingValue,
      [name]: !wordSettingValue[name],
    });
  };

  return (
    <div className="width80 mt-3">
      <div className="d-flex gap-3 align-items-center">
        <p className="margin0 text18 weight500 grey">글자 옵션 설정</p>
        <QuestionMarkIcon />
      </div>
      <div className="d-flex mt-3">
        <ProhibitWordButton />
        <ReplaceWordButton />
        <div className="d-flex align-items-end gap-3">
          <CheckBoxInput
            onClick={() => {
              clickCheckBox("isProhibit");
            }}
            checked={wordSettingValue.isProhibit}
          />
          <p className="grey margin0">금지단어</p>
        </div>
        <div className="d-flex align-items-end gap-3 ml-3">
          <CheckBoxInput
            onClick={() => {
              clickCheckBox("isReplace");
            }}
            checked={wordSettingValue.isReplace}
          />
          <p className="grey margin0">치환단어</p>
        </div>
      </div>
    </div>
  );
};
