import { useRecoilState } from "recoil";
import { isHelper } from "src/atom/atom";

const useToggleHelper = (): [boolean, () => void] => {
  const [isOpenHelper, setIsOpenHelper] = useRecoilState<boolean>(isHelper);

  const toggleHelper = () => {
    setIsOpenHelper((prev) => !prev);
  };

  return [isOpenHelper, toggleHelper];
};

export default useToggleHelper;
