import useFetch from "@hooks/useFetch";
import { MarketPrice, PriceType } from "@interface/uploadinterface";

interface Props {
  price: PriceType;
  setPrice: React.Dispatch<React.SetStateAction<PriceType>>;
}

export const AdditionalSetting = ({ price, setPrice }: Props) => {
  const [getPrice] = useFetch("price", "/Market/GetPrice");

  const inputRatio = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setPrice({
      ...price,
      [name]: parseFloat(value) ? parseFloat(value) : "",
    });
  };
  return (
    <div className="d-flex mt-3">
      <div className="width20 mt-3">
        <p className="table-bg text-center grey weight600 text14 rounded-sm py-2">
          추가 판매가 공식
        </p>
        <p className="text12 weight500 text12 grey margin0 text-center oneLine">
          기본 판매가 * (1+추가마진율)
        </p>
      </div>
      <div className="d-flex flex-col gap-3 width80 mt-3 pl-3">
        <div className="widthFull table-bg rounded-sm py-2 d-flex relative">
          <p className="margin0 widthFull text-center grey weight600 text14">
            추가 마진율 (%)
          </p>
        </div>
        <div className="widthFull d-flex gap-2 justify-content-between">
          {getPrice &&
            MARKET_PRICE_SETTING_INDEX.map((market) => (
              <div key={market.id} className="width20 d-flex flex-col">
                <p className="weight500 grey text14 text-center">
                  {market.title}
                </p>
                <input
                  onChange={inputRatio}
                  className="price-setting-input"
                  type="number"
                  name={market.name}
                  defaultValue={
                    getPrice?.marketExpandPrice
                      ? getPrice.marketExpandPrice?.filter(
                          (item: MarketPrice) => item.market === market.name
                        )[0].addRatio
                      : 0
                  }
                />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

const MARKET_PRICE_SETTING_INDEX = [
  {
    id: 0,
    title: "쿠팡",
    name: "Coupang",
  },
  {
    id: 1,
    title: "옥션",
    name: "Auction",
  },
  {
    id: 2,
    title: "지마켓",
    name: "Gmarket",
  },
  {
    id: 3,
    title: "11번가 글로벌",
    name: "Elevenst",
  },
];
