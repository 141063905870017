import { Table } from "react-bootstrap";

interface Props {
  result: any;
  account: string;
  handleChangeModal: () => void;
}

export const StatusChangeResult = ({
  result,
  account,
  handleChangeModal,
}: Props) => {
  return (
    <div>
      <Table
        width="100%"
        cellPadding={0}
        cellSpacing={0}
        border={0}
        align="center"
      >
        {Array.isArray(result) && (
          <thead>
            <tr className="h4">
              <td>계정명</td>
              <td>상품코드</td>
              <td>결과</td>
            </tr>
          </thead>
        )}
        <tbody className="">
          {Array.isArray(result) &&
            result.map((item: any) => (
              <tr key={item.objectId}>
                <td>{account}</td>
                <td>{item.code}</td>
                <td>{item.isSuccess ? "성공" : "실패"}</td>
              </tr>
            ))}
        </tbody>
      </Table>
      {!Array.isArray(result) && (
        <div>
          <h4> 시스템 오류로 인하여 실패했습니다.</h4>
        </div>
      )}
      <div className="d-flex align-items-center justify-content-center gap-3 mt-4">
        <button className="cancel-btn save-btn" onClick={handleChangeModal}>
          닫기
        </button>
      </div>
    </div>
  );
};
