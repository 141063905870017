import { CreateBillKey } from "@interface/pay.interface";
import axiosInstance from "@utils/axiosInstance";

export const createBillKey = async (cardValue: CreateBillKey) => {
  const res = await axiosInstance.post("AllddamPay/CreateBillKey", cardValue);

  return res.data;
};

export const getBillKey = async () => {
  const res = await axiosInstance.get("AllddamPay/GetBillingInfo");

  return res.data;
};

export const deleteBillKey = async () => {
  const res = await axiosInstance.delete("AllddamPay/DeleteBillKey");

  return res.data;
};
