import { Revenue } from "../../../../interface/orderManageInterface";

interface Props {
  inputRevenueValue: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => void;
  revenueValue: Revenue;
}

export const RevenueCurrency = ({ inputRevenueValue, revenueValue }: Props) => {
  return (
    <div className="d-flex align-items-center widthFull gap-3 textStart mt-4">
      <div className="d-flex align-items-center widthFull">
        <p className="ordererInfoTitle revenueModalLeft">통화/환율</p>
        <div className="d-flex align-items-center revenueModalRight gap-5">
          <select
            onChange={inputRevenueValue}
            name="currency"
            value={revenueValue.currency}
            className="orderInfoModalInput"
          >
            <option value="">통화를 선택해주세요.</option>
            <option value="CNY">CNY</option>
            <option value="USD">USD</option>
            <option value="KRW">KRW</option>
          </select>
          <div className="d-flex align-items-center widthFull">
            <input
              onChange={inputRevenueValue}
              value={revenueValue.currencyRate}
              name="currencyRate"
              className="orderInfoModalInput"
              type="number"
            />
            <p className="margin0 pl-1">원</p>
          </div>
        </div>
      </div>
    </div>
  );
};
