import { useEffect } from "react";
import { useRecoilValue } from "recoil";
import { modalAtom } from "src/atom/modalAtom";

export const GlobalModal = () => {
  const { component } = useRecoilValue(modalAtom);
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);
  return <div className="modalBg">{component}</div>;
};
