import { GreenBigButton } from "@components/Button/allttamButton/GreenBigButton";
import styles from "./FallbackUI.module.css";
import { Link } from "react-router-dom";

export const FallbackUI = () => {
  return (
    <div className={styles["error-container"]}>
      <p className="h1">시스템 에러가 발생했습니다.</p>
      <p className="h3 mb-5">관리자에게 문의바랍니다.</p>
      <GreenBigButton
        onClick={() => {
          window.location.reload();
        }}
      >
        돌아가기
      </GreenBigButton>
    </div>
  );
};
