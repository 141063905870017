import { Outlet, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axiosInstance from "@utils/axiosInstance";

import Cookies from "js-cookie";

export const AuthRoute = () => {
  const [isOk, setIsOk] = useState(false);
  useEffect(() => {
    getUser();
  }, []);

  const navigate = useNavigate();
  const getUser = async () => {
    const accessToken = Cookies.get("accessToken");
    try {
      const res = await axiosInstance.get(
        "https://testapi.allttam.com/api/Auth/user",
        {
          headers: {
            Authorization: accessToken,
          },
        }
      );
      setIsOk(true);
    } catch {
      alert("로그인이 필요합니다.");
      navigate("/login");
    }
  };

  return isOk ? <Outlet /> : <div />;
};
