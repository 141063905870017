import { ReplaceWordModal } from "src/pages/Upload/modal/ReplaceWordModal";
import { WhiteMiddleButton } from "../allttamButton/WhiteMiddleButton";
import useToggle from "@hooks/useToggle";

export const ReplaceWordButton = () => {
  const { state: isSubsitutionModal, handleToggle: handleSubsitutionModal } =
    useToggle();
  return (
    <div className="width30 d-flex flex-col gap-2 align-items-start">
      <p className="margin0 weight500 text14 grey">치환단어</p>
      <WhiteMiddleButton onClick={handleSubsitutionModal}>
        설정
      </WhiteMiddleButton>
      {isSubsitutionModal && (
        <ReplaceWordModal closeModal={handleSubsitutionModal} />
      )}
    </div>
  );
};
