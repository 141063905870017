import { useState } from "react";
import { EditProductTitleValueType } from "../../../../../interface/productmanageinterface";
import { ReplaceWordModal } from "src/pages/Upload/modal/ReplaceWordModal";
import { ProhibitionWordModal } from "src/pages/Upload/modal/ProhibitionWordModal";
import useToggle from "@hooks/useToggle";
import { upload } from "@utils/functions/postApi";
import { ProhibitReplaceWord } from "@interface/uploadinterface";
import { replaceWord } from "@utils/functions/replaceWord";
import { sliceProhibitWord } from "@utils/functions/sliceProhibitWord";
import { useTooltip } from "@hooks/useTooltip";
import { GlobalTooltip } from "@components/GlobalTooltip/GlobalTooltip";

interface Props {
  setProductTitleValue: React.Dispatch<
    React.SetStateAction<EditProductTitleValueType[]>
  >;
}

export const EditWordChange = ({ setProductTitleValue }: Props) => {
  const { state: isProhibitModal, handleToggle: handleProhibitModal } =
    useToggle();
  const { state: isSubsitutionModal, handleToggle: handleSubsitutionModal } =
    useToggle();
  const [isProhibitTooltip, enterProhibit, leaveProhibit] = useTooltip();
  const [isReplaceTooltip, enterReplace, leaveReplace] = useTooltip();

  const clickRemoveProhibitWord = async () => {
    const getRes = await upload.getProhibitReplaceWord("Remove");
    setProductTitleValue((prev) =>
      prev.map((item) => {
        return {
          ...item,
          title: sliceProhibitWord(
            item.title,
            getRes.map((item: ProhibitReplaceWord) => item.targetWord)
          ),
        };
      })
    );
  };

  const clickReplaceWord = async () => {
    const getRes = await upload.getProhibitReplaceWord("Replace");
    const replaceWordArr = getRes.map((item: ProhibitReplaceWord) => {
      return {
        before: item.targetWord,
        after: item.convertWord,
      };
    });
    setProductTitleValue((prev) =>
      prev.map((item) => {
        return {
          ...item,
          title: replaceWord(item.title, replaceWordArr),
        };
      })
    );
  };
  return (
    <div className="d-flex gap-4 mt-4 align-items-end justify-content-between">
      <p className="productNameModalSubtitle oneLine width20">단어 설정</p>
      <div className="widthFull d-flex justify-content-between">
        <div className="d-flex flex-column product-label number-character width40 relative">
          <GlobalTooltip isVisible={isProhibitTooltip as boolean}>
            상품명 내 제거할 단어를 금지단어로 설정 및 적용합니다.
          </GlobalTooltip>
          <label
            onMouseEnter={enterProhibit as () => void}
            onMouseLeave={leaveProhibit as () => void}
          >
            금지단어
          </label>

          <div className="d-flex gap-5 justify-content-between">
            <button
              onClick={handleProhibitModal}
              type="button"
              className="cancel-btn forbidden-btn apply-btn1 "
            >
              설정
            </button>
            <button
              onClick={clickRemoveProhibitWord}
              className="cancel-btn save-btn correction-btn apply-btn1 "
            >
              적용
            </button>
          </div>
        </div>
        <div className="d-flex flex-column product-label number-character width40 relative">
          <GlobalTooltip
            style={{ right: 0 }}
            isVisible={isReplaceTooltip as boolean}
          >
            상품명 내 치환할 단어를 치환단어로 설정 및 적용합니다.
          </GlobalTooltip>
          <label
            onMouseEnter={enterReplace as () => void}
            onMouseLeave={leaveReplace as () => void}
          >
            치환단어
          </label>
          <div className="d-flex gap-5 justify-content-between">
            <button
              onClick={handleSubsitutionModal}
              type="button"
              className="cancel-btn forbidden-btn apply-btn1"
            >
              설정
            </button>
            <button
              onClick={clickReplaceWord}
              className="cancel-btn save-btn correction-btn  apply-btn1"
            >
              적용
            </button>
          </div>
        </div>
      </div>
      {isProhibitModal && (
        <ProhibitionWordModal closeModal={handleProhibitModal} />
      )}
      {isSubsitutionModal && (
        <ReplaceWordModal closeModal={handleSubsitutionModal} />
      )}
    </div>
  );
};
