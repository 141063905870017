import { getKoreanTiemFromUTC } from "@components/AllttamCare/function/getKoreanTimeFromUTC";
import { ModalBg } from "@components/ModalLayout/ModalBg";
import { ModalBody } from "@components/ModalLayout/ModalBody";
import { ModalHead } from "@components/ModalLayout/ModalHead";
import { ModalInnerTop } from "@components/ModalLayout/ModalInnerTop";
import HTMLReactParser from "html-react-parser";
import styles from "../Inquiries.module.css";
import { GreenBigButton } from "@components/Button/allttamButton/GreenBigButton";
import { useCallback, useState } from "react";
import { OrangeMiddleButton } from "@components/Button/allttamButton/OrangeMiddleButton";
import useToggle from "@hooks/useToggle";
import { AdditionalInquiryModal } from "./AdditionalInquiryModal";
import { DownArrowIcon } from "@components/svg/DownArrowIcon";
import axios from "axios";
import { AdditionalContent } from "./AdditionalContent";
import { useQuery } from "react-query";

interface Props {
  name: string;
  createdAt: string;
  content: string;
  answer: string;
  closeModal: () => void;
  isAdmin: boolean;
  isAnswerModal?: boolean;
  handleAnswerModal: () => void;
  _id: string;
  isAdditionalQna: boolean;
}

export const InquiryModal = ({
  handleAnswerModal,
  isAnswerModal = false,
  name,
  createdAt,
  content,
  answer,
  closeModal,
  isAdmin,
  _id,
  isAdditionalQna,
}: Props) => {
  const { state: isAdditionalModal, handleToggle: handleAdditionalModal } =
    useToggle();
  const [isFetch, setIsFetch] = useState(false);
  // const [additionalQna, setAdditionalQna] = useState<AdditionalQna[]>();
  const clickAnswer = useCallback(() => {
    handleAnswerModal();
    closeModal();
  }, [isAdmin]);

  const getAdditionalQna = useCallback(async () => {
    const res = await axios.get(
      `https://center.allttam.com/api/allttam/qna/additional/${_id}`
    );

    return res.data;
  }, [_id]);

  const { data: additionalQna } = useQuery<AdditionalQna[]>(
    ["additionalQna", _id],
    getAdditionalQna,
    { enabled: isFetch, refetchOnWindowFocus: false, staleTime: 60000 }
  );

  return (
    <ModalBg
      onClick={(e) => {
        e.stopPropagation();
        closeModal();
      }}
    >
      <ModalInnerTop>
        <ModalHead clickClose={closeModal}>문의글</ModalHead>
        <ModalBody>
          <p className="text-start cursor">{name}</p>
          <p className="grey textEnd">{getKoreanTiemFromUTC(createdAt)}</p>
          <p className="text-start">질문내용</p>
          <div className={`text-start ${styles["answer-container"]}`}>
            {HTMLReactParser(content)}
          </div>
          <p className="mt-5 textStart">답변</p>
          <div className={`mb-4 ${styles["answer-container"]}`}>
            {answer ? (
              <div className="text-start">{HTMLReactParser(answer)}</div>
            ) : (
              <p className="grey mt-5 mb-5">답변이 아직 없습니다.</p>
            )}
          </div>
          {isAdmin && (
            <GreenBigButton onClick={clickAnswer}>답변하기</GreenBigButton>
          )}
          {answer && !isAdditionalQna && (
            <OrangeMiddleButton onClick={handleAdditionalModal}>
              추가질문하기
            </OrangeMiddleButton>
          )}
          <div>
            {isAdditionalQna && answer && !additionalQna && (
              <button className="madebutton" onClick={() => setIsFetch(true)}>
                <p className="margin0">추가질문보기</p>
                <DownArrowIcon />
              </button>
            )}
          </div>
          <>
            {additionalQna?.map((item, idx) => (
              <AdditionalContent
                id={item._id}
                isAdmin={isAdmin}
                key={item._id}
                content={item.content}
                answer={item.answer}
                parentId={_id}
                isMore={idx === additionalQna.length - 1}
              />
            ))}
          </>
          {isAdditionalModal && (
            <AdditionalInquiryModal
              id={_id}
              closeModal={handleAdditionalModal}
            />
          )}
        </ModalBody>
      </ModalInnerTop>
    </ModalBg>
  );
};

export interface AdditionalQna {
  _id: string;
  content: string;
  answer: string;
}
