import saleUp from "@assets/images/landing/effectiveseo.svg";
import yellowArrow from "@assets/images/landing/effectiveSeoArrow.png";

export const SeoImageCorrection = () => {
  return (
    <div className="seoMainContentRightSide component-transition">
      <div className="textEnd">
        <p className="textContentMain">이미지 보정</p>
        <p className="seoSubText oneLine">
          필요한 부분을 지우는<span className="textblack"> 「잔상제거」</span>{" "}
        </p>
        <p className="seoSubText oneLine">
          지정영역만 원래 상태로<span className="textblack"> 「영역복구」</span>
        </p>
      </div>
      <div className="seoMainSmallText mt-5">
        <div className="seoIconBox">
          <img src={saleUp} />
          <img src={yellowArrow} />
        </div>
        <div className="seoContentText">
          <p className="margin0 mt-4 oneLine">
            이미지에 브랜드명, 상호명, 워터마크가 있나요?
          </p>
          <p className="margin0 oneLine">
            오픈마켓에 상품을 등록했는데, 썸네일 부적격 통보를 받았나요?
          </p>
          <p className="margin0 oneLine">
            상품의 상세페이지로 유입되도록
            <span className="seoContentTextBold"> 클린한 썸네일</span>이
            필요합니다.
          </p>
          <p className="margin0 oneLine">
            즉, 상품의 <span className="seoContentTextBold">상위 노출</span>에
            있어 이미지 보정이 필수입니다.
          </p>
        </div>
      </div>
    </div>
  );
};
