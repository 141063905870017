import { CollectValue } from "@interface/collectInterface";

interface Props {
  collectValue: CollectValue;
  inputCollectValue: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => void;
}

export const CostcoSearchingDuplicateSelect = ({
  collectValue,
  inputCollectValue,
}: Props) => {
  return (
    <div className="product-label select-image col-md-3 col-12">
      <label>중복상품 처리</label>
      <div className="state-selection">
        <select
          onChange={inputCollectValue}
          value={collectValue.duplicate}
          name="duplicate"
          className="form-select"
        >
          <option value="UPDATE">업데이트</option>
          <option value="SKIP">건너뛰기</option>
        </select>
      </div>
    </div>
  );
};
