interface Props {
  children: React.ReactNode;
  width?: number;
  unset?: boolean;
  fixHeight?: number;
}

export const ModalInnerTop = ({
  children,
  width = 800,
  unset = false,
  fixHeight,
}: Props) => {
  const widthStyle = {
    width: `${width ? width : "800"}px`,
  };
  const unsetStyle = {
    overflow: unset ? "unset" : "",
    height: `${fixHeight ? `${fixHeight}px` : "none"}`,
  };

  return (
    <div
      style={widthStyle}
      className={`modal-dialog1 d-flex align-items-center justify-content-center`}
    >
      <div
        style={unsetStyle}
        className="modal-content modalInner"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {children}
      </div>
    </div>
  );
};
