import { useState } from "react";
import { orderManage } from "@utils/functions/postApi";
import useToggle from "@hooks/useToggle";
import { useAlert } from "@hooks/useAlert";
import { ModalBg } from "@components/ModalLayout/ModalBg";
import { ModalInnerTop } from "@components/ModalLayout/ModalInnerTop";
import { AlertConfirmAndClose } from "@components/Alert/AlertConfirmAndClose";
import { AlertOnlyClose } from "@components/Alert/AlertOnlyClose";
import { SelectCS } from "../OrderManageCS/OrderManageCS";

interface Props {
  closeModal: () => void;
  selectedCS: SelectCS | undefined;
}

export const CSModal = ({ closeModal, selectedCS }: Props) => {
  const { state: isConfirmAlert, handleToggle: handleConfirmAlert } =
    useToggle();
  const { isAlert, handleAlert, alertMessage } = useAlert();
  const [answer, setAnswer] = useState<string>("");

  const inputAnswer = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setAnswer(e.target.value);
  };

  const sendAnswer = async () => {
    const answerRes = await orderManage.handleCS({
      market: STORE_INDEX[selectedCS?.cs?.market as Store],
      marketAccount: selectedCS?.account,
      csId: selectedCS?.cs!.csId,
      token: selectedCS?.cs!.token,
      comment: answer,
    });
    handleConfirmAlert();
    if (answerRes.status === "Success") {
      handleAlert("성공적으로 답변이 작성되었습니다.");
    }
  };

  const clickSendAnswer = () => {
    if (answer === "") {
      handleAlert("답변을 작성해주세요.");
    } else {
      handleConfirmAlert();
    }
  };

  return (
    <ModalBg onClick={closeModal}>
      <ModalInnerTop>
        <>
          <div className="modal-header">
            <h5
              className="modal-title d-flex align-items-center"
              id="exampleModalLabel"
            >
              문의 답변
            </h5>
            <button onClick={closeModal} className="btn-close"></button>
          </div>
          <div
            className="modal-body 
				inquiries-body1"
          >
            <div className="row">
              <div className="d-flex align-items-center gap-3 justify-content-end">
                <a href={selectedCS?.cs!.marketProductUrl} target="_blank">
                  <button className="cancel-btn forbidden-btn add-option-btn view-o-btn">
                    마켓 상품 보기
                  </button>
                </a>
              </div>
            </div>
            <div>
              <div className="row mt-4 option-one-inner ordering-col1">
                <div className="ordering-col">
                  <h3>Q. {selectedCS?.cs!.title}</h3>
                  <h6 className="pt-2">{selectedCS?.cs!.content}</h6>
                </div>
              </div>
              <div className="row">
                <div className="col-12 p-0">
                  <div className="mt-3 enter-answer ">
                    <textarea
                      onChange={inputAnswer}
                      className="option-one-inner resizeNone"
                      placeholder="답변을 입력해주세요"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="d-flex align-items-center justify-content-center gap-3 mt-4">
                  <button onClick={closeModal} className="cancel-btn">
                    취소
                  </button>
                  <button
                    className="cancel-btn save-btn"
                    onClick={clickSendAnswer}
                  >
                    저장
                  </button>
                </div>
              </div>
            </div>
          </div>
          {isAlert && (
            <AlertOnlyClose
              message={alertMessage}
              closeAlert={handleAlert as () => {}}
              additionalClose={closeModal}
            />
          )}
          {isConfirmAlert && (
            <AlertConfirmAndClose
              confirmDoing={sendAnswer}
              closeAlert={handleConfirmAlert}
              message="문의답변을 저장하시겠습니까?"
            />
          )}
        </>
      </ModalInnerTop>
    </ModalBg>
  );
};

const STORE_INDEX = {
  스마트스토어: "SmartStore",
  쿠팡: "Coupang",
  옥션: "Auction",
  지마켓: "Gmarket",
  "11번가": "Elevenst",
};

type Store = "스마트스토어" | "쿠팡" | "지마켓" | "옥션" | "11번가";
