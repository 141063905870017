import { ReactCropperElement } from "react-cropper";
import leftIcon from "@assets/images/imgToolLeft.svg";
import { useTooltip } from "@hooks/useTooltip";
import { UploadTooltip } from "../../../Tooltip/UploadTooltip";

interface Props {
  cropperRef: React.RefObject<ReactCropperElement>;
  mouseHover: (string: string) => void;
  mouseLeave: () => void;
}

export const MoveLeftButton = ({
  cropperRef,
  mouseHover,
  mouseLeave,
}: Props) => {
  const handleMoveLeft = () => {
    cropperRef.current?.cropper.move(-10, 0);
  };
  return (
    <button
      onClick={handleMoveLeft}
      className="madebutton thumbIcon tooltipRel"
      onMouseLeave={mouseLeave}
      onMouseEnter={() => mouseHover("좌로 이동")}
    >
      <img src={leftIcon} />
    </button>
  );
};
