import { QuestionMarkIcon } from "@components/svg/QuestionMarkIcon";
import { SearchingIconGreen } from "@components/svg/SearchingIconGreen";
import { useState } from "react";

interface Props {
  setSelectedTag: React.Dispatch<React.SetStateAction<string[]>>;
}

export const TagInputDirect = ({ setSelectedTag }: Props) => {
  const [tagKeyword, setTagKeyword] = useState<string>("");

  const inputTagKeyword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTagKeyword(e.target.value);
  };

  const submitTagInputDirect = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const a = tagKeyword.split(/[,#\s]+/);
    setSelectedTag((prev) => [...prev, ...a]);
    setTagKeyword("");
  };

  return (
    <div className="row mt-4">
      <div className="d-flex align-items-center gap-3">
        <p className="margin0 text18 weight500">태그 입력</p>
        <QuestionMarkIcon />
      </div>
      <div className="col-12 mt-3">
        <form onSubmit={submitTagInputDirect} className="relative">
          <input
            value={tagKeyword}
            type="text"
            className="allttam-input input-pr-3 tag-placeholder"
            placeholder="쉼표(,) # 또는 공백 으로 구분하여 태그 직접입력"
            onChange={inputTagKeyword}
          />
          <button className="madebutton input-searching-icon">
            <SearchingIconGreen />
          </button>
        </form>
      </div>
    </div>
  );
};
