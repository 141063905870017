import useFetch from "../../../hooks/useFetch";
import { NoticeComponent } from "./component/NoticeComponent";
import { NoticeData } from "../../../interface/defaultinterface";
import { Link } from "react-router-dom";
import { RightArrowIcon } from "../../svg/RightArrowIcon";
import useHomeNotice from "./component/useHomeNotice";
import { Notice } from "@interface/notice";
import { PinnedNotice } from "./component/PinnedNotice";
import Spacing from "@components/shared/Spacing";

export const HomeNotice = () => {
  const [noticeList] = useFetch("noticeList", "/AdminPage/GetNoticeTop5List");
  const { data } = useHomeNotice();

  return (
    <div className="heading-two heading-three home-middle-row widthHalf">
      <div className="heading-two-inner">
        <h6>공지사항</h6>
        <Link to="/notice" className="heading-two-inner-1">
          <p>전체보기</p>
          <RightArrowIcon />
        </Link>
      </div>
      {data && data.pinned.length !== 0 && (
        <div className="pinned-container">
          {data.pinned.map((notice: Notice) => (
            <PinnedNotice key={notice._id} notice={notice} />
          ))}
        </div>
      )}
      <Spacing.Height height="height16" />
      {data?.top5?.map((notice: Notice) => (
        <NoticeComponent key={notice?._id} notice={notice} />
      ))}
    </div>
  );
};
