import { SignupInput } from "./SignupInput";
import { LeftIcon } from "@components/svg/LeftIcon";
import allttamA from "@assets/images/login/a.svg";
import { useTooltip } from "@hooks/useTooltip";
import { useEffect, useState } from "react";
import { GreenMiddleButton } from "@components/Button/allttamButton/GreenMiddleButton";
import axios from "axios";
import madeYearMonthDayTime from "@utils/functions/madeKmcDate";
import { KmcResult } from "./Kmc";
import axiosInstance from "@utils/axiosInstance";
import { useInfoWindow } from "@hooks/useInfoWindow";
import { useNavigate } from "react-router-dom";
import visible from "@assets/images/login/visible.svg";
import invisible from "@assets/images/login/invisible.svg";
import { Signup as ISignup } from "@interface/user";
import { signup } from "@apis/authApis";

interface Props {
  setIsAllttamSignup: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Signup = ({ setIsAllttamSignup }: Props) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const openWindow = useInfoWindow();
  const navigate = useNavigate();
  const [isHoverBack, enterBack, leaveBack] = useTooltip();
  const [signupValue, setSignupValue] = useState<ISignup>({
    account: "",
    userName: "",
    contactTellNo: "",
    email: "",
    password: "",
    passwordCheck: "",
  });

  const passwordRegex =
    /^(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&₩~`;:'",.<>/\[\]{}\\|=+_)(^#])[A-Za-z\d$@$!%*?&₩~`;:'",.<>/\[\]{}\\|=+_)(^#-]{8,}$/;
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

  const idRegex = /^[a-zA-Z0-9-_]{6,}$/;

  const inputSignupValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setSignupValue({
      ...signupValue,
      [name]: value,
    });
  };

  const clickCert = async () => {
    const res = await axios.post(
      "https://center.allttam.com/kmcert/kmc/encode",
      {
        data: `ATTT1001/005001/${madeYearMonthDayTime()}${Math.ceil(
          Math.random() * 1000000
        ).toString()}/${madeYearMonthDayTime()}/M////////0000000000000000`,
      },
      {
        headers: {
          "Cache-Control": "no-cache",
        },
      }
    );
    window.open(
      `https://www.kmcert.com/kmcis/web/kmcisReq.jsp?tr_cert=${res.data}&tr_url=https://center.allttam.com/kmcert/allttam`,
      "_blank",
      "width=400, height=650"
    );
  };

  useEffect(() => {
    const handler = (e: MessageEvent) => {
      const result: KmcResult = e.data;

      if (result.result === "Y") {
        setSignupValue({
          ...signupValue,
          contactTellNo: result.number,
          userName: result.name,
        });
      }
    };

    window.addEventListener("message", handler);

    return () => window.removeEventListener("message", handler);
  }, [signupValue]);

  const clickSignup = async () => {
    if (!idRegex.test(signupValue.account)) {
      return openWindow(
        "아이디는 영문 숫자를 조합하여 최소 6자 이상 입력해주세요."
      );
    }

    if (!emailRegex.test(signupValue.email)) {
      return openWindow("이메일을 이메일 형식으로 입력해주세요.");
    }

    if (!passwordRegex.test(signupValue.password)) {
      return openWindow(
        "비밀번호는 특수문자 대·소문자 숫자를 조합하여 8자이상 16자미만으로 입력해주세요."
      );
    }

    if (signupValue.password !== signupValue.passwordCheck) {
      return openWindow("비밀번호와 비밀번호 확인이 일치하지 않습니다.");
    }

    if (signupValue.userName === "") {
      return openWindow("본인인증이 필요합니다.");
    }

    try {
      const res = await signup(signupValue);

      if (res.status === 200) {
        openWindow("회원가입에 성공했습니다.");
        navigate("/login");
      }
    } catch (e: any) {
      openWindow(e.response.data);
    }
  };

  const clickVisibleButton = () => {
    setIsPasswordVisible((prev) => !prev);
  };

  return (
    <>
      <SignupInput
        label="아이디"
        value={signupValue.account}
        onChange={inputSignupValue}
        name="userId"
      />
      {signupValue.account !== "" && !idRegex.test(signupValue.account) ? (
        <p className="margin0 text8 textRed textEnd">
          아이디는 영문 숫자를 조합하여 최소 6자 이상 입력해주세요.
        </p>
      ) : (
        <div className="height15" />
      )}
      <SignupInput
        label="이름"
        value={signupValue.userName}
        onChange={inputSignupValue}
        name="userName"
        // disabled={true}
        // placeholder="본인인증을 통해 입력해주세요"
      />
      <div className="height15" />
      <div className="relative">
        <SignupInput
          label="휴대전화"
          value={signupValue.contactTellNo}
          onChange={inputSignupValue}
          name="phoneNumber"
          // disabled={true}
          // placeholder="본인인증을 통해 입력해주세요"
        />
        {/* <div className="absolute kmc-btn">
          <GreenMiddleButton onClick={clickCert}>본인인증</GreenMiddleButton>
        </div> */}
      </div>

      <div className="height15" />
      <SignupInput
        label="이메일"
        value={signupValue.email}
        onChange={inputSignupValue}
        name="email"
        type="email"
      />
      {signupValue.email !== "" && !emailRegex.test(signupValue.email) ? (
        <p className="margin0 text8 textRed textEnd">
          이메일 형식으로 입력해주세요
        </p>
      ) : (
        <div className="height15" />
      )}
      <div className="relative">
        <SignupInput
          label="비밀번호"
          value={signupValue.password}
          onChange={inputSignupValue}
          name="password"
          type={isPasswordVisible ? "text" : "password"}
        />
        <button
          onClick={clickVisibleButton}
          className="madebutton signupPasswordInvisible paddingZero"
        >
          <img src={isPasswordVisible ? invisible : visible} />
        </button>
      </div>
      {signupValue.password !== "" &&
      !passwordRegex.test(signupValue.password) ? (
        <p className="margin0 text8 textRed textEnd">
          특수문자 대·소문자 숫자를 조합하여 8자이상 16자미만으로 입력해주세요.
        </p>
      ) : (
        <div className="height15" />
      )}

      <SignupInput
        value={signupValue.passwordCheck}
        onChange={inputSignupValue}
        name="passwordCheck"
        type={isPasswordVisible ? "text" : "password"}
        label={
          <p className="margin0">
            비밀번호 <br />
            확인
          </p>
        }
      />
      {signupValue.passwordCheck !== "" &&
      signupValue.password !== signupValue.passwordCheck ? (
        <p className="textRed text12 weight500 textEnd margin0">
          비밀번호가 일치하지 않습니다.
        </p>
      ) : (
        <div
          style={{
            height: "18px",
          }}
        />
      )}
      <div className="d-flex gap-3 justify-content-end mt-3">
        <button
          onClick={() => {
            setIsAllttamSignup(false);
          }}
          onMouseEnter={enterBack}
          onMouseLeave={leaveBack}
          className="madebutton signup-back-btn d-flex justify-content-center align-items-center gap-2"
        >
          <LeftIcon color={isHoverBack ? "#fff" : ""} />
          <p className="margin0">뒤로</p>
        </button>
        <button
          onClick={clickSignup}
          className="madebutton signup-btn d-flex align-items-center justify-content-center gap-2"
        >
          <img src={allttamA} />
          <p className="margin0">회원 가입</p>
        </button>
      </div>
    </>
  );
};
