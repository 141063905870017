import { DownArrowIcon } from "@components/svg/DownArrowIcon";
import edu1 from "@assets/images/care/edu1.svg";
import edu2 from "@assets/images/care/edu2.svg";
import edu3 from "@assets/images/care/edu3.svg";
import { ConsultInfo, DetailView } from "./CareContent";
import { useCallback, useState } from "react";
import axios from "axios";
import { useInfoWindow } from "@hooks/useInfoWindow";
import { maskLastThreeCharacters } from "../function/madeUserId";
import useFetch from "@hooks/useFetch";
import { Comment } from "./Comment";
import { useQueryClient } from "react-query";

interface Props {
  clickDetailView: (value: DetailView) => void;
  detail: DetailView;
  consultInfo: ConsultInfo;
  userEmail: string;
}

export const PayEducation = ({
  clickDetailView,
  detail,
  consultInfo,
  userEmail,
}: Props) => {
  const [commentAndPhone, setCommentAndPhone] = useState<Value>({
    comment: "",
    phoneNumber: "",
  });
  const openWindow = useInfoWindow();
  const queryClient = useQueryClient();
  const [comment] = useFetch(
    "comments",
    "https://center.allttam.com/api/consulting/comment"
  );

  const inputValue = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      setCommentAndPhone({
        ...commentAndPhone,
        [name]: value,
      });
    },
    [commentAndPhone]
  );

  const submitConsulting = useCallback(async () => {
    const [res1, res2] = await Promise.all([
      axios.post("https://center.allttam.com/api/consulting", {
        ...consultInfo,
        isFree: false,
        phoneNumber: commentAndPhone.phoneNumber,
      }),
      axios.post("https://center.allttam.com/api/consulting/comment", {
        name: maskLastThreeCharacters(userEmail.split("@")[0]),
        content: commentAndPhone.comment,
      }),
    ]);
    if (res1.status === 201 || res2.status === 201) {
      openWindow("신청되었습니다.");
      queryClient.invalidateQueries("comments");
    }
  }, [commentAndPhone, consultInfo]);

  return (
    <div className="d-flex flex-col align-items-center mt-4">
      <p className="careTitle widthFull margin0">(유료) 밀착 1:1 집중 코칭</p>
      <div className="white-box mt-3">
        <p className="careWeight5 careText14 margin0">
          해외구매대행과 솔루션 사용백서를 1:1 집중 코칭으로 빠르고 확실하게
          교육해드려요.
        </p>
        {detail !== "pay" && (
          <button
            onClick={() => {
              clickDetailView("pay");
            }}
            className="madebutton paddingZero d-flex flex-col align-items-center widthFull mt-3"
          >
            <p className="careWeight5 careText14 margin0">자세히 보기</p>
            <DownArrowIcon />
          </button>
        )}
        {detail === "pay" && (
          <>
            <div className="mt-3">
              <div className="careText14 careWeight6 d-flex gap-3">
                <img src={edu1} />
                <p className="margin0">단계별 맞춤형 대면 코칭 교육</p>
              </div>
              <div className="careText14 careWeight6 d-flex gap-3">
                <img src={edu2} />
                <p className="margin0">실시간 비대면 ZOOM 코칭 교육 </p>
              </div>
              <div className="careText14 careWeight6 d-flex gap-3">
                <img src={edu3} />
                <p className="margin0">솔루션 사용 가이드 & 실전형 활용 꿀팁</p>
              </div>
            </div>
            <div className="mt-3">
              <p className="careText13 careWeight5 margin0 mt-1">
                1. 솔루션 기본 셋팅에서 사용 방법 교육
              </p>
              <p className="careText13 careWeight5 margin0 mt-1">
                2. 상품 수집에서 판매 등록까지 과정 교육
              </p>
              <p className="careText13 careWeight5 margin0 mt-1">
                3. 셀프 상품수집에서 판매등록까지 1:1 코칭
              </p>
              <p className="careText13 careWeight5 margin0 mt-1">
                4. 판매노하우/실전 꿀팁 공유 및 질의응답
              </p>
              <p className="careText13 careWeight5 margin0 mt-4 oneLine letterSpacingSmaller">
                교육시간 : 평일 10~18시(협의 가능) 중 4시간 <br />
                교육장소 : 경기도 하남시 교육장 or 비대면 ZOOM
              </p>
            </div>
            <div className="mt-3">
              <p className="careText14 careWeight5 careCenter oneLine letterSpacingSmaller margin0">
                교육비 <span className="carePriceText">20만원 </span>(vat.포함)
                <br />
                우리은행 1002-232-753744 우승지
                <br /> 입금 후, 신청 댓글 남겨주시면 확인 후 연락드려요.
              </p>
            </div>
          </>
        )}
      </div>
      {detail === "pay" && (
        <>
          <div className="mt-3 widthFull d-flex gap-3">
            <div className="d-flex flex-col gap-2 widthFull">
              <input
                onChange={inputValue}
                name="phoneNumber"
                value={commentAndPhone.phoneNumber}
                className="careInput care-borderNone"
                placeholder="연락처를 남겨주세요. ( - 없이 입력)"
              />
              <input
                onChange={inputValue}
                name="comment"
                value={commentAndPhone.comment}
                className="careInput care-borderNone"
                placeholder="신청댓글을 남겨주세요"
              />
            </div>
            <button
              onClick={submitConsulting}
              className="madebutton careButton oneLine"
            >
              신청하기
            </button>
          </div>
          <div className="edu-comment mt-3">
            {comment?.map((item: CommentType) => (
              <Comment key={item._id} item={item} />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

interface Value {
  comment: string;
  phoneNumber: string;
}

export interface CommentType {
  _id: string;
  name: string;
  content: string;
  createdAt: string;
}
