import { ReactCropperElement } from "react-cropper";
import { EnlargeIcon } from "../../../../../components/svg/EnlargeIcon";
import { useTooltip } from "../../../../../hooks/useTooltip";
import { UploadTooltip } from "../../../Tooltip/UploadTooltip";

interface Props {
  cropperRef: React.RefObject<ReactCropperElement>;
  mouseHover: (string: string) => void;
  mouseLeave: () => void;
}

export const EnlargeImgButton = ({
  cropperRef,
  mouseHover,
  mouseLeave,
}: Props) => {
  const handleScaleUp = () => {
    cropperRef.current?.cropper.zoom(0.1);
  };
  return (
    <button
      onClick={handleScaleUp}
      className="madebutton thumbIcon tooltipRel"
      onMouseLeave={mouseLeave}
      onMouseEnter={() => mouseHover("확대 보기")}
    >
      <EnlargeIcon />
    </button>
  );
};
