interface Props {
  isChecked: boolean;
  children: string;
  informationText: string;
  name: string;
  changeCheckBoxValue: (name: string, value: boolean) => void;
  clickTab: (name: string) => void;
  clickPriceTab?: () => void;
  noPinter?: boolean;
}

export const EditProductCheckBox = ({
  isChecked,
  children,
  informationText,
  name,
  changeCheckBoxValue,
  clickTab,
  clickPriceTab,
  noPinter,
}: Props) => {
  return (
    <div
      onClick={() => {
        clickTab(name);
      }}
      className={`${
        isChecked && "edit-check-box-selected"
      } mt-4 widthFull edit-checkbox d-flex align-items-center gap-3 mb-4 ${
        !noPinter && "pointer"
      } ${!noPinter && "edit-check-box-hover"}`}
    >
      <div className="form-group">
        <input checked={isChecked} type="checkbox" name={name} readOnly />
        <label
          onClick={(e) => {
            e.stopPropagation();
            changeCheckBoxValue(name, isChecked);
            clickPriceTab && clickPriceTab();
          }}
        ></label>
      </div>
      <p className="margin0 edit-check-mainText">{children}</p>
      {isChecked && (
        <p className="margin0 edit-check-infoText">{informationText}</p>
      )}
    </div>
  );
};
