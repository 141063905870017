import { useState } from "react";
import { UseMutationResult } from "react-query";
import { SelectCount } from "./component/SelectCount";
import { ModifyProductButton } from "./component/ModifyProductButton";
import { SaleDeleteButton } from "./component/SaleDeleteButton";
import { SaleStartButton } from "./component/SaleStartButton";
import { SaleStopButton } from "./component/SaleStopButton";
import {
  MarketAccount,
  ProdcutManageResultItem,
  ProductManageSearchingValue,
  StatusChangeModal,
} from "@interface/productmanageinterface";
import useToggle from "@hooks/useToggle";
import { ProductEditModal } from "../modal/ProductEditModal/ProductEditModal";
import { ProductStatusChangeModal } from "../modal/ProductStatusChangeModal";
import { useInfoWindow } from "@hooks/useInfoWindow";
import useModal from "@hooks/useModal";

interface Props {
  totalCount: number;
  getSearchResult: UseMutationResult<any, unknown, void, unknown>;
  setSearchingValue: React.Dispatch<
    React.SetStateAction<{
      pageLimit: number;
      code: string;
      productName: string;
      memo: string;
      groupName: string;
      uploadWorkNumber: string;
      sort: boolean;
    }>
  >;
  selectedItem: ProdcutManageResultItem[];
  searchingValue: ProductManageSearchingValue;
  marketAccountValue: MarketAccount | undefined;
  setSelectedItem: React.Dispatch<
    React.SetStateAction<ProdcutManageResultItem[]>
  >;
  setPageNum: React.Dispatch<React.SetStateAction<number>>;
  searchLength: number;
}

export const ProductManageSubtitle = ({
  searchLength,
  totalCount,
  getSearchResult,
  setSearchingValue,
  selectedItem,
  searchingValue,
  marketAccountValue,
  setSelectedItem,
  setPageNum,
}: Props) => {
  const { openModal } = useModal();
  const openWindow = useInfoWindow();

  const clickChangeBtn = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (selectedItem.length > 50)
      return openWindow("상품 일괄 상태변경은 50개 이하로 선택해주세요.");
    openModal(
      <ProductStatusChangeModal
        marketAccountValue={marketAccountValue}
        selectedItem={selectedItem}
        searchingValue={searchingValue}
        modalState={
          MODAL_STATE_STATUS[
            e.currentTarget.value as "SALE" | "SUSPENSION" | "REMOVE"
          ]
        }
      />
    );
  };

  return (
    <div className="row buttonBoxSticky">
      <div className="col-12">
        <div className="total-block">
          <div className="total-block-inner">
            {totalCount && (
              <h4>
                검색 {searchLength}개{" "}
                <span className="text14">
                  {" "}
                  (전체 {totalCount.toLocaleString()})
                </span>
              </h4>
            )}
          </div>
          <div className="d-flex align-items-center gap-2">
            <ModifyProductButton
              onClick={() => {
                if (selectedItem.length !== 0) {
                  openModal(
                    <ProductEditModal
                      getSearchResult={getSearchResult}
                      marketAccountValue={marketAccountValue}
                      searchingValue={searchingValue}
                      selectedItem={selectedItem}
                    />
                  );
                }
              }}
            />
            <SaleStartButton
              value="SALE"
              onClick={(e) => {
                if (selectedItem.length !== 0) {
                  clickChangeBtn(e);
                }
              }}
            />
            <SaleStopButton
              value="SUSPENSION"
              onClick={(e) => {
                if (selectedItem.length !== 0) {
                  clickChangeBtn(e);
                }
              }}
            />
            <SaleDeleteButton
              value="REMOVE"
              onClick={(e) => {
                if (selectedItem.length !== 0) {
                  clickChangeBtn(e);
                }
              }}
            />
            <SelectCount
              setPageNum={setPageNum}
              setSelectedItem={setSelectedItem}
              searchingValue={searchingValue}
              setSearchingValue={setSearchingValue}
              getSearchResult={getSearchResult}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const MODAL_STATE_STATUS = {
  SALE: {
    status: "SALE",
    title: "상품 판매시작",
    message: "판매 시작하시겠습니까?",
    button: "시작",
  },
  SUSPENSION: {
    status: "SUSPENSION",
    title: "상품 판매중지",
    message: "판매 중단하시겠습니까?",
    button: "중단",
  },
  REMOVE: {
    status: "REMOVE",
    title: "상품 판매삭제",
    message: "판매 삭제하시겠습니까?",
    button: "삭제",
  },
};
