import useFetch from "@hooks/useFetch";
import { Fqa, IndexType } from "@interface/fqa.interface";
import { QuestionItem } from "./QuestionItem";

interface Props {
  id: string;
  selectComponent: (type: IndexType, id: string) => void;
}

export const CategoryBody = ({ id, selectComponent }: Props) => {
  const [fqaByCategory] = useFetch(
    `fqaByCategory${id}`,
    `https://center.allttam.com/api/allttam/fqaCategory/${id}`
  );

  return (
    <div>
      <p className="margin0 weight600 text16">
        {fqaByCategory?.category.title}
      </p>
      <div>
        {fqaByCategory?.fqa.map((item: Fqa) => (
          <QuestionItem
            key={item._id}
            selectComponent={selectComponent}
            item={item}
          />
        ))}
      </div>
      {fqaByCategory?.fqa.length === 0 && (
        <div className="d-flex align-items-center justify-content-center paddingLarge">
          <p className="weight500 text12">아직 도움말이 작성되지 않았습니다.</p>
        </div>
      )}
    </div>
  );
};
