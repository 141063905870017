import { useEffect, useRef } from "react";

interface Props {
  closeModal: () => void;
}

export const PrivacyPolicy = ({ closeModal }: Props) => {
  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  const menuRef = useRef<any>(null);

  useEffect(() => {
    const menuBarEvent = (e: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(e.target)) {
        closeModal();
      }
    };
    document.addEventListener("mousedown", menuBarEvent);

    return () => {
      document.removeEventListener("mousedown", menuBarEvent);
    };
  }, []);

  return (
    <div
      className="policy-modal"
      ref={menuRef}
      onClick={(e) => e.stopPropagation()}
    >
      <div className="policy-modal-sticky">
        <div className="policymodalHeader">
          <p>개인정보처리지침</p>
          <button
            onClick={closeModal}
            type="button"
            className="btn-close"
          ></button>
        </div>
      </div>
      <p className="mt-5">
        이들(이하 ‘사업자’라 한다)은 「개인정보보호법」에 기초하여 구매대행관리
        서비스 플랫폼(이하 ‘플랫폼’이라 한다)의 웹 서비스(이하 ‘서비스’라
        한다)를 제공받는 정보 주체(이하 ‘회원 ’이라 한다)의 개인정보를 보호하기
        위해 개인정보처리지침을 정하고, 게시한다.
      </p>

      <p className="mt-3">개인정보처리 목적</p>
      <p>사업자는 다음 각 항의 목적을 위하여 개인정보를 처리한다.</p>
      <p>① 플랫폼 회원가입 의사 확인</p>
      <p>② 플랫폼 및 서비스 제공에 따른 회원의 본인 식별․인증</p>
      <p>③ 회원 자격 유지와 관리</p>
      <p>④ 서비스 제공에 따른 대금 결제</p>
      <p>⑤ 서비스의 공급 및 상품의 배송</p>
      <p>⑥ 서비스 관련 부가 콘텐츠 제공</p>
      <p>⑦ 서비스 부정이용 방지</p>
      <p>⑧ 각종 안내 및 고지</p>
      <p>⑨ 일시적인 목적(설문, 통계분석, 경품 제공와 같은 이벤트 등)</p>
      <p>⑩ CS 관리 등</p>
      <p className="mt-3">2. 개인정보처리 취급 방침</p>
      <p>① 사업자는 회원으로부터 개인정보 수집에 대해 동의를 받아야 한다.</p>
      <p>② 동의를 얻은 개인정보는 처리 목적에 따른다.</p>
      <p>
        ③ 동의를 얻은 개인정보의 보유 및 처리, 이용 기간은 관련 법령에 우선하며,
        그 외 사업자가 정한 개인정보처리지침에 따른다.
      </p>
      <p>
        ④ 일시적인 목적을 위한 개인정보 수집 시에는, 기본적 인권 침해의 우려가
        있는 민감한 개인정보(인종, 민족, 사상 및 신조, 출신지 및 본적지, 정치적
        성향 및 범죄기록, 건강상태 및 성생활 등)는 수집하지 않으며 부득이하게
        수집할 때에는 회원들의 사전 동의를 반드시 구해야 한다.
      </p>
      <p className="mt-3">3. 개인정보처리 보유기간</p>
      <p>
        상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에
        의하여 보존할 필요가 있는 경우 사업자는 관계 법령에서 정한 일정한
        기간으로 회원 정보를 보존하며 보유 기간은 아래와 같다.
      </p>
      <p>
        ① 계약 또는 청약철회 등에 관한 기록은 「전자상거래 등에서의 소비자보호에
        관한 법률」에 따라 5년으로 한다.
      </p>
      <p>
        ② 대금결제 및 재화 등의 공급에 관한 기록은 「전자상거래 등에서의
        소비자보호에 관한 법률」에 따라 5년으로 한다.
      </p>
      <p>
        ③ 소비자의 불만 또는 분쟁처리에 관한 기록은 「전자상거래 등에서의
        소비자보호에 관한 법률」에 따라 3년으로 한다.
      </p>
      <p>
        ④ 로그 기록 관련한 보존 개인정보는 「접속기록 등에서의 통신비밀보호에
        관한 법률」에 따라 3개월로 한다.
      </p>
      <p>
        ⑤ 회원가입 및 관리에 있어 회원의 개인정보 보유 기간은 서비스 이용계약
        또는 회원가입에서 해지 시까지로 정한다. 단, 채권 또는 채무 관계 잔존
        시에는 해당 채권 또는 채무 관계 정산 시까지로 한다.
      </p>
      <p>
        ⑥ 사업자는 불량 회원의 부정한 이용의 재발을 방지하기 위해, 서비스
        해지일로부터 1년간 해당 회원의 개인정보를 보유할 수 있다.
      </p>
      <p className="mt-3">4. 개인정보의 제3자 제공</p>
      <p>
        사업자는 회원의 별도 동의나 법률의 특별한 규정(「개인정보
        보호법」 제17조를 포함한다) 또는 수사 목적으로 법령에 정해진 절차와
        방법에 따라 수사기관의 요구가 있는 경우를 제외하고는 개인정보를
        제 3자에게 제공하지 않는다.
      </p>
      <p className="mt-3">5. 개인정보처리의 위탁</p>
      <p>
        ① 사업자는 위탁계약 체결 시 개인정보 보호법 제25조에 따라 위탁업무
        수행목적 외 개인정보 처리금지, 재위탁 제한, 수탁자에 대한
        관리․감독, 책임에 관한 사항을 문서에 명시하고, 수탁자가 개인정보를
        안전하게 처리하는지를 감독한다.
      </p>
      <p>
        ② 사업자는 위탁업무의 내용이나 수탁자가 변경될 경우, 약관의
        제 11조 ‘게시 및 공지의 방법’에 따라 해당 내용을 지체없이 공개한다.
      </p>
      <p>6. 처리하는 개인정보 항목</p>
      <p>
        ① 수집항목 : 아이디, 비밀번호, 성명, 전화번호, 이메일, 사업자명, 결제기록, 상품등록기록, 신용카드번호
        및 은행계좌번호 등 결제정보, 인터넷 서비스 제공 과정에서 자동으로
        생성되어 수집될 수 있는 정보들(접속 IP주소, 쿠키,
        MAC주소, 서비스이용기록, 접속 로그, 불량 이용기록 등)
      </p>
      <p>
        ② 개인정보 수집방법 : 플랫폼(회원가입/결제정보 등), 서비스 제공 관련한
        모든 방법
      </p>
      <p className="mt-3">7. 개인정보의 파기 및 방법</p>
      <p>
        ① 사업자는 개인정보 보유 기간의 도달, 처리목적 달성 등에 따라 해당
        개인정보를 지체없이 파기한다.
      </p>
      <p>
        ② 사업자는 파기 사유가 발생한 개인정보를 선정하고, 사업자의 개인정보
        보호책임자의 승인을 받아 개인정보를 파기한다.
      </p>
      <p>
        ③ 전자적 파일 형태의 개인정보 파기 방법으로는 파일 삭제 또는 디스크 등
        저장매체 포맷으로 한다.
      </p>
      <p>④ 수기 문서 형태의 개인정보 파기 방법으로는 분쇄 또는 소각으로 한다</p>
      <p>8. 개인정보 관리(열람 및 정정, 삭제 등)에 관한 사항</p>
      <p>
        ① 회원은 사업자에게 다음 각 호의 개인정보보호 관련 권리를 행사할 수
        있다.
      </p>
      <p>가. 개인정보 열람</p>
      <p>나. 개인정보 오류가 있을 경우의 정정</p>
      <p>다. 개인정보 삭제</p>
      <p>라. 개인정보처리 정지</p>
      <p>
        ② 제1항에 따른 권리 행사는 「개인정보보호법 시행령」 제41조 제1항에 따라
        서면, 전자우편, FAX 등을 통해 할 수 있으며, 사업자는 지체없이 조치한다.
      </p>
      <p>
        ③ 제1항에 따른 권리 행사는 회원의 법정대리인이나 위임을 받은 자 등
        대리인을 통하여 할 수 있으며, 이 경우 「개인정보보호법 시행규칙」 별지
        제11호 서식에 따른 위임장을 제출해야 한다.
      </p>
      <p>
        ④ 개인정보 열람 및 처리정지 요구는 「개인정보보호법」 제35조
        제5항, 제37조 제2항에 의하여 회원의 권리가 제한될 수 있다.
      </p>
      <p>
        ⑤ 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집
        대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없다.
      </p>
      <p>
        ⑥ 사업자는 회원에 따른 열람의 요구, 정정. 삭제의 요구, 처리정지의 요구
        시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인한다
      </p>
      <p className="mt-3">9. 개인정보의 안전성 확보조치</p>
      <p>
        사업자는 개인정보의 안전성 확보를 위해 개인정보보호 책임자를 두어 독립된
        관리를 하도록 한다. 관련 업무를 하는 임직원이 증원된 경우 내부관리계획을
        수립하여 정기적 교육을 하도록 한다. 개인정보가 저장된 서버의 비밀번호
        설정 등 접근권한 강화와 백신 등의 보안프로그램 설치로 기술적 안정성을
        확보한다. 단, 회원 본인의 부주의나 본인 접속 IP에 따른 개인정보유출 등의
        문제는 사업자가 책임을 지지 않는다.
      </p>
      <p>10. 개인정보보호 책임자</p>
      <p>① 성명 : 유성용</p>
      <p>② 이메일 : operate@allttam.com</p>
      <p>③ 전화번호 : 050-6969-3931</p>
      <p>④ 상담이 필요한 경우,</p>

      <p>가. 업무시간 평일 10~17시(점심시간 12~14시)</p>
      <p>나. 플랫폼 게신판</p>
      <p>⑤ 개인정보침해에 대한 신고나 상담이 필요한 경우,</p>
      <p>가. 개인분쟁조정위원회</p>
      <p>(www.1336.or.kr / 1336)</p>
      <p>나. 정보보호마크인증위원회</p>
      <p>(www.eprivacy.or.kr / 02-580-0533~4)</p>
      <p>다. 대검찰청 인터넷범죄수사센터</p>
      <p>(http://icic.sppo.go.kr / 02-3480-3600)</p>
      <p>라. 경찰청 사이버테러대응센터</p>
      <p>(www.ctrc.go.kr / 국번없이 182)</p>
      <p className="mt-3">11. 고지의 의무</p>
      <p>
        개인정보처리지침의 내용 추가, 변경 및 삭제가 있을 때에는 개정
        최소 7일전부터 플랫폼 게시판을 통해 고지해야 한다. 본 개인정보처리지침의
        시행일자는 2023년 05월 01일로 한다.
      </p>
      <div className="d-flex justify-content-end">
        <button
          onClick={closeModal}
          className="mt-4 mb-3 policy-confirm-button madebutton"
        >
          확인
        </button>
      </div>
    </div>
  );
};
