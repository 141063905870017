import { Fqa, IndexType } from "@interface/fqa.interface";

interface Props {
  item: Fqa;
  selectComponent: (type: IndexType, id: string) => void;
}

export const QuestionItem = ({ item, selectComponent }: Props) => {
  return (
    <button
      onClick={() => {
        selectComponent("detail", item._id);
      }}
      className="mt-2 madebutton text14 weight500 grey widthFull"
    >
      {item.title}
    </button>
  );
};
