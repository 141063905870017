import useFetch from "../../../../../hooks/useFetch";
import {
  Delivery,
  ResultItem,
} from "../../../../../interface/orderManageInterface";

interface Props {
  isSelect: boolean;
  item: ResultItem;
  orderList: ResultItem[];
  idx: number;
  setOrderList: React.Dispatch<React.SetStateAction<ResultItem[]>>;
}

export const ItemProductOrderInfo = ({
  idx,
  isSelect,
  item,
  orderList,
  setOrderList,
}: Props) => {
  const [deliveryCompany] = useFetch(
    `deliveryCompany${item.market}`,
    `/MarketOrder/GetDeliveryCompany?market=${item.market}`
  );

  const selectDeliveryCompany = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newArr = [...orderList];
    if (e.target.value === "") {
      newArr[idx] = {
        ...newArr[idx],
        deliveryCompanyCode: "",
        deliveryCompanyName: "",
      };
    } else {
      newArr[idx] = {
        ...newArr[idx],
        deliveryCompanyCode: deliveryCompany[parseInt(e.target.value)].code,
        deliveryCompanyName: deliveryCompany[parseInt(e.target.value)].name,
      };
    }
    setOrderList(newArr);
  };

  const inputOrderListEditValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const newArr = [...orderList];
    newArr[idx] = {
      ...newArr[idx],
      [name]: value,
    };
    setOrderList(newArr);
  };

  return (
    <td className="width15">
      <div className={`collect-inner-1 noScroll ${isSelect && "selectedItem"}`}>
        <div onClick={(e) => e.stopPropagation()} className="flex-col">
          <input
            value={orderList[idx].overseaOrderNumber}
            onChange={inputOrderListEditValue}
            name="overseaOrderNumber"
            className="tableItemInput"
            placeholder="소싱마켓 상품주문번호"
          />
          <input
            onChange={inputOrderListEditValue}
            value={
              orderList[idx].purchasePriceOrigin === 0
                ? ""
                : orderList[idx].purchasePriceOrigin
            }
            name="purchasePriceOrigin"
            className="tableItemInput"
            placeholder="구입금액(신고금액)"
          />
          <input
            onChange={inputOrderListEditValue}
            value={orderList[idx].trackingNumber}
            name="trackingNumber"
            className="tableItemInput"
            placeholder="트래킹번호"
          />
          <select
            value={deliveryCompany?.findIndex(
              (item: Delivery) =>
                item.name === orderList[idx].deliveryCompanyName
            )}
            onChange={selectDeliveryCompany}
            className="tableItemInput"
          >
            <option value="">택배사를 선택 해 주세요</option>
            {deliveryCompany?.map((item: Delivery, idx: number) => (
              <option key={idx} value={idx}>
                {item.name}
              </option>
            ))}
          </select>
          <input
            onChange={inputOrderListEditValue}
            value={orderList[idx].invoiceNumber}
            name="invoiceNumber"
            className="tableItemInput"
            placeholder="국내운송장번호"
          />
          <input
            onChange={inputOrderListEditValue}
            value={
              orderList[idx].shipmentFee === 0 ? "" : orderList[idx].shipmentFee
            }
            name="shipmentFee"
            className="tableItemInput"
            placeholder="국제운송료"
          />
        </div>
      </div>
    </td>
  );
};
