export const RightIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <g data-name="그룹 11140">
        <path
          data-name="사각형 74"
          fill="none"
          d="M0 0h20v20H0z"
          transform="rotate(-90 10 10)"
        />
        <path
          data-name="패스 10533"
          d="M5.679 5.679V0H0"
          transform="rotate(45 -2.981 13.233)"
          stroke="#a7b0c7"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2px"
          fill="none"
        />
      </g>
    </svg>
  );
};
