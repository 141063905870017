import { useAlert } from "@hooks/useAlert";
import useToggle from "@hooks/useToggle";
import { useTooltip } from "@hooks/useTooltip";
import { UploadSummary } from "@interface/uploadinterface";
import { UploadTooltip } from "../../Tooltip/UploadTooltip";
import { AlertOnlyClose } from "@components/Alert/AlertOnlyClose";
import { ProductUploadModal } from "../../modal/ProductUploadModal";
import useModal from "@hooks/useModal";
import { useInfoWindow } from "@hooks/useInfoWindow";

interface Props {
  selectedItem: UploadSummary[];
}

export const ProductUploadButton = ({ selectedItem }: Props) => {
  const [isTooltip, handleMouseOn, handleMouseOff] = useTooltip();
  const { openModal } = useModal();
  const openWindow = useInfoWindow();

  const clickOpenModal = () => {
    if (selectedItem.length === 0) {
      openWindow("선택된 상품이 없습니다.");
    } else {
      openModal(<ProductUploadModal selectedItem={selectedItem} />);
    }
  };

  return (
    <div className="tooltipRel">
      {isTooltip && (
        <UploadTooltip>상품DB를 오픈마켓에 판매등록합니다.</UploadTooltip>
      )}
      <button
        onMouseEnter={handleMouseOn as () => void}
        onMouseLeave={handleMouseOff as () => void}
        type="button"
        onClick={clickOpenModal}
        className="cancel-btn save-btn reset-btn del-btn sale-reg-btn"
      >
        판매등록
      </button>
    </div>
  );
};
