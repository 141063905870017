import { useEffect, useState } from "react";
import detail1 from "@assets/images/detailicon/detail1.png";
import detail2 from "@assets/images/detailicon/detail2.png";
import detail3 from "@assets/images/detailicon/detail3.png";
import detail4 from "@assets/images/detailicon/detail4.png";
import detail5 from "@assets/images/detailicon/detail5.png";
import detail6 from "@assets/images/detailicon/detail6.png";
import { upload } from "@utils/functions/postApi";
import { UploadSummary } from "@interface/uploadinterface";
import { useQueryClient } from "react-query";
import useModal from "@hooks/useModal";

interface Props {
  setIsUseHTMLSetting: React.Dispatch<
    React.SetStateAction<{
      detailPageTop: boolean;
      top: boolean;
      bottom: boolean;
    }>
  >;
  isUseHTMLSetting: {
    detailPageTop: boolean;
    top: boolean;
    bottom: boolean;
  };
  SelectselectValue: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  selectValue: {
    style: string;
    fontSize: string;
    placementOrder: string;
  };
  bottomModel: string;
  topModel: string;
  clickReset: () => void;
}

export const DetailPageSettingOption = ({
  setIsUseHTMLSetting,
  isUseHTMLSetting,
  SelectselectValue,
  selectValue,
  topModel,
  bottomModel,
  clickReset,
}: Props) => {
  const { closeModal } = useModal();
  const selectUseHTMLSetting = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setIsUseHTMLSetting({
      ...isUseHTMLSetting,
      [name]: value === "true",
    });
  };
  const queryClient = useQueryClient();
  const [img1, setImg1] = useState([detail1, detail2, detail3]);
  const [img2, setImg2] = useState([detail4, detail5, detail6]);

  useEffect(() => {
    const interval = setInterval(() => {
      setImg1((prev) => [prev[1], prev[2], prev[0]]);
      setImg2((prev) => [prev[1], prev[2], prev[0]]);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const comfirmSetDetailPage = async () => {
    const setDetailRes = await upload.setDetailPageSetting({
      objectIds: [],
      useBaseConfig: true,
      detailPageConfig: {
        market: "",
        useDesc: isUseHTMLSetting.detailPageTop,
        collocate: selectValue.placementOrder
          ? selectValue.placementOrder
          : "POD",
        addOptionImageConfig: {
          style: selectValue.style ? selectValue.style : "22",
          fontSize: selectValue.fontSize ? selectValue.fontSize : "SMALL",
        },
        addContent: {
          bottomHtml: bottomModel ? bottomModel : "",
          topHtml: topModel ? topModel : "",
          useBottomContent: isUseHTMLSetting.bottom,
          useTopContent: isUseHTMLSetting.top,
        },
      },
    });
    if (setDetailRes === "적용 완료") {
      closeModal();
      queryClient.invalidateQueries("detailPageConfig");
    }
  };

  return (
    <>
      <div className="detailSetting">
        <div className="d-flex justify-content-between widthFull gap-5">
          <div className="settingConfigBox">
            <img
              src={img1[0]}
              style={{
                transition: "opacity 1s ease-in-out",
              }}
            />
            <div className="row">
              <div className="settingValueHeader widthFull textCenter">
                <p className="margin0">기본 설정값</p>
              </div>
              <div className="arrangSettingBox">
                <p className="width40 margin0">배치 순서</p>
                <select
                  className="largeSelect"
                  onChange={SelectselectValue}
                  value={selectValue.placementOrder}
                  name="placementOrder"
                >
                  <option value="POD">속성 - 옵션이미지 - 상세페이지</option>
                  <option value="PDO">속성 - 상세페이지 - 옵션이미지</option>
                  <option value="OPD">옵션이미지 - 속성 - 상세페이지</option>
                  <option value="ODP">옵션이미지 - 상세페이지 - 속성</option>
                </select>
              </div>
              <div className="arrangSettingBox">
                <p className="width40 margin0 oneLine">옵션이미지</p>
                <div className="d-flex widthHalf align-items-center gap-4 justify-content-between">
                  <p className="pl2 margin0 oneLine">배치</p>
                  <select
                    className="smallSelect"
                    onChange={SelectselectValue}
                    value={selectValue.style}
                    name="style"
                  >
                    <option value="22"> 2 x 2 </option>
                    {/* <option value="33"> 3 x 3</option> */}
                  </select>
                </div>
                <div className="d-flex widthHalf align-items-center gap-4 justify-content-between">
                  <p className="pl2 margin0 oneLine">크기</p>
                  <select
                    className="smallSelect"
                    onChange={SelectselectValue}
                    name="fontSize"
                    value={selectValue.fontSize}
                  >
                    <option value="SMALL">작게</option>
                    <option value="MEDIUM">보통</option>
                    <option value="LARGE">크게</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="settingConfigBox">
            <img src={img2[0]} />
            <div className="widthFull">
              <div className="settingValueHeader widthFull textCenter">
                <p className="margin0">추가 설정값</p>
              </div>
              <div className="arrangSettingBox widthHalf">
                <p className="width40 margin0">상품속성</p>
                <select
                  className="smallSelect"
                  name="detailPageTop"
                  onChange={selectUseHTMLSetting}
                  value={isUseHTMLSetting.detailPageTop?.toString()}
                >
                  <option value="true">설정</option>
                  <option value="false">설정안함</option>
                </select>
              </div>
              <div className="d-flex mt-3">
                <div className="arrangSettingBox widthHalf">
                  <p className="width40 margin0">상단내용</p>
                  <select
                    className="smallSelect oneLine"
                    name="top"
                    value={isUseHTMLSetting.top?.toString()}
                    onChange={selectUseHTMLSetting}
                  >
                    <option value="true">설정</option>
                    <option value="false">설정안함</option>
                  </select>
                </div>
                <div className="arrangSettingBox widthHalf">
                  <p className="width40 margin0 oneLine">하단내용</p>
                  <select
                    name="bottom"
                    className="smallSelect"
                    onChange={selectUseHTMLSetting}
                    value={isUseHTMLSetting.bottom?.toString()}
                  >
                    <option value="true">설정</option>
                    <option value="false">설정안함</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="detailSettingButtonBox">
          <button onClick={clickReset} className="cancel-btn">
            초기화
          </button>
          <button
            onClick={comfirmSetDetailPage}
            className="cancel-btn save-btn"
          >
            저장
          </button>
        </div>
      </div>
    </>
  );
};
