import backIcon from "@assets/images/payment/backIcon.svg";

interface Props {
  setComponent: React.Dispatch<React.SetStateAction<"sub" | "main">>;
}

export const StarterSubMobile = ({ setComponent }: Props) => {
  const getBack = () => {
    setComponent("main");
  };

  return (
    <div className="mobile-contents-sub-container d-flex align-items-start gap-3">
      <button className="madebutton" onClick={getBack}>
        <img src={backIcon} />
      </button>
      <div className="d-flex flex-col gap-1">
        <div className="d-flex widthfull justify-content-between">
          <p className="weight500 text8 navy margin0">
            # 오픈마켓 출하지/반품지, 상/하단이미지, 태그API 기본 셋팅 및
            마켓연동
          </p>
        </div>
        <div className="d-flex widthfull justify-content-between">
          <p className="weight500 text8 navy margin0"># 단일수집 시현</p>
          <p className="weight500 text8 navy margin0"># 대량수집 시현</p>
          <p className="weight500 text8 navy margin0"># 가격설정 시현</p>
          <p className="weight500 text8 navy margin0"># 태그설정 시현</p>
        </div>
        <div className="d-flex widthfull justify-content-between">
          <p className="weight500 text8 navy margin0">
            # 카테고리 오토매핑 설명
          </p>
          <p className="weight500 text8 navy margin0">
            # 단일/일괄 상품 판매등록 설명
          </p>
          <p className="weight500 text8 navy margin0"># 질의응답</p>
        </div>
        <div className="d-flex widthfull justify-content-between">
          <p className="weight500 text8 none-select margin0"># 이미지번역</p>
          <p className="weight500 text8 none-select margin0">
            # 상품명/옵션설정
          </p>
          <p className="weight500 text8 none-select margin0"># 메모/그룹기능</p>
          <p className="weight500 text8 none-select margin0"># 금지/치환기능</p>
        </div>
        <div className="d-flex widthfull justify-content-between">
          <p className="weight500 text8 none-select margin0"># 유입수관리</p>
          <p className="weight500 text8 none-select margin0"># 상품관리</p>
          <p className="weight500 text8 none-select margin0"># 판매상품수정</p>
          <p className="weight500 text8 none-select margin0"># 주문관리</p>
          <p className="weight500 text8 none-select margin0"># 송장전송</p>
        </div>
        <div className="d-flex widthfull justify-content-start gap-3">
          <p className="weight500 text8 none-select margin0"># 수익계산기</p>
          <p className="weight500 text8 none-select margin0"># 주문엑셀추출</p>
          <p className="weight500 text8 none-select margin0"># CS관리</p>
        </div>
        <p className="weight500 text6 none-select margin0 mt-2">
          # 이미지번역 ~ # CS관리 는 올땀케어의 심화 강의에서 수강 부탁드립니다.
        </p>
      </div>
    </div>
  );
};
