import { useEffect, useState } from "react";
import NavBar from "../../components/NavBar/NavBar";
import { NavManageOrder } from "../../components/NavBar/component/NavManageOrder";
import { OrderManageCS } from "./OrderManageCS/OrderManageCS";
import { OrderManageOrder } from "./OrderManageOrder/OrderManageOrder";
import { useNavigate } from "react-router-dom";
import chromeAPI from "../../utils/functions/chromeAPI";
import "./OrderManage.css";

export const OrderManage = () => {
  const [navState, setNavState] = useState<"order" | "cs">("order");
  const navigate = useNavigate();
  const checkExtension = async () => {
    const res = await chromeAPI.exCheck();

    return res.data;
  };

  useEffect(() => {
    checkExtension()
      .then((res) => {})
      .catch(() => {
        navigate("/home");
      });
  }, []);

  return (
    <div className="orderManage">
      <NavBar>
        <NavManageOrder setNavState={setNavState} navState={navState} />
      </NavBar>
      {TAB_INDEX[navState as "cs" | "order"]}
    </div>
  );
};

const TAB_INDEX = {
  order: <OrderManageOrder />,
  cs: <OrderManageCS />,
};
