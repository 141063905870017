import { useEffect, useState } from "react";
import NavBar from "../../components/NavBar/NavBar";
import { NavCollect } from "../../components/NavBar/component/NavCollect";
import { CollectTaobao } from "./CollectTaobao/CollectTaobao";
import { CollectWay } from "../../interface/collectInterface";
import { Collect1688 } from "./Collect1688/Collect1688";
import { CollectVvic } from "./CollectVvic/CollectVvic";
import { CollectAli } from "./CollectAli/CollectAli";
import "./Collect.css";
import { CollectCostco } from "./CollectCostco/CollectCostco";
import chromeAPI from "../../utils/functions/chromeAPI";
import { useNavigate } from "react-router-dom";

export const Collect = () => {
  const navigate = useNavigate();
  const checkExtension = async () => {
    const res = await chromeAPI.exCheck();

    return res.data;
  };

  useEffect(() => {
    checkExtension()
      .then((res) => {})
      .catch(() => {
        navigate("/home");
      });
  }, []);

  const [tabValue, setTabValue] = useState<
    "taobao" | "ali" | "1688" | "vvic" | "costco"
  >("taobao");

  const [collectWay, setCollectWay] = useState<CollectWay>("multi");

  const TabIndex = {
    1688: (
      <Collect1688
        collectWay={collectWay}
        setCollectWay={setCollectWay}
        COLLECT_VALUE_DEFAULT={COLLECT_VALUE_DEFAULT}
      />
    ),
    taobao: (
      <CollectTaobao
        collectWay={collectWay}
        setCollectWay={setCollectWay}
        COLLECT_VALUE_DEFAULT={COLLECT_VALUE_DEFAULT}
      />
    ),
    vvic: (
      <CollectVvic
        collectWay={collectWay}
        setCollectWay={setCollectWay}
        COLLECT_VALUE_DEFAULT={COLLECT_VALUE_DEFAULT}
      />
    ),
    ali: (
      <CollectAli
        collectWay={collectWay}
        setCollectWay={setCollectWay}
        COLLECT_VALUE_DEFAULT={COLLECT_VALUE_DEFAULT}
      />
    ),
    costco: <CollectCostco COLLECT_VALUE_DEFAULT={COLLECT_VALUE_DEFAULT} />,
  };

  return (
    <div>
      <NavBar>
        <NavCollect navState={tabValue} setNavState={setTabValue} />
      </NavBar>
      {TabIndex[tabValue]}
    </div>
  );
};

export interface PageData {
  currentPage: number;
  pageSize: number;
  totalCount: number;
  totalPage: number;
}

const COLLECT_VALUE_DEFAULT = {
  duplicate: "SKIP",
  endpage: 1,
  groupName: "",
  imageIndex: 1,
  memo: "",
  startPage: 1,
  translate: "ko",
  url: "",
  usingSort: false,
};
