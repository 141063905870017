import { useEffect, useRef, useState } from "react";
import allttama from "../../../assets/images/login/a.svg";
import allttamwhite from "../../../assets/images/login/allttamWhite.svg";
import naverLogin from "@assets/images/login/simple-icons_naver.svg";
import naverLoginWhite from "@assets/images/login/simple-icons_naver (1).svg";
import { useTooltip } from "../../../hooks/useTooltip";

interface Props {
  setIsAllttamSignup: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SignupDefault = ({ setIsAllttamSignup }: Props) => {
  const [isTooltipVisible, handleMouseEnter, handleMouseLeave] = useTooltip();
  const [isNaverHover, handleNaverEnter, handleNaverLeave] = useTooltip();
  const naverRef = useRef<any>();
  const { naver } = window as any;
  const NAVER_CLIENT_ID = process.env.REACT_APP_NAVER_CLIENT_ID;
  const NAVER_CALLBACK_URL = process.env.REACT_APP_CALLBACK_URL;

  const initializeNaverLogin = () => {
    const naverLogin = new naver.LoginWithNaverId({
      clientId: NAVER_CLIENT_ID,
      callbackUrl: NAVER_CALLBACK_URL,
      isPopup: false,
      loginButton: { color: "green", type: 3, height: 60 },
      callbackHandle: true,
    });
    naverLogin.init();
  };

  const userAccessToken = () => {
    window.location.href.includes("access_token") && getToken();
  };

  const getToken = () => {
    const token = window.location.href.split("=")[1].split("&")[0];
  };

  useEffect(() => {
    initializeNaverLogin();
    userAccessToken();
  }, []);

  return (
    <>
      <button
        onClick={() => {
          setIsAllttamSignup((prev) => !prev);
        }}
        onMouseEnter={handleMouseEnter as () => void}
        onMouseLeave={handleMouseLeave as () => void}
        className="allttamLoginButton widthFull madebutton login-button d-flex justify-content-center gap-2 align-items-center"
      >
        <img src={isTooltipVisible ? allttamwhite : allttama} />
        <p className="margin0 oneLine">올땀 아이디 회원가입</p>
      </button>
      <div id="naverIdLogin" ref={naverRef}></div>
      <button
        onClick={() => {
          naverRef.current.children[0].click();
        }}
        onMouseEnter={handleNaverEnter as () => void}
        onMouseLeave={handleNaverLeave as () => void}
        className="naverLoginButton widthFull madebutton login-button d-flex justify-content-center gap-2 align-items-center"
      >
        <img src={isNaverHover ? naverLoginWhite : naverLogin} />
        <p className="margin0 oneLine">네이버 아이디로 회원가입</p>
      </button>
    </>
  );
};
