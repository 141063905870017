import useFetch from "@hooks/useFetch";
import { ModalBg } from "@components/ModalLayout/ModalBg";
import { useTooltip } from "@hooks/useTooltip";
import { HomeToolTip } from "../Tooltip/HomeTooltip";
import { copyFunction } from "@utils/functions/copyFunction";
import { ModalInnerTop } from "@components/ModalLayout/ModalInnerTop";

interface Props {
  closeModal: () => void;
  workNumber: number;
}

export const CollectDetailLogModal = ({ closeModal, workNumber }: Props) => {
  const [isTooltipVisible, handleMouseEnter, handleMouseLeave] = useTooltip();
  const [detailData] = useFetch(
    `detail${workNumber}`,
    `/Log/GetDetailLog?type=Collect&workNumber=${workNumber}`
  );

  return (
    <ModalBg onClick={closeModal}>
      <ModalInnerTop>
        <>
          <div className="modal-header modal-sticky">
            <h5 className="modal-title d-flex align-items-center">수집기록</h5>
            <button
              onClick={closeModal}
              type="button"
              className="btn-close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="collectLogDetailInfo">
              <p className="collectLogDetailInfoTitle">작업번호</p>
              <p>{detailData?.workNumber}</p>
            </div>
            <div className="collectLogDetailInfo">
              <p className="collectLogDetailInfoTitle">수집마켓</p>
              <p>{detailData?.site}</p>
            </div>
            <div className="collectLogDetailInfo">
              <p className="collectLogDetailInfoTitle">상품그룹명</p>
              <p>{detailData?.groupName ? detailData?.groupName : "X"}</p>
            </div>
            <div className="collectLogDetailInfo">
              <p className="collectLogDetailInfoTitle">메모</p>
              <p>{detailData?.collectLog?.memo}</p>
            </div>
            <div className="collectLogDetailInfo">
              <p className="collectLogDetailInfoTitle">수집상태</p>
              <p>{detailData?.status}</p>
            </div>
            {/* <div className="collectLogDetailInfo">
              <p className="collectLogDetailInfoTitle">수집 개수</p>
              <p>asdasd</p>
            </div> */}
            <div className="collectLogDetailInfo">
              <p className="collectLogDetailInfoTitle">수집 (성공 / 요청)</p>
              <p>
                {detailData?.collectLog?.successProduct} /{" "}
                {detailData?.collectLog?.totalProduct}
              </p>
            </div>
            {/* <div className="collectLogDetailInfo">
              <p className="collectLogDetailInfoTitle">페이지 (요청 / 성공)</p>
              <p>
                {detailData?.collectLog?.successPage} /{" "}
                {detailData?.collectLog?.totalPage}
              </p>
            </div> */}
            <div className="collectLogDetailInfo">
              <p className="collectLogDetailInfoTitle">시작일</p>
              <p>
                {detailData?.collectLog?.startDate
                  .split("T")[0]
                  .replaceAll("-", ". ")}
              </p>
            </div>
            <div className="collectLogDetailInfo">
              <p className="collectLogDetailInfoTitle">완료일</p>
              <p>
                {detailData?.collectLog?.endDate
                  .split("T")[0]
                  .replaceAll("-", ". ")}
              </p>
            </div>
            <div className="collectLogDetailInfo tooltipRel">
              {isTooltipVisible && (
                <div className="tooltipCenter">
                  <HomeToolTip>복사하기</HomeToolTip>
                </div>
              )}
              <p className="collectLogDetailInfoTitle">수집키워드(URL)</p>
              <p
                onClick={() => copyFunction(detailData?.collectLog?.keyword)}
                className="eclips collectLogDetailInfoContent oneLine pointer"
                onMouseEnter={handleMouseEnter as () => void}
                onMouseLeave={handleMouseLeave as () => void}
              >
                {detailData?.collectLog?.keyword}
              </p>
            </div>

            {detailData?.collectLog &&
              Object?.keys(detailData?.collectLog?.failReason)?.length > 0 && (
                <>
                  <hr className="hr2" />
                  <div>
                    <p className="textStart textRed">상품수집 실패이유</p>
                    <div>
                      {Object.entries(
                        detailData?.collectLog?.failReason as Object
                      ).map((item, idx) => (
                        <div key={idx} className="d-flex">
                          <p>{item[0]} : </p>
                          <p>{item[1]} 건</p>
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              )}
          </div>
        </>
      </ModalInnerTop>
    </ModalBg>
  );
};
