import { ReaminingIcon } from "../svg/RemainingDayIcon";

export const UserInfoRemainingDay = ({ remainDay }: { remainDay: number }) => {
  return (
    <div className="col-6 col-md-3">
      <div className="calendar">
        <div className="calendar-inner d-flex justify-content-end">
          <ReaminingIcon />
        </div>
        <h4 className="textEnd oneLine">{remainDay ? remainDay : 0} 일</h4>
        <p className="textEnd oneLine">남은 일 수</p>
      </div>
    </div>
  );
};
