import useFetch from "@hooks/useFetch";
import s1 from "@assets/images/payment/s1.png";
import s2 from "@assets/images/payment/s2.png";
import s3 from "@assets/images/payment/s3.png";
import free from "@assets/images/payment/free.png";
import best from "@assets/images/payment/best.png";
import hot from "@assets/images/payment/hot.png";
import { PlanType } from "@interface/payment";
import { PlanV2 } from "../PaymentPlanSubscribe/compoenent/PlanV2";

export const PlanTab = () => {
  const [plan] = useFetch("plan", "/Plan/GetAllddamPlanList");
  return (
    <>
      <div className="d-flex gap-5 mt-4">
        {plan
          ?.filter((item: PlanType) => item.type === "PLAN")
          .map((item: PlanType, idx: number) => (
            <PlanV2 key={idx} item={item} plan={PLAN_INDEX[idx]} />
          ))}
      </div>
    </>
  );
};

const PLAN_INDEX = [
  {
    ribon: best,
    planName: "S1",
    img: s1,
    speedText: (
      <p className="text12 grey margin0 weight500 oneLine">기본 수집 속도</p>
    ),
    functionText: (
      <p className="text12 grey margin0 weight500 oneLine">
        올땀 ALL 기능 제공
        <br />
        <span className="text8 weight500 oneLine grey margin0">
          (단, 언어자동번역/이미지번역기 코인 사용)
        </span>
      </p>
    ),
  },
  {
    planName: "S2",
    img: s2,
    speedText: (
      <p className="text12 grey margin0 weight500 oneLine">
        {" "}
        S1 보다 <span className="textGreen">2~3 배</span> 빠른 수집 속도
      </p>
    ),
    functionText: (
      <p className="text12 grey margin0 weight500 oneLine">
        {" "}
        S1 보다 올땀 ALL 기능 제공
        <br />
        <span className="text8 weight500 oneLine grey margin0">
          (단, 언어자동번역/이미지번역기 코인 사용)
        </span>
      </p>
    ),
  },
  {
    planName: "S3",
    img: s3,
    speedText: (
      <p className="text12 grey margin0 weight500 oneLine">
        S1 보다 <span className="textGreen">4~5 배</span> 빠른 수집 속도
      </p>
    ),
    functionText: (
      <p className="text12 grey margin0 weight500 oneLine">
        올땀 ALL 기능 제공
        <br />
        <span className="text8 weight500 oneLine grey margin0">
          (단, 언어자동번역/이미지번역기 코인 사용)
        </span>
      </p>
    ),
  },
  {
    ribon: hot,
    planName: "Unlimited",
    img: free,
    speedText: (
      <p className="text12 grey margin0 weight500 oneLine">기본 수집 속도</p>
    ),
    functionText: (
      <p className="text12 grey margin0 weight500 oneLine">
        올땀 ALL 기능 제공{" "}
        <span className="text8 weight500 oneLine grey margin0">(무제한)</span>
      </p>
    ),
  },
];
