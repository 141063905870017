import { Notice as INotice } from "@interface/notice";
import { NoticeItem } from "./NoticeItem";
import styles from "./NoticeSearchBoard.module.css";

interface Props {
  noticeList: INotice[];
  onDelete?: (id: string) => void;
  isAdmin?: boolean;
}

export const NoticeTable = ({
  noticeList,
  isAdmin = false,
  onDelete,
}: Props) => {
  return (
    <table className="allttam-table">
      <thead>
        <tr className="">
          <th className="width5">No.</th>
          <th className="width15">카테고리</th>
          <th className="width40">제목</th>
          <th className="width10">작성자</th>
          <th className="width10">작성일자</th>
          {isAdmin && <th className="width20">기능</th>}
        </tr>
      </thead>
      <tbody>
        {noticeList?.map((notce) => (
          <NoticeItem notice={notce} onDelete={onDelete} isAdmin={isAdmin} />
        ))}
      </tbody>
    </table>
  );
};
