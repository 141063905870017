interface Props {
  className?: string;
}

export const ViewWayDetailIcon = ({ className }: Props) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <path data-name="사각형 3887" fill="none" d="M0 0h30v30H0z" />
      <g data-name="그룹 11039" transform="translate(-1669 -570.303)">
        <rect
          data-name="사각형 3888"
          width="11"
          height="2"
          rx="1"
          transform="translate(1683 580)"
          fill="#a7b0c7"
        />
        <rect
          data-name="사각형 3893"
          width="11"
          height="2"
          rx="1"
          transform="translate(1683 589)"
          fill="#a7b0c7"
        />
        <path
          data-name="패스 10531"
          d="M5.585 0H2.021A1.959 1.959 0 0 0 0 2.124v3.358a1.957 1.957 0 0 0 2.021 2.124h3.564a1.957 1.957 0 0 0 2.021-2.124V2.124A1.957 1.957 0 0 0 5.585 0z"
          transform="translate(1674 577)"
          fill="#a7b0c7"
          fillRule="evenodd"
        />
        <path
          data-name="패스 10532"
          d="M5.585 0H2.021A1.959 1.959 0 0 0 0 2.124v3.358a1.957 1.957 0 0 0 2.021 2.124h3.564a1.957 1.957 0 0 0 2.021-2.124V2.124A1.957 1.957 0 0 0 5.585 0z"
          transform="translate(1674 586)"
          fill="#a7b0c7"
          fillRule="evenodd"
        />
      </g>
    </svg>
  );
};
