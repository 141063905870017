import { GreenBigButton } from "@components/Button/allttamButton/GreenBigButton";
import { WhiteBigButton } from "@components/Button/allttamButton/WhiteBigButton";
import { ModalBg } from "@components/ModalLayout/ModalBg";
import { ModalBody } from "@components/ModalLayout/ModalBody";
import { ModalHead } from "@components/ModalLayout/ModalHead";
import { ModalInnerTop } from "@components/ModalLayout/ModalInnerTop";

interface Props {
  closeModal: () => void;
}

export const FreeModal = ({ closeModal }: Props) => {
  return (
    <ModalBg onClick={closeModal}>
      <ModalInnerTop>
        <ModalHead clickClose={closeModal}>알림</ModalHead>
        <ModalBody>
          <p className="navy weight500 text16">
            올땀 네이버 카페에서 [무료사용 신청글] 을 작성해주세요.
            <br />
            관리자가 확인 후, [S1 플랜 체험] 을 제공해드립니다.
          </p>
          <div className="d-flex justify-content-center gap-3 mt-4">
            <WhiteBigButton onClick={closeModal}>닫기</WhiteBigButton>
            <GreenBigButton
              onClick={() => {
                window.open("https://cafe.naver.com/mandeunydle", "_blank");
              }}
            >
              신청하기
            </GreenBigButton>
          </div>
        </ModalBody>
      </ModalInnerTop>
    </ModalBg>
  );
};
