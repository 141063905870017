export const TextTranslateIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <g transform="translate(5 5)">
        <path
          data-name="합치기 11"
          d="M4.273 10.256a.807.807 0 0 1-.854-.856.808.808 0 0 1 .854-.855V1.709H1.709a.808.808 0 0 1-.855.854A.807.807 0 0 1 0 1.709V.854A.807.807 0 0 1 .854 0H9.4a.807.807 0 0 1 .854.854v.855a.807.807 0 0 1-.854.854.808.808 0 0 1-.855-.854H5.983v6.838a.808.808 0 0 1 .854.855.807.807 0 0 1-.854.854z"
          transform="translate(4.872 4.872)"
          fill="#fff"
        />
        <rect
          data-name="사각형 3776"
          width="20"
          height="20"
          rx="6"
          stroke="#fff"
          strokeWidth="2px"
          fill="none"
        />
      </g>
      <path data-name="사각형 3775" fill="none" d="M0 0h30v30H0z" />
    </svg>
  );
};
