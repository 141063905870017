import NavBar from "@components/NavBar/NavBar";
import { NavBarDefaultComponent } from "@components/NavBar/component/NavBarDefaultComponent";
import { Popup } from "@components/Popup/Popup";
import useFetch from "@hooks/useFetch";
import { HomeBanner } from "../components/Home/HomeBanner/component/HomeBanner";
import { HomeCollectLog } from "../components/Home/HomeCollectLog/HomeCollectLog";
import { HomeNotice } from "../components/Home/HomeNotice/HomeNotice";
import { HomeProductManage } from "../components/Home/HomeProductManage/HomeProductManage";
import { HomeSalesGraph } from "../components/Home/HomeSalesGraph/HomeSalesGraph";
import { HomeUploadLog } from "../components/Home/HomeUploadLog/HomeUploadLog";
import { HomeUserInfo } from "../components/Home/HomeUserInfo/HomeUserInfo";
import "@components/Home/Home.css";
import "@components/Admin/Admin.css";

export const Home = () => {
  const [popupList] = useFetch(
    "popupList",
    "https://center.allttam.com/api/banner/popup",
    100000
  );

  return (
    <div>
      {popupList?.map((item: Popup) => (
        <Popup
          key={item._id}
          img={item.imgUrl}
          link={item.link}
          name={`allttam-popup_${item._id}`}
        />
      ))}
      <NavBar>
        <NavBarDefaultComponent />
      </NavBar>
      <div className="row">
        <HomeUserInfo />
        <HomeBanner />
      </div>
      <div className="row">
        <div className="col-md-6 col-12 d-flex gap-3">
          <HomeNotice />
          <HomeProductManage />
        </div>
        <HomeSalesGraph />
      </div>
      <div className="row">
        <HomeCollectLog />
        <HomeUploadLog />
      </div>
    </div>
  );
};

interface Popup {
  _id: string;
  imgUrl: string;
  link: string;
}
