export const UserInfoCoin = ({ coin }: { coin: number }) => {
  return (
    <div className="col-12 col-md-6">
      <div className="s1-inner s2-inner d-flex align-items-center justify-content-between">
        <h6>코인</h6>
        <p>{coin ? coin : 0}</p>
      </div>
    </div>
  );
};
