import { VMarkGreen } from "@components/svg/VMarkGreen";
import { Keyword } from "../../../../../interface/uploadinterface";
import { SquarePlusIcon } from "@components/svg/SquarePlusIcon";
import { GlobalTooltip } from "@components/GlobalTooltip/GlobalTooltip";
import { useTooltip } from "@hooks/useTooltip";
import { QuestionMarkIcon } from "@components/svg/QuestionMarkIcon";

interface Props {
  setSelectedTag: React.Dispatch<React.SetStateAction<string[]>>;
  sortData: Keyword[];
  selectedTag: string[];
}

export const TagSettingSelectTopButton = ({
  setSelectedTag,
  sortData,
  selectedTag,
}: Props) => {
  const clickSelectTop5 = () => {
    const topArr = [
      ...selectedTag,
      ...sortData.slice(0, 5).map((item) => item.relKeyword),
    ]?.reduce((acc: string[], cur: string) => {
      if (!acc.find((item: string) => item === cur)) {
        acc.push(cur);
      }
      return acc;
    }, []);
    setSelectedTag(topArr);
  };

  const [isTopTooltip, enterTop, leaveTop] = useTooltip();
  return (
    <div className="row">
      <div className="col-12">
        <div className="d-flex align-items-center gap-3 relative">
          <GlobalTooltip
            isVisible={isTopTooltip as boolean}
            style={{ right: "0", top: "-20px" }}
          >
            검색 키워드 결과의 상위에 위치한 5개 키워드를 태그로 추가합니다.
          </GlobalTooltip>
          <button
            className="madebutton mob-key1"
            onClick={clickSelectTop5}
            disabled={sortData === undefined}
          >
            <SquarePlusIcon />
          </button>
          <p className="tag-modal-top-text margin0">TOP5 선택</p>
          <QuestionMarkIcon
            onMouseEnter={enterTop as () => void}
            onMouseLeave={leaveTop as () => void}
          />
        </div>
      </div>
    </div>
  );
};
