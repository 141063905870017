import { useEffect, useState } from "react";
import { StarterMainContent } from "./StarterMainContents";
import { StarterSubContents } from "./StarterSubContents";

interface Props {
  isOffline: boolean;
}

export const StarterContents = ({ isOffline }: Props) => {
  const [component, setComponent] = useState<"main" | "sub">("main");

  useEffect(() => {
    setComponent("main");
  }, [isOffline]);

  return (
    <div className="width70 starter-contents mt-5 d-flex flex-col align-items-center">
      {component === "main" ? (
        <StarterMainContent setComponent={setComponent} isOffline={isOffline} />
      ) : (
        <StarterSubContents setComponent={setComponent} />
      )}
    </div>
  );
};
