import { NavPaymentLog } from "../../components/NavBar/component/NavPaymentLog";
import NavBar from "../../components/NavBar/NavBar";
import useFetch from "../../hooks/useFetch";
import { PayLogItem } from "./PayLogItem/PayLogItem";
import useToggle from "../../hooks/useToggle";
import { ImgTranslateModal } from "../../components/Home/modal/ImgTranslateModal";

export const PaymentLog = () => {
  const { state: isImgLogModal, handleToggle: handleImgLogModal } = useToggle();
  const { state: isTextLogModal, handleToggle: handleTextLogModal } =
    useToggle();

  const [payLog] = useFetch("payLog", "/Ipay/returnPay");

  return (
    <div>
      <NavBar>
        <NavPaymentLog />
      </NavBar>
      {/* <div className="row mt-5">
        <div className="col-12">
          <div className="payment-detail payment-detail1">
            <SearchingBoardPeriodComponent date={date} setDate={setDate} />
            <div className="state-selection">
              <select
                className="form-select"
                aria-label="Default select example"
              >
                <option>상태 선택</option>
                <option value="1">상태 선택</option>
                <option value="2">상태 선택</option>
                <option value="3">상태 선택</option>
              </select>
            </div>
            <div className="search-btn">
              <button className="cancel-btn save-btn">
                <SearchingIcon />
                검색
              </button>
            </div>
          </div>
        </div>
      </div> */}
      <div className="row total-table-col">
        <div className="col-12">
          <div className="heading-two heading-three heading-five heading-five1">
            <div className="heading-two-inner">
              <h6>전체 {payLog?.length.toLocaleString("KR")}</h6>
              <div className="translateLogButtonBox gap-4">
                <button
                  onClick={handleImgLogModal}
                  className="madebutton textYellow imgLogButton"
                >
                  올땀 스튜디오 내역
                </button>
                <button
                  onClick={handleTextLogModal}
                  className="madebutton textLogButton"
                >
                  텍스트 수집 내역
                </button>
              </div>
            </div>
            <table width="100%" align="center" className="payLogTable">
              <thead className="head-one">
                <tr>
                  <th>상품명</th>
                  <th>결제카드</th>
                  <th>상품가격</th>
                  <th>휴대폰 번호</th>
                  <th>상태</th>
                  <th>완료일시</th>
                </tr>
              </thead>
              <tbody className="body-one">
                {payLog?.map((log: PayLogType) => (
                  <PayLogItem key={log.id} log={log} />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {isImgLogModal && (
        <ImgTranslateModal closeModal={handleImgLogModal} type="IT" />
      )}
      {isTextLogModal && (
        <ImgTranslateModal closeModal={handleTextLogModal} type="CT" />
      )}
    </div>
  );
};

export interface PayLogType {
  approvalAmt: string;
  approvalNo: string;
  buyerName: string;
  buyerPhoneNo: string;
  cardAcquireCode: string;
  cardAcquireName: string | null;
  cardIssueCode: string;
  cardIssueName: string;
  cardNo: string | null;
  cardQuota: string;
  createDate: string;
  goodsAmt: string;
  goodsName: string;
  id: string;
  payMethod: string;
  payMethodName: string | null;
  pgAppDate: string;
  pgAppTime: string;
  pgResultCode: string;
  pgResultMsg: string;
  pgTid: string;
  stateCd: string;
  status: string;
  statusName: string;
  tid: string;
  userId: string | null;
  userName: string | null;
  userPhoneNo: string | null;
}
