import { useState } from "react";
import { CollectValue, CollectWay } from "@interface/collectInterface";
import { collect } from "@utils/functions/postApi";
import { useAlert } from "@hooks/useAlert";
import chromeAPI from "@utils/functions/chromeAPI.js";
import { PageData } from "../Collect";
import { Spinner } from "@components/Spinner/Spinner";
import { VvicCollectItem } from "./component/VvicCollectItem";
import { VvicSearchingBoard } from "./component/VvicSearchingBoard";
import { MultiSelectedCollect } from "@components/MultiSelectedCollect/MultiSelectedCollect";
import { CollectMultiSubMenu } from "../CollectTaobao/component/TaobaoMultiSubMenu";
import { AlertOnlyClose } from "@components/Alert/AlertOnlyClose";
import { useInfoWindow } from "@hooks/useInfoWindow";

interface Props {
  COLLECT_VALUE_DEFAULT: CollectValue;
  collectWay: CollectWay;
  setCollectWay: React.Dispatch<React.SetStateAction<CollectWay>>;
}

export const CollectVvic = ({
  COLLECT_VALUE_DEFAULT,
  collectWay,
  setCollectWay,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const { isAlert, handleAlert, alertMessage } = useAlert();
  const [collectValue, setCollectValue] = useState<CollectValue>(
    COLLECT_VALUE_DEFAULT
  );
  const [selectedCollectItem, setSelectedCollectItem] = useState<string[]>([]);
  const [pageData, setPageData] = useState<PageData>();
  const [itemList, setItemList] = useState<any>([]);

  const openWindow = useInfoWindow();

  const inputCollectValue = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setCollectValue({
      ...collectValue,
      [name]: value,
    });
  };

  const clickCollect = async (page: number) => {
    if (collectValue.url === "") {
      return handleAlert("검색 url 혹은 키워드를 입력해주세요.");
    }
    if (collectValue.groupName === "") {
      return handleAlert("상품 그룹명을 입력해주세요.");
    }
    if (collectWay === "one") {
      try {
        const collectRes = await collect.collectVvicUsingApi(collectValue);
        if (collectRes.result) {
          handleAlert(collectRes.result);
        }
      } catch (e: any) {
        handleAlert(e.response.data.result);
      }
    } else {
      //대량수집 기능 확인 후 수정 필요!!
      setIsLoading(true);
      const collectRes = await chromeAPI.VvicItemSearch(collectValue.url, page);
      if (collectRes.responseMsg.data.search_page.recordList.length !== 0) {
        setItemList(collectRes.responseMsg.data.search_page.recordList);
        setPageData(collectRes.responseMsg.data.search_page);
        setIsLoading(false);
      } else {
        setItemList([]);
        setPageData(undefined);
        openWindow("검색결과가 없습니다.");
        setIsLoading(false);
      }
    }
  };
  const clickCollectMulti = async () => {
    try {
      if (collectValue.groupName !== "") {
        const collectRes = await collect.collectVvicUsingExtension({
          duplicate: collectValue.duplicate,
          groupName: collectValue.groupName,
          imageIndex: collectValue.imageIndex,
          memo: collectValue.memo,
          translate: collectValue.translate,
          url: collectValue.url,
          itemIds: selectedCollectItem.map((item) => item.toString()),
        });
        handleAlert(collectRes.result);
        resetSelect();
      } else {
        handleAlert("상품 그룹명을 입력해주세요.");
      }
    } catch (e: any) {
      handleAlert(e.response.data.result);
    }
  };

  const clickNextPage = async () => {
    clickCollect(pageData?.currentPage! + 1);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const clickPrevPage = async () => {
    clickCollect(pageData?.currentPage! - 1);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const clickPage = async (e: React.MouseEvent<HTMLButtonElement>) => {
    clickCollect(parseInt(e.currentTarget.value));
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const resetSelect = () => {
    setSelectedCollectItem([]);
  };
  return (
    <>
      <VvicSearchingBoard
        inputCollectValue={inputCollectValue}
        collectValue={collectValue}
        setCollectWay={setCollectWay}
        collectWay={collectWay}
        clickCollect={clickCollect}
        setCollectValue={setCollectValue}
      />
      {isAlert && (
        <AlertOnlyClose
          message={alertMessage}
          closeAlert={handleAlert as () => void}
        />
      )}
      {isLoading && (
        <div className="spinnerContainer">
          <Spinner />
        </div>
      )}
      {!isLoading && collectWay === "multi" && itemList.length > 0 && (
        <>
          <MultiSelectedCollect
            clickPage={clickPage}
            totalPages={(pageData as any)?.pageCount!}
            currentPage={pageData?.currentPage!}
            clickNextPage={clickNextPage}
            clickPrevPage={clickPrevPage}
            resetSelect={resetSelect}
            clickCollectMulti={clickCollectMulti}
            length={selectedCollectItem.length}
          />
          <CollectMultiSubMenu
            totalData={(pageData as any)?.recordCount}
            itemList={itemList}
            setSelectedCollectItem={setSelectedCollectItem}
            selectedCollectItem={selectedCollectItem}
            site="vvic"
          />
          <div className="row justify-content-center">
            {itemList.map((item: any) => (
              <VvicCollectItem
                key={item.vid}
                item={item}
                selectedCollectItem={selectedCollectItem}
                setSelectedCollectItem={setSelectedCollectItem}
              />
            ))}
          </div>
        </>
      )}
      {isAlert && (
        <AlertOnlyClose
          message={alertMessage}
          closeAlert={handleAlert as () => void}
        />
      )}
    </>
  );
};
