import { Link } from "react-router-dom";
import { UserInfoAccount } from "./components/UserInfoAccount";
import { UserInfoCoin } from "./components/UserInfoCoin";
import { UserInfoCollectProduct } from "./components/UserInfoCollectProduct";
import { UserInfoExpireDate } from "./components/UserInfoExpireDate";
import { UserInfoImageTranslate } from "./components/UserInfoImageTranslate";
import { UserInfoRemainingDay } from "./components/UserInfoRemainingDay";
import { UserInfoSpeed } from "./components/UserInfoSpeed";
import { UserInfoTextTranslate } from "./components/UserInfoTextTranslate";
import Cookies from "js-cookie";
import useFetch from "../../../hooks/useFetch";
import { useGetCoin } from "../../../hooks/useGetCoin";
import useUser from "@hooks/useUser";

export const HomeUserInfo = () => {
  const [userPlanInfo] = useFetch("userPlanInfo", "/Log/GetUserPlanSummary");
  const userInfo = useUser();
  const coin = useGetCoin();
  const [mainpage] = useFetch("main", "/Log/GetMainPageData");

  return (
    <div className="col-md-6 col-12">
      <div className="header-one-inner header-col-1">
        <div className="header-one-inner-1">
          <h6>계정정보</h6>
          <Link to="/payment?tab=starter" className="a-btn">
            구독/충전
          </Link>
        </div>
        <div className="row">
          <UserInfoSpeed planName={userPlanInfo?.planName} />
          <UserInfoCoin coin={coin} />
          <UserInfoAccount account={userInfo?.role} />
          <UserInfoExpireDate expireDate={userPlanInfo?.expireDate} />
        </div>
        <div className="row">
          <UserInfoRemainingDay remainDay={mainpage?.usePeriod} />
          <UserInfoTextTranslate />
          <UserInfoImageTranslate />
          <UserInfoCollectProduct
            collectProduct={userPlanInfo?.collectionCount}
          />
        </div>
      </div>
    </div>
  );
};
