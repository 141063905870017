interface Props {
  salePrice: { type: string; value: number };
  inputSalePrice: () => void;
}

export const EditSalePriceComponent = ({
  salePrice,
  inputSalePrice,
}: Props) => {
  return (
    <div className="d-flex align-items-center gap-3 product-edit-price-box">
      <p className="margin0 product-edit-price-box-text oneLine">
        등록된 판매가에서
      </p>
      <div className="state-selection">
        <select
          onChange={inputSalePrice}
          name="type"
          className="form-select"
          defaultValue=""
          value={salePrice.type}
        >
          {CHANGE_PRICE_INDEX.map((item) => (
            <option key={item.value} value={item.value}>
              {item.text}
            </option>
          ))}
        </select>
      </div>
      <div className="">
        <input
          onWheel={(e) => {
            e.currentTarget.blur();
          }}
          onChange={inputSalePrice}
          value={salePrice.value}
          name="value"
          type="number"
          className="form-control typeNumber"
          id="salePrice"
          placeholder="가격을 입력하세요"
        />
      </div>
      <p className="margin0 product-edit-price-box-text oneLine">
        = 수정된 판매가
      </p>
    </div>
  );
};

const CHANGE_PRICE_INDEX = [
  { value: "", text: "변경 타입" },
  { value: "M", text: "곱 (*)" },
  { value: "A", text: "합 (+)" },
  { value: "S", text: "차 (-)" },
  { value: "R", text: "비 (%)" },
];
