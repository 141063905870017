import { useNavigate } from "react-router-dom";
import { IndexNav } from "./LandingNav/IndexNav";
import { StarterTab } from "../Payment/Tab/StarterTab";
import { Footer } from "@components/Footer/Footer";
import { StarterMobile } from "../Payment/Stater/StarterMobile";
import { useEffect, useMemo, useState } from "react";

export const Price = () => {
  const navigate = useNavigate();

  const handleNavClick = (scroll: string) => {
    navigate(`/?scroll=${scroll}`);
  };

  const handleNavButtonClick = (ref: any) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 900);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <IndexNav
        handleNavClick={handleNavClick}
        isAtTop={false}
        handleNavButtonClick={handleNavButtonClick}
      />
      <div className="landing-starter flex-col">
        {window.innerWidth > 900 ? (
          <div className="starter-margin-top widthFull d-flex flex-col align-items-center">
            <StarterTab />
          </div>
        ) : (
          <StarterMobile />
        )}
        <Footer />
      </div>
    </>
  );
};
