import { useState } from "react";
import { CollectSearchingBoard } from "@components/Collect/CollectSearchingBoard/CollectSerchingBorad";
import { CollectValue, CollectWay } from "@interface/collectInterface";
import { collect } from "@utils/functions/postApi";
import { PageData } from "../Collect";
import { CollectListItem } from "@components/Collect/CollectListItem/CollectListItem";
import chromeAPI from "@utils/functions/chromeAPI.js";
import { useAlert } from "@hooks/useAlert";
import { Spinner } from "@components/Spinner/Spinner";
import { CollectMultiSubMenu } from "./component/TaobaoMultiSubMenu";
import { MultiSelectedCollect } from "@components/MultiSelectedCollect/MultiSelectedCollect";
import { AlertOnlyClose } from "@components/Alert/AlertOnlyClose";
import { useInfoWindow } from "@hooks/useInfoWindow";
import axios from "axios";

interface Props {
  COLLECT_VALUE_DEFAULT: CollectValue;
  collectWay: CollectWay;
  setCollectWay: React.Dispatch<React.SetStateAction<CollectWay>>;
}

export const CollectTaobao = ({
  COLLECT_VALUE_DEFAULT,
  collectWay,
  setCollectWay,
}: Props) => {
  const { isAlert, handleAlert, alertMessage } = useAlert();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCollectItem, setSelectedCollectItem] = useState<string[]>([]);
  const [collectValue, setCollectValue] = useState(COLLECT_VALUE_DEFAULT);
  const [pageData, setPageData] = useState<PageData>();
  const [itemList, setItemList] = useState<any>([]);
  const [isTaobaoTimeout, setIsTaobaoTimeout] = useState(false);

  const openWindow = useInfoWindow();

  const clickReset = () => {
    setCollectValue(COLLECT_VALUE_DEFAULT);
  };

  const inputCollectValue = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setCollectValue({
      ...collectValue,
      [name]: value,
    });
  };

  const resetSelect = () => {
    setSelectedCollectItem([]);
  };

  const clickCollectMulti = async () => {
    try {
      if (collectValue.groupName !== "") {
        const collectRes = await collect.collectTaobaoExtension({
          duplicate: collectValue.duplicate,
          groupName: collectValue.groupName,
          imageIndex: collectValue.imageIndex,
          memo: collectValue.memo,
          translate: collectValue.translate,
          url: collectValue.url,
          itemIds: selectedCollectItem,
        });
        if (collectRes.result) {
          handleAlert(collectRes.result);
          clickReset();
        }
      } else {
        handleAlert("상품 그룹명을 입력해주세요.");
      }
    } catch (e: any) {
      handleAlert(e.response.data.result);
    }
  };

  const collectFunction = async (page: number) => {
    if (collectValue.url === "") {
      return handleAlert("검색 url 혹은 키워드를 입력해주세요.");
    }
    if (collectValue.groupName === "") {
      return handleAlert("상품 그룹명을 입력해주세요.");
    }
    if (collectWay === "multi") {
      setIsLoading(true);
      const taobaoLoginRes = await chromeAPI.TaobaoLogin();
      const a = encodeURIComponent(collectValue.url);

      const url = await collect.collectTaobaoItemList(
        taobaoLoginRes.responseMsg.data,
        page,
        a
      );
      setIsTaobaoTimeout(true);
      const collectRes = await chromeAPI.TaobaoCollect(url.result);

      const string = collectRes.responseMsg.data;
      const arr = string.split(")");

      const data = JSON.parse(
        arr
          .slice(0, arr.length - 1)
          .join("")
          .split("(")
          .slice(1)
          .join("")
      );

      if (data.data?.url?.includes("captcha")) {
        openWindow("타오바오 캡챠를 해제해주세요.");
      }

      if (data.data.itemsArray.length) {
        setItemList(data.data.itemsArray);

        setPageData({
          currentPage: parseInt(data.data?.mainInfo?.page),
          totalCount: data.data?.mainInfo?.totalResults,
          totalPage: data.data?.mainInfo?.totalPage,
          pageSize: data.data?.mainInfo?.pageSize,
        });
      }
      setIsLoading(false);
      setTimeout(() => setIsTaobaoTimeout(false), 5000);
    } else {
      try {
        const res = await collect.collectTaobaoApi(collectValue);
        if (res.result) {
          handleAlert(res.result);
        }
      } catch (e: any) {
        handleAlert(e.response.data.result);
      }
    }
  };

  const clickNextPage = async () => {
    collectFunction(pageData?.currentPage! + 1);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const clickPrevPage = async () => {
    collectFunction(pageData?.currentPage! - 1);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const clickPage = async (e: React.MouseEvent<HTMLButtonElement>) => {
    collectFunction(parseInt(e.currentTarget.value));
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div>
      <CollectSearchingBoard
        setCollectValue={setCollectValue}
        clickReset={clickReset}
        collectFunction={collectFunction}
        inputCollectValue={inputCollectValue}
        collectValue={collectValue}
        collectWay={collectWay}
        setCollectWay={setCollectWay}
      />
      {isLoading && (
        <div className="spinnerContainer">
          <Spinner />
        </div>
      )}
      {!isLoading && collectWay === "multi" && itemList?.length > 0 && (
        <>
          <MultiSelectedCollect
            clickPage={clickPage}
            totalPages={pageData?.totalPage!}
            currentPage={pageData?.currentPage!}
            clickNextPage={clickNextPage}
            clickPrevPage={clickPrevPage}
            resetSelect={resetSelect}
            clickCollectMulti={clickCollectMulti}
            length={selectedCollectItem.length}
            isTaobaoTimeout={isTaobaoTimeout}
          />
          <CollectMultiSubMenu
            totalData={pageData?.totalCount!}
            itemList={itemList}
            setSelectedCollectItem={setSelectedCollectItem}
            selectedCollectItem={selectedCollectItem}
            site="taobao"
          />
          <div className="row justify-content-center">
            {itemList.map((item: any) => (
              <CollectListItem
                key={item.item_id}
                item={item}
                selectedCollectItem={selectedCollectItem}
                setSelectedCollectItem={setSelectedCollectItem}
              />
            ))}
          </div>
        </>
      )}
      {isAlert && (
        <AlertOnlyClose
          message={alertMessage}
          closeAlert={handleAlert as () => void}
        />
      )}
    </div>
  );
};
