import autoMappingVideo from "@assets/video/autoMapping.mp4";
import editImg from "@assets/video/editImg.mp4";
import transVideo from "@assets/video/imagetranslate.mp4";
import tagVideo from "@assets/video/tagset.mp4";
import correctImgVideo from "@assets/video/imagemodify.mp4";
import left from "@assets/images/landing/seo/Arrow_Component01.png";
import right from "@assets/images/landing/seo/Arrow_Component02.png";
import { useEffect, useState } from "react";

interface Props {
  componentState: number;
  setComponentState: React.Dispatch<React.SetStateAction<number>>;
}

export const SeoVideoComponent = ({
  componentState,
  setComponentState,
}: Props) => {
  const [videoSrc, setVideoSrc] = useState(transVideo);

  useEffect(() => {
    setVideoSrc(VEDIO_INDEX[componentState as 1 | 2 | 3 | 4 | 5]);
  }, [componentState]);

  // useEffect(() => {
  //   const videoElement = document.querySelector(".seoVideo");

  //   videoElement!.classList.add("fade-out");

  //   setTimeout(() => {
  //     setVideoSrc(VEDIO_INDEX[componentState as 1 | 2 | 3 | 4 | 5]);
  //     videoElement!.classList.remove("fade-out");
  //   }, 1000);
  // }, [componentState]);

  const clickNext = () => {
    if (componentState < 5) {
      setComponentState((prev) => prev + 1);
    } else {
      return;
    }
  };
  const clickPrev = () => {
    if (componentState > 1) {
      setComponentState((prev) => prev - 1);
    } else {
      return;
    }
  };

  return (
    <div className="widthFull seoMainContentLeftSide">
      <div className="widthFull relative seoVideoContainer">
        <div className="videoContainerSeo relative">
          <video
            muted
            autoPlay
            loop
            playsInline
            className="seoVideo"
            src={videoSrc}
          />
          <div className="buttonBox">
            <img onClick={clickPrev} src={left} className="seoButtonArrowImg" />
            <img
              onClick={clickNext}
              src={right}
              className="seoButtonArrowImg"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const VEDIO_INDEX = {
  1: transVideo,
  2: correctImgVideo,
  3: editImg,
  4: tagVideo,
  5: autoMappingVideo,
};
