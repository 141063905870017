import fqa from "@assets/images/helper/fqa.svg";
import useFetch from "@hooks/useFetch";
import { FqaCategory, IndexType } from "@interface/fqa.interface";

interface Props {
  selectComponent: (type: IndexType, id: string) => void;
}

export const Fqa = ({ selectComponent }: Props) => {
  const [frequencyFqa] = useFetch(
    "frequencyFqa",
    "https://center.allttam.com/api/allttam/fqafrequency"
  );

  // const [frequencyFqa] = useFetch(
  //   "frequencyFqa",
  //   "https://center.allttam.com/api/allttam/fqa?categoryId=&keyword="
  // );

  return (
    <div className="">
      <div className="d-flex align-items-center gap-3">
        <img src={fqa} />
        <p className="margin0 text16 weight500 grey">자주 묻는 질문</p>
      </div>
      <div className="fqa-container mt-3">
        {frequencyFqa?.map((item: FqaCategory) => (
          <button
            onClick={() => {
              selectComponent("detail", item._id);
            }}
            key={item._id}
            className="madebutton weight600 text14 grey fqa-button"
          >
            {item.title}
          </button>
        ))}
      </div>
    </div>
  );
};
