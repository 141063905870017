import { ResultItem, SendInvoiceRes } from "@interface/orderManageInterface";

import { CheckBoxInput } from "@components/Input/CheckBoxInput";
import { useInfoWindow } from "@hooks/useInfoWindow";
import useModal from "@hooks/useModal";
import { orderManage } from "@utils/functions/postApi";
import { useCallback, useState } from "react";
import { ORDER_STATUS } from "src/common/const/OrderStatus";
import { SendInvoiceResultModal } from "../../modal/SendInvoiceResultModal";
import { OrderManageDownloadExcelButton } from "../component/OrderManageDownloadExcelButton";
import { SelectTableRow } from "./SelectTableRow";

interface Props {
  selectedOrder: ResultItem[];
  collectRow: (row: number) => void;
  orderList: ResultItem[] | undefined;
  refetchNowValue: () => void;
  searchCount?: number;
  totalCount?: number;
}

const genUpdateData = (selected: ResultItem[], orderList: ResultItem[]) => {
  const selectedId = selected.map((item) => item.idx);
  return orderList.filter((list) => selectedId.includes(list.idx));
};

export const OrderManageSubMenu = ({
  selectedOrder,
  collectRow,
  orderList = [],
  refetchNowValue,
  searchCount,
  totalCount,
}: Props) => {
  const { openModal } = useModal();
  const [isCheckedStatusChange, setIsChecedStatusChange] = useState(false);
  const [changeStatus, setChangeStatus] = useState<string>("");
  const openWindow = useInfoWindow();
  const [sendInvoiceRes, setSendInvoiceRes] = useState<SendInvoiceRes[]>([]);
  const [invoiceLoading, setInvoiceLoading] = useState(false);

  const confirmUpdate = useCallback(async () => {
    const updateData = genUpdateData(selectedOrder, orderList);
    let updateRes;
    let toStringIdxData;
    if (isCheckedStatusChange) {
      toStringIdxData = updateData.map((item) => ({
        ...item,
        status: changeStatus,
        idx: item.idx.toString(),
      }));
    }

    if (!isCheckedStatusChange) {
      toStringIdxData = updateData.map((item) => {
        return {
          ...item,
          idx: item.idx.toString(),
        };
      });
    }

    updateRes = await orderManage.updateOrderStatus(toStringIdxData as any);
    if (updateRes.status === "Success") {
      openWindow("성공적으로 수정했습니다.");
      refetchNowValue();
    }
  }, [selectedOrder, orderList, isCheckedStatusChange, changeStatus]);

  const confirmSendInvoice = async () => {
    setSendInvoiceRes([]);
    const updateData = genUpdateData(selectedOrder, orderList);

    const hasEmptyValues = updateData.some(
      (item) => item.deliveryCompanyCode === "" || item.invoiceNumber === ""
    );

    if (hasEmptyValues) {
      openWindow("송장번호와 택배사를 선택해주세요.");
      return;
    } else {
      setInvoiceLoading(true);
      let res: SendInvoiceRes[] = [];
      for (const [idx, item] of updateData.entries()) {
        const sendRes = await orderManage.sendInvoice({
          deliveryCompanyCode: item.deliveryCompanyCode,
          deliveryCompanyName: item.deliveryCompanyName,
          idx: item.idx,
          invoiceNumber: item.invoiceNumber,
          sequence: idx,
        });
        res = [...res, sendRes.data];
      }
      openModal(<SendInvoiceResultModal sendInvoiceRes={res} />);
    }
    setInvoiceLoading(false);
  };

  return (
    <div className="row buttonBoxSticky">
      <div className="col-12">
        <div className="total-block">
          <div className="total-block-inner">
            <h4>
              검색 {searchCount}개{" "}
              <span className="text14">(전체 {totalCount}개)</span>
            </h4>
          </div>
          <div className="d-flex align-items-center gap-4">
            <div className="d-flex gap-3">
              <CheckBoxInput
                checked={isCheckedStatusChange}
                onClick={() => setIsChecedStatusChange((prev) => !prev)}
              />
              <select
                onChange={(e) => setChangeStatus(e.target.value)}
                className="cancel-btn save-btn reset-btn  allttam-input input-border-2"
              >
                <option>주문상태 변경</option>
                {ORDER_STATUS.map((status) => (
                  <option key={status.id} value={status.value}>
                    {status.title}
                  </option>
                ))}
              </select>
            </div>
            <button
              onClick={confirmUpdate}
              className="cancel-btn save-btn reset-btn name-set-btn"
            >
              주문수정
            </button>
            <button
              onClick={confirmSendInvoice}
              className="cancel-btn save-btn reset-btn name-set-btn"
            >
              송장전송
            </button>
            <OrderManageDownloadExcelButton selectedOrder={selectedOrder} />
            <SelectTableRow collectRow={collectRow} />
          </div>
        </div>
      </div>
    </div>
  );
};
