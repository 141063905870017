import HTMLReactParser from "html-react-parser";
import { useEffect, useState } from "react";

interface Props {
  item: any;
  setSelectedCollectItem: React.Dispatch<React.SetStateAction<string[]>>;
  selectedCollectItem: string[];
}

export const VvicCollectItem = ({
  item,
  selectedCollectItem,
  setSelectedCollectItem,
}: Props) => {
  const [isSelect, setIsSelect] = useState<boolean>(false);

  useEffect(() => {
    if (selectedCollectItem.includes(item.id)) {
      setIsSelect(true);
    } else {
      setIsSelect(false);
    }
  }, [selectedCollectItem]);

  const selectItem = () => {
    if (!isSelect) {
      setSelectedCollectItem((prev) => [...prev, item.id]);
    } else {
      setSelectedCollectItem((prev) =>
        prev.filter((collect) => collect !== item.id)
      );
    }
  };

  return (
    <div className="custom-col-product" onClick={selectItem}>
      <div
        className={`product-col  collectItem ${
          isSelect && "selectedCollectItem"
        }`}
      >
        <img className="collectItemImg" src={item.index_img_url} />
        <h4>{HTMLReactParser(item.title ? item.title : "")}</h4>
        <div className="product-col-inner">
          <h6>판매가</h6>
          <h5>{item.discount_price}</h5>
        </div>
        <a href={`https://www.vvic.com/item/${item.vid}`} target="_blank">
          <button className="cancel-btn short-btn">바로가기</button>
        </a>
      </div>
    </div>
  );
};
