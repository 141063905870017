import { Link } from "react-router-dom";

import useFetch from "../../../hooks/useFetch";
import { PaymentLog } from "../../../interface/paymentInterface";
import { changeTimeFormat } from "../../../utils/functions/changeTimeFormat";
import { useEffect, useState } from "react";
import { RightArrowIcon } from "../../../components/svg/RightArrowIcon";

export const PaymentPayLog = () => {
  const [payLog] = useFetch("payLog", "/Ipay/returnPay");
  const [newArr, setNewArr] = useState<PaymentLog[]>([]);

  useEffect(() => {
    if (payLog) {
      let arr = [...payLog];
      arr = arr.slice(0, 3);
      setNewArr(arr);
    }
  }, [payLog]);

  return (
    <div className="col-md-6 col-12 payment-log">
      <div className="heading-two history-col">
        <div className="heading-two-inner">
          <h3 className="h3 weight600">결제 내역</h3>
          <Link to="/payment/log" className="heading-two-inner-1">
            <p>전체보기</p>
            <RightArrowIcon />
          </Link>
        </div>
      </div>
      {(newArr.length === 0 || !newArr) && (
        <h6 className="h5 d-flex widthFull justify-content-center mt-5">
          결제 내역이 없습니다.
        </h6>
      )}
      {newArr?.map((item: PaymentLog) => (
        <div
          key={item.id}
          className="d-flex align-items-center justify-content-between under-heading-1 history-col-inner"
        >
          <h6>{item?.goodsName}</h6>
          <h5>{parseInt(item?.approvalAmt).toLocaleString("KR")}</h5>
          <p>
            {changeTimeFormat(item.createDate).split("T")[0]}{" "}
            {changeTimeFormat(item.createDate).split("T")[1]}
          </p>
        </div>
      ))}
    </div>
  );
};
