import axiosInstance from "./axiosInstance";

export const axios = {
  post: async (url: string, body: {}) => {
    try {
      const response = await axiosInstance.post(url, body);
      return response;
    } catch (e) {
      return false;
    }
  },
};
