import { useNavigate, useParams } from "react-router-dom";
import { AdminNavState } from "../../../interface/admininterface";

export const NavAdmin = () => {
  const { component = "" } = useParams();
  const navigate = useNavigate();
  return (
    <div className="heading-one payment-sec detail-sec product-collect-nav">
      <ul className="d-flex align-items-center">
        {TAB_INDEX.map((tab) => (
          <li className="nav-item" key={tab.id}>
            <button
              onClick={() => navigate(`/admin/${tab.value}`)}
              value={tab.value}
              className={`nav-link madebutton oneLine ${
                component === tab.value && "selectedNavItem"
              }`}
            >
              {tab.titile}
            </button>
            {component !== tab.value && <span />}
          </li>
        ))}
      </ul>
    </div>
  );
};

const TAB_INDEX = [
  { id: 0, titile: "사용자 관리", value: "user" },
  { id: 1, titile: "공지사항 관리", value: "notice" },
  { id: 8, titile: "공지사항 관리2", value: "notice2" },
  { id: 2, titile: "카테고리 맵핑", value: "categoryMapping" },
  { id: 3, titile: "카테고리 맵핑 관리", value: "categoryMappingAdmin" },
  { id: 4, titile: "배너", value: "banner" },
  { id: 5, titile: "상담신청", value: "care" },
  { id: 6, titile: "도움말", value: "help" },
  { id: 7, titile: "문의사항", value: "inquiry" },
];
