import ModalContent from "@components/shared/modal/ModalContent";
import { NoticeForm } from "./NoticeForm";
import { Notice, NoticeValues } from "@interface/notice";
import useNoticeMutation from "@components/Notice/hooks/useNoticeMutation";
import { useInfoWindow } from "@hooks/useInfoWindow";
import useModal from "@hooks/useModal";
import { useCallback } from "react";
import { useQueryClient } from "react-query";

interface Props {
  queryKey: string[];
  notice?: Notice;
  isEdit?: boolean;
}

const NoticeWriteModal = ({ queryKey, notice, isEdit = false }: Props) => {
  const { closeModal } = useModal();
  const openWindow = useInfoWindow();
  const queryClient = useQueryClient();

  const { mutate } = useNoticeMutation({
    id: notice?._id,
    isEdit: isEdit,
    onSuccess: () => {
      openWindow("작성되었습니다.");
      queryClient.invalidateQueries(queryKey);
      closeModal();
    },
    onError: () => {},
  });

  const onSubmit = useCallback(
    (noticeValues: NoticeValues) => {
      mutate(noticeValues);
    },
    [mutate]
  );

  return (
    <ModalContent width={1200}>
      <ModalContent.ModalHead>공지사항 작성</ModalContent.ModalHead>
      <ModalContent.ModalBody>
        <div>
          <NoticeForm onSubmit={onSubmit} notice={notice} />
        </div>
      </ModalContent.ModalBody>
    </ModalContent>
  );
};

export default NoticeWriteModal;
