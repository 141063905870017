import styles from "./translate.module.css";
import { Translate } from "./component/Tanslate";
import { ExchangeRate } from "./component/ExchangeRate";
import { XIcon } from "@components/Admin/AdminCategoryMapping/component/svg/XIcon";
import { useRef, useState } from "react";

interface Props {
  handleTranslate: () => void;
}

export const TranslateAndExchange = ({ handleTranslate }: Props) => {
  return (
    <div className={`${styles["tanslate-window"]}`}>
      <div
        className={`${styles["translate-header"]} d-flex justify-content-between align-items-center`}
      >
        <p className="margin0 weight500 text14 white">번역 및 환율</p>
        <button className="madebutton paddingZero" onClick={handleTranslate}>
          <XIcon color="#fff" />
        </button>
      </div>
      <div className="px-3">
        <Translate />
        <ExchangeRate />
      </div>
    </div>
  );
};
