import { orderManage } from "@utils/functions/postApi";
import { useAlert } from "@hooks/useAlert";
import { DeliveryInfo } from "../OrderInfoModal";
import { AlertOnlyClose } from "@components/Alert/AlertOnlyClose";

interface Props {
  deliveryInfo: DeliveryInfo;
  inputDeliveryInfo: (e: React.ChangeEvent<HTMLInputElement>) => void;
  setIsCheckPcc: React.Dispatch<React.SetStateAction<boolean>>;
}

export const OrderInfoPcc = ({
  deliveryInfo,
  inputDeliveryInfo,
  setIsCheckPcc,
}: Props) => {
  const { isAlert, handleAlert, alertMessage } = useAlert();
  const clickCheckPcc = async () => {
    const checkRes = await orderManage.checkPcc({
      pcc: deliveryInfo.pcc,
      receiverName: deliveryInfo.receiverName,
      receiverTelNo: deliveryInfo.receiverContactTelNo,
    });
    if (checkRes.code === 200) {
      handleAlert("통관부호가 확인되었습니다.");
      setIsCheckPcc(true);
    }
    if (checkRes.code !== 200) handleAlert(checkRes.message);
  };

  return (
    <div className="d-flex gap-3 mt-3">
      <div className="d-flex align-items-center widthHalf">
        <p className="ordererInfoTitle">통관부호</p>
        <input
          value={deliveryInfo?.pcc}
          name="pcc"
          className="orderInfoModalInput"
          onChange={inputDeliveryInfo}
        />
      </div>
      <button onClick={clickCheckPcc} className="madebutton pccButton">
        통관부호검증
      </button>
      {isAlert && (
        <AlertOnlyClose
          message={alertMessage}
          closeAlert={handleAlert as () => void}
        />
      )}
    </div>
  );
};
