import { FroalaEditor } from "@components/shared/Froala/Froala";

interface Props {
  editorState: string;
  topModel: string;
  setTopModel: React.Dispatch<React.SetStateAction<string>>;
  setBottomModel: React.Dispatch<React.SetStateAction<string>>;
  bottomModel: string;
}

export const DetailPageHTMLEditor = ({
  editorState,
  topModel,
  setTopModel,
  setBottomModel,
  bottomModel,
}: Props) => {
  return (
    <div className="defaultHeight">
      {editorState === "top" && (
        <FroalaEditor
          // radius={true}
          placeholder="상세페이지 상단에 들어갈 내용을 입력하세요"
          setModel={setTopModel}
          model={topModel}
        />
      )}
      {editorState === "bottom" && (
        <FroalaEditor
          // radius={true}
          placeholder="상세페이지 상단에 들어갈 내용을 입력하세요"
          setModel={setBottomModel}
          model={bottomModel}
        />
      )}
    </div>
  );
};
