import { useEffect, useState } from "react";
import useFetch from "@hooks/useFetch";
import { CollectTable } from "./components/ CollectTable";
import { useScroll } from "@hooks/useScroll";
import { CollectLog } from "@interface/defaultinterface";
import { RefreshIcon } from "@components/svg/RefreshIcon";
import { QuestionMarkIcon } from "@components/svg/QuestionMarkIcon";
import useSelectHelperComponent from "@hooks/useSelectHelperComponent";
import { QuestionButton } from "@components/Button/QuestionButton";

export const HomeCollectLog = () => {
  const openHelper = useSelectHelperComponent();
  const { count, scrollEvent } = useScroll(43);
  const [renderData, setRenderData] = useState<CollectLog[]>([]);
  const [collectLog, collectLogRefetch] = useFetch(
    "collectLog",
    "/Log/GetCollectLogAll?param=All"
  );

  useEffect(() => {
    if (collectLog) {
      const newArr = [...collectLog];
      setRenderData(newArr.reverse().slice(0, 100 * count));
    }
  }, [collectLog, count]);

  return (
    <div className="col-md-6 col-12">
      <div className="heading-two heading-three heading-five">
        <div className="heading-two-inner">
          <div className="d-flex gap-3 align-items-center">
            <h6>수집기록</h6>
            <QuestionButton openId="651a9572834ba1f9efdf60e9" />
          </div>
          <div className="heading-two-inner-1 heading-five-inner heading-six-inner">
            <button
              className="madebutton"
              onClick={() => {
                collectLogRefetch();
              }}
            >
              <RefreshIcon />
            </button>
          </div>
        </div>
        <div className="logTableWrapper" onScroll={scrollEvent}>
          <CollectTable collectLog={renderData} />
        </div>
      </div>
    </div>
  );
};
