import greyAMob from "@assets/images/logo/allttamAgrey.svg";

interface Props {
  setComponent: React.Dispatch<React.SetStateAction<"sub" | "main">>;

  isOffline: boolean;
}

export const StarterMainMobile = ({ setComponent, isOffline }: Props) => {
  const changeSub = () => {
    setComponent("sub");
  };

  return (
    <>
      <div className="mobile-contents-container">
        <div className="d-flex align-items-center gap-3 mt-2">
          <div className="d-flex gap-2">
            <img src={greyAMob} className="greyAMob" />
            <p className="margin0 text12 weight500 navy oneLine">
              <span className="weight600 textGreen text14">기본 셋팅</span> 진행
            </p>
          </div>
          <div className="d-flex gap-2 oneLine">
            <p className="margin0 none-select weight500 text8">셋팅 내용</p>
            <button
              className="madebutton starter-check-button text8"
              onClick={changeSub}
            >
              확인하기
            </button>
            {isOffline && (
              <p className="margin0 none-select weight500 text8">노트북 필수</p>
            )}
          </div>
        </div>
        <div className="d-flex align-items-center gap-3 mt-2">
          <div className="d-flex gap-2">
            <img src={greyAMob} className="greyAMob" />
            <p className="margin0 text12 weight500 navy oneLine">
              S1 플랜
              <span className="weight600 textGreen text14"> 2개월</span> 제공
            </p>
          </div>
          <p className="margin0 none-select weight500 oneLine text8">
            만료일 이후 기간 연장 신청 가능해요
          </p>
        </div>
        <div className="d-flex align-items-center gap-3 mt-2">
          <div className="d-flex gap-2">
            <img src={greyAMob} className="greyAMob" />
            <p className="margin0 text12 weight500 navy">
              <span className="weight600 textGreen text14">5,000 코인</span>{" "}
              제공
            </p>
          </div>
          <p className="margin0 none-select weight500 oneLine text8">
            10만원 상당의 코인을 제공해드려요
          </p>
        </div>
        <div className="d-flex align-items-center gap-3 mt-2">
          <div className="d-flex gap-2">
            <img src={greyAMob} className="greyAMob" />
            <div className="d-flex flex-col">
              <p className="margin0 text12 weight500 navy">
                <span className="weight600 textGreen text14">10% 캐시백</span>{" "}
                추천인 코드 제공
              </p>
              <p className="margin0 none-select weight500 oneLine text8">
                올땀파트너스 가입 시 혜택을 제공해드려요
              </p>
              <p className="margin0 none-select weight500 oneLine text6 mt-2">
                스타트 패키지 결제 후, 채널톡/올땀케어를 통해 반드시 원격셋팅
                <span className="textYellow">일정 조율 요청</span>해주세요.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
