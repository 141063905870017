import { useLocation, useNavigate } from "react-router-dom";
import mobileMenu from "@assets/images/landing/responsiblilty/menuBar.svg";
import logo from "@assets/images/landing/allttam.png";
import useToggle from "@hooks/useToggle";
import { MobileMenu } from "./MobileMenu";

interface Props {
  isAtTop: boolean;
  handleNavButtonClick: (ref: any) => void;
  priceRef?: any;
  communityRef?: any;
  functionRef?: any;
  landingTopRef?: any;
  handleNavClick: (scroll: string) => void;
}

export const IndexNav = ({
  handleNavClick,
  isAtTop,
  handleNavButtonClick,
  priceRef,
  communityRef,
  functionRef,
  landingTopRef,
}: Props) => {
  const { state: isMobileMenuBar, handleToggle: toggleMobileMenu } =
    useToggle();

  const navigate = useNavigate();

  const clickToLogin = () => {
    navigate("/login");
  };

  const clickToSignUp = () => {
    navigate("/signup");
  };

  const { pathname } = useLocation();

  return (
    <div
      className={`d-flex align-items-center justify-content-between align-items-center landingNav ${
        !isAtTop && "navBg"
      }`}
    >
      <div className="d-flex align-items-center">
        <button
          onClick={() => {
            if (pathname === "/price") {
              navigate("/");
            } else {
              handleNavButtonClick(landingTopRef);
            }
          }}
          className="madebutton landingLogo d-flex align-items-center"
        >
          <img className="" src={logo} />
        </button>
        <ul className="landingMenuWrapper">
          <li
            onClick={(e) => {
              if (pathname === "/price") {
                navigate("/?scroll=function");
              } else {
                handleNavClick("function");
                handleNavButtonClick(functionRef);
              }
            }}
            className="landingMenu oneLine inter"
          >
            <button className="madebutton">기능소개</button>
          </li>
          <li
            onClick={() => {
              navigate("/price");
            }}
            className="landingMenu oneLine"
          >
            <button className="madebutton">가격정책</button>
          </li>
          <li
            onClick={() => {
              if (pathname === "/price") {
                navigate("/?scroll=community");
              } else {
                handleNavClick("community");
                handleNavButtonClick(communityRef);
              }
            }}
            className="landingMenu oneLine"
          >
            <button className="madebutton">커뮤니티</button>
          </li>
        </ul>
      </div>
      <div className="d-flex gap-3 landingNavButtonBox">
        <button
          onClick={clickToLogin}
          className="madebutton landingButton landingLoginButton inter d-flex align-items-center"
        >
          LOGIN
        </button>
        <button
          onClick={clickToSignUp}
          className="madebutton landingButton landingFreeButton inter d-flex align-items-center"
        >
          FREE
        </button>
        <button
          className="madebutton landing-mobile-nav"
          onClick={toggleMobileMenu}
        >
          <img src={mobileMenu} />
        </button>
      </div>
      {isMobileMenuBar && <MobileMenu closeModal={toggleMobileMenu} />}
    </div>
  );
};
