import { TranslateLog } from "./ImgTranslateModal";

interface Props {
  item: TranslateLog;
}

export const ImgTransLateLogItem = ({ item }: Props) => {
  return (
    <tr>
      <td>
        <div>{item.no}</div>
      </td>
      <td>
        <div>
          {item.type === "상품번역"
            ? "텍스트 수집"
            : item.type !== null
            ? item.type
            : "올땀 스튜디오"}
        </div>
      </td>
      <td>
        <div>{item.usePoint}</div>
      </td>
      <td>
        <div>
          {item.regDate.split(" ")[0].replaceAll("-", ". ")} /{" "}
          {item.regDate.split(" ")[1]}
        </div>
      </td>
    </tr>
  );
};
