import { useCallback } from "react";
import axiosInstance from "../utils/axiosInstance";
import { useQuery } from "react-query";
import { GenericType, Type } from "typescript";

const useFetch = (
  name: string | string[],
  url: string,
  staleTime?: number,
  enabled?: boolean
) => {
  const getData = useCallback(async () => {
    const res = await axiosInstance.get(url);
    if (res.data.data) {
      return res.data.data;
    } else {
      return res.data;
    }
  }, [url]);

  const { data, refetch, isLoading, isError } = useQuery(name, getData, {
    refetchOnWindowFocus: false,
    retry: 0,
    staleTime: staleTime ? staleTime : 60000,
    enabled: enabled,
  });

  return [data, refetch, isLoading, isError];
};

export default useFetch;
