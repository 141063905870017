import useFetch from "@hooks/useFetch";
import { CollectValue } from "@interface/collectInterface";
import styles from "./MemoSelect.module.css";
import { SearchValue } from "@interface/uploadinterface";

type SetType = CollectValue | SearchValue;

interface Props<T> {
  setCollectValue: React.Dispatch<React.SetStateAction<T>>;
  closeModal: () => void;
}

export const MemoSelect = <T extends SetType>({
  setCollectValue,
  closeModal,
}: Props<T>) => {
  const [productMemoList] = useFetch("productMemo", "/Product/GetProductMemos");

  const selectGroupName = (value: string) => {
    setCollectValue((prev) => {
      return {
        ...prev,
        memo: value,
      };
    });
    closeModal();
  };

  return (
    <div className={`${styles["memo-container"]}`}>
      {productMemoList?.map((item: string) => (
        <button
          onClick={() => selectGroupName(item)}
          className={`madebutton weight500 text13 grey  ${styles["memo-item"]}`}
        >
          {item}
        </button>
      ))}
      <div className="menuoutside" onClick={closeModal}></div>
    </div>
  );
};
