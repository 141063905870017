import { UploadSummary } from "@interface/uploadinterface";
import "font-awesome/css/font-awesome.css";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/js/plugins.pkgd.min.js";
import "froala-editor/js/plugins/font_size.min.js";
import "froala-editor/js/third_party/embedly.min.js";
import "froala-editor/js/third_party/font_awesome.min.js";
import "froala-editor/js/third_party/image_tui.min.js";
import Cookies from "js-cookie";
import * as React from "react";
import Froala from "react-froala-wysiwyg";

interface Props {
  model: string;
  setModel: React.Dispatch<React.SetStateAction<string>>;
  placeholder: string;
  height?: number;
  item?: UploadSummary;
  isEditor?: boolean;
  clickImgInEditor?: (src: string) => void;
}

export const FroalaEditor = ({
  clickImgInEditor,
  model,
  setModel,
  placeholder,
  height,
}: Props) => {
  const handleModelChange = (model: any) => {
    setModel(model);
  };

  return (
    <div className={`App relative`}>
      <Froala
        model={model}
        onModelChange={handleModelChange}
        tag="textarea"
        config={{
          imageUploadParam: "files",
          imageUploadURL: "https://testapi.allttam.com/api/Image/UploadImage",
          requestHeaders: {
            Authorization: "Bearer " + Cookies.get("accessToken"),
          },
          imageUploadMethod: "POST",
          imageUploadParams: { type: "edit" },
          imageMaxSize: 5 * 1024 * 1024,
          imageAllowedTypes: ["jpeg", "jpg", "png", "gif"],
          videoUploadParam: "video",
          videoUploadURL: "https://center.allttam.com/api/video",
          videoMaxSize: 10 * 1024 * 1024,
          fileUploadURL: "https://center.allttam.com/api/files",
          fileUploadParam: "files",
          key: "uXD2lC7D5E5A4G4B3B9dxvwhiC-7rC7bmnxE2F2G2F1B10B2D2E6D1G1==",
          fontFamily: {
            SCDream3: "SCDream3",
            SCDream4: "SCDream4",
            SCDream5: "SCDream5",
            SCDream6: "SCDream6",
            SCDream7: "SCDream7",
            SCDream8: "SCDream8",
            SCDream9: "SCDream9",
          },
          events: {
            click: function (e: React.MouseEvent) {
              if (e.currentTarget.tagName === "IMG" && clickImgInEditor) {
                clickImgInEditor(
                  (e.currentTarget as HTMLImageElement).currentSrc
                );
              } else {
              }
            },
          },
          heightMin: height ? height : 400,
          heightMax: 550,
          attribution: false,
          placeholder: placeholder,
        }}
      />
    </div>
  );
};
