import { UploadLog } from "../../../../../interface/uploadinterface";
import { LogItem } from "./LogItem";

interface Props {
  logList: UploadLog[];
}

export const CheckLogAuction = ({ logList }: Props) => {
  return (
    <div className="tab-content" id="myTabContent">
      <div className="tab-pane fade show active">
        {logList?.map((item) => (
          <LogItem key={item.workNumber} item={item} />
        ))}
        {logList?.length === 0 && <p className="mt-4 pt-4">업로드 기록이 없습니다.</p>}
      </div>
    </div>
  );
};
