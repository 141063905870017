import { GreenBigButton } from "@components/Button/allttamButton/GreenBigButton";
import NavBar from "@components/NavBar/NavBar";
import { NavInquiries } from "@components/NavBar/component/NavInquiries";
import { WriteIcon } from "@components/svg/WriteIcon";
import styles from "./Inquiries.module.css";
import useToggle from "@hooks/useToggle";
import { InquiryWrite } from "./modal/InquiryWrite";
import useFetch from "@hooks/useFetch";
import { useQuery } from "react-query";
import axios from "axios";
import { InquiryItem } from "./modal/InquiryItem";
import useUser from "@hooks/useUser";

const Inquiries = () => {
  const { state: isOpenWriteModal, handleToggle: handleWriteModal } =
    useToggle();

  const userInfo = useUser();

  const getInquiries = async () => {
    const res = await axios.get(
      `https://center.allttam.com/api/allttam/qna/${userInfo.username}`
    );
    return res.data;
  };

  const { data: inquiries } = useQuery(
    ["inquiries", userInfo?.username],
    getInquiries,
    {
      refetchOnWindowFocus: false,
      staleTime: 60000,
    }
  );

  return (
    <>
      <NavBar>
        <NavInquiries />
      </NavBar>
      <div className="mt-4">
        <div className="d-flex justify-content-end">
          <GreenBigButton onClick={handleWriteModal}>
            <div className="d-flex justify-content-center gap-2 align-items-center">
              <WriteIcon />
              <p className="margin0">문의글 작성</p>
            </div>
          </GreenBigButton>
        </div>
        <div className="mt-5">
          <table width="100%" className={`${styles["inquiries-table"]}`}>
            <thead>
              <tr>
                <th className="width5 text-center grey weight500">번호</th>
                <th className="width20 text-center grey weight500">카테고리</th>
                <th className="width40 text-center grey weight500">제목</th>
                <th className="width10 text-center grey weight500">작성자</th>
                <th className="width15 text-center grey weight500">작성일</th>
                <th className="width10 text-center grey weight500">답변여부</th>
              </tr>
            </thead>
            <tbody>
              {inquiries?.map((item: Inquiries, idx: number) => (
                <InquiryItem
                  key={item.item._id}
                  idx={idx}
                  item={item.item}
                  isAdditionalQna={item.isAdditionalQna}
                  isAnswered={item.isAnswered}
                />
              ))}
            </tbody>
          </table>
        </div>
        {isOpenWriteModal && (
          <InquiryWrite
            handleWriteModal={handleWriteModal}
            userId={userInfo.username}
            userName={userInfo.originalUserName}
          />
        )}
      </div>
    </>
  );
};

export default Inquiries;

export interface Inquiries {
  item: Inquiry;
  isAdditionalQna: boolean;
  isAnswered: boolean;
}

export interface Inquiry {
  _id: string;
  name: string;
  userId: string;
  userName: string;
  content: string;
  answer: string;
  createdAt: string;
  updatedAt: string;
  category: InquiryCategory;
}

interface InquiryCategory {
  _id: string;
  title: string;
}
