import { AdminGuard } from "@components/AuthRoute/AdminGuard";
import { AuthRoute } from "@components/AuthRoute/AuthRoute";
import { InfoWindow } from "@components/InfoWindow/InfoWindow";
import useAnimation from "@hooks/useAnimation";
import useChannelTalk from "@hooks/useChannelTalk";
import useUser from "@hooks/useUser";
import { Suspense, lazy } from "react";
import { createPortal } from "react-dom";
import { Route, Routes } from "react-router-dom";
import { useRecoilState } from "recoil";
import SideBarRouter from "./SideBarRouter";
import { infoWindowState } from "./atom/atom";
import { Developer } from "./components/Admin/Developer/Developer";
import { Collect } from "./pages/Collect/Collect";
import { Home } from "./pages/Home";
import Inquiries from "./pages/Inquiries/Inquiries";
import { Index } from "./pages/Landing/Index";
import { Price } from "./pages/Landing/Price";
import { Login } from "./pages/Login";
import { Notice } from "./pages/Notice/Notice";
import { OrderManage } from "./pages/Ordermanage/OrderManage";
import { Payment } from "./pages/Payment/Payment";
import { PaymentResult } from "./pages/Payment/PaymentResult";
import { PaymentLog } from "./pages/PaymentLog/PaymentLog";
import { ProductManage } from "./pages/Productmanage/ProductManage";
import { Upload } from "./pages/Upload/Upload";
import { UserInfo } from "./pages/UserInfo";

const NaverLoginCallBack = lazy(
  () => import("./components/Login/NaverLoginCallBack/NaverLoginCallBack")
);
const Kmc = lazy(() => import("./components/Login/SignupComponent/Kmc"));
const BoostAdmin = lazy(() => import("@pages/BoostAdmin/BoostAdmin"));
const Boost = lazy(() => import("@pages/Boost/Boost"));
const DashBoard = lazy(() => import("@components/Admin/DashBoard/DashBoard"));
const Admin = lazy(() => import("@pages/Admin"));
const ManualPreparePage = lazy(
  () => import("@pages/Manual/component/ManualPreparePage")
);
const ManualSetting = lazy(
  () => import("@pages/Manual/SettingSequence/ManualSetting")
);
const Manual = lazy(() => import("@pages/Manual/Manual"));
const ManualUsing = lazy(() => import("@pages/Manual/Using/ManualUsing"));
const Setting = lazy(() => import("@pages/Setting/Setting"));

export const Router = () => {
  const userInfo = useUser();
  const $alertPortal = document.getElementById("alert-portal");
  const [isInfoWindow, setIsInfoWindow] = useRecoilState(infoWindowState);

  useAnimation();
  useChannelTalk(userInfo);

  return (
    <Suspense fallback={<></>}>
      {$alertPortal != null && isInfoWindow.isOpen
        ? createPortal(
            <InfoWindow message={isInfoWindow.message} />,
            $alertPortal
          )
        : null}
      <Routes>
        <Route path="/kmc" element={<Kmc />} />
        <Route path="/" element={<Index />} />
        <Route path="/price" element={<Price />} />
        <Route path="/user/naverLogin" element={<NaverLoginCallBack />} />
        <Route path="/manual" element={<Manual />} />
        <Route path="manual/setting" element={<ManualSetting />} />
        <Route path="manual/using" element={<ManualUsing />} />
        <Route path="manual/prepare" element={<ManualPreparePage />} />
        <Route path="/signup" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route element={<SideBarRouter />}>
          <Route element={<AuthRoute />}>
            <Route path="/notice" element={<Notice />} />
            <Route path="/inquiries" element={<Inquiries />} />
            <Route path="/home" element={<Home />} />
            <Route path="/collect" element={<Collect />} />
            <Route path="/productmanage" element={<ProductManage />} />
            <Route path="/ordermanage" element={<OrderManage />} />
            <Route path="/admin/developer" element={<Developer />} />
            <Route path="/payment" element={<Payment />} />
            <Route path="/payment/log" element={<PaymentLog />} />
            <Route path="/payment/result" element={<PaymentResult />} />
            <Route path="/upload" element={<Upload />} />
            <Route path="/setting" element={<Setting />} />
            <Route path="/userinfo" element={<UserInfo />} />
            <Route path="/boost/admin" element={<BoostAdmin />} />
            <Route path="/boost" element={<Boost />} />
            <Route element={<AdminGuard {...userInfo} />}>
              <Route path="/admin" element={<DashBoard />} />
              <Route path="/admin/:component" element={<Admin />} />
            </Route>
          </Route>
        </Route>
      </Routes>
    </Suspense>
  );
};
