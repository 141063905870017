interface Props {
  children: string;
}

export const HomeToolTip = ({ children }: Props) => {
  return (
    <div className="d-flex justify-content-center">
      <div className="homeTooltip oneLine">{children}</div>
    </div>
  );
};
