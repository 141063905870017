import { ProfileButton } from "./ProfileButton";
import { ProgressIcon } from "@components/svg/ProgressIcon";
import { Notification } from "./Notification/Notification";
import { TranslateAndExchange } from "@components/TranslateAndExchange/TranslateAndExchange";
import useToggle from "@hooks/useToggle";
import { useRecoilState } from "recoil";
import { translateWindowState } from "src/atom/atom";

interface Props {
  setIsNotificationBar: () => void;
  isNotificationBar: boolean;
}

export const NavBarButtonTab = ({
  setIsNotificationBar,
  isNotificationBar,
}: Props) => {
  return (
    <div className="width20">
      <div className="d-flex gap-3 align-items-center justify-content-end notify-panel">
        <div className="d-flex align-items-center gap-3 relative">
          {/* <button className="madebutton" onClick={clickToggleTranslate}>
            <ProgressIcon />
          </button> */}
          <Notification />
        </div>
        <ProfileButton
          onClick={setIsNotificationBar}
          isNotificationBar={isNotificationBar}
        />
      </div>
    </div>
  );
};

export interface NotificationType {
  content: string;
  createdAt: string;
  isRead: false;
  _id: string;
}
