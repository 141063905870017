import { useState } from "react";

import { ItemMainImg } from "./ItemMainImg";

import { UseMutationResult } from "react-query";
import { UploadSummary } from "@interface/uploadinterface";
import { useTooltip } from "@hooks/useTooltip";
import { useAlert } from "@hooks/useAlert";
import { upload } from "@utils/functions/postApi";
import { copyFunction } from "@utils/functions/copyFunction";
import { UploadTooltip } from "../../Tooltip/UploadTooltip";
import { AlertOnlyClose } from "@components/Alert/AlertOnlyClose";

interface Props {
  item: UploadSummary;
  isSelect: boolean;
  isViewWayDetail: boolean;
  collectProductSummary: UseMutationResult<any, unknown, void, unknown>;
}
export const ItemProductInfo = ({
  item,
  isSelect,
  isViewWayDetail,
  collectProductSummary,
}: Props) => {
  const [isCopyTooltip, enterCopy, leaveCopy] = useTooltip();
  const { isAlert, alertMessage, handleAlert } = useAlert();
  const [productNameAndMemo, setProductNameAndMemo] = useState({
    productName: item.title[1]?.text
      ? item.title[1]?.text
      : item.title[0]?.text,
    memo: item.memo ? item.memo : "",
  });
  const inputProductNameAndMemo = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setProductNameAndMemo({
      ...productNameAndMemo,
      [name]: value,
    });
  };
  const clickChangeProductName = async () => {
    const changeRes = await upload.changeOneProductName([
      {
        code: item.id,
        text: productNameAndMemo.productName,
      },
    ]);
  };

  const clickEditMemo = async () => {
    const editRes = await upload.changeProductMemo({
      objectId: item.id,
      memo: productNameAndMemo.memo,
    });
  };

  return (
    <td>
      <div className={`collect-inner-1 ${isSelect && "selectedItem"}`}>
        <div>
          <div className="input-collect gap-3">
            <ItemMainImg
              item={item}
              isViewWayDetail={isViewWayDetail}
              collectProductSummary={collectProductSummary}
            />
            <div>
              <div
                className="input-collect-inner mb-2"
                onClick={(e) => e.stopPropagation()}
              >
                <input
                  className={`${!isViewWayDetail && "simpleUploadItemInput"}`}
                  name="productName"
                  type="text"
                  onChange={inputProductNameAndMemo}
                  placeholder={productNameAndMemo.productName}
                  value={productNameAndMemo.productName}
                />
                <div className="input-collect-btn">
                  <button
                    className={`cancel-btn save-btn correction-btn ${
                      !isViewWayDetail && "simpleUploadItemButton"
                    }`}
                    onClick={clickChangeProductName}
                  >
                    수정
                  </button>
                </div>
              </div>
              <div
                className="input-collect-inner"
                onClick={(e) => e.stopPropagation()}
              >
                <input
                  className={`${!isViewWayDetail && "simpleUploadItemInput"}`}
                  name="memo"
                  onChange={inputProductNameAndMemo}
                  type="text"
                  value={productNameAndMemo.memo}
                  placeholder={"메모를 입력해주세요"}
                />
                <div className="input-collect-btn">
                  <button
                    onClick={clickEditMemo}
                    className={`cancel-btn save-btn correction-btn correction-btn-2 ${
                      !isViewWayDetail && "simpleUploadItemButton"
                    }`}
                  >
                    수정
                  </button>
                </div>
              </div>
              <div className="coupan-text">
                {isViewWayDetail && (
                  <>
                    <div className="d-flex align-items-center">
                      <h6 onClick={(e) => e.stopPropagation()}>
                        수집작업번호 : {item.workNumber}
                      </h6>
                      <span onClick={(e) => e.stopPropagation()}></span>
                      <h5 onClick={(e) => e.stopPropagation()}>
                        원본상품코드 : {item.code}
                      </h5>
                      <span
                        onClick={(e) => {
                          e.stopPropagation();
                          copyFunction(item.code);
                        }}
                        className="copy-1 tooltipRel"
                        onMouseEnter={enterCopy as () => void}
                        onMouseLeave={leaveCopy as () => void}
                      >
                        {isCopyTooltip && (
                          <UploadTooltip className="copyTooltip">
                            코드복사
                          </UploadTooltip>
                        )}
                      </span>
                      <span
                        onClick={(e) => {
                          e.stopPropagation();
                          copyFunction(item.code);
                        }}
                        onMouseEnter={enterCopy as () => void}
                        onMouseLeave={leaveCopy as () => void}
                        className="copy-1 copy-2"
                      ></span>
                    </div>
                    <h4>상품 그룹명 : {item.groupName}</h4>
                  </>
                )}
                <h4>
                  업로드 마켓 :{" "}
                  {item.uploadCompleteSummaryInfo.isSmartStore &&
                    "스마트스토어"}{" "}
                  {item.uploadCompleteSummaryInfo.isCoupang && "쿠팡"}{" "}
                  {item.uploadCompleteSummaryInfo.isAuction && "옥션"}{" "}
                  {item.uploadCompleteSummaryInfo.isGmarket && "지마켓"}{" "}
                  {item.uploadCompleteSummaryInfo.isElevenst && "11번가"}
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isAlert && (
        <AlertOnlyClose
          closeAlert={handleAlert as () => void}
          message={alertMessage}
        />
      )}
    </td>
  );
};
