import { UseMutationResult } from "react-query";
import { upload } from "../../../../../utils/functions/postApi";
import { RightIcon } from "@components/svg/RightIcon";
import { GreyMiddleButton } from "@components/Button/allttamButton/GreyMiddleButton";

interface Props {
  replaceWordValue: {
    targetWord: string;
    convertWord: string;
  };
  setReplaceWordValue: React.Dispatch<
    React.SetStateAction<{
      targetWord: string;
      convertWord: string;
    }>
  >;
  getReplaceWord: UseMutationResult<any, unknown, void, unknown>;
}

export const ReplaceWordInput = ({
  replaceWordValue,
  setReplaceWordValue,
  getReplaceWord,
}: Props) => {
  const inputWord = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setReplaceWordValue({
      ...replaceWordValue,
      [name]: value,
    });
  };
  const clickRegistReplaceWord = async () => {
    const registRes = await upload.setReplaceWord({
      targetWord: replaceWordValue.targetWord,
      convertWord: replaceWordValue.convertWord,
      type: "Replace",
    });
    if (registRes.status === 200) {
      getReplaceWord.mutate();
    }
  };

  return (
    <div className="d-flex align-items-center gap-3">
      <input
        name="targetWord"
        onChange={inputWord}
        value={replaceWordValue.targetWord}
        type="text"
        className="allttam-input width40"
        placeholder="변경 단어"
      />
      <div>
        <RightIcon />
      </div>
      <input
        name="convertWord"
        onChange={inputWord}
        value={replaceWordValue.convertWord}
        type="text"
        className="allttam-input width40"
        placeholder="치환 단어"
      />
      <GreyMiddleButton onClick={clickRegistReplaceWord}>등록</GreyMiddleButton>
    </div>
  );
};
