import { NoticeSearchBoard } from "@components/Notice/NoticeSearchBoard";
import { NoticeTable } from "@components/Notice/NoticeTable";
import useNotice from "@components/Notice/hooks/useNotice";
import Spacing from "@components/shared/Spacing";
import { NoticeSearchValues } from "@interface/notice";
import { useCallback, useState } from "react";
import NavBar from "@components/NavBar/NavBar";
import { NavBarDefaultComponent } from "@components/NavBar/component/NavBarDefaultComponent";
import { NoticeData } from "@interface/defaultinterface";
import "./Notice.css";

export const Notice = () => {
  const [enabled, setEnabled] = useState(true);
  const [collectedSearchValue, setCollectedSearchValue] = useState<
    Partial<NoticeSearchValues>
  >({});

  const { data } = useNotice({
    searchValues: collectedSearchValue as NoticeSearchValues,
    enabled: enabled,
  });

  const onSubmit = useCallback(
    (searchValues: NoticeSearchValues) => {
      setCollectedSearchValue(searchValues);
      setEnabled(true);
    },
    [setEnabled, setCollectedSearchValue]
  );

  return (
    <div>
      <NavBar>
        <NavBarDefaultComponent />
      </NavBar>
      <NoticeSearchBoard onSubmit={onSubmit} />
      <Spacing.Height height="height32" />
      <NoticeTable noticeList={data?.notice} />
    </div>
  );
};

export interface Notice {
  nowPage: number;
  totalPage: number;
  noticeList: NoticeData[];
}
