import consult1 from "@assets/images/care/consult1.svg";
import consult2 from "@assets/images/care/consult2.svg";
import consult3 from "@assets/images/care/consult3.svg";
import { DownArrowIcon } from "@components/svg/DownArrowIcon";
import { ConsultInfo, DetailView } from "./CareContent";
import { useCallback, useState } from "react";
import axios from "axios";
import { useInfoWindow } from "@hooks/useInfoWindow";
import ChannelService from "@components/ChannelTalk/ChannelService.js";

interface Props {
  clickDetailView: (value: DetailView) => void;
  detail: DetailView;
  consultInfo: ConsultInfo;
}

export const Consult = ({ clickDetailView, detail, consultInfo }: Props) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const openWindow = useInfoWindow();

  const inputNumber = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(e.target.value);
  }, []);

  const submitConsulting = useCallback(async () => {
    const res = await axios.post("https://center.allttam.com/api/consulting", {
      ...consultInfo,
      phoneNumber,
      isFree: true,
    });
    if (res.status === 201) {
      openWindow("상담신청이 완료되었습니다.");
    }
  }, [phoneNumber, consultInfo]);

  return (
    <>
      <div className="d-flex flex-col align-items-center mt-4">
        <p
          className="careTitle widthFull margin0"
          onClick={() => {
            clickDetailView("free");
          }}
        >
          (무료) 매칭 케어 상담
        </p>
        {detail !== "pay" && (
          <div className="white-box mt-3">
            <p className="careWeight5 careText14 margin0">
              해외구매대행 솔루션을 처음 사용하세요? <br />
              올땀 사용 백서를 친절하게 가이드 해드려요.
            </p>
            {detail === "free" && (
              <div className="mt-3">
                <div className="careText14 careWeight6 d-flex justify-content-between">
                  <div className="d-flex align-items-center gap-3">
                    <img src={consult1} />
                    <p className="margin0">실시간 채널톡 상담 서비스</p>
                  </div>
                  <button
                    onClick={() => {
                      ChannelService.openChat();
                    }}
                    className="madebutton careButton"
                  >
                    상담 받기
                  </button>
                </div>
                <div className="careText14 careWeight6 d-flex justify-content-between mt-2">
                  <div className="d-flex align-items-center gap-3">
                    <img src={consult2} />
                    <p className="margin0">카카오톡 다이렉트 상담 서비스</p>
                  </div>
                  <button
                    className="madebutton careButton"
                    onClick={() => {
                      window.open(
                        "https://open.kakao.com/me/allttam",
                        "_blank"
                      );
                    }}
                  >
                    상담 받기
                  </button>
                </div>
                <div className="d-flex align-items-center gap-3 careText14 careWeight6 mt-2">
                  <img src={consult3} />
                  <p className="margin0">핫라인 콜 상담 서비스</p>
                </div>
                <div className="mt-2 d-flex gap-3">
                  <input
                    value={phoneNumber}
                    onChange={inputNumber}
                    className="careInput"
                    placeholder="연락처를 남겨주세요. ( - 없이 입력)"
                  />
                  <button
                    onClick={submitConsulting}
                    className="madebutton careButton oneLine"
                  >
                    상담 받기
                  </button>
                </div>
              </div>
            )}
            {detail !== "free" && (
              <button
                onClick={() => {
                  clickDetailView("free");
                }}
                className="madebutton paddingZero d-flex flex-col align-items-center widthFull mt-3"
              >
                <p className="careWeight5 careText14 margin0">자세히 보기</p>
                <DownArrowIcon />
              </button>
            )}
          </div>
        )}
      </div>
    </>
  );
};
