import { GreyMiddleButton } from "@components/Button/allttamButton/GreyMiddleButton";
import { EraseIcon } from "@components/svg/EraseIcon";
import { QuestionMarkIcon } from "@components/svg/QuestionMarkIcon";
import { Keyword } from "@interface/uploadinterface";

interface Props {
  selectedTag: string[];
  setSelectedTag: React.Dispatch<React.SetStateAction<string[]>>;
}

export const TagList = ({ selectedTag, setSelectedTag }: Props) => {
  const deleteTag = (tag: string) => {
    setSelectedTag(selectedTag.filter((item) => item !== tag));
  };

  const removeDuplicate = () => {
    const newArr = selectedTag.filter(
      (item, idx, self) => self.indexOf(item) === idx
    );

    setSelectedTag(newArr);
  };

  return (
    <div className="row">
      <div className="mt-3 d-flex justify-content-between">
        <div className="d-flex align-items-center gap-3">
          <p className="text18 weight500 margin0">태그 목록</p>
          <span className="text14 weight500">
            <span
              className={selectedTag?.length > 20 ? "textRed" : "textGreen"}
            >
              {selectedTag?.length}
            </span>{" "}
            / 20
          </span>
          <QuestionMarkIcon />
        </div>
        <div>
          <GreyMiddleButton onClick={removeDuplicate}>
            중복제거
          </GreyMiddleButton>
        </div>
      </div>
      <div className="d-flex flex-wrap widthFull tag-list-box">
        {selectedTag.map((item, idx) => (
          <span key={idx} className="tag-1 tag width23">
            <span className="margin0 d-flex align-items-center justify-content-between">
              {item}
              <button
                onClick={() => deleteTag(item!)}
                className="madebutton paddingZero"
              >
                <EraseIcon />
              </button>
            </span>
          </span>
        ))}
      </div>
    </div>
  );
};
