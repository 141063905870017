import { PlanType } from "@interface/payment";

interface Props {
  item: PlanType;
  setSelectedPlan: React.Dispatch<React.SetStateAction<number>>;
  selectedPlan: number;
}

export const PlanMonth = ({ item, selectedPlan, setSelectedPlan }: Props) => {
  const selectPeriod = (grade: number) => {
    setSelectedPlan(grade);
  };

  return (
    <div className="d-flex gap-3 align-items-center">
      <p className="weight600 text14 margin0">개월</p>
      {item.planOptionInfo
        .filter((item) => item.grade !== 6)
        .map((period, idx) => (
          <div key={idx} className="form-check radio-free-inner">
            <input
              onChange={() => {
                selectPeriod(period.grade);
              }}
              checked={selectedPlan === period.grade}
              className="form-check-input"
              type="checkbox"
              readOnly
            />
            <label onClick={() => {}} className="text12 grey weight500">
              {period.grade} 개월
            </label>
          </div>
        ))}
    </div>
  );
};
