import { LeftIcon } from "@components/svg/LeftIcon";
import { QuestionMarkIcon } from "@components/svg/QuestionMarkIcon";
import { RightIcon } from "@components/svg/RightIcon";
import { UploadSummary } from "@interface/uploadinterface";
import { useMemo, useState } from "react";
import { CrawledKeyword } from "../../TagSettingModal";
import { getCrawledItemList } from "../../function/getCrawledItemLIst";

interface Props {
  selectedItem: UploadSummary[];
  setCrawledKeyword: React.Dispatch<React.SetStateAction<CrawledKeyword>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
}

export const ProductNameKeywordList = ({
  selectedItem,
  setCrawledKeyword,
  setIsLoading,
  isLoading,
}: Props) => {
  const [productIndex, setProductIndex] = useState(0);

  const productNameList = useMemo(() => {
    return selectedItem.map(
      (item) => item.title.filter((item) => item.language === "KR")[0].text
    );
  }, [selectedItem]);

  const maxLength = useMemo(() => {
    return selectedItem.length;
  }, [selectedItem]);

  const clickPrevItem = () => {
    setProductIndex((prev) => prev - 1);
  };

  const clickNextItem = () => {
    setProductIndex((prev) => prev + 1);
  };

  const clickNameKeyword = async (e: React.MouseEvent<HTMLButtonElement>) => {
    setIsLoading(true);
    const { rel, auto } = await getCrawledItemList(e.currentTarget.value);

    setCrawledKeyword({
      rel,
      auto,
    });
    setIsLoading(false);
  };

  return (
    <div>
      <div className="d-flex align-items-center gap-3">
        <p className="margin0 text18 weight500">상품명 키워드</p>
        <QuestionMarkIcon />
      </div>
      <div className="d-flex align-items-start gap-2 mt-3">
        <button
          disabled={productIndex === 0}
          onClick={clickPrevItem}
          className="madebutton button-grey-border paddingZero"
        >
          <LeftIcon />
        </button>
        <div className="d-flex flex-wrap gap-2">
          {productNameList[productIndex].split(" ").map(
            (item, idx) =>
              item && (
                <button
                  disabled={isLoading}
                  onClick={clickNameKeyword}
                  key={idx}
                  value={item}
                  className="madebutton weight200 text12 button-grey-border grey"
                >
                  {item}
                </button>
              )
          )}
        </div>
        <button
          disabled={productIndex >= maxLength - 1}
          onClick={clickNextItem}
          className="madebutton button-grey-border paddingZero"
        >
          <RightIcon />
        </button>
      </div>
    </div>
  );
};
