export const WriteIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        d="M15.823 291.182a2.689 2.689 0 0 0-1.9.782l-1.037 1.013H3.028a2.516 2.516 0 0 0-2.5 2.5v11.193a2.516 2.516 0 0 0 2.5 2.5h11.194a2.515 2.515 0 0 0 2.5-2.5v-9.855l1.013-1.033a2.692 2.692 0 0 0-1.91-4.6zm0 1.778a.912.912 0 0 1 .639.272.884.884 0 0 1 0 1.275l-.007.007-7.305 7.458H7.726v-1.421l7.458-7.309.007-.007a.9.9 0 0 1 .636-.272zm-12.8 1.815h8.027l-4.858 4.759a.9.9 0 0 0-.265.636v2.7a.9.9 0 0 0 .9.9h2.7a.893.893 0 0 0 .636-.265l4.759-4.854v8.019a.68.68 0 0 1-.7.7H3.028a.68.68 0 0 1-.7-.7v-11.194a.68.68 0 0 1 .7-.7z"
        transform="translate(3.138 -288.512)"
        fill="#fff"
      />
      <path data-name="사각형 3951" fill="none" d="M0 0h24v24H0z" />
    </svg>
  );
};
