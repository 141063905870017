import useModal from "@hooks/useModal";

interface Props {
  children: React.ReactNode;
  width?: number;
  unset?: boolean;
  fixHeight?: number;
}

const ModalContent = ({
  children,
  width = 800,
  unset = false,
  fixHeight,
}: Props) => {
  const widthStyle = {
    width: `${width ? width : "800"}px`,
  };
  const unsetStyle = {
    overflow: unset ? "unset" : "",
    height: `${fixHeight ? `${fixHeight}px` : "none"}`,
  };
  return (
    <div
      style={widthStyle}
      className={`modal-dialog1 d-flex align-items-center justify-content-center`}
    >
      <div
        style={unsetStyle}
        className="modal-content modalInner"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {children}
      </div>
    </div>
  );
};

const ModalBody = ({ children }: { children: React.ReactNode }) => {
  return <div className="modal-body">{children}</div>;
};

const ModalHead = ({ children }: { children: React.ReactNode }) => {
  const { closeModal } = useModal();
  return (
    <div className="modal-header modal-sticky">
      <h5 className="d-flex align-items-center h1">{children}</h5>
      <button type="button" className="btn-close" onClick={closeModal}></button>
    </div>
  );
};

ModalContent.ModalHead = ModalHead;
ModalContent.ModalBody = ModalBody;

export default ModalContent;
