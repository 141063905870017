import { ProgressBar } from "react-bootstrap";

interface Props {
  progress: {
    id: number;
    title: string;
    dataName: string;
    color: string;
  };
  data: number;
  total: number;
}

export const HomeProgressBar = ({ progress, data, total }: Props) => {
  return (
    <div className={`progress-col ${progress.color}`}>
      <div className="progress-col-inner">
        <h6>{progress.title}</h6>
        <p>
          {data?.toLocaleString()} / {total?.toLocaleString()}
        </p>
      </div>
      <ProgressBar now={(data / total) * 100} />
    </div>
  );
};
