import saleUp from "@assets/images/landing/qualityeditting.svg";
import yellowArrow from "@assets/images/landing/pigTailArrow.png";

export const SeoImageEdit = () => {
  return (
    <div className="seoMainContentRightSide component-transition">
      <div className="textEnd"> 
        <p className="textContentMain">이미지 편집</p>
        <p className="seoSubText oneLine">글꼴, 정렬 등 텍스트 편집은 기본</p>
        <p className="seoSubText oneLine">도형과 브러쉬에서 꾸미기 기능까지</p>
      </div>
      <div className="seoMainSmallText mt-5">
      <div className="seoIconBox">
          <img src={saleUp} />
          <img src={yellowArrow} />
        </div>
        <div className="seoContentText">
          <p className="margin0 mt-4 oneLine">
            상품의 상세페이지의 소비자 체류시간이 짧나요?
          </p>
          <p className="margin0 oneLine">
            오픈마켓에 상품을 등록했는데, 썸네일 부적격 통보를 받았나요?
          </p>
          <p className="margin0 oneLine">
            상품의 상세페이지로 유입되도록
            <span className="seoContentTextBold"> 클린한 썸네일</span>이
            필요합니다.
          </p>
          <p className="margin0 oneLine">
            즉, 상품의 <span className="seoContentTextBold">상위 노출</span>에
            있어 이미지 편집이 필수입니다.
          </p>
        </div>
      </div>
    </div>
  );
};
