import { useEffect } from "react";
import { Cropper, ReactCropperElement } from "react-cropper";

interface Props {
  isSquare: boolean;
  cropImg: string;
  cropperRef: React.RefObject<ReactCropperElement>;
  setEditedImg: React.Dispatch<React.SetStateAction<string>>;
}

export const Layer = ({
  isSquare,
  cropImg,
  cropperRef,
  setEditedImg,
}: Props) => {
  const onCrop = () => {
    const imageElement: any = cropperRef?.current;
    const cropper = imageElement?.cropper;
    setEditedImg(cropper.getCroppedCanvas().toDataURL());
  };

  useEffect(() => {
    if (cropperRef.current) {
      cropperRef.current!.cropper.setCropBoxData({
        left: 0,
        top: 0,
        width: 350, // 크롭 박스의 너비를 원하는 값으로 설정
        height: 350, // 크롭 박스의 높이를 원하는 값으로 설정
      });
    }
  }, [cropperRef.current]);

  return (
    <div className="widthHalf cropperheight mt-4">
      <div className="d-flex widthFull align-items-center gap-3 mb-3">
        <h3 className="h3 oneLine margin0">올땀 에디터</h3>
        <hr className="hr4 widthFull" />
      </div>
      <Cropper
        aspectRatio={isSquare ? 1 : undefined}
        style={{ width: 350, height: 350 }}
        src={cropImg}
        viewMode={1}
        ref={cropperRef}
        crop={onCrop}
        checkCrossOrigin={false}
        crossOrigin="anonymous"
      />
    </div>
  );
};
