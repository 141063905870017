import { QuestionMarkIcon } from "@components/svg/QuestionMarkIcon";
import { CollectLog } from "../../../../interface/defaultinterface";
import { CollectComponent } from "./CollectComponent";
import { NoneTableItem } from "./NoneTabletem";
import useSelectHelperComponent from "@hooks/useSelectHelperComponent";

interface Props {
  collectLog: CollectLog[];
}

export const CollectTable = ({ collectLog }: Props) => {
  const openHelper = useSelectHelperComponent();
  return (
    <table
      className="homeLogTable"
      width="100%"
      cellPadding={0}
      cellSpacing={0}
      border={0}
      align="center"
    >
      <thead className="head-one table-sticky">
        <tr>
          <th>작업번호</th>
          <th>수집마켓</th>
          <th>그룹명</th>
          <th>요청</th>
          <th>
            {" "}
            <div className="d-flex gap-2 justify-content-center">
              <p className="margin0">성공</p>
              <button
                onClick={() => {
                  // openHelper("detail", "64e37ffd8081d5317ca7760c");
                }}
                className="madebutton paddingZero"
              >
                <QuestionMarkIcon />
              </button>
            </div>
          </th>
          <th>비고</th>
        </tr>
      </thead>
      <tbody className="body-one">
        {(collectLog as CollectLog[])?.map((log) => (
          <CollectComponent key={log?.workNumber} log={log} />
        ))}
        {!collectLog ||
          (collectLog.length === 0 && <NoneTableItem colSpan={6} />)}
      </tbody>
    </table>
  );
};
