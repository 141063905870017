import bg from "@assets/images/landing/computer/computerBg.png";

export const LandingComputer = () => {
  return (
    <div className="landingComputer relative fullStackPadding">
      <img src={bg} className="computerbg" />
      <h4 className="seoMainText oneLine pt-5 relative">
        <span className="textGreen">강력</span>한
        <span className="landingTextYellow"> 풀스택 </span>
        기능
      </h4>
      <div className="computerWrapper relative fullStackImgPadding">
        <div className="computerImg"></div>
      </div>
    </div>
  );
};
3;
