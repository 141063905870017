import useToggle from "@hooks/useToggle";
import { changeTimeFormat } from "@utils/functions/changeTimeFormat";
import { CircleIcon } from "@components/Admin/AdminCategoryMapping/component/svg/CircleIcon";
import { XIcon } from "@components/Admin/AdminCategoryMapping/component/svg/XIcon";
import { Inquiry } from "../Inquiries";

import { InquiryAnswer } from "@components/Admin/AdminInquiry/modal/InquiryAnswer";
import { InquiryModal } from "./InquiryModal";

interface Props {
  item: Inquiry;
  idx: number;
  isAdmin?: boolean;
  adminHasAnswerd?: string;
  adminCategoryId?: string;
  isAdditionalQna: boolean;
  isAnswered: boolean;
}

export const InquiryItem = ({
  idx,
  item,
  isAdmin = false,
  isAdditionalQna,
  isAnswered,
  adminHasAnswerd = "",
  adminCategoryId = "",
}: Props) => {
  const { state: isInquiryModal, handleToggle: handleInquiryModal } =
    useToggle();
  const { state: isAnswerModal, handleToggle: handleAnswerModal } = useToggle();

  return (
    <>
      <tr className="pointer" onClick={handleInquiryModal}>
        <td className="weight500 text-center wdith5 text14">{idx + 1}</td>
        <td className="weight500 text-center wdith40 text14">
          {item.category?.title}
        </td>
        <td className="weight500 text-center wdith40 text14">{item.name}</td>
        <td className="weight500 text-center wdith10 text14">
          {item.userName}
        </td>
        <td className="weight500 text-center wdith15 text14">
          {changeTimeFormat(item.createdAt)?.split("T")[0]}
        </td>
        <td className="weight500 text-center wdith10 text14">
          <div className="cursor">
            {isAnswered ? <CircleIcon /> : <XIcon />}
            {isInquiryModal && (
              <InquiryModal
                handleAnswerModal={handleAnswerModal}
                isAnswerModal={isAnswerModal}
                isAdmin={isAdmin}
                closeModal={handleInquiryModal}
                isAdditionalQna={isAdditionalQna}
                {...item}
              />
            )}
            {isAnswerModal && (
              <InquiryAnswer
                adminHasAnswerd={adminHasAnswerd}
                adminCategoryId={adminCategoryId}
                answer={item.answer}
                closeModal={handleAnswerModal}
                content={item.content}
                id={item._id}
                name={item.name}
                createdAt={item.createdAt}
              />
            )}
          </div>
        </td>
      </tr>
      {/* {isInquiryModal && (
        <tr>
          <td colSpan={6}>
            <div className={styles["answer-container"]}>
              <div className={styles["answer-box"]}>
                {item.answer ? (
                  HTMLReactParser(item.answer)
                ) : (
                  <p className="text14 grey text-center margin0 weight400">
                    답변이 아직 작성되지 않았습니다.
                  </p>
                )}
              </div>
              <div>
                {item.answer !== "" && (
                  <OrangeMiddleButton>추가질문</OrangeMiddleButton>
                )}
              </div>
            </div>
            <div className="d-flex justify-content-end">
              <GreySmallButton>닫기</GreySmallButton>
            </div>
          </td>
        </tr>
      )} */}
    </>
  );
};
