import infinity from "@assets/images/payment/infinity.png";
import coin2 from "@assets/images/payment/coin2.png";
import coin3 from "@assets/images/payment/coin3.png";
import coin4 from "@assets/images/payment/coin4.png";
import { CoinItem } from "./CoinItem";

export const PaymentCoin = () => {
  return (
    <>
      <div>
        <p className="h3 weight600">10코인 상한제</p>
        <div className="widthHalf px-4 mt-3">
          <p className="text14 weight500">
            상품 건당 텍스트 수집 시, 과다하게 코인 차감되지 않도록 10코인
            상한제를 적용하고 있습니다.
          </p>
          <img
            className="mt-3"
            src="https://image.allttam.com/api/image?id=71fe38f7-7871-4af8-a3de-51977fd3e983"
          />
        </div>
      </div>
      <div className="col-12 mt-3">
        <div className="heading-one payment-sec">
          <h3 className="h3 weight600">코인 충전</h3>
        </div>
      </div>
      <div className="d-flex gap-5 mt-4">
        {COIN_PAYMENT.map((item, idx) => (
          <CoinItem key={idx} item={item} idx={idx} />
        ))}
      </div>
    </>
  );
};

const COIN_PAYMENT = [
  {
    id: 0,
    goodsName: "무제한 플랜",
    coin: 0,
    originalPrice: 250000,
    discountPercent: 0,
    displayPrice: 250000,
    paymentPrice: 275000,
    img: infinity,
    description: (
      <p className="text14 weight600 navy">
        상품을 발굴하는 <span className="textGreen">올땀 부스트</span> 기능{" "}
        <span className="textYellow">지원</span>
        <br />
        <span className="textGreen">코인 차감없이</span> 올땀 기능 ALL 무제한{" "}
        <span className="textYellow">가능</span>
      </p>
    ),
    buttonType: 1,
  },
  {
    id: 1,
    goodsName: "올땀 10000코인",
    coin: 10000,
    originalPrice: 200000,
    discountPercent: 20,
    displayPrice: 160000,
    paymentPrice: 176000,
    img: coin2,
    description: (
      <p className="text14 weight600 navy">
        <span className="textGreen">초보 투잡/부업</span>이신 셀러님께{" "}
        <span className="textYellow">추천</span>
        <br />
        상품 수집 건수 최소 <span className="textGreen">1천~ 5천개</span>{" "}
        <span className="textYellow">가능</span>
      </p>
    ),
    buttonType: 2,
  },
  {
    id: 2,
    goodsName: "올땀 20000코인",
    coin: 20000,
    originalPrice: 400000,
    discountPercent: 30,
    displayPrice: 280000,
    paymentPrice: 308000,
    img: coin3,
    description: (
      <p className="text14 weight600 navy">
        <span className="textGreen">알뜰한 투잡/부업</span>이신 셀러님께{" "}
        <span className="textYellow">추천</span>
        <br /> 상품 수집 건수 최소 <span className="textGreen">
          2천~1만개
        </span>{" "}
        <span className="textYellow">가능</span>
      </p>
    ),
    buttonType: 2,
  },
  {
    id: 3,
    goodsName: "올땀 50000코인",
    coin: 50000,
    originalPrice: 1000000,
    discountPercent: 40,
    displayPrice: 600000,
    paymentPrice: 660000,
    img: coin4,
    description: (
      <p className="text14 weight600 navy">
        <span className="textGreen">매출 볼륨업</span>이신 셀러님께{" "}
        <span className="textYellow">추천</span> <br />
        상품 수집 건수 최소 <span className="textGreen">
          5천~ 2만5천개
        </span>{" "}
        <span className="textYellow">가능</span>
      </p>
    ),
    buttonType: 1,
  },
];
