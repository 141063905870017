import { useEffect } from "react";
import { TextTranslateIcon } from "../svg/TextTranslateIcon";
import { HomeToolTip } from "../../Tooltip/HomeTooltip";
import { usePost } from "../../../../hooks/usePost";
import { useTooltip } from "../../../../hooks/useTooltip";

export const UserInfoTextTranslate = () => {
  const [isTooltip, handleMouseEnter, handleMouseLeave] = useTooltip();

  const textTranslate = usePost("/Log/SearchUserPoint", {
    startDate: "2022-01-01",
    endDate: new Date().toISOString().slice(0, 10),
    page: 1,
    maxRow: 10,
    filter: "CT",
  });

  useEffect(() => {
    textTranslate.mutate();
  }, []);

  return (
    <div className="col-6 col-md-3 tooltipRel">
      {isTooltip && <HomeToolTip>단위 : 100자당 3코인 차감</HomeToolTip>}
      <div
        className="calendar calendar-1"
        onMouseEnter={handleMouseEnter as () => void}
        onMouseLeave={handleMouseLeave as () => void}
      >
        <div className="calendar-inner d-flex justify-content-end">
          <TextTranslateIcon />
        </div>
        <h4 className="textEnd oneLine">
          {textTranslate?.data?.totalRows
            ? textTranslate?.data?.totalRows.toLocaleString()
            : 0}{" "}
          건
        </h4>
        <p className="textEnd oneLine">텍스트 수집</p>
      </div>
    </div>
  );
};
