interface Props {
  onClick?: () => void;
  children: string | JSX.Element;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

export const GreyBigButton = ({
  onClick,
  children,
  onMouseEnter,
  onMouseLeave,
}: Props) => {
  return (
    <button
      className="gray-button allttam-button button-big madebutton"
      onClick={onClick}
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
    >
      {children}
    </button>
  );
};
