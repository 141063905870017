
import { HomeToolTip } from "../../Tooltip/HomeTooltip";
import { useTooltip } from "../../../../hooks/useTooltip";
import { CollectProductIcon } from "../svg/CollectProductIcon";

export const UserInfoCollectProduct = ({
  collectProduct,
}: {
  collectProduct: string;
}) => {
  const [isTooltip, handleMouseEnter, handleMouseLeave] = useTooltip();

  return (
    <div className="col-6 col-md-3 tooltipRel">
      {isTooltip && <HomeToolTip>수집한 상품 개수</HomeToolTip>}
      <div
        className="calendar calendar-3"
        onMouseEnter={handleMouseEnter as () => void}
        onMouseLeave={handleMouseLeave as () => void}
      >
        <div className="calendar-inner d-flex justify-content-end">
          <CollectProductIcon />
        </div>
        <h4 className="textEnd oneLine">
          {collectProduct ? collectProduct?.toLocaleString() : 0} 건
        </h4>
        <p className="textEnd oneLine">수집한 상품</p>
      </div>
    </div>
  );
};
