import { CollectValue } from "@interface/collectInterface";

interface Props {
  collectValue: CollectValue;
  inputCollectValue: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => void;
}

export const CostcoSearchingUrl = ({
  collectValue,
  inputCollectValue,
}: Props) => {
  return (
    <div className="product-label product-label-1 col-md-9 col-12">
      <label htmlFor="product-name1">URL</label>
      <input
        onChange={inputCollectValue}
        value={collectValue.url}
        type="text"
        className="form-control"
        name="url"
        placeholder="url을 입력하세요."
      />
    </div>
  );
};
