import { AlertOnlyClose } from "@components/Alert/AlertOnlyClose";
import { GreenBigButton } from "@components/Button/allttamButton/GreenBigButton";
import { WhiteGreenBigButton } from "@components/Button/allttamButton/WhiteGreenBigButton";
import { useAlert } from "@hooks/useAlert";
import useFetch from "@hooks/useFetch";
import useUser from "@hooks/useUser";
import { CoinType } from "@interface/payment";
import axiosInstance from "@utils/axiosInstance";
import { payment } from "@utils/functions/paymentFunction";

interface Props {
  item: CoinType;
  idx: number;
}

export const CoinItem = ({ item, idx }: Props) => {
  const { isAlert, handleAlert, alertMessage } = useAlert();

  const userInfo = useUser();

  const clickCoinPay = async (coin: number, price: number) => {
    if (coin) {
      const isPossibleToPay = await axiosInstance.post(
        "/Ipay/CreateAllddamPaymentId",
        {
          type: "COIN",
          level: 0,
          grade: coin,
          salePrice: price,
        }
      );
      if (!isPossibleToPay.data.data) {
        handleAlert(isPossibleToPay.data.message);
      }
      const res = await payment({
        GoodsName: `올땀 ${coin}코인`,
        Amt: (price + price * 0.1).toString(),
        BuyerName: userInfo.originalUserName,
        BuyerTel: userInfo.userTelNo,
        BuyerEmail: userInfo.userEmail,
        mallReserved: isPossibleToPay.data.data,
      });
    } else {
      const isPossibleToPay = await axiosInstance.post(
        "/Ipay/CreateAllddamPaymentId",
        {
          type: "PLAN",
          level: 4,
          grade: 1,
          salePrice: price,
          smartStore: 1,
          coupang: 1,
          auction: 1,
          gmarket: 1,
          elevenst: 1,
        }
      );

      if (!isPossibleToPay.data.data) {
        handleAlert(isPossibleToPay.data.message);
      }

      const res = await payment({
        GoodsName: `올땀 무제한플랜`,
        Amt: (price + price * 0.1).toString(),
        BuyerName: userInfo.originalUserName,
        BuyerTel: userInfo.userTelNo,
        BuyerEmail: userInfo.userEmail,
        mallReserved: isPossibleToPay.data.data,
      });
    }
  };

  return (
    <div className="mt-3 d-flex flex-col align-items-center">
      {item.description}
      <div>
        <img src={item.img} />
        <div className="coinBox ml-7">
          <div className="d-flex justify-content-between">
            <p className="text14 weight600 navy">상품금액</p>
            <p className="text16 weight600 navy">
              {"\\"} {item.originalPrice.toLocaleString()}
            </p>
          </div>
          <div className="d-flex justify-content-between">
            <div className="d-flex align-items-end gap-3">
              <p className="text14 weight600 navy margin0">할인금액</p>
              {item.discountPercent !== 0 && (
                <p className="text14 weight800 discountBox margin0">
                  {item.discountPercent}% 할인
                </p>
              )}
            </div>
            <p className="text14 weight800 textOrange margin0">
              {"\\"}{" "}
              {(
                item.originalPrice *
                (item.discountPercent * 0.01)
              ).toLocaleString()}
            </p>
          </div>
          <hr />
          <div className="d-flex justify-content-between">
            <div className="d-flex align-items-end gap-2">
              <p className="text14 weight600 navy margin0">결제 금액</p>
              <p className="margin0 vat">(vat 별도)</p>
            </div>
            <p className="text20 weight800 textGreen margin0">
              {"\\"} {item.displayPrice.toLocaleString()}
            </p>
          </div>
          <div className="d-flex justify-content-center mt-4">
            {item.buttonType === 1 ? (
              <WhiteGreenBigButton
                onClick={() => {
                  clickCoinPay(item.coin, item.displayPrice);
                }}
              >
                충전하기
              </WhiteGreenBigButton>
            ) : (
              <GreenBigButton
                onClick={() => {
                  clickCoinPay(item.coin, item.displayPrice);
                }}
              >
                충전하기
              </GreenBigButton>
            )}
          </div>
        </div>
        {isAlert && (
          <AlertOnlyClose
            closeAlert={handleAlert as () => void}
            message={alertMessage}
          />
        )}
      </div>
    </div>
  );
};
