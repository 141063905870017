import { NavBarButtonTab } from "./component/NavBarButtonTab";
import useToggle from "@hooks/useToggle";
import "./NavBar.css";
import useFetch from "@hooks/useFetch";
import { AgreeGuard } from "@components/Guard/AgreeGuard";
import { upload } from "@utils/functions/postApi";
import { memo } from "react";
import useUser from "@hooks/useUser";

interface Props {
  children: JSX.Element;
}

const NavBar = ({ children }: Props) => {
  const userInfo = useUser();
  const { state: isNotificationBar, handleToggle: setIsNotificationBar } =
    useToggle();

  return (
    <div className="row navAlign">
      {userInfo && !userInfo?.isAgreeTerms && <AgreeGuard />}
      <div className="col-12 col-md-6">{children}</div>
      <NavBarButtonTab
        setIsNotificationBar={setIsNotificationBar as () => void}
        isNotificationBar={isNotificationBar}
      />
      {/* {isNotificationBar && (
        <NotificationBar
          setIsNotificationBar={setIsNotificationBar as () => void}
        />
      )} */}
    </div>
  );
};

export default memo(NavBar);
