import { NavyMiddleButton } from "@components/Button/allttamButton/NavyMiddleButton";
import { useInfoWindow } from "@hooks/useInfoWindow";
import axios from "axios";
import { useEffect, useState } from "react";
import styles from "../translate.module.css";
import { ResetIcon } from "@components/svg/ResetIcon";
import { ChangeIcon } from "@components/svg/ChangeIcon";
import useDebounce from "@hooks/useDebounce";

export const Translate = () => {
  const [languageValue, setLanguageValue] = useState({
    source: "ko",
    target: "zh-CN",
  });
  const [transText, setTransText] = useState("");
  const debounceValue = useDebounce(transText, 1000);
  const [resultText, setResultText] = useState("");

  const openWindow = useInfoWindow();

  const inputText = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTransText(e.target.value);
  };

  const selectLanguage = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setLanguageValue({
      ...languageValue,
      [name]: value,
    });
  };

  const submitTranselate = async () => {
    if (languageValue.source === languageValue.target)
      return openWindow("서로 다른 언어를 선택해주세요.");
    const transResult = await axios.post(
      "https://center.allttam.com/api/translate",
      {
        source: languageValue.source,
        target: languageValue.target,
        text: transText,
      }
    );
    setResultText(transResult.data.translatedText);
  };

  const autoTranslate = async (searchText: string) => {
    if (languageValue.source === languageValue.target)
      return openWindow("서로 다른 언어를 선택해주세요.");
    const transResult = await axios.post(
      "https://center.allttam.com/api/translate",
      {
        source: languageValue.source,
        target: languageValue.target,
        text: searchText,
      }
    );
    setResultText(transResult.data.translatedText);
  };

  useEffect(() => {
    if (debounceValue == "") return;
    autoTranslate(debounceValue);
  }, [debounceValue]);

  const resetTransResult = () => {
    setResultText("");
  };

  const changeLanguageEachOther = () => {
    setLanguageValue({
      target: languageValue.source,
      source: languageValue.target,
    });
  };

  return (
    <div>
      <div className="mt-3">
        <p className="margin0 grey weight700 navy">번역</p>
        <div className="d-flex gap-4 mt-2">
          <div className="widthHalf">
            <select
              className="allttam-input input-border-2"
              value={languageValue.source}
              name="source"
              onChange={selectLanguage}
            >
              {LANGUAGE.map((item) => (
                <option key={item.id} value={item.value}>
                  {item.language}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <div className="widthFull mt-3 text10">
        <textarea
          className={`allttam-input input-border-2 resize ${styles["trans-textarea"]}`}
          value={transText}
          onChange={inputText}
          onKeyDown={(e) => {
            if (!transText) return;
            if (e.key === "Enter") submitTranselate();
          }}
        />
      </div>
      <div className="d-flex justify-content-center mt-3 gap-3">
        <button
          onClick={changeLanguageEachOther}
          className={`madebutton d-flex gap-3 justify-content-center align-items-center ${styles["language-change-btn"]}`}
        >
          <p className="weight500 margin0 navy oneLine text12">언어 교환</p>
          <ChangeIcon color="#39415c" />
        </button>
        <NavyMiddleButton onClick={submitTranselate}>번역</NavyMiddleButton>
      </div>
      <div className="d-flex align-items-end gap-3">
        <div className="widthHalf mt-3">
          <select
            className="allttam-input input-border-2"
            value={languageValue.target}
            onChange={selectLanguage}
            name="target"
          >
            {LANGUAGE.map((item) => (
              <option key={item.id} value={item.value}>
                {item.language}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="d-flex justify-content-center widthFull mt-3">
        <textarea
          className={`allttam-input input-border-2 resize ${styles["trans-textarea"]}`}
          readOnly
          value={resultText}
        />
      </div>
    </div>
  );
};

const LANGUAGE = [
  { id: 0, language: "한국어", value: "ko" },
  { id: 1, language: "영어", value: "en" },
  { id: 2, language: "일본어", value: "ja" },
  { id: 3, language: "중국어(간체)", value: "zh-CN" },
  { id: 4, language: "중국어(번체)", value: "zh-TW" },
];
