import saleUp from "@assets/images/landing/saleUp.svg";
import yellowArrow from "@assets/images/landing/yellowArrow.png";

export const SeoImageTranslate = () => {
  return (
    <div className="seoMainContentRightSide component-transition">
      <div className="textEnd">
        <p className="textContentMain">이미지 번역</p>
        <p className="seoSubText oneLine">
          이미지 모든 텍스트를 <span className=" textblack"> 「전체번역」</span>{" "}
        </p>
        <p className="seoSubText oneLine">
          드래그를 이용한 <span className=" textblack"> 「영역번역」</span>
        </p>
        <p className="seoSubText oneLine">
          텍스트만 골라 제거하는{" "}
          <span className=" textblack"> 「글자제거」</span>
        </p>
      </div>
      <div className="seoMainSmallText mt-5">
        <div className="seoIconBox">
          <img src={saleUp} />
          <img src={yellowArrow} />
        </div>
        <div className="seoContentText">
          <p className="margin0 mt-4 oneLine">
            중국어, 일어, 영어 상세페이지로 판매중이세요?
          </p>
          <p className="margin0 oneLine">
            상품의 상세페이지 유입수는 있는데 판매가 일어나지 않나요?
          </p>
          <p className="margin0 oneLine">
            상품을 구매할 수 있도록{" "}
            <span className="seoContentTextBold">한국어로 정보제공</span>이
            필요합니다.
          </p>
          <p className="margin0 oneLine">
            즉, 상품의{" "}
            <span className="seoContentTextBold"> 구매전환율 상승</span>에 있어
            이미지 번역은 필수입니다.
          </p>
        </div>
      </div>
    </div>
  );
};
