import { DownArrowIcon } from "@components/svg/DownArrowIcon";
import { ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { Consult } from "./Consult";
import { PayEducation } from "./PayEducation";
import { TodayContent } from "./TodayContent";
import useFetch from "@hooks/useFetch";
import { useGetCoin } from "@hooks/useGetCoin";
import { XIcon } from "@components/Admin/AdminCategoryMapping/component/svg/XIcon";
import { XCircleIcon } from "@components/svg/XCircleIcon";
import useUser from "@hooks/useUser";

interface Props {
  close: () => void;
}

export const CareContent = ({ close }: Props) => {
  const [detail, setDetail] = useState<DetailView>("default");
  const userInfo = useUser();
  const coin = useGetCoin();
  const [payLog] = useFetch("payLog", "/Ipay/returnPay");
  const consultInfo = useMemo<ConsultInfo>(() => {
    return {
      name: userInfo?.originalUserName,
      payLog: payLog?.length,
      coin: coin,
    };
  }, [userInfo, coin, payLog]);

  const clickDetailView = (value: DetailView) => {
    setDetail(value);
  };

  return (
    <div className="care-bg" onClick={close}>
      <div
        className="care-content"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <button
          className="madebutton careCloseButton"
          onClick={(e) => {
            close();
          }}
        >
          <XIcon color="#fff" />
        </button>
        <TodayContent detail={detail} clickDetailView={clickDetailView} />
        <Consult
          consultInfo={consultInfo}
          detail={detail}
          clickDetailView={clickDetailView}
        />
        <PayEducation
          userEmail={userInfo?.userEmail}
          consultInfo={consultInfo}
          clickDetailView={clickDetailView}
          detail={detail}
        />
      </div>
    </div>
  );
};

export type DetailView = "default" | "content" | "free" | "pay";
export interface ConsultInfo {
  name: string;
  coin: number;
  payLog: number;
}
