import { GreyMiddleButton } from "@components/Button/allttamButton/GreyMiddleButton";
import { GlobalTooltip } from "@components/GlobalTooltip/GlobalTooltip";
import { QuestionMarkIcon } from "@components/svg/QuestionMarkIcon";
import { SearchingIcon } from "@components/svg/SeachingIcon";
import { SearchingIconGreen } from "@components/svg/SearchingIconGreen";
import { useTooltip } from "@hooks/useTooltip";
import { getCrawledItemList } from "../../function/getCrawledItemLIst";
import { useState } from "react";
import { CrawledKeyword } from "../../TagSettingModal";
import { upload } from "@utils/functions/postApi";
import useFetch from "@hooks/useFetch";
import { useInfoWindow } from "@hooks/useInfoWindow";
import { Keyword } from "@interface/uploadinterface";

interface Props {
  settingKeyWordValue: {
    forwardKeyword: "";
    backKeyword: "";
  };
  inputSettingKeyWordValue: () => void;
  setProductNameValueList: React.Dispatch<
    React.SetStateAction<
      {
        code: string;
        text: string;
      }[]
    >
  >;
  productNameValueList: {
    code: string;
    text: string;
  }[];
  setRelKeywordList: React.Dispatch<React.SetStateAction<CrawledKeyword>>;
  setNaverAdKeyword: React.Dispatch<React.SetStateAction<string[]>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ProductNameKeyWordSetting = ({
  inputSettingKeyWordValue,
  settingKeyWordValue,
  productNameValueList,
  setProductNameValueList,
  setRelKeywordList,
  setNaverAdKeyword,
  setIsLoading,
}: Props) => {
  const [getTagApi] = useFetch("tagAPI", "/UserConfig/GetNaverKeywordAPI");
  const [keyword, setKeyword] = useState("");
  const [isForwardTooltip, enterForward, leaveForward] = useTooltip();
  const [isBackTooltip, enterBack, leaveBack] = useTooltip();
  const openWindow = useInfoWindow();

  const clickApplyKeyWord = () => {
    if (
      settingKeyWordValue.forwardKeyword === "" &&
      settingKeyWordValue.backKeyword === ""
    )
      return;
    if (settingKeyWordValue.forwardKeyword === "") {
      const editedProductName = productNameValueList.map((item) => {
        return {
          ...item,
          text: `${item.text} ${settingKeyWordValue.backKeyword}`,
        };
      });
      setProductNameValueList(editedProductName);
    }
    if (settingKeyWordValue.backKeyword === "") {
      const editedProductName = productNameValueList.map((item) => {
        return {
          ...item,
          text: `${settingKeyWordValue.forwardKeyword} ${item.text}`,
        };
      });
      setProductNameValueList(editedProductName);
    } else {
      const editedProductName = productNameValueList.map((item) => {
        return {
          ...item,
          text: `${settingKeyWordValue.forwardKeyword} ${item.text} ${settingKeyWordValue.backKeyword}`,
        };
      });
      setProductNameValueList(editedProductName);
    }
  };

  const inputKeyword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(e.target.value);
  };

  const submitRelKeyword = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    if (!getTagApi.apiKey) openWindow("태그 API 설정이 필요합니다.");

    const searchRes = await getCrawledItemList(keyword);
    const { keywordList } = await upload.getNaverKeyword(keyword);

    setRelKeywordList(searchRes);
    setNaverAdKeyword(
      keywordList
        ? keywordList.map((item: Keyword) => item.relKeyword).slice(0, 20)
        : []
    );
    setIsLoading(false);
  };

  return (
    <div className="d-flex align-items-center gap-2 mt-3">
      <div className="oneLine width15 margin0 d-flex align-items-center gap-3">
        <p className="productNameModalSubtitle margin0">글자 추가 설정</p>
        <QuestionMarkIcon color="#39415c" />
      </div>
      <div className="width85 d-flex align-items-end justify-content-between">
        <div className="width35 relative">
          {/* <GlobalTooltip isVisible={isForwardTooltip as boolean}>
            상품명 맨앞에 글자를 추가합니다.
          </GlobalTooltip> */}
          <form onSubmit={submitRelKeyword}>
            <label htmlFor="product-name35" className="weight500 text14 grey">
              상품명 추천키워드 검색
            </label>
            <input
              value={keyword}
              onChange={inputKeyword}
              type="text"
              className="allttam-input input-middle mt-2"
              placeholder="키워드를 검색해주세요"
            />
            <button className="madebutton absolute recommend-search-btn">
              <SearchingIconGreen />
            </button>
          </form>
        </div>
        <div className="width25 relative">
          <GlobalTooltip isVisible={isForwardTooltip as boolean}>
            상품명 맨앞에 글자를 추가합니다.
          </GlobalTooltip>
          <label
            onMouseEnter={enterForward as () => void}
            onMouseLeave={leaveForward as () => void}
            htmlFor="product-name35"
            className="weight500 text14 grey"
          >
            상품명 앞글자
          </label>
          <input
            onChange={inputSettingKeyWordValue}
            value={settingKeyWordValue.forwardKeyword}
            name="forwardKeyword"
            type="text"
            className="allttam-input input-middle mt-2"
            placeholder="키워드를 입력해주세요"
          />
        </div>
        <div className="width25 relative">
          <GlobalTooltip isVisible={isBackTooltip as boolean}>
            상품명 맨뒤에 글자를 추가합니다.
          </GlobalTooltip>
          <label
            onMouseEnter={enterBack as () => void}
            onMouseLeave={leaveBack as () => void}
            htmlFor="product-name35"
            className="weight500 text14 grey"
          >
            상품명 뒤글자
          </label>
          <input
            onChange={inputSettingKeyWordValue}
            value={settingKeyWordValue.backKeyword}
            name="backKeyword"
            type="text"
            className="allttam-input input-middle mt-2"
            placeholder="키워드를 입력해주세요"
          />
        </div>
        <GreyMiddleButton onClick={clickApplyKeyWord}>적용</GreyMiddleButton>
      </div>
    </div>
  );
};
