import { useTooltip } from "@hooks/useTooltip";
import {
  EditProductTitleValueType,
  ProdcutManageResultItem,
} from "../../../../../interface/productmanageinterface";
import { GlobalTooltip } from "@components/GlobalTooltip/GlobalTooltip";

interface Props {
  wordLengthLimit: number;
  selectedItem: ProdcutManageResultItem[];
  setProductTitleValue: React.Dispatch<
    React.SetStateAction<EditProductTitleValueType[]>
  >;
  productTitleValue: {
    objectId: string;
    code: string;
    title: string;
  }[];
}

export const EidtProductTitleTable = ({
  selectedItem,
  setProductTitleValue,
  productTitleValue,
  wordLengthLimit,
}: Props) => {
  const [isLengthTooltip, enterLength, leaveLength] = useTooltip();
  const inputProductTitle = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    setProductTitleValue(
      (prev) =>
        prev.map((item) => {
          if (item.objectId === id) {
            return { ...item, title: e.target.value };
          }
          return item;
        }) as { objectId: string; code: string; title: string }[]
    );
  };

  return (
    <div className="row total-table-col mt-4">
      <div className="col-12">
        <div className="heading-two heading-three heading-five heading-five1 m-0 relative">
          <GlobalTooltip
            isVisible={isLengthTooltip as boolean}
            style={{ right: "0px", top: "-5rem" }}
          >
            <span className="margin0">
              ‘실제 글자수 / 최대 글자수 ‘ 로 표기됩니다. <br></br>실제 글자수가
              최대 글자수를 넘어서면,<br></br> 실제 글자수의 색상이 빨간색으로
              표기됩니다.
            </span>
          </GlobalTooltip>
          <table
            className="enter-product-name"
            width="100%"
            cellPadding="0"
            cellSpacing="0"
            border={0}
            align="center"
          >
            <thead className="head-one">
              <tr>
                <th>ID</th>
                <th></th>
                <th>상품명</th>
                <th
                  onMouseEnter={enterLength as () => void}
                  onMouseLeave={leaveLength as () => void}
                >
                  글자수
                </th>
              </tr>
            </thead>
            <tbody className="body-one price-one">
              {selectedItem.map((item, idx) => (
                <tr key={item.id}>
                  <td>
                    <p className="b-table-p margin0">{idx + 1}</p>
                  </td>
                  <td></td>
                  <td>
                    <div className="price-td">
                      <div className="price-state">
                        <input
                          onChange={(e) => inputProductTitle(e, item.id)}
                          type="email"
                          className="form-control pricing-input1 mt-2"
                          id="product-name35"
                          aria-describedby="emailHelp"
                          value={productTitleValue[idx]?.title}
                        />
                      </div>
                    </div>
                  </td>
                  <td>
                    <p className="b-table-l margin0">
                      <span
                        className={`${
                          productTitleValue[idx]?.title?.length >
                            wordLengthLimit && "textRed"
                        }`}
                      >
                        {productTitleValue[idx]?.title?.length}{" "}
                      </span>
                      <span> / {wordLengthLimit}</span>
                    </p>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
