import { FreeButton } from "@components/Button/LandingFreeButton/FreeButton";
import screen from "@assets/video/mobileSub.mp4";
import { useNavigate } from "react-router-dom";

export const LandingSub = () => {
  const navigate = useNavigate();
  return (
    <div className="landingSubMain relative">
      <div className="contentsBoxSub responsibleDisplay justify-content-start">
        <div className="mainTextBoxSub">
          <p className="landingMainSubText marin0 oneLine">
            상품 수집ㆍ등록의 <span className="landingTextYellow">입문서</span>
          </p>
          <h4 className="mainText oneLine mt-3">상품 소싱ㆍ판매도 원클릭</h4>
          <div className="landingMainSmallText oneLine mt-4">
            단일ㆍ대량 상품{" "}
            <span className="landingTextGreen oneLine">수집에서 등록까지</span>
            {"ㆍ"}
            <span className="landingTextGreen"></span>
            <span className="landingTextYellow">원스톱</span> 서비스
            <div className="stretchbarMain" />
            <div className="subFreeContainer">
              <FreeButton
                onClick={() => {
                  navigate("/signup");
                }}
              >
                무료 사용
              </FreeButton>
            </div>
          </div>
        </div>
        <video
          className="mobileVideo"
          playsInline
          muted
          autoPlay
          loop
          src={screen}
        />
      </div>
    </div>
  );
};
