import { GlobalTooltip } from "@components/GlobalTooltip/GlobalTooltip";
import { LeftIcon } from "@components/svg/LeftIcon(2)";
import { RightIcon } from "@components/svg/RightIcon(2)";
import { useTooltip } from "@hooks/useTooltip";
import { EditProductTitleValueType } from "@interface/productmanageinterface";
import { duplicateRemover } from "@utils/functions/duplicateRemover";
import { useState } from "react";

interface Props {
  setWordLengthLimit: React.Dispatch<React.SetStateAction<number>>;
  wordLengthLimit: number;
  setProductTitleValue: React.Dispatch<
    React.SetStateAction<EditProductTitleValueType[]>
  >;
}

export const EditWordLengthComponent = ({
  setProductTitleValue,
  setWordLengthLimit,
  wordLengthLimit,
}: Props) => {
  const [isFillNumber, setIsFillNumber] = useState<boolean>(false);
  const [isDuplicateRemove, setIsDulicateRemove] = useState<boolean>(true);
  const [isLengthTooltip, enterLength, leaveLength] = useTooltip();
  const [isNumberTooltip, enterNumber, leaveNumber] = useTooltip();
  const [isDuplicateTooltip, enterDuplicate, leaveDuplicate] = useTooltip();

  const limitAndRemoveDuplicate = () => {
    if (isFillNumber) {
      if (isDuplicateRemove) {
        setProductTitleValue((prev: EditProductTitleValueType[]) =>
          prev.map((item: EditProductTitleValueType, idx: number) => {
            const number = (idx + 1).toString().length;
            return {
              ...item,
              title:
                duplicateRemover(item.title).slice(
                  0,
                  wordLengthLimit - number
                ) +
                (idx + 1),
            };
          })
        );
      } else {
        setProductTitleValue((prev) =>
          prev.map((item, idx) => {
            return {
              ...item,
              title: item.title + (idx + 1),
            };
          })
        );
      }
    } else if (isDuplicateRemove) {
      setProductTitleValue((prev) =>
        prev.map((item) => {
          return {
            ...item,
            title: duplicateRemover(item.title).slice(0, wordLengthLimit),
          };
        })
      );
    } else {
      setProductTitleValue((prev) =>
        prev.map((item) => {
          return {
            ...item,
            title: item.title.slice(0, wordLengthLimit),
          };
        })
      );
    }
  };

  const inputWordLength = (e: React.ChangeEvent<HTMLInputElement>) => {
    setWordLengthLimit(parseInt(e.target.value));
  };

  const clickPlusWordLength = () => {
    setWordLengthLimit((prev) => prev + 1);
  };

  const clickMinusWordLength = () => {
    setWordLengthLimit((prev) => prev - 1);
  };

  const clickCheckFillNumber = () => {
    if (isFillNumber) {
      setIsFillNumber(false);
    } else {
      setIsFillNumber(true);
    }
  };

  const clickCheckDuplicateRemove = () => {
    if (isDuplicateRemove) {
      setIsDulicateRemove(false);
    } else {
      setIsDulicateRemove(true);
    }
  };

  return (
    <div className="d-flex mt-4 align-items-end gap-4">
      <p className="productNameModalSubtitle oneLine width20">글자 옵션 설정</p>
      <div className="widthFull align-items-end d-flex justify-content-between relative">
        <div className="product-label number-character width40">
          <GlobalTooltip isVisible={isLengthTooltip as boolean}>
            상품명 글자수의 최대값을 설정합니다.
          </GlobalTooltip>
          <label
            onMouseEnter={enterLength as () => void}
            onMouseLeave={leaveLength as () => void}
          >
            최대 글자 수
          </label>
          <input
            onWheel={(e) => e.currentTarget.blur()}
            onChange={inputWordLength}
            type="number"
            className="form-control"
            id="wordLength"
            value={wordLengthLimit}
          />
          <div className="next-prev-svg">
            <button className="madebutton" onClick={clickMinusWordLength}>
              <LeftIcon />
            </button>
            <button className="madebutton" onClick={clickPlusWordLength}>
              <RightIcon />
            </button>
          </div>
        </div>
        <div className="d-flex gap-5 width40 justify-content-center">
          <div className="d-flex align-items-center gap-3 attributes1">
            <div className="form-group relative">
              <GlobalTooltip isVisible={isNumberTooltip as boolean}>
                상품명 맨뒤에 일련의 숫자를 추가합니다.
              </GlobalTooltip>
              <input
                onClick={clickCheckFillNumber}
                checked={isFillNumber}
                type="checkbox"
                id="htmla1zsa"
                readOnly
              />
              <label htmlFor="htmla1zsa"></label>
            </div>
            <p
              onMouseEnter={enterNumber as () => void}
              onMouseLeave={leaveNumber as () => void}
            >
              숫자+
            </p>
          </div>
          <div className="d-flex align-items-center gap-3 attributes1">
            <div className="form-group relative">
              <GlobalTooltip isVisible={isDuplicateTooltip as boolean}>
                상품명 내 중복되는 단어들을 제거합니다.
              </GlobalTooltip>
              <input
                onClick={clickCheckDuplicateRemove}
                checked={isDuplicateRemove}
                readOnly
                type="checkbox"
                id="htmla12z"
              />
              <label htmlFor="htmla12z"></label>
            </div>
            <p
              className=""
              onMouseEnter={enterDuplicate as () => void}
              onMouseLeave={leaveDuplicate as () => void}
            >
              중복단어 -
            </p>
          </div>
        </div>

        <div className="">
          <button
            className="cancel-btn save-btn correction-btn apply-btn1"
            onClick={limitAndRemoveDuplicate}
          >
            적용
          </button>
        </div>
      </div>
    </div>
  );
};
