import { ProductManageSearchingValue } from "@interface/productmanageinterface";

interface Props {
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void
  value: ProductManageSearchingValue
}

export const InputBoxProductName = ({ onChange, value }: Props) => {
  return (
    <div className="product-label col-md-3 col-12 p-name width-devide-1">
      <label htmlFor="productName">상품명</label>
      <input
        value={value.productName}
        onChange={onChange}
        type="text"
        className="form-control"
        id="productName"
        placeholder="상품명을 입력해주세요"
        name="productName"
      />
    </div>
  );
};
