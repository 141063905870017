interface Props {
  clickClose: () => void;
  children: string;
}

export const ModalHead = ({ clickClose, children }: Props) => {
  return (
    <div className="modal-header modal-sticky">
      <h5 className="d-flex align-items-center h1">{children}</h5>
      <button type="button" className="btn-close" onClick={clickClose}></button>
    </div>
  );
};
