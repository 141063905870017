import useToggle from "@hooks/useToggle";
import { UploadSummary } from "@interface/uploadinterface";
import { EditDetailPageModal } from "../../modal/EditDetailPageModal";

interface Props {
  item: UploadSummary;
}

export const ItemFunctionEditDetailPage = ({ item }: Props) => {
  const { state: isEditDetailModal, handleToggle: handleEditDetailModal } =
    useToggle();

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <button
        onClick={handleEditDetailModal}
        className="cancel-btn save-btn reset-btn name-set-btn basic-btn"
      >
        상세페이지
      </button>
      {isEditDetailModal && (
        <EditDetailPageModal item={item} closeModal={handleEditDetailModal} />
      )}
    </div>
  );
};
