import { ResultItem } from "@interface/orderManageInterface";
import { OrderManageTableItem } from "../component/OrderManageTableItem";
import { OrderManageTableHead } from "../component/OrderManageTableHead";
import { UploadNoneResult } from "src/pages/Upload/Uploadresulttable/UploadNoneResult";
import { UseMutationResult } from "react-query";
import { Spinner } from "@components/Spinner/Spinner";

interface Props {
  orderList: ResultItem[] | undefined;
  setSelectedOrder: React.Dispatch<React.SetStateAction<ResultItem[]>>;
  selectedOrder: ResultItem[];
  isLoading: boolean;
  setOrderList: React.Dispatch<React.SetStateAction<ResultItem[]>>;
  refetchNowValue: () => void;
}

export const OrderManageTable = ({
  orderList = [],
  selectedOrder,
  setSelectedOrder,
  isLoading,
  setOrderList,
  refetchNowValue,
}: Props) => {
  return (
    <div className="row total-table-col collect-market">
      <div className="col-12">
        <div className="heading-two heading-three heading-five heading-five2">
          <table cellPadding="0" cellSpacing="0" border={0} align="center">
            <OrderManageTableHead
              selectedOrder={selectedOrder}
              orderList={orderList}
              setSelectedOrder={setSelectedOrder}
            />
            <tbody className="body-one">
              {isLoading && <TableSpinner />}
              {!orderList?.length && orderList == null ? (
                <UploadNoneResult colSpan={8} />
              ) : (
                orderList.map((item, idx) => (
                  <OrderManageTableItem
                    refetchNowValue={refetchNowValue}
                    setOrderList={setOrderList}
                    idx={idx}
                    orderList={orderList}
                    key={item.idx}
                    item={item}
                    setSelectedOrder={setSelectedOrder}
                    selectedOrder={selectedOrder}
                  />
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const TableSpinner = () => {
  return (
    <tr>
      <td className="" colSpan={8}>
        <div className="d-flex justify-content-center mt-5">
          <Spinner />
        </div>
      </td>
    </tr>
  );
};
