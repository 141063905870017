import { useEffect, useState } from "react";
import useFetch from "../../../../../hooks/useFetch";
import axios from "axios";
import { ExchangeRateTableHead } from "./ExchangeRateTableHead";
import { QuestionMarkIcon } from "@components/svg/QuestionMarkIcon";
import { ExchangeRateTableItem } from "./ExchangeRateTableItem";
import { CustomRates } from "@interface/uploadinterface";
import { useTooltip } from "@hooks/useTooltip";
import { GlobalTooltip } from "@components/GlobalTooltip/GlobalTooltip";

interface Props {
  exchangeRateValue: { exchangeCNYBase: number; exchangeUSDBase: number };
  setExchangeRateValue: React.Dispatch<
    React.SetStateAction<{
      exchangeCNYBase: number;
      exchangeUSDBase: number;
    }>
  >;
}

export const PriceSettingExchangeRate = ({
  setExchangeRateValue,
  exchangeRateValue,
}: Props) => {
  const [customsRates, setCustomsRates] = useState<CustomRates>();
  const [isTooltip, hover, leave] = useTooltip();

  const [getExchangeRate] = useFetch(
    "exChangeRate",
    "/UserSetting/GetExchange"
  );
  const [getPrice] = useFetch("price", "/Market/GetPrice");

  const getCustomRates = async () => {
    const res = await axios.get(
      `https://center.allttam.com/api/customsrates?date=${new Date()
        .toISOString()
        .split("T")[0]
        .replaceAll("-", "")}`
    );
    setCustomsRates(res.data);
  };

  useEffect(() => {
    getCustomRates();
  }, []);

  useEffect(() => {
    if (!getPrice) return;
    setExchangeRateValue({
      exchangeCNYBase: getPrice.exchangeCNYBase ? getPrice.exchangeCNYBase : 0,
      exchangeUSDBase: getPrice.exchangeUSDBase ? getPrice.exchangeUSDBase : 0,
    });
  }, [getPrice]);

  return (
    <>
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center gap-3 relative">
          <p className="margin0 weight500 navy text18">환율 설정</p>
          <QuestionMarkIcon
            color="#39415c"
            onMouseEnter={hover}
            onMouseLeave={leave}
          />
          {
            <GlobalTooltip isVisible={isTooltip}>
              올땀 적용환율의 원가격을 말합니다.
            </GlobalTooltip>
          }
        </div>
        <div className="weight500 text12 grey d-flex gap-3">
          <p className="margin0">관세 적용기간: {customsRates?.applyDate}</p>
          <p className="margin0">단위 : 1원 (\)</p>
        </div>
      </div>
      <table width="100%" className="exchangeTable mt-3">
        <ExchangeRateTableHead />
        <tbody>
          {EXCHANGE_RATE_INDEX.map((item) => (
            <ExchangeRateTableItem
              key={item.id}
              item={item}
              customRate={
                customsRates?.data.filter(
                  (country) => country.currSgn === item.currSgn
                )[0]!
              }
              exchangeRateValue={exchangeRateValue}
              setExchangeRateValue={setExchangeRateValue}
              basePrice={
                getExchangeRate?.find(
                  (country: any) => country.symbolCode === item.symbolCode
                ).basePrice
              }
            />
          ))}
        </tbody>
      </table>
    </>
  );
};

const EXCHANGE_RATE_INDEX = [
  {
    id: 0,
    country: "미국",
    currency: "달러 1 $",
    symbolCode: "FRX.KRWUSD",
    currSgn: "USD",
    base: "exchangeUSDBase",
  },
  {
    id: 1,
    country: "중국",
    currency: "위안화 1 ¥",
    symbolCode: "FRX.KRWCNY",
    currSgn: "CNY",
    base: "exchangeCNYBase",
  },
  {
    id: 2,
    country: "유럽",
    currency: "유로 1 €",
    symbolCode: "FRX.KRWEUR",
    currSgn: "EUR",
    base: "none",
  },
  {
    id: 3,
    country: "일본",
    currency: "엔화 100 ¥",
    symbolCode: "FRX.KRWJPY",
    currSgn: "JPY",
    base: "none",
  },
];
