import axiosInstance from "@utils/axiosInstance";

export const checkImgTranslate = async () => {
  const today = new Date().toISOString().slice(0, 10);

  return await check(today);
};

const check = async (today: string) => {
  const res = await axiosInstance.post("/Log/SearchUserPoint", {
    startDate: today,
    endDate: today,
    page: 1,
    maxRow: 10,
    filter: "IT",
  });
  return res.data.data.totalRows;
};
