interface Props {
  colSpan: number;
}

export const NoTagMessage = ({ colSpan }: Props) => {
  return (
    <tr>
      <th colSpan={colSpan}>
        <div className="d-flex align-items-center justify-content-center noTag weight500">
          <h6>태그가 검색되지 않았습니다.</h6>
        </div>
      </th>
    </tr>
  );
};
