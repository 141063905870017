import { ReactCropperElement } from "react-cropper";
import upIcon from "@assets/images/imgToolup.svg";
import { useTooltip } from "@hooks/useTooltip";
import { UploadTooltip } from "../../../Tooltip/UploadTooltip";

interface Props {
  cropperRef: React.RefObject<ReactCropperElement>;
  mouseHover: (string: string) => void;
  mouseLeave: () => void;
}

export const MoveUpButton = ({ cropperRef, mouseHover, mouseLeave }: Props) => {
  const handleMoveUp = () => {
    cropperRef.current?.cropper.move(0, -10);
  };

  return (
    <button
      onClick={handleMoveUp}
      className="madebutton thumbIcon tooltipRel"
      onMouseLeave={mouseLeave}
      onMouseEnter={() => mouseHover("위로 이동")}
    >
      <img src={upIcon} />
    </button>
  );
};
