export const OrderManageIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <g data-name="cart (1)">
        <path
        className="icon"
          data-name="패스 10475"
          d="M6.3 18.438a2.314 2.314 0 1 0 2.31-2.314 2.316 2.316 0 0 0-2.31 2.314zm3.016 0a.69.69 0 0 1-.692.692.692.692 0 1 1 0-1.384.69.69 0 0 1 .689.692zm3.957 0a2.314 2.314 0 1 0 2.314-2.314 2.316 2.316 0 0 0-2.317 2.314zm3.016 0a.69.69 0 0 1-.692.692.692.692 0 1 1 0-1.384.683.683 0 0 1 .689.692zM.75 1.561a.817.817 0 0 0 .811.811h1.524a.477.477 0 0 1 .476.4L4 5.064l1.351 7.644a2.635 2.635 0 0 0 2.606 2.173h8.368a2.646 2.646 0 0 0 2.575-2.033l1.83-7.741a.836.836 0 0 0-.151-.692.8.8 0 0 0-.638-.3H5.475l-.314-1.657A2.12 2.12 0 0 0 3.085.75H1.561a.817.817 0 0 0-.811.811zm18.163 4.173-1.589 6.736a1.008 1.008 0 0 1-.995.778H7.961a1.022 1.022 0 0 1-1.005-.832L5.777 5.734z"
          transform="translate(4.25 4.25)"
          fill="#fff"
        />
        <path
          className="icon"
          data-name="패스 10476"
          d="M9.951 9.712h3.492a.811.811 0 1 0 0-1.622H9.951a.811.811 0 0 0 0 1.622z"
          transform="translate(4.931 4.846)"
          fill="#fff"
        />
      </g>
      <path data-name="사각형 3748" fill="none" d="M0 0h30v30H0z" />
    </svg>
  );
};
