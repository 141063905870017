import { PayLogType } from "../PaymentLog";

interface Props {
  log: PayLogType;
}

export const PayLogItem = ({ log }: Props) => {
  return (
    <tr>
      <td>
        <p className="b-table-p">{log.goodsName}</p>
      </td>
      <td>
        <p>{log.cardIssueName}</p>
      </td>
      <td>
        <p>{parseInt(log.goodsAmt).toLocaleString("KR")} 원</p>
      </td>
      <td>
        <p>{log.buyerPhoneNo}</p>
      </td>
      <td>
        <p>{log.statusName}</p>
      </td>
      <td>
        <p className="b-table-l">
          {log.createDate.split("T")[0].replaceAll("-", ". ")}{" "}
          {log.createDate.split("T")[1]}
        </p>
      </td>
    </tr>
  );
};
