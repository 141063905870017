import axios from "axios";
import { CrawlKeywordType } from "../component/TagSetting/KeywordSearching";

export const getCrawledItemList = async (keyword: string) => {
  const relList: string[] = [];
  const autoList: string[] = [];
  try {
    const coupangRes = await axios.get(
      `https://center.allttam.com/api/coupangkeyword?keyword=${keyword}`
    );
    coupangRes.data.rel.forEach((item: CrawlKeywordType) => {
      if (relList.includes(item.keyword)) return;
      else return relList.push(item.keyword);
    });

    coupangRes.data.auto.forEach((item: CrawlKeywordType) => {
      if (autoList.includes(item.keyword)) return;
      else return autoList.push(item.keyword);
    });
  } catch (e) {}

  const naverRes = await axios.get(
    `https://center.allttam.com/api/naverkeyword?keyword=${keyword}`
  );
  naverRes.data.rel.forEach((item: CrawlKeywordType) => {
    if (relList.includes(item.keyword)) return;
    else return relList.push(item.keyword);
  });

  naverRes.data.auto.forEach((item: CrawlKeywordType) => {
    if (autoList.includes(item.keyword)) return;
    else return autoList.push(item.keyword);
  });

  return {
    rel: relList,
    auto: autoList,
  };
};
