export const OptionDefaultImgIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <path
        d="M16.167 2H4.5A2.5 2.5 0 0 0 2 4.5v11.667a2.5 2.5 0 0 0 2.5 2.5h11.667a2.342 2.342 0 0 0 .408-.042l.25-.058h.1l.308-.117.108-.058c.083-.05.175-.092.258-.15a3.158 3.158 0 0 0 .317-.267l.058-.075a2.242 2.242 0 0 0 .225-.267l.075-.108a1.925 1.925 0 0 0 .15-.292.833.833 0 0 0 .058-.125c.042-.1.067-.208.1-.317v-.125a2.167 2.167 0 0 0 .083-.5V4.5A2.5 2.5 0 0 0 16.167 2zM4.5 17a.833.833 0 0 1-.833-.833v-3.592l2.742-2.75a.833.833 0 0 1 1.183 0L14.758 17zm12.5-.833a.677.677 0 0 1-.125.417.784.784 0 0 1-.075.1l-4.458-4.458.733-.733a.833.833 0 0 1 1.183 0L17 14.242zm0-4.283-1.567-1.55a2.567 2.567 0 0 0-3.533 0l-.733.733-2.4-2.4a2.567 2.567 0 0 0-3.533 0l-1.567 1.55V4.5a.833.833 0 0 1 .833-.833h11.667A.833.833 0 0 1 17 4.5z"
        transform="translate(4.667 4.667)"
        fill="#a7b0c7"
      />
      <path data-name="사각형 3970" fill="none" d="M0 0h30v30H0z" />
    </svg>
  );
};
