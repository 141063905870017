import { CollectValue } from "@interface/collectInterface";

interface Props {
  collectValue: CollectValue;
  inputCollectValue: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => void;
}

export const CostcoSearchingImgSelect = ({
  collectValue,
  inputCollectValue,
}: Props) => {
  return (
    <div className="product-label select-image col-md-3 col-12">
      <label htmlFor="product-name3">대표 이미지 선택</label>
      <div className="state-selection">
        <select
          onChange={inputCollectValue}
          name="imageIndex"
          value={collectValue.imageIndex}
          className="form-select"
        >
          <option value={1}>1</option>
          <option value={2}>2</option>
          <option value={3}>3</option>
          <option value={4}>4</option>
        </select>
      </div>
    </div>
  );
};
