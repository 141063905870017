function madeYearMonthDayTime() {
  const curr = new Date();
  const utc = curr.getTime() + curr.getTimezoneOffset() * 60 * 1000;

  // 3. UTC to KST (UTC + 9시간)
  const KR_TIME_DIFF = 9 * 60 * 60 * 1000;
  const kr_curr = new Date(utc + KR_TIME_DIFF);

  const date = new Intl.DateTimeFormat("kr", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  })
    .format(kr_curr)
    .replaceAll(".", "")
    .replaceAll(" ", "");

  const time = kr_curr.toTimeString().split(" ")[0].replaceAll(":", "");

  return `${date}${time}`;
}
export default madeYearMonthDayTime;
