import { useState } from "react";
import { SignupDefault } from "./SignupDefault";
import { Signup } from "./Signup";

export const SignupComponent = () => {
  const [isAllttamSignup, setIsAllttamSignup] = useState(false);

  return (
    <div className="widthFull d-flex flex-column gap-2">
      {isAllttamSignup ? (
        <Signup setIsAllttamSignup={setIsAllttamSignup} />
      ) : (
        <SignupDefault setIsAllttamSignup={setIsAllttamSignup} />
      )}
    </div>
  );
};
