import greyA from "@assets/images/logo/allttamAgrey.svg";

interface Props {
  setComponent: React.Dispatch<React.SetStateAction<"sub" | "main">>;
  isOffline: boolean;
}

export const StarterMainContent = ({ setComponent, isOffline }: Props) => {
  const changeSub = () => {
    setComponent("sub");
  };
  return (
    <>
      <div>
        <div className="d-flex align-items-center gap-5 mt-2">
          <div className="d-flex gap-3">
            <img src={greyA} className="greyA" />
            <p className="margin0 text20 weight500 navy">
              <span className="weight600 textGreen text25">기본 셋팅</span> 진행
            </p>
          </div>
          <div className="d-flex gap-2">
            <p className="margin0 none-select weight500">셋팅 내용</p>
            <button
              className="madebutton starter-check-button"
              onClick={changeSub}
            >
              확인하기
            </button>
            {isOffline && (
              <p className="margin0 none-select weight500">노트북 필수</p>
            )}
          </div>
        </div>
        <div className="d-flex align-items-center gap-5 mt-2">
          <div className="d-flex gap-3">
            <img src={greyA} className="greyA" />
            <p className="margin0 text20 weight500 navy">
              S1 플랜
              <span className="weight600 textGreen text25"> 2개월</span> 제공
            </p>
          </div>
          <p className="margin0 none-select weight500">
            만료일 이후 기간 연장 신청 가능해요
          </p>
        </div>
        <div className="d-flex align-items-center gap-5 mt-2">
          <div className="d-flex gap-3">
            <img src={greyA} className="greyA" />
            <p className="margin0 text20 weight500 navy">
              <span className="weight600 textGreen text25">5,000 코인</span>{" "}
              제공
            </p>
          </div>
          <p className="margin0 none-select weight500">
            10만원 상당의 코인을 제공해드려요
          </p>
        </div>
        <div className="d-flex align-items-start gap-3 mt-2">
          <img src={greyA} className="greyA" />
          <div>
            <div className="d-flex align-items-center gap-5">
              <p className="margin0 text20 weight500 navy">
                <span className="weight600 textGreen text25">10% 캐시백</span>{" "}
                추천인 코드 제공
              </p>
              <p className="margin0 none-select weight500">
                올땀파트너스 가입 시 혜택을 제공해드려요
              </p>
            </div>
            <p className="margin0 none-select weight500 mt-2">
              스타트 패키지 결제 후, 채널톡/올땀케어를 통해 반드시 원격셋팅{" "}
              <span className="textYellow">일정 조율을 요청</span>해주세요.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
