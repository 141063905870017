import { EraseIcon } from "@components/svg/EraseIcon";
import { usePost } from "@hooks/usePost";
import { useScroll } from "@hooks/useScroll";
import { Keyword } from "@interface/uploadinterface";
import { comparePriority } from "@utils/functions/compareGrade";
import { upload } from "@utils/functions/postApi";
import { compareValues } from "@utils/functions/settingTagCompare";
import { useEffect, useState } from "react";
import { TagSettingSearchingBarProductManage } from "src/pages/Upload/modal/component/TagSetting/TagSettingSearchingBarProductManage";
import { TagSettingSelectTopButton } from "src/pages/Upload/modal/component/TagSetting/TagSettingSelectTopButton";
import { TagSettingTable } from "src/pages/Upload/modal/component/TagSetting/TagSettingTable";

interface Props {
  selectedTag: string[];
  setSelectedTag: React.Dispatch<React.SetStateAction<string[]>>;
}

export const EditProductTagSetting = ({
  selectedTag,
  setSelectedTag,
}: Props) => {
  const [searchAdKeywordList, setSearchAdKeywordList] = useState<Keyword[]>([]);
  const { count, scrollEvent } = useScroll(45);

  // const getSearchKeyword = usePost("/Market/GetNaverKeyword", keywordValue);
  const [renderData, setRenderData] = useState<Keyword[]>([]);
  const [sortData, setSortData] = useState<Keyword[]>([]);

  useEffect(() => {
    setSortData(searchAdKeywordList);
  }, [searchAdKeywordList]);

  useEffect(() => {
    setRenderData(sortData?.slice(0, 100 * count));
  }, [sortData, count]);

  const clickSort = (keyword: string, sortWay: "desc" | "asc") => {
    if (!sortData) {
      return;
    }
    setSortData(
      [...sortData].sort((a: any, b: any) => {
        switch (true) {
          case isNaN(a[keyword]) && isNaN(b[keyword]):
            return compareValues(a, b, keyword, sortWay);
          case isNaN(a[keyword]):
            return sortWay === "desc" ? 1 : -1;
          case isNaN(b[keyword]):
            return sortWay === "desc" ? -1 : 1;
          default:
            return compareValues(a, b, keyword, sortWay);
        }
      })
    );
  };

  const clickSortLiteral = (keyword: string, sortWay: "desc" | "asc") => {
    if (!sortData) {
      return;
    }
    setSortData(
      [...sortData].sort((a: any, b: any) => {
        const valueA =
          typeof a[keyword] === "string"
            ? a[keyword].toLowerCase()
            : a[keyword];
        const valueB =
          typeof b[keyword] === "string"
            ? b[keyword].toLowerCase()
            : b[keyword];
        return sortWay === "asc"
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      })
    );
  };

  const clickSortGrade = (keyword: string, sortWay: "desc" | "asc") => {
    if (!sortData) {
      return;
    }
    setSortData(
      [...sortData].sort((a: any, b: any) => {
        const valueA = comparePriority(a[keyword]);
        const valueB = comparePriority(b[keyword]);
        return sortWay === "asc" ? valueA - valueB : valueB - valueA;
      })
    );
  };

  const clickReset = () => {
    setSelectedTag([]);
  };

  const deleteTag = (tag: string) => {
    setSelectedTag(selectedTag.filter((item) => item !== tag));
  };

  return (
    <div className="inner-contents-padding">
      <TagSettingSearchingBarProductManage
        setSearchAdKeywordList={setSearchAdKeywordList}
      />
      <TagSettingSelectTopButton
        selectedTag={selectedTag}
        sortData={sortData}
        setSelectedTag={setSelectedTag}
      />
      <TagSettingTable
        clickSortGrade={clickSortGrade}
        clickSortLiteral={clickSortLiteral}
        clickSort={clickSort}
        keywordList={renderData}
        selectedTag={selectedTag}
        setSelectedTag={setSelectedTag}
        scrollEvent={scrollEvent}
      />
      <div className="row">
        <div className="col-12">
          <div className="tags-selecter-text mt-3">
            <p>
              선택된 태그 개수 <span>{selectedTag?.length}</span>
            </p>
          </div>
        </div>
        <div className="col-12 tags-btn mb-1 ">
          {selectedTag.map((item) => (
            <span key={item} className="tag-1 tag">
              <span className="margin0 d-flex align-items-center">
                {item}
                <button
                  onClick={() => deleteTag(item!)}
                  className="madebutton paddingZero"
                >
                  <EraseIcon />
                </button>
              </span>
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};
