export const DefaultProfileIcon = () =>{

  return (
    <svg
                    className="profile"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M4.679 10.84a21.537 21.537 0 0 1 3.461 0 14.111 14.111 0 0 1 1.869.235 3.485 3.485 0 0 1 2.58 1.425 1.97 1.97 0 0 1 0 1.772 3.34 3.34 0 0 1-2.6 1.423 13.425 13.425 0 0 1-1.869.242A15.936 15.936 0 0 1 6.374 16H5.316a5.049 5.049 0 0 0-.644-.043 12.8 12.8 0 0 1-1.872-.235A3.441 3.441 0 0 1 .222 14.3 1.952 1.952 0 0 1 0 13.4a1.93 1.93 0 0 1 .215-.9A3.4 3.4 0 0 1 2.8 11.075a13.653 13.653 0 0 1 1.879-.235zM6.4 0a4.262 4.262 0 0 1 4.2 4.32 4.262 4.262 0 0 1-4.2 4.32 4.262 4.262 0 0 1-4.2-4.32A4.262 4.262 0 0 1 6.4 0z"
                      transform="translate(5.6 4)"
                      fill="#a7b0c7"
                    ></path>
                    <path
                      data-name="사각형 3573"
                      fill="none"
                      d="M0 0h24v24H0z"
                    ></path>
                  </svg>
  )
}