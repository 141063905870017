import { useEffect, useState } from "react";
import { UploadTable } from "./components/UploadTable";
import { useScroll } from "../../../hooks/useScroll";
import { UploadLog } from "../../../interface/defaultinterface";
import useFetch from "../../../hooks/useFetch";
import { RefreshIcon } from "../../svg/RefreshIcon";
import useSelectHelperComponent from "@hooks/useSelectHelperComponent";
import { QuestionMarkIcon } from "@components/svg/QuestionMarkIcon";

export const HomeUploadLog = () => {
  const openHelper = useSelectHelperComponent();
  const { count, scrollEvent } = useScroll(43);
  const [renderData, setRenderData] = useState<UploadLog[]>([]);
  const [uploadLog, uploadLogRefetch] = useFetch(
    "uploadLog",
    "/Log/GetUploadLogAll?param=All"
  );

  useEffect(() => {
    if (uploadLog) {
      const newArr = [...uploadLog];
      setRenderData(newArr.reverse().slice(0, 100 * count));
    }
  }, [uploadLog, count]);

  return (
    <div className="col-md-6 col-12">
      <div className="heading-two heading-three heading-five heading-six">
        <div className="heading-two-inner">
          <div className="d-flex align-items-center gap-3">
            <h6>업로드 기록</h6>
            <button
              onClick={() => {
                // openHelper("detail", "64e383e98081d5317ca7761f");
              }}
              className="madebutton paddingZero"
            >
              <QuestionMarkIcon color="#39415c" />
            </button>
          </div>
          <div className="heading-two-inner-1 heading-five-inner heading-six-inner">
            <button
              className="madebutton"
              onClick={() => {
                uploadLogRefetch();
              }}
            >
              <RefreshIcon />
            </button>
          </div>
        </div>
        <div className="logTableWrapper" onScroll={scrollEvent}>
          {<UploadTable uploadLog={renderData} />}
        </div>
      </div>
    </div>
  );
};
