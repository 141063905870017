import { FreeButton } from "@components/Button/LandingFreeButton/FreeButton";
import mobileMain from "@assets/video/mobileMain.mp4";
import { useNavigate } from "react-router-dom";

export const LandingMain = () => {
  const navigate = useNavigate();
  return (
    <div className="relative landingMain">
      <div className="contentsBox responsibleDisplay">
        <div className="mainTextBox">
          <p className="landingMainSubText marin0 oneLine">
            글로벌셀러의 <span className="landingTextYellow">바이블</span>
          </p>
          <h4 className="mainText oneLine mt-3">해외구매대행 솔루션</h4>
          <div className="landingMainSmallText oneLine mt-4">
            상품{" "}
            <span className="landingTextGreen oneLine">수집</span>
            {"ㆍ"}
            <span className="landingTextGreen">편집</span>
            {"ㆍ"}
            <span className="landingTextGreen">등록</span>
            {"ㆍ"}
            <span className="landingTextGreen">관리</span>
            {"ㆍ"}
            <span className="landingTextGreen">교육</span> 등{" "}
            <span className="landingTextYellow">올케어</span> 시스템
            <div className="stretchbarMain" />
            <div className="d-flex justify-content-end">
              <FreeButton
                onClick={() => {
                  navigate("/signup");
                }}
              >
                무료 사용
              </FreeButton>
            </div>
          </div>
        </div>
        <video
          src={mobileMain}
          playsInline
          muted
          autoPlay
          loop
          className="mobileVideo"
        />
      </div>
    </div>
  );
};
