import { DownArrowIcon } from "@components/svg/DownArrowIcon";
import { QuestionMarkIcon } from "@components/svg/QuestionMarkIcon";
import useSelectHelperComponent from "@hooks/useSelectHelperComponent";
import useToggle from "@hooks/useToggle";
import { CollectValue } from "@interface/collectInterface";
import { MemoSelect } from "../../MemoSelect/MemoSelect";

interface Props {
  collectValue: CollectValue;
  inputCollectValue: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => void;
  setCollectValue: React.Dispatch<React.SetStateAction<CollectValue>>;
}

export const AliSearchingMemo = ({
  setCollectValue,
  collectValue,
  inputCollectValue,
}: Props) => {
  const { state: isSelect, handleToggle: handleSelect } = useToggle();
  const openHelper = useSelectHelperComponent();
  return (
    <div className="product-label product-label-1 col-md-6 col-12">
      <div className="d-flex align-items-center gap-3 mb-2">
        <label htmlFor="product-name" className="paddingZero">
          메모
        </label>
        <button
          onClick={() => {
            // openHelper("detail", "64e5ecf38081d5317ca779d4")
          }}
          className="madebutton d-flex align-items-center paddingZero"
        >
          <QuestionMarkIcon color="#39415c" />
        </button>
      </div>
      <div className="relative">
        <input
          onChange={inputCollectValue}
          value={collectValue.memo}
          type="text"
          name="memo"
          className="form-control"
          placeholder="메모를 입력해주세요"
        />
        <button
          className="madebutton collect-group-select-btn"
          onClick={handleSelect}
        >
          <DownArrowIcon />
        </button>
        {isSelect && (
          <MemoSelect
            setCollectValue={setCollectValue}
            closeModal={handleSelect}
          />
        )}
      </div>
    </div>
  );
};
