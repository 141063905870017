import { useEffect, useState } from "react";
import blackPin from "../../../../../assets/images/landing/earth/blackPin.svg";

interface Props {
  selectedState: string[];
}

export const FirstBlackPin = ({ selectedState }: Props) => {
  const calScale = () => {
    if (window.innerWidth / 1920 < 1) {
      return window.innerWidth / 1920;
    } else {
      return 1;
    }
  };

  return (
    <img
      src={blackPin}
      style={{
        position: "absolute",
        top: LOCATION_INDEX[selectedState[0] as EarthState].top,
        left: LOCATION_INDEX[selectedState[0] as EarthState].left,
        transition: "all 1s ease",
        transform: `scale(${calScale}`,
      }}
    />
  );
};

type EarthState = "order" | "cs" | "profit" | "influent";

const LOCATION_INDEX = {
  order: {
    top: "17%",
    left: "44%",
  },
  cs: {
    top: "52%",
    left: "19%",
  },
  profit: {
    top: "23%",
    left: "18%",
  },
  influent: {
    top: "52%",
    left: "19%",
  },
};
