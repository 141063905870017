import useFetch from "@hooks/useFetch";
import { DetailView } from "./CareContent";
import { useMemo } from "react";

interface Props {
  clickDetailView: (value: DetailView) => void;
  detail: DetailView;
}

export const TodayContent = ({ detail, clickDetailView }: Props) => {
  const [todayContent] = useFetch(
    "todayContent",
    "https://center.allttam.com/api/banner/today"
  );

  const videoId = useMemo(() => {
    return todayContent?.url?.split("https://youtu.be/")[1];
  }, [todayContent]);

  return (
    <div className="d-flex flex-col align-items-center">
      <p
        className="careTitle widthFull margin0"
        onClick={() => {
          clickDetailView("content");
        }}
      >
        오늘의 맞춤 컨텐츠
      </p>
      {detail !== "pay" && (
        <iframe
          className="mt-3 iframeVideo"
          width="338"
          height="190"
          src={`https://www.youtube.com/embed/${videoId}?autoplay=1&mute=1`}
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      )}
    </div>
  );
};
