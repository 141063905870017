import dots from "../../../../../assets/images/landing/earth/threeWhiteDots.svg";
import svg8 from "../../../../../assets/images/landing/earth/svg/svg8.svg";
import svg7 from "../../../../../assets/images/landing/earth/svg/svg7.svg";
import svg11 from "../../../../../assets/images/landing/earth/svg/svg11.svg";

interface Props {
  style?: any;
}

export const EarthMobileProfitWindow = ({ style }: Props) => {
  return (
    <div className="landingMobileEarthBoard mt-5" style={style}>
      <div className="d-flex justify-content-end widthFull">
        <img src={dots} className="mb-2" />
      </div>
      <div className="landingMobileEarthBoardInner">
        <div className="d-flex justify-content-center">
          <p className="mobileEarthMainTitle">순수익 계산</p>
        </div>
        <ul className="moblieEarthTextBox">
          <li className="d-flex align-items-start gap-3">
            <img src={svg8} />
            <div>
              <p className="moblieEarthTextBoxMain">예상수익 계산기</p>
              <p className="moblieEarthTextBoxSub">
                판매자가 사전에 정한 값으로 예상수익 자동 계산
              </p>
            </div>
          </li>
          <li className="d-flex align-items-start gap-3">
            <img src={svg11} />
            <div>
              <p className="moblieEarthTextBoxMain">매출과 순수익 그래프</p>
              <p className="moblieEarthTextBoxSub">
                예상수익 계산기의 데이터를 기반으로 그래프 제공
              </p>
            </div>
          </li>
          <li className="d-flex align-items-start gap-3">
            <img src={svg7} />
            <div>
              <p className="moblieEarthTextBoxMain">
                부가가치세 신고 증빙 서류
              </p>
              <p className="moblieEarthTextBoxSub">
                부가가치세 신고 시 세무 제출 Format 으로 엑셀 제공
              </p>
            </div>
          </li>
        </ul>
        <p className="moblieEarthTextBoxSub">
          모든 오픈마켓의 매출과 순수익을 조회·관리할 수 있습니다.
        </p>
        <p className="moblieEarthTextBoxSub">
          부가가치세 신고에 필요한 구매대행 증빙 내역으로 추출합니다.
        </p>
      </div>
    </div>
  );
};
