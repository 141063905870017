import { useState } from "react";
import { CollectValue, CollectWay } from "@interface/collectInterface";
import { collect } from "@utils/functions/postApi";
import { useAlert } from "@hooks/useAlert";
import chromeAPI from "@utils/functions/chromeAPI.js";
import { CollectItem1688 } from "./component/CollectItem1688";
import { Spinner } from "@components/Spinner/Spinner";
import { SearchingBoard1688 } from "./component/SearchingBoard1688";
import { CollectMultiSubMenu } from "../CollectTaobao/component/TaobaoMultiSubMenu";
import { MultiSelectedCollect } from "@components/MultiSelectedCollect/MultiSelectedCollect";
import { AlertOnlyClose } from "@components/Alert/AlertOnlyClose";
import { useInfoWindow } from "@hooks/useInfoWindow";
import urlencode from "urlencode";

interface Props {
  collectWay: CollectWay;
  setCollectWay: React.Dispatch<React.SetStateAction<CollectWay>>;
  COLLECT_VALUE_DEFAULT: CollectValue;
}

export const Collect1688 = ({
  collectWay,
  setCollectWay,
  COLLECT_VALUE_DEFAULT,
}: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { isAlert, handleAlert, alertMessage } = useAlert();
  const [collectValue, setCollectValue] = useState<CollectValue>(
    COLLECT_VALUE_DEFAULT
  );
  const [collectData, setCollectData] = useState<any>([]);
  const [selectedCollectItem, setSelectedCollectItem] = useState<string[]>([]);
  const resetValue = () => {
    setCollectValue(COLLECT_VALUE_DEFAULT);
  };

  const openWindow = useInfoWindow();

  const inputCollectValue = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setCollectValue({
      ...collectValue,
      [name]: value,
    });
  };

  const clickCollect = async (page: number) => {
    if (collectValue.url === "") {
      return handleAlert("검색 url 혹은 키워드를 입력해주세요.");
    }
    if (collectValue.groupName === "") {
      return handleAlert("상품 그룹명을 입력해주세요.");
    }
    if (collectWay === "one") {
      try {
        const collectRes = await collect.collect1688UsingApi(collectValue);
        if (collectRes.result) {
          handleAlert(collectRes.result);
        }
      } catch (e: any) {
        handleAlert(e.response.data.result);
      }
    } else {
      setIsLoading(true);
      const multiRes = await chromeAPI.oseeItemSearch(
        urlencode(collectValue.url, "gbk"),
        page
      );
      if (multiRes.responseMsg.code === 200) {
        setIsLoading(false);
        setCollectData(multiRes.responseMsg.data);
      } else {
        openWindow("검색결과가 없습니다.");
        setCollectData([]);
        setIsLoading(false);
      }
    }
  };

  const resetSelect = () => {
    setSelectedCollectItem([]);
  };

  const clickCollectMulti = async () => {
    try {
      if (collectValue.groupName !== "") {
        const collectRes = await collect.collect1688UsingExtension({
          duplicate: collectValue.duplicate,
          groupName: collectValue.groupName,
          imageIndex: collectValue.imageIndex,
          itemIds: selectedCollectItem.map((item) => item.toString()),
          memo: collectValue.memo,
          translate: collectValue.translate,
          url: `https://s.1688.com/selloffer/offer_search.htm?keywords=${collectValue.url}`,
        });
        if (collectRes.result) {
          handleAlert(collectRes.result);
          resetSelect();
        }
      } else {
        handleAlert("상품 그룹명을 입력해주세요.");
      }
    } catch (e: any) {
      handleAlert(e.response.data.result);
    }
  };

  const clickNextPage = async () => {
    clickCollect(collectData.trace.beginPage + 1);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const clickPrevPage = async () => {
    clickCollect(collectData.trace.beginPage - 1);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const clickPage = async (e: React.MouseEvent<HTMLButtonElement>) => {
    clickCollect(parseInt(e.currentTarget.value));
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <SearchingBoard1688
        clickCollect={clickCollect}
        collectWay={collectWay}
        setCollectWay={setCollectWay}
        inputCollectValue={inputCollectValue}
        collectValue={collectValue}
        resetValue={resetValue}
        setCollectValue={setCollectValue}
      />
      {isLoading && (
        <div className="spinnerContainer">
          <Spinner />
        </div>
      )}
      {!isLoading && collectWay === "multi" && collectData?.offerList && (
        <>
          <MultiSelectedCollect
            clickPage={clickPage}
            totalPages={collectData.pageCount}
            currentPage={collectData.trace.beginPage}
            clickNextPage={clickNextPage}
            clickPrevPage={clickPrevPage}
            resetSelect={resetSelect}
            clickCollectMulti={clickCollectMulti}
            length={selectedCollectItem.length}
          />
          <CollectMultiSubMenu
            itemList={collectData?.offerList}
            setSelectedCollectItem={setSelectedCollectItem}
            totalData={collectData?.totalCount}
            selectedCollectItem={selectedCollectItem}
            site="1688"
          />
          <div className="row justify-content-center">
            {collectData.offerList?.map((offer: any, idx: number) => (
              <CollectItem1688
                key={idx}
                offer={offer}
                selectedCollectItem={selectedCollectItem}
                setSelectedCollectItem={setSelectedCollectItem}
              />
            ))}
          </div>
        </>
      )}
      {isAlert && (
        <AlertOnlyClose
          message={alertMessage}
          closeAlert={handleAlert as () => void}
        />
      )}
    </>
  );
};
