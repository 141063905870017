import { useTooltip } from "@hooks/useTooltip";

interface Props {
  isNav: boolean;
}

export const SideBarAnchorBox = ({ isNav }: Props) => {
  const [isYoutube, enterYoutube, leaveYoutube] = useTooltip();
  const [isCafe, enterCafe, leaveCafe] = useTooltip();

  return (
    <div className="anchorWrapper">
      <div className="sidebarBottom">
        <a
          onMouseEnter={enterYoutube as () => void}
          onMouseLeave={leaveYoutube as () => void}
          href="https://www.youtube.com/@allttam"
          target="_blank"
          className="relative"
        >
          {!isNav && isYoutube && (
            <div className="nav-tooltip2 oneLine">공식 유튜브</div>
          )}
          <img src="/images/lnb_youtube.svg" alt="유튜브" />
          <p>유튜브</p>
        </a>
        <div className="divider" />
        <a
          onMouseEnter={enterCafe as () => void}
          onMouseLeave={leaveCafe as () => void}
          href="https://cafe.naver.com/mandeunydle"
          target="_blank"
          className="relative"
        >
          {!isNav && isCafe && (
            <div className="nav-tooltip2 oneLine">공식 N카페</div>
          )}
          <img src="/images/lnb_naver.png" alt="네이버" />
          <p>카페</p>
        </a>
      </div>
    </div>
  );
};
