import { UseMutationResult } from "react-query";
import { SelectCS } from "../OrderManageCS";
import { CSTableHead } from "../component/CSTableHead";
import { CSTableItem } from "../component/CSTableItem";
import { UploadNoneResult } from "src/pages/Upload/Uploadresulttable/UploadNoneResult";
import { Spinner } from "@components/Spinner/Spinner";

interface Props {
  CSList: UseMutationResult<any, unknown, void, unknown>;
  selectedCS: SelectCS | undefined;
  setSelectedCS: React.Dispatch<React.SetStateAction<SelectCS | undefined>>;
}

export const CSTable = ({ CSList, selectedCS, setSelectedCS }: Props) => {
  return (
    <div className="row total-table-col">
      <div className="col-12">
        <div className="heading-two heading-three heading-five heading-five2 market-name-col">
          <table className="csTable" width="100%">
            <CSTableHead />
            <tbody className="body-one">
              {Array.isArray(CSList?.data) &&
                CSList?.data?.map((cs: CS) => (
                  <CSTableItem
                    key={cs.no}
                    cs={cs}
                    setSelectedCS={setSelectedCS}
                    selectedCS={selectedCS}
                  />
                ))}
              {CSList.isLoading && (
                <tr>
                  <td colSpan={9}>
                    <div
                      className="textCenter mt-5"
                      style={{
                        border: "none",
                      }}
                    >
                      <div className="widthFull d-flex justify-content-center">
                        <Spinner />
                      </div>
                    </div>
                  </td>
                </tr>
              )}
              {(Array.isArray(!CSList?.data) || CSList?.data?.length === 0) && (
                <UploadNoneResult colSpan={9} />
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export interface CS {
  content: string;
  csId: string;
  market: string;
  marketProductUrl: string;
  no: number;
  orderNo: string;
  productName: string;
  productNo: string;
  questioner: string;
  regDate: string;
  title: string;
  token: string;
}
