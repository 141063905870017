import Spacing from "@components/shared/Spacing";
import { SelectHTMLAttributes } from "react";

interface Props {
  label?: string;
  children?: React.ReactNode;
}

interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  children: React.ReactNode;
}

const TextField = ({ label, children }: Props) => {
  return (
    <div className="d-flex flex-column">
      <p className="margin0 h5">{label}</p>
      <Spacing.Height height="height8" />
      {children}
    </div>
  );
};

const Select = ({ children, ...props }: SelectProps) => {
  return (
    <select {...props} className="allttam-input input-border-2">
      <option value="">값을 선택해주세요</option>
      {children}
    </select>
  );
};

TextField.Select = Select;

export default TextField;
