import { Input } from "@components/Input/Input";
import styles from "./NoticeSearchBoard.module.css";
import TextField from "@components/Input/TextField";
import { SearchingBoardPeriodComponent } from "src/pages/Productmanage/ProductManageSearchingBoard/component/SearcingBoardPeriodComponent";
import { ChangeEvent, useCallback, useState } from "react";
import Spacing from "@components/shared/Spacing";
import { CheckBoxInput } from "@components/Input/CheckBoxInput";
import { GreenBigButton } from "@components/Button/allttamButton/GreenBigButton";
import { SearchingIcon } from "@components/svg/SeachingIcon";
import { SearchValue } from "@interface/uploadinterface";
import { NoticeSearchValues } from "@interface/notice";
import useNoticeCategory from "./hooks/useNoticeCategory";

interface Props {
  onSubmit: (searchValues: NoticeSearchValues) => void;
}

export const NoticeSearchBoard = ({ onSubmit }: Props) => {
  const [searchValues, setSearchValues] = useState<
    Omit<NoticeSearchValues, "startDate" | "endDate">
  >({
    keyword: "",
    category: "",
    isPinned: "",
  });

  const { data: categoies } = useNoticeCategory();

  const [date, setDate] = useState({
    startDate: new Date(new Date().setMonth(new Date().getMonth() - 1))
      .toISOString()
      .slice(0, 10),
    endDate: new Date(new Date().setDate(new Date().getDate() + 2))
      .toISOString()
      .slice(0, 10),
  });

  const inputSearchValues = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setSearchValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  return (
    <div className="white-box shadow mt-5">
      <div className="d-flex widthFull gap-3">
        <div className="widthHalf">
          <TextField label="검색어">
            <Input
              name="keyword"
              onChange={inputSearchValues}
              value={searchValues.keyword}
            />
          </TextField>
        </div>
        <div className="widthHalf d-flex gap-3">
          <div className="widthHalf">
            <TextField label="카테고리">
              <TextField.Select onChange={inputSearchValues} name="category">
                {categoies?.map((category) => (
                  <option key={category._id} value={category.title}>
                    {category.title}
                  </option>
                ))}
              </TextField.Select>
            </TextField>
          </div>
          <div className="widthHalf">
            <TextField label="고정된 공지">
              <TextField.Select onChange={inputSearchValues} name="isPinned">
                <option value="true">고정</option>
                <option value="false">미고정</option>
              </TextField.Select>
            </TextField>
          </div>
        </div>
      </div>
      <Spacing.Height height="height32" />
      <SearchingBoardPeriodComponent date={date} setDate={setDate} />
      <Spacing.Height height="height16" />
      <div className="d-flex justify-content-end">
        <GreenBigButton
          onClick={() =>
            onSubmit({
              ...searchValues,
              ...date,
            })
          }
        >
          <SearchingIcon />
          검색
        </GreenBigButton>
      </div>
    </div>
  );
};
