import {
  OptionGroupType,
  OptionValueType,
  UploadSummary,
} from "@interface/uploadinterface";
import { OptionValue } from "./OptionValue";
import { PlusMinusIcon } from "@components/svg/PlusMinuseIcon";
import { XIcon } from "@components/Admin/AdminCategoryMapping/component/svg/XIcon";
import { PlusIcon } from "@components/svg/PlusIcon";
import { SearchingIcon } from "@components/svg/SeachingIcon";
import { SearchinGreenCircleIcon } from "@components/svg/SearchingGreenCircleIcon";

interface Props {
  group: OptionGroupType;
  index: number;
  setOptionGroups: React.Dispatch<React.SetStateAction<OptionGroupType[]>>;
  optionGroups: OptionGroupType[];
  code: string;
  item: UploadSummary;
  info: {
    language: string;
    price: string;
  };
}

export const OptionGroup = ({
  info,
  item,
  code,
  optionGroups,
  group,
  index,
  setOptionGroups,
}: Props) => {
  const editOptionGroupName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newArr = [...optionGroups];
    newArr[index].groupName[1].text = e.target.value;
    setOptionGroups(newArr);
  };

  const deleteAllOptionValue = () => {
    const newGroup = optionGroups[index].groups.map((item) => {
      const newName = [
        item.optionName.filter((name) => name.language !== "KR")[0],
        {
          language: "KR",
          text: "",
        },
      ];
      return {
        ...item,
        optionName: newName,
      };
    });
    const newOptionGroups = [...optionGroups];
    newOptionGroups[index] = {
      ...optionGroups[index],
      groups: newGroup,
    };
    setOptionGroups(newOptionGroups);
  };

  const clickAddOptionValue = () => {
    const newOptionArr = [...optionGroups];
    const newOption = {
      id: "0",
      optionImage: null,
      optionName: [
        { language: info!.language, text: window.crypto.randomUUID() },
        { language: "KR", text: "" },
      ],
      price: null,
      quantity: 0,
    };

    newOptionArr[index].groups[optionGroups[index].groups.length] = newOption;
    setOptionGroups(newOptionArr);
  };

  return (
    <div className="width90 d-flex flex-col m-auto">
      <div className="d-flex align-items-center mt-3 gap-3 justify-content-start">
        <p className="weigth500 text14 grey width10 margin0">옵션명</p>
        <input
          onChange={editOptionGroupName}
          type="text"
          className="allttam-input width60"
          placeholder="옵션 명을 입력해주세요"
          value={
            optionGroups[index]?.groupName?.filter(
              (item) => item.language === "KR"
            )[0].text
          }
        />
      </div>
      <div className="d-flex table-bg border-left border-right px-3 mt-3 ">
        <div className="width15  py-3 d-flex align-items-center gap-2">
          <p className="weight600 text14 grey margin0 oneLine">이미지</p>
          <SearchinGreenCircleIcon />
        </div>
        <div className="width60 d-flex justify-content-between px-2">
          <span></span>
          <p className="weight600 text14 grey margin0 py-3">옵션값</p>
          <button onClick={deleteAllOptionValue} className="madebutton">
            <XIcon />
          </button>
        </div>
        <p className="width20 weight600 text14 grey margin0 py-3">글자수</p>
        <div className="width10 d-flex align-items-center justify-content-center">
          <PlusMinusIcon />
        </div>
      </div>
      <div className="option-group-height px-3">
        {group?.groups?.map((option: OptionValueType, idx: number) => (
          <OptionValue
            item={item}
            code={code}
            key={idx}
            option={option}
            optionGroups={optionGroups}
            setOptionGroups={setOptionGroups}
            groupIndex={index}
            optionIndex={idx}
            info={info}
          />
        ))}
        <div className="d-flex">
          <div className="width90"></div>
          <div className="d-flex width10 justify-content-center pr-2">
            <button className="madebutton" onClick={clickAddOptionValue}>
              <div className="m-0 option-minus-border">
                <PlusIcon color="#00b8ba" />
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
