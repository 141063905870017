export const ModalInnerCenter = ({ children }: { children: JSX.Element }) => {
  return (
    <div className="modal-dialog2">
      <div
        className="modal-content modalInner"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {children}
      </div>
    </div>
  );
};
