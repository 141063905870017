import { PlanType } from "@interface/payment";
import { useMemo, useState } from "react";
import { PlanMonth } from "./PlanMonth";
import { PlanAccount } from "./PlanAccount";
import { PlanFreeText } from "./PlanFreeText";
import { PlanTotalPrice } from "./PlanTotalPrice";
import { PlanSubscribeButton } from "./PlanSubscribeButton";
import greyChecked from "@assets/images/payment/greyCheck.svg";

interface Props {
  plan: {
    planName: string;
    img: string;
    speedText: JSX.Element;
    functionText: JSX.Element;
    ribon?: string;
  };
  item: PlanType;
}

export const PlanV2 = ({ plan, item }: Props) => {
  const [selectedPlan, setSelectedPlan] = useState(0);
  const [accountValue, setAccountValue] = useState<string>("1");
  const [code, setCode] = useState("");

  const selectedPlanInfo = useMemo(() => {
    return item.planOptionInfo.filter((item) => item.grade === selectedPlan)[0];
  }, [selectedPlan]);

  const accountPrice = useMemo(() => {
    return (
      selectedPlanInfo?.planOptionDetails[0].Value.originalPrice *
      (parseInt(accountValue) - 1)
    );
  }, [selectedPlanInfo, accountValue]);

  const payPrice = useMemo(() => {
    if (selectedPlanInfo || accountPrice) {
      return selectedPlanInfo.salePrice + accountPrice;
    }
  }, [selectedPlanInfo, accountPrice]);

  return (
    <div className="d-flex flex-col justify-content-end">
      {plan.planName === "S1" && <h3 className="pl1 h3 weight600">플랜구독</h3>}
      {plan.planName === "Unlimited" && (
        <h3 className="pl1 h3 weight600">파트너스 전용</h3>
      )}
      <div
        className="relative"
        style={
          plan.planName === "Unlimited"
            ? {
                top: "5px",
                right: "4px",
              }
            : {}
        }
      >
        <img src={plan.img} className="mt-3" />
        {plan.ribon && <img src={plan.ribon} className="absolute ribon" />}
      </div>
      <div className="planBox d-flex flex-col justify-content-between">
        <div className="d-flex flex-col gap-2">
          <div className="d-flex gap-3 align-items-center">
            <p className="weight600 text14 margin0">속도</p>
            <img src={greyChecked} />
            {plan.speedText}
          </div>
          <div className="d-flex gap-3 align-items-center">
            <p className="weight600 text14 margin0 oneLine">기능</p>
            <img src={greyChecked} />
            {plan.functionText}
          </div>
          <PlanMonth
            item={item}
            setSelectedPlan={setSelectedPlan}
            selectedPlan={selectedPlan}
          />
          <PlanAccount
            setAccountValue={setAccountValue}
            accountValue={accountValue}
            accountPrice={accountPrice}
          />
          {plan.planName === "Unlimited" && (
            <PlanFreeText setCode={setCode} code={code} />
          )}
        </div>
        <div>
          <hr />
          <div>
            <PlanTotalPrice payPrice={payPrice!} />
            <PlanSubscribeButton
              plan={plan.planName}
              code={code}
              item={item}
              accountValue={accountValue}
              selectedPlanInfo={selectedPlanInfo}
              payPrice={payPrice!}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
