import { intlTimeFormat } from "../../../../../utils/functions/intlTimeFormat";
import { UploadLog } from "../../../../../interface/uploadinterface";

interface Props {
  item: UploadLog;
}

export const LogItem = ({ item }: Props) => {
  return (
    <div className="work-number-table-tab heading-five1 uploadLogWrapper">
      <table className="table">
        <thead>
          <tr>
            <th className="oneLine">작업 번호</th>
            <th className="oneLine">사용자 정보</th>
            <th className="oneLine">상태</th>
            <th className="oneLine">마켓 고유번호</th>
            <th className="oneLine">등록 시간</th>
            {/* <th className="oneLine">수정 시간</th> */}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{item.workNumber}</td>
            <td>{item.account}</td>
            <td className={item.status === "Success" ? "textGreen" : "textRed"}>
              {item.status}
            </td>
            <td>{item.marketProductCode}</td>
            <td>{intlTimeFormat(item.regDate)}</td>
            {/* <td>{intlTimeFormat(item.updateDate)}</td> */}
          </tr>
          <tr>
            <td colSpan={5}>
              <div
                className={
                  item.status === "Success"
                    ? "green-success-text mt-4"
                    : "red-error-text mt-4"
                }
              >
                {item.status === "Success" ? (
                  <a
                    href={item.marketEtcInfo}
                    target="_blank"
                    className="textGreen"
                  >
                    {item.marketEtcInfo}
                  </a>
                ) : (
                  <p>{item.marketEtcInfo}</p>
                )}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
