import { useCallback, useEffect } from "react";
import { useQuery } from "react-query";
import axiosInstance from "../utils/axiosInstance";

export const useGetCoin = () => {
  const getUserInfo = useCallback(async () => {
    const res = await axiosInstance.get("/Auth/user");
    const coinRes = await axiosInstance.get(
      `/Log/CanUsePoint?userId=${res?.data.username}`
    );

    return coinRes.data.data;
  }, []);

  const { data: coin } = useQuery("coin", getUserInfo, {
    refetchOnWindowFocus: false,
  });

  return coin;
};
