import { useInfoWindow } from "@hooks/useInfoWindow";
import useToggle from "@hooks/useToggle";
import { ResultItem } from "@interface/orderManageInterface";
import { getEasyque, getSBLink } from "@utils/functions/getSBLink";
import { madeSBunid } from "@utils/functions/madeSBunid";
import axios from "axios";
import { OrderInfoModal } from "src/pages/Ordermanage/modal/OrderInfoModal";

interface Props {
  item: ResultItem;
  isSelect: boolean;
}

export const ItemProductInfo = ({ item, isSelect }: Props) => {
  const openWindow = useInfoWindow();
  const { state: isOrderInfoModal, handleToggle: handleOrderInfoModal } =
    useToggle();

  return (
    <td className="width30">
      <div
        className={`justify-content-start noScroll collect-inner-1 ${
          isSelect && "selectedItem"
        } orderProductInfo`}
      >
        <div className="textStart">
          <div className="input-collect gap-3">
            {item.mainImageUrl ? (
              <img
                className="componentItemImg rounded-md mt-2"
                src={item.mainImageUrl}
              />
            ) : (
              <div className="noneImg d-flex justify-content-center align-items-center">
                <p className="text8 textWhite weight500 navy margin0">
                  이미지가 없습니다
                </p>
              </div>
            )}
            <div className="">
              <div
                className="input-collect-inner mb-2 "
                onClick={(e) => e.stopPropagation()}
              >
                <p className="font-bold margin0">
                  [{item.marketAccount}] {item.orderNumber}
                </p>
                <p className="font-bold margin0">
                  상품주문번호 :{item.orderCode}
                </p>
                <p className="margin0 mt-2">
                  [{item.originalSite}] {item.productName}
                </p>
                <p className="textGreen mt-2">{item?.optionName}</p>
              </div>
              <div className="" onClick={(e) => e.stopPropagation()}>
                <button
                  onClick={handleOrderInfoModal}
                  className="tableItemInnerButton madebutton mr-2"
                >
                  주문정보
                </button>
                <button
                  onClick={async () => {
                    if (item.originalProductUrl != "") {
                      getSBLink(item.code, item.originalProductUrl);
                    }
                    if (item.originalProductUrl == "") {
                      const url = await getEasyque(
                        item.openmarketProductNumber
                      );

                      if (url == null || url.originalLink == "") {
                        return openWindow("원본상품링크가 없습니다.");
                      }

                      getSBLink(item.code, url.originalLink);
                    }
                  }}
                  className="tableItemInnerButton madebutton mr-2"
                >
                  원본상품링크
                </button>
                <button
                  onClick={() => {
                    window.open(item.openmarketProductUrl, "_blank");
                  }}
                  className="tableItemInnerButton madebutton mr-2"
                >
                  판매상품링크
                </button>
              </div>
              <div className="coupan-text"></div>
            </div>
          </div>
        </div>
      </div>
      {isOrderInfoModal && (
        <OrderInfoModal idx={item?.idx} closeModal={handleOrderInfoModal} />
      )}
    </td>
  );
};
