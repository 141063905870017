import { ModalBg } from "@components/ModalLayout/ModalBg";
import { ModalBody } from "@components/ModalLayout/ModalBody";
import { ModalInnerTop } from "@components/ModalLayout/ModalInnerTop";

interface Props {
  closeModal: () => void;
}

export const FreeModalMobile = ({ closeModal }: Props) => {
  return (
    <ModalBg onClick={closeModal}>
      <ModalInnerTop width={330}>
        <div className="modal-header">
          <h5 className="d-flex align-items-center weight500 text12">알림</h5>
        </div>
        <ModalBody>
          <p className="weight500 text10 grey">
            올땀 네이버 카페에서 [무료사용 신청글] 을 작성해주세요. <br />
            관리자가 확인 후, [S1 플랜 체험] 을 제공해드립니다.
          </p>
          <div className="d-flex align-items-center justify-content-center gap-3">
            <button
              onClick={() => {
                closeModal();
              }}
              className="madebutton navy text10 weight600 mobile-modal-button-white mobile-modal-button"
            >
              닫기
            </button>
            <button
              onClick={() => {
                window.open("https://cafe.naver.com/mandeunydle", "_blank");
              }}
              className="madebutton text10 weight600 mobile-modal-button mobile-modal-button-green"
            >
              신청하기
            </button>
          </div>
        </ModalBody>
      </ModalInnerTop>
    </ModalBg>
  );
};
