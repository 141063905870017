export const CSTableHead = () => {
  return (
    <thead className="head-one">
      <tr>
        <th>마켓명</th>
        <th>접수일</th>
        <th>주문번호</th>
        <th>제목</th>
        <th>내용</th>
        <th>상품번호</th>
        <th>상품명</th>
        <th>문의고객명</th>
        <th>마켓상품주소</th>
      </tr>
    </thead>
  );
};
