import { MinusIcon } from "@components/svg/MinusIcon";
import { PlusIcon } from "@components/svg/PlusIcon";
import { QuestionMarkIcon } from "@components/svg/QuestionMarkIcon";
import { OptionGroupType, UploadSummary } from "@interface/uploadinterface";
import { OptionGroup } from "./OptionGroupOne";
import { useState } from "react";
import { useInfoWindow } from "@hooks/useInfoWindow";
import { GreyMiddleButton } from "@components/Button/allttamButton/GreyMiddleButton";
import { GreyBigButton } from "@components/Button/allttamButton/GreyBigButton";

interface Props {
  optionGroups: OptionGroupType[];
  setOptionGroups: React.Dispatch<React.SetStateAction<OptionGroupType[]>>;
  info:
    | {
        language: any;
        price: any;
      }
    | undefined;
  item: UploadSummary;
  setGroupOptionIndex: React.Dispatch<React.SetStateAction<number>>;
  groupOptionIndex: number;
}

export const OptionGroupComponent = ({
  optionGroups,
  setOptionGroups,
  info,
  item,
  groupOptionIndex,
  setGroupOptionIndex,
}: Props) => {
  const openWindow = useInfoWindow();

  const clickAddOptionGroup = () => {
    if (optionGroups.length >= 2) {
      openWindow("옵션 그룹은 최대 2개까지 설정가능합니다.");
    } else {
      const copyArr = [...optionGroups];
      const newGroup = {
        id: window.crypto.randomUUID(),
        groupName: [
          { language: info?.language, text: window.crypto.randomUUID() },
          { language: "KR", text: "" },
        ],
        groups: [
          {
            id: window.crypto.randomUUID(),
            optionImage: null,
            optionName: [
              { language: info?.language, text: window.crypto.randomUUID() },
              { language: "KR", text: "" },
            ],
            price: null,
            quantity: 0,
          },
        ],
      };
      copyArr[optionGroups.length] = newGroup;
      setOptionGroups(copyArr);
    }
  };

  const deleteOptionGroup = () => {
    if (optionGroups.length >= 2) {
      const newArr = [...optionGroups];
      newArr.splice(groupOptionIndex, 1);
      setOptionGroups(newArr);
      setGroupOptionIndex(0);
    } else {
      openWindow("옵션 그룹은 최소 한 개 이상 있어야 합니다.");
    }
  };

  return (
    <>
      <div className="width90 mt-4 d-flex m-auto">
        <div className="d-flex widthHalf gap-5">
          <div className="d-flex align-items-center gap-3">
            <button
              className="madebutton text18 weight500 margin0"
              onClick={() => {
                setGroupOptionIndex(0);
              }}
            >
              옵션 그룹1 편집
            </button>
            <QuestionMarkIcon />
          </div>
          <button
            onClick={clickAddOptionGroup}
            className="madebutton d-flex align-items-center gap-2"
          >
            <p className="margin0 weight500 text14 grey">옵션 그룹</p>
            <span className="circle30Green">
              <PlusIcon />
            </span>
          </button>
        </div>
        <div
          className={`d-flex widthHalf gap-5 ${
            optionGroups?.length === 1 && "opacity5"
          }`}
        >
          <div className="d-flex align-items-center gap-3">
            <button
              onClick={() => {
                setGroupOptionIndex(1);
              }}
              disabled={optionGroups?.length === 1}
              className="madebutton text18 weight500 margin0"
            >
              옵션 그룹2 편집
            </button>
            <QuestionMarkIcon />
          </div>
          <button
            onClick={deleteOptionGroup}
            className="madebutton d-flex align-items-center gap-2"
          >
            <p className="margin0 weight500 text14 grey">옵션 그룹</p>
            <span className="circle30Yellow">
              <MinusIcon />
            </span>
          </button>
        </div>
      </div>
      <OptionGroup
        info={info!}
        item={item}
        code={item.code}
        key={groupOptionIndex}
        group={optionGroups[groupOptionIndex]}
        index={groupOptionIndex}
        optionGroups={optionGroups}
        setOptionGroups={setOptionGroups}
      />
    </>
  );
};
