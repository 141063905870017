interface Props {
  color?: string;
  width?: string;
  height?: string;
}

export const LeftIcon = ({ color, width, height }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : "24"}
      height={height ? height : "24"}
      viewBox="0 0 24 24"
    >
      <path data-name="패스 176" d="M24 0H0v24h24z" fill="none"></path>
      <path
        data-name="패스 177"
        d="M15.29 6.71a1 1 0 0 1 0 1.41L11.41 12l3.88 3.88a1 1 0 1 1-1.41 1.41L9.29 12.7a1 1 0 0 1 0-1.41l4.59-4.59a1 1 0 0 1 1.41.01z"
        transform="translate(-.58)"
        fill={color ? color : "#39415c"}
      ></path>
    </svg>
  );
};
