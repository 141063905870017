import { SquarePlusIcon } from "@components/svg/SquarePlusIcon";
import useToggle from "@hooks/useToggle";
import {
  OptionGroupType,
  OptionValueType,
  UploadSummary,
} from "@interface/uploadinterface";
import { OptionImageEdit } from "./OptionImageEdit";
import { TrashCanIcon } from "@components/svg/TrashCanIcon";
import { OptionDefaultImgIcon } from "@components/svg/OptionDefaultImgIcon";
import { MinusIcon } from "@components/svg/MinusIcon";
import { useMemo } from "react";
import { XIcon } from "@components/Admin/AdminCategoryMapping/component/svg/XIcon";
import { useTooltip } from "@hooks/useTooltip";

interface Props {
  info: {
    language: string;
    price: string;
  };
  option: OptionValueType;
  setOptionGroups: React.Dispatch<React.SetStateAction<OptionGroupType[]>>;
  optionGroups: OptionGroupType[];
  optionIndex: number;
  groupIndex: number;
  code: string;
  item: UploadSummary;
}
export const OptionValue = ({
  info,
  item,
  code,
  option,
  setOptionGroups,
  optionGroups,
  groupIndex,
  optionIndex,
}: Props) => {
  const { state: isEditThumbnail, handleToggle: handleEditThumbnail } =
    useToggle();
  const [hoverImg, enterImg, leaveImg] = useTooltip();

  const inputOptionName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newOption = [...optionGroups];
    if (newOption[groupIndex].groups[optionIndex].optionName[1]) {
      newOption[groupIndex].groups[optionIndex].optionName[1].text =
        e.target.value;
      setOptionGroups(newOption);
    } else {
      newOption[groupIndex].groups[optionIndex].optionName[0].text =
        e.target.value;
      setOptionGroups(newOption);
    }
  };

  const deleteOptionName = () => {
    const newOption = [...optionGroups];
    if (newOption[groupIndex].groups[optionIndex].optionName[1]) {
      newOption[groupIndex].groups[optionIndex].optionName[1].text = "";
      setOptionGroups(newOption);
    } else {
      newOption[groupIndex].groups[optionIndex].optionName[0].text = "";
      setOptionGroups(newOption);
    }
  };

  const clickDeleteOption = () => {
    const newOption = [...optionGroups];
    if (newOption[groupIndex].groups.length > 1) {
      newOption[groupIndex].groups.splice(optionIndex, 1);
      setOptionGroups(newOption);
    } else {
      return;
    }
  };

  const optionName = useMemo(() => {
    return option.optionName.filter((item) => item.language === "KR")[0].text;
  }, [optionGroups]);

  return (
    <div className="row align-items-center mt-2 px-3">
      <div className="click-img width10 text-center">
        {hoverImg && (
          <img
            src={option.optionImage!}
            className="absolute option-thumb-big"
          />
        )}
        {option.optionImage ? (
          <button
            onClick={handleEditThumbnail}
            className="madebutton paddingZero"
            onMouseEnter={enterImg}
            onMouseLeave={leaveImg}
          >
            <img className="optionThumbnail" src={option.optionImage} />
          </button>
        ) : (
          <button
            onClick={handleEditThumbnail}
            className="madebutton paddingZero"
          >
            <OptionDefaultImgIcon />
          </button>
        )}
      </div>
      <div className="width60 relative">
        <input
          onChange={inputOptionName}
          type="text"
          className="form-control"
          placeholder="옵션 값을 입력해주세요"
          value={optionName}
        />
        <button
          onClick={deleteOptionName}
          className="madebutton absolute option-input-delete-btn"
        >
          <XIcon color="#C9CEDB" />
        </button>
      </div>
      <div className="width20">
        <p className="margin0 weight500">{optionName.length} / 49</p>
      </div>
      <div className="width10 d-flex justify-content-center">
        <button className="paddingZero madebutton" onClick={clickDeleteOption}>
          <div className="m-0 option-minus-border">
            <MinusIcon color="#ffb500" />
          </div>
        </button>
      </div>
      {isEditThumbnail && (
        <OptionImageEdit
          item={item}
          code={code}
          setOptionGroups={setOptionGroups}
          groupIndex={groupIndex}
          optionIndex={optionIndex}
          closeModal={handleEditThumbnail}
          mainImg={option.optionImage as string}
          optionGroups={optionGroups}
        />
      )}
    </div>
  );
};
