interface Props {
  name: string;
}

export const RevenueProductName = ({ name }: Props) => {
  return (
    <div className="d-flex align-items-center widthFull gap-3 textStart mt-3">
      <div className="d-flex align-items-center widthFull">
        <p className="ordererInfoTitle revenueModalLeft">상품명</p>
        <p className="oneLine margin0">{name}</p>
      </div>
    </div>
  );
};
