import useFetch from "@hooks/useFetch";
import { QuestionItem } from "./QuestionItem";
import { Fqa, IndexType } from "@interface/fqa.interface";

interface Props {
  id: string;
  selectComponent: (type: IndexType, id: string) => void;
}

export const HelperSearchResult = ({ id, selectComponent }: Props) => {
  const [fqaByCategory] = useFetch(
    `fqaBySearchKeyword${id}`,
    `https://center.allttam.com/api/allttam/fqa?keyword=${id}`
  );

  return (
    <>
      <p className="wieght500 text14 text-center">
        <span className="weight600 navy">{id}</span> 을(를) 포함한 검색결과{" "}
        {fqaByCategory?.length} 건이 검색되었습니다.
      </p>
      <div>
        {fqaByCategory?.map((item: Fqa) => (
          <QuestionItem
            key={item._id}
            selectComponent={selectComponent}
            item={item}
          />
        ))}
      </div>
    </>
  );
};
