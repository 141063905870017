import { IndexType } from "@interface/fqa.interface";
import { CateogoryFqa } from "./CategoryFqa";
import { Fqa } from "./Fqa";

interface Props {
  selectComponent: (type: IndexType, id: string) => void;
  isViewAll: boolean;
  setIsViewAll: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DefaultBody = ({
  selectComponent,
  setIsViewAll,
  isViewAll,
}: Props) => {
  return (
    <>
      <Fqa selectComponent={selectComponent} />
      <CateogoryFqa
        selectComponent={selectComponent}
        isViewAll={isViewAll}
        setIsViewAll={setIsViewAll}
      />
      {/* <Qna selectComponent={selectComponent} /> */}
    </>
  );
};
