import { Component, ErrorInfo } from "react";
import { FallbackUI } from "./FallbackUI";
import axios from "axios";
import axiosInstance from "@utils/axiosInstance";

interface ErrorBoundaryProps {
  children: React.ReactNode;
  fallbackUI?: React.ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  async componentDidCatch(error: Error, errorInfo: ErrorInfo): Promise<void> {
    const { data } = await axiosInstance.get("/Auth/user");
    console.log(errorInfo);

    // const res = await axios.post("https://center.allttam.com/api/errorlog", {
    //   userName: data.originalUserName,
    //   errorLog: `${error.toString()} ${errorInfo.componentStack}`,
    // });
  }

  render() {
    if (this.state.hasError) {
      return <FallbackUI /> ?? <h1>에러가 발생했습니다.</h1>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
