import axios from "axios";
import { madeSBunid } from "./madeSBunid";

export const getEasyque = async (productCode: string) => {
  const { data } = await axios.get(
    `https://center.allttam.com/api/easyque?productCode=${productCode}`
  );

  return data;
};

export const getSBLink = async (code: string, ogLink: string) => {
  try {
    const { data } = await axios.get(
      `https://center.allttam.com/api/smilebates?code=${code}&unid=${madeSBunid()}`
    );

    if (data.cash_link === "캐시백 정보가 없습니다.") {
      window.open(ogLink, "_blank");
    } else {
      window.open(data.cash_link, "_blank");
    }
  } catch {
    window.open(ogLink, "_blank");
  }
};
