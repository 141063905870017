import best from "@assets/images/payment/best.png";
import { StarterMainContent } from "../Stater/StarterMainContents";
import { StarterContents } from "../Stater/StarterContents";
import { useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { payment } from "@utils/functions/paymentFunction";
import useFetch from "@hooks/useFetch";
import useToggle from "@hooks/useToggle";
import { FreeModal } from "../modal/FreeModal";
import useUser from "@hooks/useUser";

export const StarterTab = () => {
  const [isOffline, setIsOffline] = useState(false);
  const { state: isFreeModal, handleToggle: toggleIsFreeModal } = useToggle();
  const userInfo = useUser();
  const navigate = useNavigate();

  const toggleIsOffline = () => {
    setIsOffline((prev) => !prev);
  };

  const starterPrice = useMemo(() => {
    return isOffline ? 140000 : 120000;
  }, [isOffline]);

  const location = useLocation();

  return (
    <>
      <div className="width70 mb-3">
        <div className="heading-one payment-sec">
          <h3 className="h3 weight600">가격정책</h3>
        </div>
        <div className="d-flex align-items-end justify-content-end gap-4">
          <p className="weight600 text13 navy margin0">
            아직도 <span className="textRed">월요금제</span> 내면서 프로그램
            배우고 계세요? <span className="textYellow">단기 속성</span>으로{" "}
            <span className="textGreen">단시간</span>에 셋팅하고 매출에
            집중하세요
          </p>
          <p
            onClick={() => {
              setIsOffline(false);
            }}
            className={`pointer weight600 text20 navy margin0 oneLine ${
              isOffline && "none-select"
            }`}
          >
            <span className={`${!isOffline && "textYellow"}`}>원격</span>셋팅
          </p>
          <input
            onClick={toggleIsOffline}
            checked={isOffline}
            className="payment-starter"
            type="checkbox"
            readOnly
          />
          <p
            onClick={() => {
              setIsOffline(true);
            }}
            className={`pointer weight600 text20 navy margin0 offline-button oneLine ${
              !isOffline && "none-select"
            }`}
          >
            <span className={`${isOffline && "textYellow"}`}>대면</span>셋팅
          </p>
        </div>
        <div className="mt-4 widthFull starter-container d-flex justify-content-between align-items-center relative">
          <img src={best} className="ribon2 absolute" />
          <p className="weight700 text40  margin0 oneLine">
            <span className="textYellow text50">스타트</span> 패키지
          </p>
          <p className="weight600  margin0 text50 navy">
            {"\\"}
            {starterPrice.toLocaleString()}
          </p>
          <div className="d-flex flex-col justify-content-between">
            <button
              onClick={() => {
                toggleIsFreeModal();
              }}
              className="madebutton starter-inside-button starter-inside-button-top weight600 text20 oneLine"
            >
              무료사용
            </button>
            <button
              onClick={() => {
                if (location.pathname.includes("/payment")) {
                  payment({
                    GoodsName: "스타트 패키지",
                    Amt: (starterPrice + 0.1 * starterPrice).toString(),
                    BuyerName: userInfo.originalUserName,
                    BuyerTel: userInfo.userTelNo,
                    BuyerEmail: userInfo.userEmail,
                    mallReserved: "",
                  });
                } else {
                  navigate("/payment?tab=starter");
                }
              }}
              className="textGreen madebutton starter-inside-button starter-inside-button-bottom weight600 text20 oneLine"
            >
              결제하기
            </button>
          </div>{" "}
        </div>
      </div>
      {isFreeModal && <FreeModal closeModal={toggleIsFreeModal} />}
      <StarterContents isOffline={isOffline} />
    </>
  );
};
