interface Props {
  name?: string;
  type?: "text" | "password" | "email";
  label?: string | React.ReactNode;
  value?: string;
  placeholder?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

export const SignupInput = ({
  name,
  type,
  label,
  value,
  onChange,
  placeholder,
  disabled,
}: Props) => {
  return (
    <div className="d-flex gap-3 align-items-center">
      <label className="oneLine width20">{label}</label>
      <input
        className="signup-input width80"
        name={name}
        type={type}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        disabled={disabled}
      />
    </div>
  );
};
