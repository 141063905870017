export const EraseIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g data-name="그룹 9820">
        <path
          data-name="패스 10357"
          d="m1020.028 1893.339 10 10"
          transform="translate(-1013.028 -1886.339)"
          stroke="#a7b0c7"
          strokeLinecap="round"
          strokeWidth="2px"
        />
        <path
          data-name="패스 10358"
          d="m1030.028 1893.339-10 10"
          transform="translate(-1013.028 -1886.339)"
          stroke="#a7b0c7"
          strokeLinecap="round"
          strokeWidth="2px"
          fill="none"
        />
      </g>
      <path data-name="사각형 3654" fill="none" d="M0 0h24v24H0z" />
    </svg>
  );
};
