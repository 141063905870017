import { QuestionMarkIcon } from "@components/svg/QuestionMarkIcon";
import { CrawledKeyword } from "../../TagSettingModal";
import { Spinner } from "@components/Spinner/Spinner";

interface Props {
  crawledKeyword: CrawledKeyword;
  setSelectedTag: React.Dispatch<React.SetStateAction<string[]>>;
  isLoading: boolean;
}

export const KeywordList = ({
  crawledKeyword,
  setSelectedTag,
  isLoading,
}: Props) => {
  const selectKeyword = (string: string) => {
    setSelectedTag((prev) => [...prev, string]);
  };
  return (
    <div className="">
      <div className="d-flex gap-3 align-items-center">
        <div className="width15">
          <div className="d-flex gap-3 align-items-center justify-content-between">
            <p className="text16 weight500 margin0">자동완성키워드</p>
            <QuestionMarkIcon />
          </div>
        </div>
        <div className="d-flex width80 gap-3 keyword-container">
          {!isLoading && crawledKeyword.auto.length ? (
            crawledKeyword.auto.map((item, idx) => (
              <button
                key={idx}
                onClick={() => selectKeyword(item)}
                className="madebutton weight200 text14 oneLine button-grey-border grey"
              >
                {item}
              </button>
            ))
          ) : isLoading ? (
            <></>
          ) : (
            <p className="weight500 text12 grey text-center margin0 widthFull">
              검색 결과가 없습니다.
            </p>
          )}
        </div>
      </div>
      <div className="d-flex gap-3 align-items-center mt-4">
        <div className="width15">
          <div className="d-flex gap-3 align-items-center justify-content-between">
            <p className="text16 weight500 margin0">연관 검색어</p>
            <QuestionMarkIcon />
          </div>
        </div>
        <div className="d-flex width80 gap-3 keyword-container">
          {!isLoading && crawledKeyword.rel.length ? (
            crawledKeyword.rel.map((item, idx) => (
              <button
                key={idx}
                onClick={() => selectKeyword(item)}
                className="madebutton weight200 text14 oneLine button-grey-border grey"
              >
                {item}
              </button>
            ))
          ) : isLoading ? (
            <div className="widthFull d-flex justify-content-center relative hidden py-3 bottom20">
              <Spinner />
            </div>
          ) : (
            <p className="weight500 text12 grey text-center margin0 widthFull">
              검색 결과가 없습니다.
            </p>
          )}
        </div>
      </div>
    </div>
  );
};
