import { SearchingIcon } from "@components/svg/SeachingIcon";

interface Props {
  clickButtonSubmit: () => void;
}

export const SearchingBoardSearchingButton = ({ clickButtonSubmit }: Props) => {
  return (
    <div className="d-flex align-items-center gap-1 view-count-sec new-order-manage gap-4">
      <button onClick={clickButtonSubmit} className="cancel-btn save-btn">
        <SearchingIcon />
        검색
      </button>
    </div>
  );
};
