import { FroalaEditor } from "@components/shared/Froala/Froala";
import { ModalBg } from "@components/ModalLayout/ModalBg";
import ModalContent from "@components/shared/modal/ModalContent";
import { useCallback, useState } from "react";
import { GreyMiddleButton } from "@components/Button/allttamButton/GreyMiddleButton";
import { GreenMiddleButton } from "@components/Button/allttamButton/GreenMiddleButton";
import axios from "axios";
import { useQueryClient } from "react-query";

interface Props {
  id: string;
  closeModal: () => void;
}

export const AdditionalInquiryModal = ({ id, closeModal }: Props) => {
  const [model, setModel] = useState("");
  const queryClient = useQueryClient();

  const createAdditionalQna = useCallback(async () => {
    const res = await axios.post(
      `https://center.allttam.com/api/allttam/qna/${id}`,
      {
        content: model,
      }
    );

    if (res.status === 201) {
      queryClient.invalidateQueries(["additionalQna", id]);
      closeModal();
    }
  }, [id, model]);

  return (
    <ModalBg onClick={() => {}}>
      <ModalContent>
        <ModalContent.ModalHead>추가질문작성</ModalContent.ModalHead>
        <ModalContent.ModalBody>
          <FroalaEditor placeholder="" model={model} setModel={setModel} />
          <div className="d-flex justify-content-center gap-3 mt-4">
            <GreyMiddleButton onClick={closeModal}>닫기</GreyMiddleButton>
            <GreenMiddleButton onClick={createAdditionalQna}>
              작성
            </GreenMiddleButton>
          </div>
        </ModalContent.ModalBody>
      </ModalContent>
    </ModalBg>
  );
};
