import useFetch from "@hooks/useFetch";
import HTMLReactParser from "html-react-parser";
import { FqaRating } from "./FqaRating";

interface Props {
  id: string;
}

export const DetailBody = ({ id }: Props) => {
  const [fqa] = useFetch(
    `fqa${id}`,
    `https://center.allttam.com/api/allttam/fqa/${id}`
  );

  return (
    fqa && (
      <>
        <h3 className="text14 widthFull weight600 el">{fqa.fqa?.title}</h3>
        <h5 className="text12 weight500 grey">{fqa.fqa.categoryId.title}</h5>
        <div className="detail-html-container">
          {HTMLReactParser(fqa.fqa.contents)}
          {fqa.isPossibleWriteRating && <FqaRating fqaId={id} />}
          {!fqa.isPossibleWriteRating && (
            <div className="mt-4 d-flex justify-content-center">
              <p className="text14 grey">이미 평가가 하신 도움말입니다.</p>
            </div>
          )}
        </div>
      </>
    )
  );
};
