import { CheckBoxInput } from "@components/Input/CheckBoxInput";
import { ResultItem } from "@interface/orderManageInterface";
import { useMemo } from "react";

interface Props {
  orderList: ResultItem[];
  setSelectedOrder: React.Dispatch<React.SetStateAction<ResultItem[]>>;
  selectedOrder: ResultItem[];
}

export const OrderManageTableHead = ({
  selectedOrder,
  orderList,
  setSelectedOrder,
}: Props) => {
  const isSelectedAll = useMemo(() => {
    return orderList?.length === selectedOrder?.length;
  }, [selectedOrder, orderList]);

  const selectAll = () => {
    if (isSelectedAll) setSelectedOrder([]);
    if (!isSelectedAll) setSelectedOrder(orderList);
  };

  return (
    <thead className="head-one">
      <tr className="stickyHeader">
        <th className="width5">
          <div className="form-group">
            <CheckBoxInput
              onClick={selectAll}
              checked={!!orderList?.length && isSelectedAll}
            />
          </div>
        </th>
        <th className="width5">
          <p className="oneLine margin0">마켓</p>
        </th>
        <th className="width30">
          <p className="oneLine margin0">상품정보</p>
        </th>
        <th className="width15">
          <p className="oneLine margin0">주문인</p>
          <p className="oneLine margin0 orderCollectDay">(수령인)</p>
        </th>
        <th className="width7">
          <p className="oneLine margin0">결제금액</p>
          <p className="oneLine margin0 orderCollectDay">(예상수익)</p>
        </th>
        <th className="width5">
          <p className="oneLine margin0">상품주문정보</p>
        </th>
        <th className="width15">
          <p className="oneLine margin0">주문상태</p>
        </th>
        <th className="width15">
          <div>
            <p className="margin0">주문일자</p>
            <span className="orderCollectDay">(주문수집일자)</span>
          </div>
        </th>
      </tr>
    </thead>
  );
};
