import { ORDER_STATUS } from "src/common/const/OrderStatus";
import { ResultItem } from "../../../../../interface/orderManageInterface";

interface Props {
  idx: number;
  isSelect: boolean;
  orderList: ResultItem[];
  setOrderList: React.Dispatch<React.SetStateAction<ResultItem[]>>;
}

export const ItemOrderState = ({
  idx,
  isSelect,
  orderList,
  setOrderList,
}: Props) => {
  const inputOrderStatus = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    const newArray = [...orderList];
    newArray[idx] = {
      ...newArray[idx],
      [name]: value,
    };
    setOrderList(newArray);
  };
  return (
    <td className="width15">
      <div className={`collect-inner-1 ${isSelect && "selectedItem"}`}>
        <div onClick={(e) => e.stopPropagation()} className="flex-col">
          <select
            onChange={inputOrderStatus}
            className="tableItemInput"
            name="status"
            value={orderList[idx].status}
          >
            <option value="">주문상태를 선택해 주세요</option>
            {ORDER_STATUS.map((item) => (
              <option value={item.value} key={item.id}>
                {item.title}
              </option>
            ))}
          </select>
          <textarea
            name="simpleMemo"
            onChange={inputOrderStatus}
            value={orderList[idx].simpleMemo}
            className="tableItemTextArea"
            placeholder="국내운송장번호"
          />
        </div>
      </div>
    </td>
  );
};
