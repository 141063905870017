import { GreenMiddleButton } from "@components/Button/allttamButton/GreenMiddleButton";
import { GreyMiddleButton } from "@components/Button/allttamButton/GreyMiddleButton";
import { GreySmallButton } from "@components/Button/allttamButton/GreySmallButton";
import { ModalBg } from "@components/ModalLayout/ModalBg";
import { ModalBody } from "@components/ModalLayout/ModalBody";
import { ModalHead } from "@components/ModalLayout/ModalHead";
import { ModalInnerTop } from "@components/ModalLayout/ModalInnerTop";
import { useInfoWindow } from "@hooks/useInfoWindow";
import axiosInstance from "@utils/axiosInstance";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

interface Props {
  closeModal: () => void;
}

export const CheckEmail = ({ closeModal }: Props) => {
  const [code, setCode] = useState("");
  const openWindow = useInfoWindow();
  const navigate = useNavigate();

  const inputCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCode(e.target.value);
  };

  const submitCode = async () => {
    try {
      const res = await axiosInstance.get(
        `/account/CheckCertificationMail?code=${code}`
      );
      if (res.status === 200) {
        navigate("/home");
      }
    } catch {
      openWindow("인증에 실패했습니다.");
    }
  };

  return (
    <ModalBg onClick={closeModal}>
      <ModalInnerTop>
        <ModalHead clickClose={closeModal}>인증번호</ModalHead>
        <ModalBody>
          <p>가입시 입력한 이메일을 확인하고 인증번호를 입력해주세요</p>
          <div className="d-flex align-items-center gap-3">
            <p className="margin0 oneLine grey ">인증번호</p>
            <input
              className="allttam-input"
              value={code}
              onChange={inputCode}
            />
          </div>
          <div className="d-flex justify-content-center gap-3 mt-4">
            <GreyMiddleButton onClick={closeModal}>닫기</GreyMiddleButton>
            <GreenMiddleButton onClick={submitCode}>인증</GreenMiddleButton>
          </div>
        </ModalBody>
      </ModalInnerTop>
    </ModalBg>
  );
};
