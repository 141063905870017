import { XIcon } from "@components/Admin/AdminCategoryMapping/component/svg/XIcon";
import { UploadSummary } from "../../../../../interface/uploadinterface";
import { useTooltip } from "@hooks/useTooltip";
import { CheckBoxInput } from "@components/Input/CheckBoxInput";

interface Props {
  item: UploadSummary;
  idx: number;
  productNameValue: string;
  wordLengthLimit: number;
  setProductNameValueList: React.Dispatch<
    React.SetStateAction<
      {
        code: string;
        text: string;
      }[]
    >
  >;
  nameSelect: string[];
  setNameSelect: React.Dispatch<React.SetStateAction<string[]>>;
}

export const ProductNameInfoTableItem = ({
  item,
  nameSelect,
  idx,
  productNameValue,
  wordLengthLimit,
  setProductNameValueList,
  setNameSelect,
}: Props) => {
  const [isHoverImg, enterImg, leaveImg] = useTooltip();

  const inputProductName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProductNameValueList((prev) =>
      prev.map((product) => {
        if (product.code === item.id) {
          return { code: product.code, text: e.target.value };
        }
        return product;
      })
    );
  };

  const resetName = () => {
    setProductNameValueList((prev) =>
      prev.map((product) => {
        if (product.code === item.id) {
          return { code: item.id, text: "" };
        }
        return product;
      })
    );
  };

  const selectItem = () => {
    if (nameSelect.includes(item.id)) {
      setNameSelect(nameSelect.filter((code) => code !== item.id));
    }
    if (!nameSelect.includes(item.id)) {
      setNameSelect((prev) => [...prev, item.id]);
    }
  };

  return (
    <tr>
      <td className="width5">
        <span className="d-flex justify-content-center">
          <CheckBoxInput
            onClick={selectItem}
            checked={nameSelect.includes(item.id)}
          />
        </span>
      </td>
      <td className="width10">
        <p className="oneLine weight500 margin0 text-center">{idx + 1}</p>
      </td>
      <td className="width10">
        <div className="d-flex justify-content-center py-2">
          <img
            className="expect-thumb"
            src={item.mainImages[0]}
            onMouseEnter={enterImg}
            onMouseLeave={leaveImg}
          />
          {isHoverImg && (
            <img src={item.mainImages[0]} className="name-hover-img" />
          )}
        </div>
      </td>
      <td className="width50">
        <div className="d-flex align-items-center justify-content-center">
          <div className="price-state widthFull">
            <input
              onChange={inputProductName}
              value={productNameValue}
              type="text"
              className="form-control pricing-input1"
              placeholder={
                item?.title?.[1]?.text
                  ? item?.title?.[1]?.text
                  : item.title[0].text
              }
            />
          </div>
        </div>
      </td>
      <td className="width5">
        <div className="d-flex justify-content-center">
          <button onClick={resetName} className="madebutton">
            <XIcon color="#C9CEDB" />
          </button>
        </div>
      </td>
      <td className="width10">
        <p className="oneLine weight500 margin0 text-center">
          <span
            className={`${
              wordLengthLimit < productNameValue.length && "textRed"
            }`}
          >
            {productNameValue.length}
          </span>{" "}
          <span> / {wordLengthLimit}</span>
        </p>
      </td>
      <td className="width20">
        <p className="weight500 text12 margin0">{item.memo}</p>
      </td>
    </tr>
  );
};
