import { GreyMiddleButton } from "@components/Button/allttamButton/GreyMiddleButton";
import { OptionListTable } from "./OptionListTable";
import { QuestionMarkIcon } from "@components/svg/QuestionMarkIcon";
import {
  DetailOptionType,
  OptionGroupType,
  UploadSummary,
} from "@interface/uploadinterface";
import { useState } from "react";
import { upload } from "@utils/functions/postApi";
import { UseMutationResult } from "react-query";
import { OptionSetButton } from "./OptionSetButton";
import { GreySmallButton } from "@components/Button/allttamButton/GreySmallButton";

interface Props {
  setDetailOptionList: React.Dispatch<React.SetStateAction<DetailOptionType[]>>;
  optionGroups: OptionGroupType[];
  detailOptionList: DetailOptionType[];
  setSelectedDetailOption: React.Dispatch<
    React.SetStateAction<DetailOptionType[]>
  >;
  selectedDetailOption: DetailOptionType[];
  optionList: any;
  item: UploadSummary;
  closeModal: () => void;
  collectProductSummary: UseMutationResult<any, unknown, void, unknown>;
}

export const OptionList = ({
  collectProductSummary,
  closeModal,
  setDetailOptionList,
  optionGroups,
  detailOptionList,
  setSelectedDetailOption,
  selectedDetailOption,
  optionList,
  item,
}: Props) => {
  const [settingValue, setSettingValue] = useState({
    price: 0,
    quantity: 0,
    soldOut: false,
  });

  const inputSettingValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setSettingValue({
      ...settingValue,
      [name]: value,
    });
  };

  const clickBatchApplyPrice = () => {
    const newDetailList = [...detailOptionList];
    const updatedDetailList = newDetailList.map((item) => {
      const selected = selectedDetailOption.find(
        (select) => select.id === item.id
      );
      if (selected) {
        const prevChinaPrice = item.price.filter(
          (prev) => prev.currency !== "KRW"
        )[0];
        return {
          ...item,
          price: [
            prevChinaPrice,
            {
              currency: "KRW",
              originalPrice: settingValue.price,
              salePrice: settingValue.price,
            },
          ],
        };
      }
      return item;
    });
    setDetailOptionList(updatedDetailList);
  };

  const clickBatchApplyStock = () => {
    const newDetailList = [...detailOptionList];
    const updatedDetailList = newDetailList.map((item) => {
      const selected = selectedDetailOption.find(
        (select) => select.id === item.id
      );
      if (selected) {
        const prevChinaPrice = item.price.filter(
          (prev) => prev.currency !== "KRW"
        )[0];
        return {
          ...item,
          quantity: settingValue.quantity,
          isSoldOut: settingValue.quantity ? false : true,
        };
      }
      return item;
    });
    setDetailOptionList(updatedDetailList);
  };

  return (
    <div className="widthHalf d-flex flex-col justify-content-between relative">
      <div className="">
        <div className="d-flex widthFull justify-content-between">
          <div className="d-flex align-items-center gap-3">
            <p className="margin0 weight500 text18 grey">옵션 목록</p>
            <QuestionMarkIcon />
          </div>
          <div className="d-flex widthHalf align-items-end mb-3 gap-3">
            <div className="d-flex flex-col widthHalf relative">
              <p className="margin0 weight500 text12 mb-2">옵션가</p>
              <input
                value={settingValue.price}
                onChange={inputSettingValue}
                name="price"
                type="number"
                className="allttam-input input-border-2"
              />
              <button
                onClick={clickBatchApplyPrice}
                className="madebutton absolute batch-apply-btn weight500"
              >
                적용
              </button>
            </div>
            <div className="d-flex flex-col widthHalf relative">
              <p className="margin0 weight500 text12 mb-2">재고</p>
              <input
                value={settingValue.quantity}
                onChange={inputSettingValue}
                name="quantity"
                type="number"
                className="allttam-input input-border-2"
              />
              <button
                onClick={clickBatchApplyStock}
                className="madebutton absolute batch-apply-btn weight500"
              >
                적용
              </button>
            </div>
            {/* <GreyMiddleButton onClick={clickBatchApply}>
              일괄수정
            </GreyMiddleButton> */}
          </div>
        </div>
        <div className="optionlist-table">
          <OptionListTable
            setDetailOptionList={setDetailOptionList}
            optionGroups={optionGroups}
            detailOptionList={detailOptionList}
            setSelectedDetailOption={setSelectedDetailOption}
            selectedDetailOption={selectedDetailOption}
          />
        </div>
        <OptionSetButton
          closeModal={closeModal}
          item={item}
          collectProductSummary={collectProductSummary}
          optionList={optionList}
          optionGroups={optionGroups}
          detailOptionList={detailOptionList}
        />
      </div>
    </div>
  );
};
