import NavBar from "@components/NavBar/NavBar";
import { NavBarDefaultComponent } from "@components/NavBar/component/NavBarDefaultComponent";
import { UserInfoAccountInfo } from "@components/UserInfo/UserInfoAccountInfo";
import { UserInfoAgreeMarketing } from "@components/UserInfo/UserInfoAgreeMarketing";
import { UserInfoPayInfo } from "@components/UserInfo/UserInfoPayInfo";
import { UserInfoSetButton } from "@components/UserInfo/UserInfoSetButton";

export const UserInfo = () => {
  return (
    <div className="px-3">
      <NavBar>
        <NavBarDefaultComponent />
      </NavBar>
      <div>
        {/* <UserInfoAccountInfo /> */}
        <UserInfoPayInfo />
        {/* <UserInfoAgreeMarketing /> */}
        {/* <UserInfoSetButton /> */}
      </div>
    </div>
  );
};
