import ImageEditor from "@toast-ui/react-image-editor";
import "tui-image-editor/dist/tui-image-editor.css";
import { ModalBg } from "./ModalLayout/ModalBg";
import { useRef } from "react";
import { ModalInnerTop } from "./ModalLayout/ModalInnerTop";

interface Props {
  closeEditor: () => void;
  cropImg: string;
  applyImage: (img: string) => void;
}

export const ToastEditor = ({ closeEditor, cropImg, applyImage }: Props) => {
  const toastRef = useRef(null);

  const clickSave = async () => {
    await applyImage((toastRef.current as any)?.imageEditorInst.toDataURL());
    closeEditor();
  };

  return (
    <ModalBg onClick={() => {}}>
      <ModalInnerTop width={1400} unset={true}>
        <>
          <div className="toastButtonBox gap-3">
            <button
              onClick={closeEditor}
              className="madebutton toastCancelButton"
            >
              Cancle
            </button>
            <button onClick={clickSave} className="madebutton toastSaveButton">
              Save
            </button>
          </div>
          <ImageEditor
            ref={toastRef}
            includeUI={{
              loadImage: {
                path: cropImg,
                name: `${Date.now()}`,
              },
              // theme: myTheme,
              menu: [
                "crop",
                "flip",
                "rotate",
                "draw",
                "shape",
                "icon",
                "text",
                "mask",
                "filter",
              ],
              menuBarPosition: "left",
              initMenu: "shape",
              uiSize: {
                width: "1400px",
                height: "1000px",
              },
            }}
            cssMaxHeight={700}
            cssMaxWidth={700}
            selectionStyle={{
              cornerSize: 20,
              rotatingPointOffset: 0,
            }}
            usageStatistics={true}
          />
        </>
      </ModalInnerTop>
    </ModalBg>
  );
};
