import { NoticeCategory } from "@interface/notice";
import { useQuery } from "react-query";
import { noticeApi } from "src/apis/noticeApis";

const useNoticeCategory = () => {
  return useQuery<NoticeCategory[]>(
    ["noticeCateogy"],
    () => noticeApi.getNoticeCategory(),
    {}
  );
};

export default useNoticeCategory;
