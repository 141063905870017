import { GreenMiddleButton } from "@components/Button/allttamButton/GreenMiddleButton";
import { GreyMiddleButton } from "@components/Button/allttamButton/GreyMiddleButton";
import { CheckBoxInput } from "@components/Input/CheckBoxInput";
import { Input } from "@components/Input/Input";
import TextField from "@components/Input/TextField";
import useNoticeCategory from "@components/Notice/hooks/useNoticeCategory";
import { FroalaEditor } from "@components/shared/Froala/Froala";
import Spacing from "@components/shared/Spacing";
import { Notice, NoticeValues } from "@interface/notice";
import { ChangeEvent, useState } from "react";

interface Props {
  notice?: Notice;
  onSubmit: (noticeValues: NoticeValues) => void;
}

export const NoticeForm = ({ onSubmit, notice }: Props) => {
  const { data } = useNoticeCategory();
  const [values, setValues] = useState({
    title: notice ? notice.title : "",
    category: notice ? notice.category : "",
    isPinned: notice ? notice.isPinned : false,
  });
  const [model, setModel] = useState(notice ? notice.content : "");

  const inputValues = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  return (
    <>
      <TextField label="제목">
        <Input
          placeholder="제목을 입력해주세요"
          name="title"
          value={values.title}
          onChange={inputValues}
        />
      </TextField>
      <Spacing.Height height="height8" />
      <TextField label="카테고리">
        <TextField.Select
          name="category"
          value={values.category}
          onChange={inputValues}
        >
          {data?.map((category) => (
            <option key={category._id} value={category.title}>
              {category.title}
            </option>
          ))}
        </TextField.Select>
      </TextField>
      <Spacing.Height height="height16" />
      <div className="d-flex align-items-center gap-3">
        <p className="h5 margin0">공지사항 고정</p>
        <CheckBoxInput
          checked={values.isPinned}
          onClick={() =>
            setValues((prevValues) => ({
              ...prevValues,
              isPinned: !prevValues.isPinned,
            }))
          }
        />
      </div>
      <Spacing.Height height="height32" />
      <FroalaEditor
        setModel={setModel}
        model={model}
        placeholder="본문 내용을 입력해주세요."
      />
      <Spacing.Height height="height32" />
      <div className="d-flex justify-content-center gap-3">
        <GreyMiddleButton>닫기</GreyMiddleButton>
        <GreenMiddleButton
          onClick={() =>
            onSubmit({
              ...values,
              content: model,
            })
          }
        >
          작성
        </GreenMiddleButton>
      </div>
    </>
  );
};
