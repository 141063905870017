import { orderManage } from "@utils/functions/postApi";
import useToggle from "@hooks/useToggle";
import { ResultItem } from "@interface/orderManageInterface";
import { AlertConfirmAndClose } from "@components/Alert/AlertConfirmAndClose";
import { OrderLogModal } from "src/pages/Ordermanage/modal/OrderLogModal";
import { useInfoWindow } from "@hooks/useInfoWindow";
import { UseMutationResult } from "react-query";
import useModal from "@hooks/useModal";

interface Props {
  item: ResultItem;
  isSelect: boolean;
  refetchNowValue: () => void;
}

export const ItemOrderDate = ({ item, isSelect, refetchNowValue }: Props) => {
  const { openModal, closeModal } = useModal();

  const { state: orderLogModal, handleToggle: handleOrderLogModal } =
    useToggle();
  const openWindow = useInfoWindow();

  const confirmDoing = async () => {
    const deleteRes = await orderManage.deleteOrder(item.idx);

    if (deleteRes.code === 200) {
      openWindow("주문이 삭제되었습니다.");
      closeModal();
      refetchNowValue();
    }
  };

  return (
    <td className="width15">
      <div
        className={`noScroll collect-inner-1 original-price div-collect-1 ${
          isSelect && "selectedItem"
        }`}
      >
        <div>
          <p className="oneLine margin0 paddingZero">
            {item.orderDate?.split(" ")[0]}
          </p>
          <p className="oneLine margin0 paddingZero">
            {item.orderDate?.split(" ")[1]}
          </p>
          <p className="oneLine margin0 paddingZero mt-2">
            ( {item.collectDate?.split(" ")[0]}
          </p>
          <p className="oneLine margin0 paddingZero">
            {item.collectDate?.split(" ")[1]} )
          </p>
          <div className="" onClick={(e) => e.stopPropagation()}>
            <button
              onClick={() =>
                openModal(
                  <AlertConfirmAndClose
                    confirmDoing={confirmDoing}
                    message="선택하신 주문을 삭제하시겠습니까?"
                  />
                )
              }
              className="textRed tableItemInnerButton madebutton mt-2"
            >
              삭제
            </button>
            <button
              onClick={handleOrderLogModal}
              className="tableItemInnerButton madebutton mt-2"
            >
              주문로그
            </button>
          </div>
        </div>
        {orderLogModal && (
          <OrderLogModal idx={item.idx} closeModal={handleOrderLogModal} />
        )}
      </div>
    </td>
  );
};
