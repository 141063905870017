import { Revenue } from "../../../../interface/orderManageInterface";

interface Props {
  inputRevenueValue: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => void;
  revenueValue: Revenue;
}

export const RevenueWeightFee = ({
  inputRevenueValue,
  revenueValue,
}: Props) => {
  return (
    <div className="d-flex align-items-center widthFull gap-3 textStart mt-3">
      <div className="d-flex align-items-center widthFull">
        <p className="ordererInfoTitle revenueModalLeft">적용무게 / 선편요금</p>
        <div className="d-flex align-items-center revenueModalRight gap-5">
          <div className="d-flex align-items-center widthFull">
            <input
              onChange={inputRevenueValue}
              value={revenueValue.weight}
              name="weight"
              className="orderInfoModalInput"
              type="number"
            />
            <p className="margin0 pl-1">Kg</p>
          </div>
          <div className="d-flex align-items-center widthFull">
            <input
              onChange={inputRevenueValue}
              value={revenueValue.weightFee}
              name="weightFee"
              className="orderInfoModalInput"
              type="number"
            />
            <p className="margin0 pl-1">원</p>
          </div>
        </div>
      </div>
    </div>
  );
};
