import one from "@assets/images/landing/seo/one.svg";
import two from "@assets/images/landing/seo/two.png";
import three from "@assets/images/landing/seo/three.png";
import selectedOne from "@assets/images/landing/seo/selectedOne.png";
import selectedTwo from "@assets/images/landing/seo/selectedTwo.svg";
import selectedThree from "@assets/images/landing/seo/selectedThree.svg";
import { SeoImageTranslate } from "./component/SeoImageTranslate";
import { useEffect, useState } from "react";
import { SeoImageCorrection } from "./component/SeoImageCorrection";
import { SeoKeywordSearching } from "./component/SeoKeywordSearching";
import { SeoCategoryMapping } from "./component/SeoCategoryMapping";
import { SeoImageEdit } from "./component/SeoImageEdit";
import { SeoVideoComponent } from "./component/SeoVideoComponent";

interface Props {
  functionRef: any;
}

export const LandingSeo = ({ functionRef }: Props) => {
  const [componentState, setComponentState] = useState<number>(1);

  useEffect(() => {
    const intervalTime = setInterval(() => {
      if (componentState < 5) {
        setComponentState((prev) => prev + 1);
      } else {
        setComponentState(1);
      }
    }, 8000);

    return () => clearInterval(intervalTime);
  }, [componentState]);

  const progressBarWidth = {
    width: `${(componentState / 5) * 100}%`,
    height: "10px",
  };

  const clickHeadButton = (index: number) => {
    setComponentState(index);
  };

  return (
    <div className="landingSeo pt-3 relative mb-5" ref={functionRef}>
      <h4 className="seoMainText oneLine mt-6">
        <span className="textGreen">SEO</span> 작업으로{" "}
        <span className="textYellow">TOOL 제공</span>
      </h4>
      <div className="subTitleSeo mt-5">
        <button
          onClick={() => clickHeadButton(1)}
          className="madebutton d-flex align-items-end justify-content-start gap-2"
        >
          <img
            src={componentState >= 1 && componentState < 4 ? selectedOne : one}
          />
          <p className="seoSubTitle oneLine">이미지 번역</p>
        </button>
        <button
          onClick={() => clickHeadButton(4)}
          className="madebutton d-flex align-items-end justify-content-start gap-2"
        >
          <img src={componentState === 4 ? selectedTwo : two} />
          <p className="seoSubTitle oneLine">키워드 검색</p>
        </button>
        <button
          onClick={() => clickHeadButton(5)}
          className="madebutton d-flex align-items-end justify-content-start gap-2"
        >
          <img src={componentState === 5 ? selectedThree : three} />
          <p className="seoSubTitle oneLine">카테고리 오토 매핑</p>
        </button>
      </div>
      <div className="subTitleSeo seoProgressWrapper mt-3">
        <div className="progress-bar">
          <div className="landing-progress" style={progressBarWidth} />
        </div>
      </div>
      <div className="seoMainContent">
        <SeoVideoComponent
          componentState={componentState}
          setComponentState={setComponentState}
        />
        <div className="seoMainTextContent">
          {COMPONENT_INDEX[componentState as 1 | 2 | 3 | 4 | 5]}
        </div>
      </div>
    </div>
  );
};

const COMPONENT_INDEX = {
  1: <SeoImageTranslate />,
  2: <SeoImageCorrection />,
  3: <SeoImageEdit />,
  4: <SeoKeywordSearching />,
  5: <SeoCategoryMapping />,
};
