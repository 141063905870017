import { AllttamCare } from "@components/AllttamCare/AllttamCare";
import { AllttamHelper } from "@components/AllttamHelper/AllttamHelper";
import { Popup } from "@components/Popup/Popup";
import SideBar from "@components/Sidebar/SideBar";
import { TranslateButton } from "@components/TranslateAndExchange/TranslateButton";
import useToggle from "@hooks/useToggle";
import { Outlet } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { modalAtom } from "./atom/modalAtom";
import { GlobalModal } from "@components/shared/modal/GlobalModal";
import { createPortal } from "react-dom";

const SideBarRouter = () => {
  const { state: isNav, handleToggle: handleIsNav } = useToggle(true);
  const { isOpen } = useRecoilValue(modalAtom);
  const $root_portal = document.getElementById("modal-portal");
  return (
    <div
      className={`main-hero-sec stickyBgHeight ${
        isNav ? "desktop-nav-active" : ""
      }`}
    >
      {$root_portal != null && isOpen
        ? createPortal(<GlobalModal />, $root_portal)
        : null}
      <SideBar isNav={isNav} handleIsNav={handleIsNav as () => void} />
      <div className={`heroSec notification`}>
        <div className="contentContainer">
          <Outlet />
          <AllttamCare />
          <AllttamHelper />
          <TranslateButton />
        </div>
      </div>
    </div>
  );
};

export default SideBarRouter;
