import useModal from "@hooks/useModal";
import { Spinner } from "../../../../components/Spinner/Spinner";

interface Props {
  isLoading: boolean;
  isSuccess: boolean;
  data: any;
}

export const ProductEditResult = ({ isLoading, isSuccess, data }: Props) => {
  const { closeModal } = useModal();
  return (
    <>
      {isLoading && (
        <div className="modal-body">
          <div className="widthFull d-flex justify-content-center">
            <Spinner />
          </div>
        </div>
      )}
      {isSuccess && (
        <div className="modal-body">
          <div className="row">
            <div className="col-12">
              <>
                {data.map((item: any, idx: number) => (
                  <div key={idx}>
                    <div className="d-flex gap-5">
                      <p>
                        상품 {idx + 1} :{" "}
                        <span
                          className={`${
                            item.isSuccess ? "textGreen" : "textRed"
                          }`}
                        >
                          {item.isSuccess ? "성공" : "실패"}
                        </span>
                      </p>
                      {item.failReason && (
                        <p>실패사유 : {`${item.failReason}`}</p>
                      )}
                    </div>
                  </div>
                ))}
              </>
              <div className="d-flex align-items-center justify-content-center gap-3 mt-4">
                <button className="cancel-btn save-btn" onClick={closeModal}>
                  닫기
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div></div>
    </>
  );
};
