import { useEffect, useState } from "react";
import loginBg from "@assets/images/login/loginBg.svg";
import loginBg2 from "@assets/images/login/loginBg2.svg";
import { Footer } from "@components/Footer/Footer";
import { LoginComponent } from "../components/Login/LoginComponent/LoginComponent";
import allttam from "@assets/images/logo/allttam.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { SignupComponent } from "../components/Login/SignupComponent/SignupComponent";
import "@styles/Login.css";

export const Login = () => {
  const [isLoginPage, setIsloginPage] = useState(true);
  const [showImage1, setShowImage1] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {}, []);

  useEffect(() => {
    if (location.pathname === "/login") {
      setIsloginPage(true);
    } else {
      setIsloginPage(false);
    }
  }, [location]);

  const changeLoginSignup = () => {
    if (isLoginPage) {
      navigate("/signup");
    } else {
      navigate("/login");
    }
  };

  useEffect(() => {
    const imgInterval = setInterval(() => {
      setShowImage1((prev) => !prev);
    }, 1500);

    return () => clearInterval(imgInterval);
  }, []);

  return (
    <div className="login-page">
      <div className="d-flex justify-content-center">
        <div
          className={`image-container ${
            showImage1 ? "show-image1" : "show-image2"
          }`}
        >
          <img src={loginBg} className="width60 loginBgImg" />
          <img src={loginBg2} className="width60 loginBgImg" />
        </div>
        <div className="width20 d-flex flex-column login-content-box justify-content-center align-items-center gap-5">
          <div className="d-flex align-items-end gap-4">
            <button
              onClick={() => {
                navigate("/");
              }}
              className="madebutton"
            >
              <img className="login-page-logo" src={allttam} />
            </button>
            <p className="login-title oneLine">
              {isLoginPage ? "로그인" : "회원가입"}
            </p>
          </div>
          {isLoginPage ? <LoginComponent /> : <SignupComponent />}
          <div className="d-flex gap-3">
            <h5>
              {isLoginPage ? "계정이 아직 없으세요 ?" : "계정을 가지고 있나요?"}
            </h5>
            <button
              onClick={changeLoginSignup}
              className="madebutton paddingZero"
            >
              <h5 className="textGreen">
                {isLoginPage ? "회원가입" : "로그인"}
              </h5>
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
