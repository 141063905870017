export const HomeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <path data-name="사각형 3743" fill="none" d="M0 0h30v30H0z" />
      <path
        className="icon"
        data-name="패스 10471"
        d="M7 19.2H4.143A2.144 2.144 0 0 1 2 17.053V6.682a2.144 2.144 0 0 1 1.512-2.049l7.437-2.289a3.572 3.572 0 0 1 2.1 0l7.437 2.289A2.144 2.144 0 0 1 22 6.682v10.371a2.144 2.144 0 0 1-2.143 2.147H17v.714a2.143 2.143 0 0 1-2.143 2.143H9.143A2.143 2.143 0 0 1 7 19.91zm8.571.714v-7.147a.715.715 0 0 0-.714-.714H9.143a.715.715 0 0 0-.714.714v7.143a.715.715 0 0 0 .714.714h5.714a.715.715 0 0 0 .714-.714zM17 17.767h2.857a.715.715 0 0 0 .714-.714V6.682a.714.714 0 0 0-.5-.683l-7.436-2.29a2.145 2.145 0 0 0-1.261 0L3.933 6a.714.714 0 0 0-.5.683v10.37a.715.715 0 0 0 .714.714H7v-5a2.143 2.143 0 0 1 2.143-2.143h5.714A2.143 2.143 0 0 1 17 12.767z"
        transform="translate(3 2.814)"
        fill="#fff"
        fillRule="evenodd"
      />
    </svg>
  );
};
