import { SqueareMinusIcon } from "@components/svg/SquareMinusIcon";
import { SquarePlusIcon } from "@components/svg/SquarePlusIcon";
import { Keyword } from "@interface/uploadinterface";
import { useEffect, useState } from "react";

interface Props {
  keyword: Keyword;
  selectedTag: string[];
  setSelectedTag: React.Dispatch<React.SetStateAction<string[]>>;
}
export const TagSettingTableItem = ({
  keyword,
  selectedTag,
  setSelectedTag,
}: Props) => {
  const [isSelected, setSelected] = useState<boolean>();
  const selectTag = () => {
    if (selectedTag.includes(keyword.relKeyword)) {
      setSelectedTag(selectedTag.filter((item) => item !== keyword.relKeyword));
    } else {
      setSelectedTag((prev) => [...prev, keyword.relKeyword]);
    }
  };
  useEffect(() => {
    setSelected(
      Boolean(selectedTag.find((item) => item == keyword.relKeyword))
    );
  }, [selectedTag]);

  return (
    <tr onClick={selectTag} className="keywordItem">
      <td>
        <div
          className={`mob-key1 mob-key3 relKeyword oneLine ${
            isSelected && "selectedItem"
          }`}
        >
          {isSelected ? <SqueareMinusIcon /> : <SquarePlusIcon />}

          {keyword.relKeyword}
        </div>
      </td>
      <td>
        <div className={`${isSelected && "selectedItem"}`}>
          {keyword.monthlyPcQcCnt}
        </div>
      </td>
      <td>
        <div className={`mob-key3 ${isSelected && "selectedItem"}`}>
          {keyword.monthlyMobileQcCnt}
        </div>
      </td>
      <td>
        <div className={`${isSelected && "selectedItem"}`}>
          {keyword.monthlyAvePcClkCnt}
        </div>
      </td>
      <td>
        <div className={`mob-key3 ${isSelected && "selectedItem"}`}>
          {keyword.monthlyAveMobileClkCnt}
        </div>
      </td>
      <td>
        <div className={`keywordComp ${isSelected && "selectedItem"}`}>
          {keyword.compIdx}
        </div>
      </td>
      <td>
        <div className={`${isSelected && "selectedItem"}`}>
          {keyword.monthlyAvePcCtr}%
        </div>
      </td>
      <td>
        <div className={`${isSelected && "selectedItem"} mob-key2`}>
          {keyword.monthlyAveMobileCtr}%
        </div>
      </td>
    </tr>
  );
};
