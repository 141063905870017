import { upload } from "@testing-library/user-event/dist/upload";
import { UploadLogComponent } from "./UploadLogComponent";
import { UploadLog } from "../../../../interface/defaultinterface";
import { NoneTableItem } from "../../HomeCollectLog/components/NoneTabletem";
import useSelectHelperComponent from "@hooks/useSelectHelperComponent";
import { QuestionMarkIcon } from "@components/svg/QuestionMarkIcon";

interface Props {
  uploadLog: UploadLog[];
}

export const UploadTable = ({ uploadLog }: Props) => {
  const openHelper = useSelectHelperComponent();
  return (
    <table
      className="homeLogTable"
      width="100%"
      cellPadding={0}
      cellSpacing={0}
      border={0}
      align="center"
    >
      <thead className="head-one table-sticky">
        <tr>
          <th>작업번호</th>
          <th>업로드마켓</th>
          <th>요청</th>
          <th>
            {" "}
            <div className="d-flex gap-2 justify-content-center">
              <p className="margin0">성공</p>
              <button
                onClick={() => {
                  // openHelper("detail", "64e5b3098081d5317ca7794d");
                }}
                className="madebutton paddingZero"
              >
                <QuestionMarkIcon />
              </button>
            </div>
          </th>
          <th>완료일자</th>
        </tr>
      </thead>
      <tbody className="body-one">
        {uploadLog?.map((log, idx) => (
          <UploadLogComponent key={idx} log={log} />
        ))}
        {!uploadLog ||
          (uploadLog.length === 0 && <NoneTableItem colSpan={5} />)}
      </tbody>
    </table>
  );
};
