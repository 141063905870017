import { Link } from "react-router-dom";

export const NavPaymentLog = () => {
  return (
    <h4 className="subTitle">
      <Link to="/payment" className="paymentGray">결제하기</Link>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        viewBox="0 0 30 30"
      >
        <path data-name="사각형 1442" fill="none" d="M0 0h30v30H0z" />
        <path
          data-name="패스 4064"
          d="m1131.574 93.9 4.757-5-5-4.757"
          transform="translate(-1118.83 -74.024)"
          stroke="#c9cedb"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          fill="none"
        />
      </svg>
      결제 내역
    </h4>
  );
};
