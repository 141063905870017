import { useEffect, useState } from "react";
import { ImgTransLateLogItem } from "./TranslateLogItem";
import { usePost } from "@hooks/usePost";
import { ModalInnerTop } from "@components/ModalLayout/ModalInnerTop";
import { ModalBg } from "@components/ModalLayout/ModalBg";
import { PagingButton } from "@components/PagingButton/PagingButton";
import { getKoreanTiemFromUTC } from "@components/AllttamCare/function/getKoreanTimeFromUTC";

interface Props {
  closeModal: () => void;
  type: string;
}

export const ImgTranslateModal = ({ closeModal, type }: Props) => {
  const [page, setPage] = useState<number>(1);
  const imgLog = usePost("/Log/SearchUserPoint", {
    startDate: "2022-01-01",
    endDate: getKoreanTiemFromUTC(new Date().toISOString())
      .replaceAll(".", "-")
      .slice(0, -1),
    page: page,
    maxRow: 10,
    filter: type,
  });

  useEffect(() => {
    imgLog.mutate();
  }, []);

  const clickNextPage = async () => {
    await setPage((prev) => prev + 1);
    imgLog.mutate();
  };

  const clickPrevPage = async () => {
    await setPage((prev) => prev - 1);
    imgLog.mutate();
  };

  const clickPage = async (e: React.MouseEvent<HTMLButtonElement>) => {
    await setPage(parseInt(e.currentTarget.value));
    imgLog.mutate();
  };
  return (
    <ModalBg
      onClick={(e) => {
        e.stopPropagation();
        closeModal();
      }}
    >
      <ModalInnerTop width={1000}>
        <>
          <div className="modal-header modal-sticky">
            <h5 className="modal-title d-flex align-items-center">
              {type === "IT" ? "올땀 스튜디오" : "텍스트 수집"}
            </h5>
            <button onClick={closeModal} className="btn-close"></button>
          </div>
          <div className="modal-body minHeight">
            <table width="100%" className="transelateLogTable">
              <thead>
                <tr>
                  <th>no</th>
                  <th>사용내역</th>
                  <th>코인 사용</th>
                  <th>날짜 / 시간</th>
                </tr>
              </thead>
              <tbody>
                {imgLog?.data?.rows?.map((item: TranslateLog, idx: number) => (
                  <ImgTransLateLogItem key={idx} item={item} />
                ))}
              </tbody>
            </table>
            <div className="mt-5">
              <PagingButton
                totalPages={imgLog?.data?.totalPage}
                clickNextPage={clickNextPage}
                clickPrevPage={clickPrevPage}
                currentPage={page}
                onClick={clickPage}
              />
            </div>
          </div>
        </>
      </ModalInnerTop>
    </ModalBg>
  );
};
export interface TranslateLog {
  code: string;
  no: number;
  regDate: string;
  site: string;
  type: string;
  usePoint: string;
}
