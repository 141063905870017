interface Props {
  color?: string;
}

export const XIcon = ({ color }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 30 30"
    >
      <path data-name="사각형 3994" fill="none" d="M0 0h30v30H0z" />
      <g data-name="그룹 11564">
        <path
          data-name="패스 10550"
          d="m-9829.023-7155.157 12-12"
          transform="translate(9838.023 7176.156)"
          stroke={color ? color : "#d62828"}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3px"
          fill="none"
        />
        <path
          data-name="패스 10551"
          d="M0 12 12 0"
          transform="rotate(90 6 15)"
          stroke={color ? color : "#d62828"}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3px"
          fill="none"
        />
      </g>
    </svg>
  );
};
