import ModalContent from "@components/shared/modal/ModalContent";
import useModal from "@hooks/useModal";
import { usePost } from "@hooks/usePost";
import {
  MarketAccount,
  ProdcutManageResultItem,
  ProductManageSearchingValue,
  StatusChangeModal,
} from "@interface/productmanageinterface";
import { Spinner } from "react-bootstrap";
import { StatusChangeResult } from "./StatusChangeResult";

interface Props {
  selectedItem: ProdcutManageResultItem[];
  searchingValue: ProductManageSearchingValue;
  modalState: StatusChangeModal | undefined;
  marketAccountValue: MarketAccount | undefined;
}

export const ProductStatusChangeModal = ({
  selectedItem,
  searchingValue,
  modalState,
  marketAccountValue,
}: Props) => {
  const { closeModal } = useModal();
  const changeStatusToSale = usePost(
    "/ProductManagement/MarketProductStatusChange",
    {
      market: marketAccountValue?.market,
      marketAccount: marketAccountValue?.account?.account,
      statusChangeTargetData: selectedItem.map((item) => {
        return { objectId: item.id, code: item.code };
      }),
      status: modalState?.status,
    }
  );

  return (
    <ModalContent>
      <ModalContent.ModalHead>
        <h5
          className="modal-title d-flex align-items-center"
          id="exampleModalLabel"
        >
          {modalState?.title}{" "}
          <span className="three-selected">
            선택상품 {selectedItem.length}개
          </span>
        </h5>
      </ModalContent.ModalHead>
      <hr className="mt-0 mb-4" />
      <ModalContent.ModalBody>
        {changeStatusToSale.isLoading && (
          <div className="widthFull d-flex justify-content-center">
            <Spinner />
          </div>
        )}
        {changeStatusToSale.isSuccess && (
          <StatusChangeResult
            account={marketAccountValue?.account?.account!}
            result={changeStatusToSale?.data}
            handleChangeModal={closeModal}
          />
        )}
        {changeStatusToSale.isError && (
          <div>
            <h4> 시스템 오류로 인하여 실패했습니다.</h4>
            <div className="row mt-4">
              <div className="col-12">
                <div className="d-flex align-items-center justify-content-center gap-3 mt-4">
                  <button className="cancel-btn" onClick={closeModal}>
                    닫기
                  </button>
                  <button
                    className="cancel-btn save-btn"
                    onClick={() => changeStatusToSale.mutate()}
                  >
                    {modalState?.button}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {!changeStatusToSale.isLoading &&
          !changeStatusToSale.isSuccess &&
          !changeStatusToSale.isError && (
            <>
              <h4>
                선택한 상품 {selectedItem.length}
                {""}개를 {modalState?.message}
              </h4>
              <div className="row mt-4">
                <div className="col-12">
                  <div className="d-flex align-items-center justify-content-center gap-3 mt-4">
                    <button className="cancel-btn" onClick={closeModal}>
                      닫기
                    </button>
                    <button
                      className="cancel-btn save-btn"
                      onClick={() => changeStatusToSale.mutate()}
                    >
                      {modalState?.button}
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
      </ModalContent.ModalBody>
    </ModalContent>
  );
};
