import { ComponentType, IndexType } from "@interface/fqa.interface";
import { atom } from "recoil";

export const translateWindowState = atom({
  key: "translateWindowState",
  default: false,
});

export const infoWindowState = atom({
  key: "infoWindowState",
  default: {
    isOpen: false,
    animation: false,
    message: "",
  },
});

export const isHelper = atom({
  key: "isHelper",
  default: false,
});

export const helperComponent = atom<ComponentType>({
  key: "helperComponent",
  default: {
    type: "default",
    id: "",
  },
});

export const helperPrevComponentList = atom<ComponentType[]>({
  key: "helperPrevComponentList",
  default: [],
});

export const productManagePeriod = atom({
  key: "productManagePeriod",
  default: "aMonth",
});

export const allttamCare = atom({
  key: "allttamCare",
  default: false,
});
