import NavBar from "@components/NavBar/NavBar";
import { NavAdmin } from "@components/NavBar/component/NavAdmin";
import { User } from "@interface/user";
import { Navigate, Outlet } from "react-router-dom";

export const AdminGuard = (user: User) => {
  const isAdmin = user.role === "Admin";
  return isAdmin ? (
    <>
      <NavBar>
        <NavAdmin />
      </NavBar>
      <Outlet />
    </>
  ) : (
    <Navigate to="/home" replace />
  );
};
