import { useState } from "react";
import emptyStar from "@assets/images/helper/emptyStar.svg";
import fillStar from "@assets/images/helper/fillStar.svg";
import axios from "axios";
import useFetch from "@hooks/useFetch";
import { useInfoWindow } from "@hooks/useInfoWindow";
import { useQueries, useQueryClient } from "react-query";
import useUser from "@hooks/useUser";

interface Props {
  fqaId: string;
}

export const FqaRating = ({ fqaId }: Props) => {
  const [rating, setRating] = useState(0);
  const openWindow = useInfoWindow();
  const [review, setReview] = useState<string>("");
  const userInfo = useUser();
  const queryClient = useQueryClient();

  const clickSendFqaRating = async () => {
    if (rating === 0) return openWindow("별점을 입력해주세요. (최소 1점)");
    if (review === "") return openWindow("평가내용을 입력해주세요");
    const res = await axios.post(
      "https://center.allttam.com/api/allttam/fqarating",
      {
        id: fqaId,
        review: review,
        rating: rating,
        writer: userInfo.username,
      }
    );

    if (res.status === 201) {
      openWindow("리뷰가 제출되었습니다.");
      queryClient.invalidateQueries("fqa");
    }
  };

  return (
    <div className="">
      <div className="d-flex justify-content-between align-items-end">
        <p className="margin0 text12 weight500 grey">
          위 도움말이 도움이 되었나요?
        </p>
        <div className="margin d-flex gap-2">
          {RATINGSTAR.map((item, idx) => (
            <button
              key={idx}
              className="madebutton paddingZero"
              onClick={() => {
                setRating(idx + 1);
              }}
            >
              <img src={idx + 1 <= rating ? fillStar : emptyStar} />
            </button>
          ))}
        </div>
      </div>
      <div className="mt-3">
        <textarea
          value={review}
          onChange={(e) => {
            setReview(e.target.value);
          }}
          placeholder="소중한 의견 주시면, 반영하도록 노력하겠습니다."
          className="resizeNone helper-rating-input"
        />
        <div className="d-flex justify-content-between mt-2">
          <p className="margin0 text10 grey weight 500">
            <span className={`${review.length > 300 && "textRed"}`}>
              {review.length}
            </span>{" "}
            / 300
          </p>
          <button
            className="madebutton review-rating-btn"
            onClick={clickSendFqaRating}
          >
            보내기
          </button>
        </div>
      </div>
    </div>
  );
};

const RATINGSTAR = [0, 0, 0, 0, 0];
