import { NoticeValues } from "@interface/notice";
import { useMutation } from "react-query";
import { noticeApi } from "src/apis/noticeApis";

interface Props {
  id?: string;
  isEdit: boolean;
  onSuccess: () => void;
  onError: () => void;
}

const useNoticeMutation = ({ onSuccess, onError, isEdit, id }: Props) => {
  if (isEdit) {
    return useMutation(
      (noticeValues: NoticeValues) =>
        noticeApi.updateNotice(id as string, noticeValues),
      {
        onSuccess: () => {
          onSuccess();
        },
        onError: () => {
          onError();
        },
      }
    );
  } else {
    return useMutation(
      (noticeValues: NoticeValues) => noticeApi.createNotice(noticeValues),
      {
        onSuccess: () => {
          onSuccess();
        },
        onError: () => {
          onError();
        },
      }
    );
  }
};

export default useNoticeMutation;
