import {
  OrdermanageSerachingValue,
  ResultItem,
} from "@interface/orderManageInterface";
import { useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import useOrderList from "../hooks/useOrderList";
import { OrderManagePaging } from "./OrderManagePaging";
import OrderManageSearchingBoard from "./OrderManageSearchingBoard/OrderManageSearchingBoard";
import { OrderManageSubMenu } from "./OrderManageSubMenu/OrderManageSubMenu";
import { OrderManageTable } from "./OrderManageTable/OrderManageTable";

export const OrderManageOrder = () => {
  const queryClient = useQueryClient();
  const [searchValue, setSearchValue] = useState<OrdermanageSerachingValue>({
    market: "",
    row: 10,
    site: "",
    type: "",
    value: "",
    status: "",
    startDate: new Date(new Date().setMonth(new Date().getMonth() - 1))
      .toISOString()
      .slice(0, 10),
    endDate: new Date().toISOString().slice(0, 10),
    page: 1,
  });
  const [orderList, setOrderList] = useState<ResultItem[]>([]);
  const [selectedOrder, setSelectedOrder] = useState<ResultItem[]>([]);
  const [enabled, setEnabled] = useState(false);

  const { data, isLoading } = useOrderList({
    searchValue,
    enabled: enabled,
    onSuccess: (data: ResultItem[]) => {
      setOrderList(data);
    },
    onError: () => {},
    onSettled: () => {
      setEnabled(false);
    },
  });

  const collectSearchValue = useCallback(
    (collectValue: Partial<OrdermanageSerachingValue>) => {
      setSelectedOrder([]);
      setSearchValue({
        ...searchValue,
        ...collectValue,
      });
      setEnabled(true);
    },
    [setEnabled, setSearchValue, searchValue]
  );

  const collectRow = useCallback(
    (row: number) => {
      setSearchValue({
        ...searchValue,
        row,
        page: 1,
      });
      setEnabled(true);
    },
    [searchValue, setEnabled, setSearchValue]
  );

  const collectPage = useCallback(
    (page: number) => {
      setSearchValue((prevValues) => ({
        ...prevValues,
        page: page,
      }));
      setEnabled(true);
    },
    [setSearchValue, setEnabled]
  );

  const refetchNowValue = useCallback(() => {
    const valueArray = Object.values(searchValue);
    queryClient.invalidateQueries(["orderList", ...valueArray]);
    setEnabled(true);
  }, [searchValue]);

  return (
    <>
      <OrderManageSearchingBoard onSubmit={collectSearchValue} />
      <OrderManageSubMenu
        searchCount={data?.data?.length}
        totalCount={data?.totalRow}
        refetchNowValue={refetchNowValue}
        collectRow={collectRow}
        orderList={orderList}
        selectedOrder={selectedOrder}
      />
      <OrderManageTable
        isLoading={isLoading}
        orderList={orderList}
        setSelectedOrder={setSelectedOrder}
        selectedOrder={selectedOrder}
        setOrderList={setOrderList}
        refetchNowValue={refetchNowValue}
      />
      <OrderManagePaging
        totalPage={data?.totalPage as number}
        currentPage={data?.nowPage as number}
        submitPage={collectPage}
      />
    </>
  );
};
