import { UseMutationResult } from "react-query";
import { upload } from "../../../../../utils/functions/postApi";
import { ProhibitReplaceWord } from "../../../../../interface/uploadinterface";
import { useTooltip } from "@hooks/useTooltip";
import { GlobalTooltip } from "@components/GlobalTooltip/GlobalTooltip";

interface Props {
  length: number;
  setSelectedReplaceWord: React.Dispatch<
    React.SetStateAction<ProhibitReplaceWord[]>
  >;
  selectedReplaceWord: ProhibitReplaceWord[];
  getReplaceWord: UseMutationResult<any, unknown, void, unknown>;
}

export const ReplaceWordDeleteButton = ({
  length,
  setSelectedReplaceWord,
  selectedReplaceWord,
  getReplaceWord,
}: Props) => {
  const [isReplaceTooltip, enterReplace, leaveReplace] = useTooltip();
  const [isCountTooltip, enterCount, leaveCount] = useTooltip();

  const clickRemoveReplaceWord = async () => {
    const removeRes = await upload.removeProhibitReplaceWord(
      selectedReplaceWord.map((item) => item.idx)
    );
    if (removeRes === "완료했습니다.") {
      getReplaceWord.mutate();
      setSelectedReplaceWord([]);
    }
  };

  return (
    <div className="row align-items-center mt-5">
      <div className="d-flex align-items-center gap-3 justify-content-between relative">
        <GlobalTooltip isVisible={isCountTooltip as boolean}>
          등록된 치환 단어의 총 개수입니다.
        </GlobalTooltip>
        <h6
          className="function-text m-0 font16"
          onMouseEnter={enterCount as () => void}
          onMouseLeave={leaveCount as () => void}
        >
          {length} 개
        </h6>
        <div className="d-flex align-items-center gap-3 relative">
          <GlobalTooltip
            isVisible={isReplaceTooltip as boolean}
            style={{
              right: "0",
            }}
          >
            선택한 치환 단어들을 등록에서 일괄로 제거합니다.
          </GlobalTooltip>
          <button
            className="newRedButton"
            onClick={clickRemoveReplaceWord}
            onMouseEnter={enterReplace as () => void}
            onMouseLeave={leaveReplace as () => void}
          >
            {selectedReplaceWord.length}개 선택 삭제
          </button>
        </div>
      </div>
    </div>
  );
};
