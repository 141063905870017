interface Props {
  deliveryPrice: {
    deliveryFee: number;
    returnFee: number;
  };
  inputDeleveryPrice: () => void;
}

export const EditDeliveryPriceComponent = ({
  deliveryPrice,
  inputDeleveryPrice,
}: Props) => {
  return (
    <div className="d-flex align-items-center gap-5 product-edit-price-box">
      <div className="d-flex gap-3 align-items-center">
        <p className="margin0 product-edit-price-box-text oneLine">배송비</p>
        <input
          name="deliveryFee"
          onChange={inputDeleveryPrice}
          value={deliveryPrice.deliveryFee}
          type="email"
          className="form-control"
          id="product-name361a"
          placeholder="가격을 입력하세요"
        />
      </div>
      <div className="d-flex gap-3 align-items-center change-price-in">
        <p className="margin0 product-edit-price-box-text oneLine">
          교환·반품 배송비
        </p>
        <input
          onWheel={(e) => {
            e.currentTarget.blur();
          }}
          onChange={inputDeleveryPrice}
          type="number"
          name="returnFee"
          value={deliveryPrice.returnFee}
          className="form-control"
          id="product-name361a"
          placeholder="가격을 입력하세요"
        />
      </div>
    </div>
  );
};
