import { useEffect, useState } from "react";
import useFetch from "@hooks/useFetch";
import { PriceType, TitleType } from "@interface/productmanageinterface";

interface Props {
  site: string;
  code: string;
}

export const ViewProductInfo = ({ site, code }: Props) => {
  const [selectedImg, setSelectedItem] = useState<string>("");

  const [itemInfo] = useFetch(
    `itemInfo${code}`,
    `Product/SearchCollectProductDetail?site=${site}&code=${code}`
  );

  useEffect(() => {
    if (itemInfo) {
      setSelectedItem(itemInfo?.mainImages[0]);
    }
  }, [itemInfo]);

  const clickSubImg = (url: string) => {
    setSelectedItem(url);
  };
  return (
    <div className="d-flex flex-column align-items-center viewModalLeftSide">
      <img className="viewMainImg" src={selectedImg} />
      <div className="viewSubImageContainer gap-3 mt-3">
        {itemInfo?.mainImages.map((item: string, index: number) => (
          <img
            key={index}
            className="viewSubImage"
            src={item}
            onClick={() => {
              clickSubImg(item);
            }}
          />
        ))}
      </div>
      <div>
        <div className="mt-4 textStart">
          <p className="titleText">수집마켓</p>
          <p>{itemInfo?.site}</p>
        </div>
        <div className="textStart mt-2">
          <p className="titleText">상품명</p>
          <p>
            {
              itemInfo?.title.filter(
                (item: TitleType) => item.language === "KR"
              )[0].text
            }
          </p>
        </div>
        <div className="textStart mt-2">
          <p className="titleText">가격</p>
          <p>
            {
              itemInfo?.price.filter(
                (item: PriceType) => item.currency === "CNY"
              )[0]?.originalPrice
            }{" "}
            ¥
          </p>
        </div>
        <div className="textStart mt-2">
          <p className="titleText">원본 주소</p>
          <a href={itemInfo?.url} target="_blank" className="contentA">
            {itemInfo?.url}
          </a>
        </div>
        <div className="textStart mt-2">
          <p className="titleText">수집일</p>
          <p>{itemInfo?.regDate.split("T")[0].replaceAll("-", ".")}</p>
        </div>
      </div>
    </div>
  );
};
