import { UseMutationResult } from "react-query";
import { upload } from "../../../../../utils/functions/postApi";
import { GreenMiddleButton } from "@components/Button/allttamButton/GreenMiddleButton";

interface Props {
  prohibitWord: string;
  setProhibitWord: React.Dispatch<React.SetStateAction<string>>;
  getProhibitWord: UseMutationResult<any, unknown, void, unknown>;
}

export const ProhibitWordInput = ({
  prohibitWord,
  setProhibitWord,
  getProhibitWord,
}: Props) => {
  const inputWord = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProhibitWord(e.target.value);
  };

  const clickSetProhibitWord = async () => {
    const prohibitRes = await upload.setProhibitReplaceWord(
      prohibitWord,
      "Remove"
    );
    if (prohibitRes.status === 200) {
      getProhibitWord.mutate();
      setProhibitWord("");
    }
  };

  return (
    <div className="d-flex gap-3">
      <input
        onChange={inputWord}
        value={prohibitWord}
        type="text"
        placeholder="복수 등록 ( , 쉼표로 구분)"
        className="allttam-input"
      />
      <GreenMiddleButton onClick={clickSetProhibitWord}>등록</GreenMiddleButton>
    </div>
  );
};
