import earthBg from "@assets/images/landing/earth/Group.png";
import yellowPin from "@assets/images/landing/earth/yellowPin.svg";
import blackPin from "@assets/images/landing/earth/blackPin.svg";

import { useEffect, useState } from "react";
import { EarthMobileOrderWindow } from "./component/EarthMobileOrderWindow";
import { EarthMobileCsWindow } from "./component/EarthMobileCsWindow";
import { EarthMobileProfitWindow } from "./component/EarthMobileProfitWindow";
import { EarthMobileInfluentWindow } from "./component/EarthMobileInfluentWindow";

export const LandingEarthMobile = () => {
  const [selectedState, setSelectedState] = useState<string[]>([
    "order",
    "cs",
    "profit",
    "influent",
  ]);

  const earthBgHeight = {
    height: window.innerWidth,
  };

  const clickSelectButton = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (e.currentTarget.value === "order") {
      setSelectedState(["order", "cs", "profit", "influent"]);
    }
    if (e.currentTarget.value === "cs") {
      setSelectedState(["cs", "profit", "influent", "order"]);
    }
    if (e.currentTarget.value === "profit") {
      setSelectedState(["profit", "influent", "order", "cs"]);
    }
    if (e.currentTarget.value === "influent") {
      setSelectedState(["influent", "order", "cs", "profit"]);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setSelectedState((prev) => [prev[1], prev[2], prev[3], prev[0]]);
    }, 4000);

    return () => clearInterval(interval);
  }, [selectedState]);

  return (
    <div className="ladingEarthMobile">
      <h4 className="seoMainText oneLine mt-4">
        <span className="textGreen">관리 </span>와
        <span className="textGreen"> 분석</span>으로
        <span className="textYellow"> 스마트 경영</span>
      </h4>
      {windowIndex[selectedState[0] as EarthState]}
      <div style={earthBgHeight} className="relative widthFull">
        <img className="mobileEarthBg" src={earthBg} />
        <img
          className={`${
            selectedState[0] === "order" ? "yellowFirstPin" : "firstPin"
          }`}
          src={selectedState[0] === "order" ? yellowPin : blackPin}
          style={{ transform: `scale(${(window.innerWidth * 1.5) / 1920}` }}
        />
        <img
          className={`${
            selectedState[0] === "cs" ? "yellowSecondPin" : "secondPin"
          }`}
          src={selectedState[0] === "cs" ? yellowPin : blackPin}
          style={{ transform: `scale(${(window.innerWidth * 1.5) / 1920}` }}
        />
        <img
          className={`${
            selectedState[0] === "profit" ? "yellowThirdPin" : "thirdPin"
          }`}
          src={selectedState[0] === "profit" ? yellowPin : blackPin}
          style={{ transform: `scale(${(window.innerWidth * 1.5) / 1920}` }}
        />
        <img
          className={`${
            selectedState[0] === "influent" ? "yellowFourthPin" : "fourthPin"
          }`}
          src={selectedState[0] === "influent" ? yellowPin : blackPin}
          style={{ transform: `scale(${(window.innerWidth * 1.5) / 1920}` }}
        />
        <div className="mobileEarthButtonBox">
          <button
            onClick={clickSelectButton}
            value="order"
            className={`madebutton oneLine ${
              selectedState[0] === "order" && "selectedMobileEarthButton"
            }`}
          >
            주문 관리
          </button>
          <button
            onClick={clickSelectButton}
            value="cs"
            className={`madebutton oneLine ${
              selectedState[0] === "cs" && "selectedMobileEarthButton"
            }`}
          >
            CS 관리
          </button>
          <button
            onClick={clickSelectButton}
            value="profit"
            className={`madebutton oneLine ${
              selectedState[0] === "profit" && "selectedMobileEarthButton"
            }`}
          >
            순수익 계산
          </button>
          <button
            onClick={clickSelectButton}
            value="influent"
            className={`madebutton oneLine ${
              selectedState[0] === "influent" && "selectedMobileEarthButton"
            }`}
          >
            유입수 분석
          </button>
        </div>
      </div>
    </div>
  );
};

type EarthState = "order" | "cs" | "profit" | "influent";

const windowIndex = {
  order: <EarthMobileOrderWindow />,
  cs: <EarthMobileCsWindow />,
  profit: <EarthMobileProfitWindow />,
  influent: <EarthMobileInfluentWindow />,
};
