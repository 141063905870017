import { Revenue } from "../../../../interface/orderManageInterface";

interface Props {
  inputRevenueValue: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => void;
  revenueValue: Revenue;
  vat: number;
}

export const RevenueVat = ({ inputRevenueValue, revenueValue, vat }: Props) => {
  //관세를 customsDuty
  //부가세를 customsDutyRate
  return (
    <div className="d-flex align-items-center widthFull gap-3 textStart mt-2">
      <div className="d-flex align-items-center widthFull">
        <p className="ordererInfoTitle revenueModalLeft">부가세</p>
        <div className="d-flex align-items-center revenueModalRight gap-5">
          <div className="d-flex align-items-center widthHalf">
            <input
              onChange={inputRevenueValue}
              value={revenueValue.customsDutyRate}
              name="customsDutyRate"
              className="orderInfoModalInput"
            />
            <p className="margin0 pl-1">%</p>
          </div>
          <div className="d-flex align-items-center widthHalf">
            <p className="margin0">{vat} 원</p>
          </div>
        </div>
      </div>
    </div>
  );
};
