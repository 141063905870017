import useFetch from "@hooks/useFetch";
import styles from "../translate.module.css";
import { RightIcon } from "@components/svg/RightIcon";
import { useMemo, useState } from "react";

export const ExchangeRate = () => {
  const [getExchangeRate] = useFetch(
    "exChangeRate",
    "/UserSetting/GetExchange"
  );

  const [exchangeType, setExchangeType] = useState({
    source: "CNY",
    target: "KRW",
  });

  const [exchangeValue, setExchangeValue] = useState({
    source: "1",
    target: "0",
  });

  const rateIndex: RateType = useMemo(() => {
    if (!getExchangeRate) return;
    if (exchangeType.source !== "KRW") {
      return getExchangeRate?.filter(
        (item: RateType) => item.currencyCode === exchangeType.source
      )[0];
    }
    if (exchangeType.target !== "KRW") {
      return getExchangeRate?.filter(
        (item: RateType) => item.currencyCode === exchangeType.target
      )[0];
    }
  }, [getExchangeRate, exchangeType]);

  const selectRateType = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    if (name === "source") {
      if (value !== "KRW") {
        setExchangeType({
          source: value,
          target: "KRW",
        });
      }
      if (value === "KRW") {
        setExchangeType({
          ...exchangeType,
          source: value,
        });
      }
      if (value === "KRW" && exchangeType.target === "KRW") {
        setExchangeType({
          source: value,
          target: "CNY",
        });
      }
    }
    if (name === "target") {
      if (value !== "KRW") {
        setExchangeType({
          source: "KRW",
          target: value,
        });
      }
      if (value === "KRW") {
        setExchangeType({
          ...exchangeType,
          target: value,
        });
      }
      if (value === "KRW" && exchangeType.source === "KRW") {
        setExchangeType({
          target: value,
          source: "CNY",
        });
      }
    }
  };

  const inputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (exchangeType.source !== "KRW") {
      setExchangeValue({
        source: value,
        target: parseFloat(
          (parseFloat(value) * rateIndex.basePrice).toFixed(2)
        ).toLocaleString(),
      });
    }
    if (exchangeType.source === "KRW") {
      setExchangeValue({
        source: value,
        target: parseFloat(
          (parseFloat(value) / rateIndex.basePrice).toFixed(2)
        ).toLocaleString(),
      });
    }
  };

  return (
    <div>
      <p className="weight700 navy mt-3">
        환율<span className="weight500 text12"> (하나은행)</span>
      </p>
      <div className="d-flex gap-3 align-items-center">
        <div className="widthHalf exchange-input-container">
          <select
            value={exchangeType.source}
            name="source"
            onChange={selectRateType}
            className={`allttam-input input-border-2 ${styles["translate-select"]}`}
          >
            {EXCHANGE_RATE_INDEX.map((item) => (
              <option key={item.id} value={item.value}>
                {item.title}
              </option>
            ))}
          </select>
          <input
            name="source"
            value={exchangeValue.source}
            onChange={inputValue}
            className={`allttam-input input-border-2 ${styles["translate-input"]}`}
          />
        </div>
        <RightIcon />
        <div className="widthHalf exchange-input-container">
          <select
            value={exchangeType.target}
            name="target"
            onChange={selectRateType}
            className={`allttam-input input-border-2 ${styles["translate-select"]}`}
          >
            {EXCHANGE_RATE_INDEX.map((item) => (
              <option value={item.value} key={item.id}>
                {item.title}
              </option>
            ))}
          </select>
          <input
            value={exchangeValue.target !== "NaN" ? exchangeValue.target : 0}
            readOnly
            className={`allttam-input input-border-2 ${styles["translate-input"]}`}
          />
        </div>
      </div>
    </div>
  );
};

const EXCHANGE_RATE_INDEX = [
  {
    id: 0,
    title: "한국 (₩)",
    value: "KRW",
  },
  {
    id: 1,
    title: "중국 (￥)",
    value: "CNY",
  },
  {
    id: 2,
    title: "미국 ($)",
    value: "USD",
  },
  {
    id: 3,
    title: "일본 (￥)",
    value: "JPY",
  },
];

interface RateType {
  basePrice: number;
  cashBuyingPrice: number;
  cashSellingPrice: number;
  change: string;
  changePrice: number;
  changeRate: number;
  chartImageUrl: any;
  country: string;
  currencyCode: string;
  currencyName: string;
  currencyUnit: number;
  date: string;
  exchangeCommission: number;
  fcSellingPrice: number | null;
  name: string;
  recurrenceCount: number;
  region: { korName: string; engName: string };
  symbolCode: string;
  tcBuyingPrice: null | number;
  ttBuyingPrice: number;
  ttSellingPrice: number;
  usDollarRate: number;
}
