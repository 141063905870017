export const replaceWord = (
  str: string,
  replaceArray: { before: string; after: string }[]
) => {
  replaceArray.forEach((obj) => {
    const regex = new RegExp(
      obj.before.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"),
      "gi"
    );
    str = str.replace(regex, obj.after);
  });
  return str;
};
