import {
  DetailOptionType,
  OptionGroupType,
  UploadSummary,
} from "@interface/uploadinterface";
import { OptionGroupComponent } from "./OptionGroupComponent";
import { OptionValueSetting } from "./OptionValueSetting";
import { OptionWordSetting } from "./OptionWordSetting";
import { useState } from "react";
import { GreyBigButton } from "@components/Button/allttamButton/GreyBigButton";
import { applyNewOption } from "../../function/applyOptionGroup2";
import axiosInstance from "@utils/axiosInstance";

interface Props {
  item: UploadSummary;
  info:
    | {
        language: any;
        price: any;
      }
    | undefined;
  optionGroups: OptionGroupType[];
  setOptionGroups: React.Dispatch<React.SetStateAction<OptionGroupType[]>>;
  detailOptionList: DetailOptionType[];
  setDetailOptionList: React.Dispatch<React.SetStateAction<DetailOptionType[]>>;
}

export const OptionNameSetting = ({
  item,
  info,
  optionGroups,
  setOptionGroups,
  detailOptionList,
  setDetailOptionList,
}: Props) => {
  const [groupOptionIndex, setGroupOptionIndex] = useState(0);

  const [wordSettingValue, setWordSettingValue] = useState<WordSettingType>({
    isProhibit: false,
    isReplace: false,
  });

  const clickApply = async () => {
    const res = await axiosInstance.post(
      `/Product/ConvertDetailOption?objectId=${item.id}`,
      optionGroups
    );

    setDetailOptionList(res.data.data);
  };

  return (
    <div className="widthHalf">
      <div className="width90 m-auto">
        <OptionWordSetting
          wordSettingValue={wordSettingValue}
          setWordSettingValue={setWordSettingValue}
        />
        <OptionValueSetting
          optionGroups={optionGroups}
          setOptionGroups={setOptionGroups}
          wordSettingValue={wordSettingValue}
          groupOptionIndex={groupOptionIndex}
        />
      </div>
      <OptionGroupComponent
        setGroupOptionIndex={setGroupOptionIndex}
        groupOptionIndex={groupOptionIndex}
        item={item}
        info={info}
        optionGroups={optionGroups}
        setOptionGroups={setOptionGroups}
      />
      <div className="mt-4">
        <GreyBigButton onClick={clickApply}>옵션 적용</GreyBigButton>
      </div>
    </div>
  );
};

export interface WordSettingType {
  isProhibit: boolean;
  isReplace: boolean;
}
