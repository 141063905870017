import { RotateLeftButton } from "../../editMainImg/RotateLeftButton";
import { MoveUpButton } from "../../editMainImg/MoveUpButton";
import { RotateRightButton } from "../../editMainImg/RotateRightButton";
import { MoveLeftButton } from "../../editMainImg/MoveLeftButton";
import { SquareCropButton } from "../../editMainImg/SquareCropButton";
import { MoveRightButton } from "../../editMainImg/MoveRightButton";
import { EnlargeImgButton } from "../../editMainImg/EnlargeImgButton";
import { FlipHorizontalButton } from "../../editMainImg/FilpHorizontalButton";
import { MoveDownButton } from "../../editMainImg/MoveDownButton";
import { FlipVerticalButton } from "../../editMainImg/FilpVerticalButton";
import { ReductionImgButton } from "../../editMainImg/ReductionImgButton";
import { GreyMiddleButton } from "@components/Button/allttamButton/GreyMiddleButton";
import { ImportOhooButton } from "../../editMainImg/ImportOhooButton";
import { ImportToastButton } from "../../editMainImg/ImportToastButton";
import { ReactCropperElement } from "react-cropper";
import { OhooTranslateResult } from "@components/OhooTranslateResult";
import { MadeCropBoxFullButton } from "../../editMainImg/MadeCropBoxFullButton";

interface Props {
  cropperRef: React.RefObject<ReactCropperElement>;
  mouseHover: (string: string) => void;
  mouseLeave: () => void;
  isSquare: boolean;
  handleSquareCrop: () => void;
  clickTranslate: () => Promise<void>;
  handleToastEditor: () => void;
  clickPrevResult: () => Promise<void>;
}

export const EditBox = ({
  cropperRef,
  mouseHover,
  mouseLeave,
  isSquare,
  handleSquareCrop,
  clickTranslate,
  handleToastEditor,
  clickPrevResult,
}: Props) => {
  return (
    <div className="widthFull d-flex justify-content-between">
      <div className="width60 d-flex flex-col gap-3">
        <div className="d-flex gap-2 justify-content-between">
          <RotateLeftButton
            cropperRef={cropperRef}
            mouseHover={mouseHover}
            mouseLeave={mouseLeave}
          />
          <MoveUpButton
            cropperRef={cropperRef}
            mouseHover={mouseHover}
            mouseLeave={mouseLeave}
          />
          <RotateRightButton
            cropperRef={cropperRef}
            mouseHover={mouseHover}
            mouseLeave={mouseLeave}
          />
          <MadeCropBoxFullButton
            cropperRef={cropperRef}
            mouseHover={mouseHover}
            mouseLeave={mouseLeave}
          />
        </div>
        <div className=" d-flex gap-2 justify-content-between">
          <MoveLeftButton
            cropperRef={cropperRef}
            mouseHover={mouseHover}
            mouseLeave={mouseLeave}
          />
          <SquareCropButton
            isSquare={isSquare}
            handleSquareCrop={handleSquareCrop}
            mouseHover={mouseHover}
            mouseLeave={mouseLeave}
          />
          <MoveRightButton
            cropperRef={cropperRef}
            mouseHover={mouseHover}
            mouseLeave={mouseLeave}
          />
          <EnlargeImgButton
            cropperRef={cropperRef}
            mouseHover={mouseHover}
            mouseLeave={mouseLeave}
          />
        </div>
        <div className="d-flex gap-2 justify-content-between">
          <FlipHorizontalButton
            cropperRef={cropperRef}
            mouseHover={mouseHover}
            mouseLeave={mouseLeave}
          />
          <MoveDownButton
            cropperRef={cropperRef}
            mouseHover={mouseHover}
            mouseLeave={mouseLeave}
          />
          <FlipVerticalButton
            cropperRef={cropperRef}
            mouseHover={mouseHover}
            mouseLeave={mouseLeave}
          />
          <ReductionImgButton
            cropperRef={cropperRef}
            mouseHover={mouseHover}
            mouseLeave={mouseLeave}
          />
        </div>
      </div>
      <div className="width40 d-flex flex-col align-items-center gap-3">
        <GreyMiddleButton
          onMouseEnter={() =>
            mouseHover("올땀 스튜디오의 이전 이미지 작업물을 불러오기")
          }
          onMouseLeave={mouseLeave}
          onClick={clickPrevResult}
        >
          이전번역 결과
        </GreyMiddleButton>
        <ImportOhooButton
          clickTranslate={clickTranslate}
          mouseHover={mouseHover}
          mouseLeave={mouseLeave}
        />
        <ImportToastButton
          handleToastEditor={handleToastEditor}
          mouseHover={mouseHover}
          mouseLeave={mouseLeave}
        />
      </div>
    </div>
  );
};
