import ChannelService from "@components/ChannelTalk/ChannelService.js";
import { User } from "@interface/user";
import { useEffect } from "react";

const useChannelTalk = (userInfo: User) => {
  ChannelService.loadScript();
  // useEffect(() => {

  // }, [userInfo]);

  ChannelService.boot({
    pluginKey: "261a0acd-6795-4dbd-8c62-556f5d642311",
    memberId: userInfo?.username,
    profile: {
      name: userInfo?.originalUserName,
      mobileNumber: userInfo?.userTelNo,
      email: userInfo?.userEmail,
    },
  });
};

export default useChannelTalk;
