import { SearchingIcon } from "@components/svg/SeachingIcon";
import questionMark from "@assets/images/helper/questionMark.svg";
import { XIcon } from "@components/Admin/AdminCategoryMapping/component/svg/XIcon";
import { LeftIcon } from "@components/svg/LeftIcon";
import { ComponentType, IndexType } from "@interface/fqa.interface";
import { useState } from "react";
import { RightIcon } from "@components/svg/RightIcon";

interface Props {
  selectComponent: (type: IndexType, id: string) => void;
  isWide: boolean;
  setIsWide: React.Dispatch<React.SetStateAction<boolean>>;
  closeModal: () => void;
}

export const DefaultHeader = ({
  selectComponent,
  isWide,
  setIsWide,
  closeModal,
}: Props) => {
  const [keyword, setKeyword] = useState("");

  const inputKeyword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(e.target.value);
  };

  const submitKeyword = (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    selectComponent("search", keyword);
  };

  const clickWide = () => {
    setIsWide((prev) => !prev);
  };
  return (
    <>
      <div className="d-flex align-items-center justify-content-center helper-header-text">
        <button
          onClick={clickWide}
          className="madebutton absolute width-change-btn"
        >
          {isWide ? (
            <RightIcon color="#fff" />
          ) : (
            <LeftIcon width="30" height="30" color="#fff" />
          )}
        </button>
        <>
          <img src={questionMark} />
          <p className="margin0 inter">도움말</p>
        </>
        <button onClick={closeModal} className="madebutton helper-close-btn">
          <XIcon color="#fff" />
        </button>
      </div>
      <form onSubmit={submitKeyword} className="helper-searching-bar mt-2">
        <input
          className="helper-searching"
          onChange={inputKeyword}
          value={keyword}
        />
        <button className="helper-searching-button madebutton">
          <SearchingIcon width="35" height="35" color="#FFB500" />
        </button>
      </form>
    </>
  );
};
