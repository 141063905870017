import { useTooltip } from "@hooks/useTooltip";

interface Props {
  infoText: string;
  infoNumber: number | string;
  color: string;
  icon?: JSX.Element;
}

export const InfoWhiteBox = ({ infoText, infoNumber, color, icon }: Props) => {
  const [hover, enter, leave] = useTooltip();
  return (
    <div
      onMouseEnter={enter}
      onMouseLeave={leave}
      style={{
        width: "130px",
        height: "114px",
        background: hover ? color : "#fff",
        boxShadow: "0px 5px 5px 0px #0000001A",
        padding: ".6rem",
      }}
      className="white-box d-flex flex-col align-items-end justify-content-between"
    >
      <div
        style={{
          backgroundColor: color,
          padding: "2px",
          borderRadius: "10px",
        }}
      >
        {icon}
      </div>
      <p
        style={{
          color: hover ? "#fff" : color,
        }}
        className="infoColorText margin0"
      >
        {infoNumber}
      </p>
      <p
        style={{
          color: hover ? "#fff" : "#39415c",
        }}
        className="text14 navy weight600 margin0 oneLine"
      >
        {infoText}
      </p>
    </div>
  );
};
