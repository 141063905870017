export const UserInfoAccount = ({ account }: { account: string }) => {
  return (
    <div className="col-12 col-md-6 mt-3">
      <div className="s1-inner d-flex align-items-center justify-content-between">
        <h6>계정</h6>
        <p>{account}</p>
      </div>
    </div>
  );
};
