import { changeTimeFormat } from "@utils/functions/changeTimeFormat";
import useToggle from "@hooks/useToggle";
import { NoticeData } from "@interface/defaultinterface";
import { NoticeDetailModal } from "@components/Admin/modal/NoticeDetailModal";
import { Notice } from "@interface/notice";
import { getKoreanTiemFromUTC } from "@components/AllttamCare/function/getKoreanTimeFromUTC";
import useModal from "@hooks/useModal";
import { NoticeModal } from "@components/Admin/RenewalNotice/components/noticeModal";

interface Props {
  notice: Notice;
}

export const NoticeComponent = ({ notice }: Props) => {
  const { openModal } = useModal();
  return (
    <div
      className="d-flex align-items-center justify-content-between homeNoticeComponent gap-2 mt-2"
      onClick={() => openModal(<NoticeModal notice={notice} />)}
    >
      <h5 className="h5 text-ellipsis width80 margin0">{notice?.title}</h5>
      <p className="margin0 text12">
        {getKoreanTiemFromUTC(notice?.createdAt)}
      </p>
    </div>
  );
};
