import { ReactCropperElement } from "react-cropper";
import { useTooltip } from "@hooks/useTooltip";
import { UploadTooltip } from "../../../Tooltip/UploadTooltip";
import { ReverseTopDownIcon } from "@components/svg/ReverseTopDownIcon";

interface Props {
  cropperRef: React.RefObject<ReactCropperElement>;
  mouseHover: (string: string) => void;
  mouseLeave: () => void;
}

export const FlipVerticalButton = ({
  cropperRef,
  mouseHover,
  mouseLeave,
}: Props) => {
  const handleFlipV = (): void => {
    cropperRef.current?.cropper?.scaleY(
      -cropperRef.current.cropper.getData().scaleY
    );
  };
  return (
    <button
      onClick={handleFlipV}
      onMouseLeave={mouseLeave}
      onMouseEnter={() => mouseHover("상하 반전")}
      className="madebutton thumbIcon tooltipRel"
    >
      <ReverseTopDownIcon />
    </button>
  );
};
