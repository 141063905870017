import { useEffect, useState } from "react";
import useFetch from "@hooks/useFetch";
import { OrderInfoSetButtonBox } from "./component/OrderInfoSetButtonBox";
import { OrderInfoTable } from "./component/OrderInfoTable";
import { OrderInfoMemoRequest } from "./component/OrderInfoMemoRequest";
import { OrderInfoDeliveryInfo } from "./component/OrderInfoDeliveryInfo";
import { ModalBg } from "@components/ModalLayout/ModalBg";
import { ModalInnerTop } from "@components/ModalLayout/ModalInnerTop";

interface Props {
  idx: number;
  closeModal: () => void;
}

export const OrderInfoModal = ({ idx, closeModal }: Props) => {
  const [deliveryInfo, setDeliveryInfo] = useState<DeliveryInfo>(DEFAULTINFO);
  const [isCheckPcc, setIsCheckPcc] = useState(false);
  const [orderBill] = useFetch(
    `productBill${idx}`,
    `/OrderManagement/GetOrderBill?idx=${idx}`
  );

  useEffect(() => {
    if (orderBill) {
      setDeliveryInfo(orderBill);
    }
  }, [orderBill]);

  const inputDeliveryInfo = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setDeliveryInfo({ ...deliveryInfo!, [name]: value });
  };

  return (
    <ModalBg onClick={() => {}}>
      <ModalInnerTop>
        <>
          <div className="modal-sticky">
            <div className="modal-header">
              <h5 className="modal-title d-flex align-items-center">
                주문정보
              </h5>
              <button
                onClick={closeModal}
                type="button"
                className="btn-close"
              ></button>
            </div>
            <hr className="margin0" />
          </div>
          <div className="modal-body">
            <>
              <div className="widthFull resultTableScroll">
                <OrderInfoTable orderBill={orderBill} />
              </div>
              <OrderInfoMemoRequest
                deliveryInfo={deliveryInfo!}
                inputDeliveryInfo={inputDeliveryInfo}
              />
              <OrderInfoDeliveryInfo
                setIsCheckPcc={setIsCheckPcc}
                deliveryInfo={deliveryInfo!}
                inputDeliveryInfo={inputDeliveryInfo}
              />
              <OrderInfoSetButtonBox
                idx={idx}
                isCheckPcc={isCheckPcc}
                deliveryInfo={deliveryInfo!}
                closeModal={closeModal}
              />
            </>
          </div>
        </>
      </ModalInnerTop>
    </ModalBg>
  );
};

export interface DeliveryInfo {
  collectDate: string;
  deliveryPrice: number;
  idx: number;
  mainimageUrl: string;
  optionName: string;
  orderAmount: number;
  orderMemo: string;
  orderNumber: string;
  orderPrice: number;
  ordererContactTelNo: string;
  ordererName: string;
  pcc: string;
  pccStatus: string;
  productName: string;
  receiverAddress: string;
  receiverContactTelNo: string;
  receiverName: string;
  receiverZipCode: string;
  request: string;
  totalPrice: number;
  unitPrice: number;
}

const DEFAULTINFO = {
  collectDate: "",
  deliveryPrice: 0,
  idx: 0,
  mainimageUrl: "",
  optionName: "",
  orderAmount: 0,
  orderMemo: "",
  orderNumber: "",
  orderPrice: 0,
  ordererContactTelNo: "",
  ordererName: "",
  pcc: "",
  pccStatus: "",
  productName: "",
  receiverAddress: "",
  receiverContactTelNo: "",
  receiverName: "",
  receiverZipCode: "",
  request: "",
  totalPrice: 0,
  unitPrice: 0,
};
