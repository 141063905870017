import { useNavigate } from "react-router-dom";

interface Props {
  closeModal: () => void;
}

export const MobileMenu = ({ closeModal }: Props) => {
  const navigate = useNavigate();

  const clickNavigate = (path: string) => {
    navigate(path);
    closeModal();
  };
  return (
    <>
      <div className="mobile-menu-bar">
        <button
          className="madebutton"
          onClick={() => clickNavigate("/?scroll=function")}
        >
          기능소개
        </button>
        <button className="madebutton" onClick={() => clickNavigate("/price")}>
          가격정책
        </button>
        <button
          className="madebutton"
          onClick={() => clickNavigate("/?scroll=community")}
        >
          커뮤니티
        </button>
      </div>
      <div className="menuoutside" onClick={closeModal}></div>
    </>
  );
};
