interface Props {
  stateValue: FilteringType;
  setFunction: React.Dispatch<React.SetStateAction<FilteringType>>;
}

export const FilteringSearchValue = ({ stateValue, setFunction }: Props) => {
  const inputSearchValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFunction({
      ...stateValue,
      [name]: value,
    });
  };

  return (
    <td className="width23 px-3 ">
      <div className="d-flex align-items-center gap-2 mt-2">
        <input
          onChange={inputSearchValue}
          className="allttam-input"
          value={stateValue.start}
          name="start"
        />
        <p className="margin0 weight700">~</p>
        <input
          onChange={inputSearchValue}
          className="allttam-input"
          value={stateValue.end}
          name="end"
        />
      </div>
    </td>
  );
};

interface FilteringType {
  start: number;
  end: number;
}
