import graphImg from "@assets/images/graph.png";
import { RightArrowIcon } from "@components/svg/RightArrowIcon";
import useFetch from "@hooks/useFetch";
import { usePost } from "@hooks/usePost";
import { ResultItem } from "@interface/orderManageInterface";
import { changePeriodTimeFormat } from "@utils/functions/changePeriodTimeFormat";
import { checkLoginFromAccountList } from "@utils/functions/checkLoginFromAccountList";
import { TooltipItem } from "chart.js";
import { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";

interface GraphData {
  labels: string[];
  datasets: {
    data: number[];
    label: string;
    tension: number;
    borderColor: string;
    pointHoverRadius: number;
  }[];
}

export const HomeSalesGraph = () => {
  const [date, setDate] = useState<StringDate>({
    startDate: "",
    endDate: "",
  });

  const [dateValue, setDateValue] = useState<Period>("aWeek");

  const [data, setData] = useState<GraphData>({
    labels: [],
    datasets: [
      {
        data: [],
        label: "My First dataset",
        tension: 0.4,
        borderColor: "#00b8ba",
        pointHoverRadius: 10,
      },
    ],
  });
  const manageOrderResult = usePost("/OrderManagement/SearchOrder", {
    startDate: date.startDate,
    endDate: date.endDate,
    row: 10000,
    page: 1,
  });

  useEffect(() => {
    manageOrderResult.mutate();
  }, [date]);

  useEffect(() => {
    const { startDate, endDate } = changePeriodTimeFormat(dateValue);
    setDate({ startDate: startDate, endDate: endDate });
  }, [dateValue]);

  useEffect(() => {
    if (!manageOrderResult?.data) {
      return;
    }
    const label = getLabel(dateValue, {
      startDate: date.startDate,
      endDate: date.endDate,
    });
    let newLabel: string[] = [];
    let sort: ResultItem[][] = [];
    if (manageOrderResult?.data) {
      if (dateValue === "aWeek" || dateValue === "aMonth") {
        label?.forEach((item) => {
          sort.push(
            manageOrderResult.data.data?.filter(
              (day: ResultItem) => day.orderDate.split(" ")[0] === item
            )
          );
        });
        (label as string[]).forEach((item) => {
          const day = item.split("-");
          newLabel.push(`${day[1]}-${day[2]}`);
        });
      } else {
        (label as { start: string; end: string }[])?.forEach((item) =>
          sort.push(
            manageOrderResult.data.data?.filter((day: ResultItem) =>
              isDateInRange(day.orderDate, item.start, item.end)
            )
          )
        );
        if (dateValue === "threeMonth") {
          (label as { start: string; end: string }[]).forEach((item, idx) =>
            newLabel.push(`${idx + 1}주`)
          );
        } else {
          (label as { start: string; end: string }[]).forEach((item, idx) => {
            const month = item.start.split("-")[1];
            newLabel.push(`${month} 월`);
          });
        }
      }
    }
    const data = sort?.map((item) => {
      if (item?.length === 0) {
        return 0;
      } else {
        return item?.reduce((total, item) => total + item.payment, 0) / 1000;
      }
    });
    setData({
      labels: newLabel,
      datasets: [
        {
          data: data,
          label: "My First dataset",
          tension: 0.4,
          borderColor: "#00b8ba",
          pointHoverRadius: 10,
        },
      ],
    });
  }, [manageOrderResult?.data]);

  return (
    <div className="col-md-6 col-12">
      <div className="heading-two heading-three heading-four-relative home-middle-row relative">
        <div className="heading-two-inner mb-3">
          {(manageOrderResult.data?.length === 0 ||
            !manageOrderResult.data?.data) && (
            <div className="prepareOpacity">
              <p>데이터가 없습니다.</p>
            </div>
          )}
          <div className="widthFull d-flex align-items-center gap-3 justify-content-end">
            <button
              className="a-btn b-btn c-btn"
              onClick={() => setDateValue("aWeek")}
            >
              1주일
            </button>
            <button
              className="a-btn b-btn c-btn"
              onClick={() => setDateValue("aMonth")}
            >
              1개월
            </button>
            <button
              className="a-btn b-btn c-btn"
              onClick={() => setDateValue("threeMonth")}
            >
              3개월
            </button>
            <button
              className="a-btn b-btn c-btn"
              onClick={() => setDateValue("sixMonth")}
            >
              6개월
            </button>
          </div>
        </div>
        <p className="textEnd graph-label">단위 : 1,000원</p>

        <Line
          height={100}
          data={data}
          options={{
            scales: {
              y: {
                ticks: {
                  callback: (tickValue: string | number): string => {
                    if (Number.isInteger(Number(tickValue))) {
                      return tickValue.toLocaleString("KR");
                    }
                    return "";
                  },
                },
              },
              x: {
                grid: {
                  display: false,
                },
              },
            },
            plugins: {
              tooltip: {
                backgroundColor: "rgba(0, 0, 0, 0.8)",
                callbacks: {
                  label: (tooltipItem: TooltipItem<"line">): string => {
                    const label = tooltipItem.label;
                    const value = tooltipItem.parsed?.y ?? tooltipItem.label;
                    return `${label}: ${value}`;
                  },
                },
              },
            },
          }}
        />
      </div>
    </div>
  );
};

type Period = "aWeek" | "aMonth" | "threeMonth" | "sixMonth";

type StringDate = {
  startDate: string;
  endDate: string;
};

function getLabel(period: Period, { startDate, endDate }: StringDate) {
  if (period === "aWeek" || period === "aMonth") {
    return getDatesInRage(startDate, endDate);
  }
  if (period === "threeMonth") {
    return getWeeksInRange(startDate, endDate);
  }
  if (period === "sixMonth") {
    return getMonthsInRange(startDate, endDate);
  }
}

function getDatesInRage(startDateStr: string, endDateStr: string) {
  const startDate = new Date(startDateStr);
  const endDate = new Date(endDateStr);
  const dates = [];

  for (
    let date = startDate;
    date <= endDate;
    date.setDate(date.getDate() + 1)
  ) {
    const formattedDate = formatDate(date);
    dates.push(formattedDate);
  }
  return dates;
}

function getDaysInMonth(year: number, month: number) {
  return new Date(year, month, 0).getDate();
}

function formatDate(date: Date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

function getWeeksInRange(startDateStr: string, endDateStr: string) {
  const startDate = new Date(startDateStr);
  const endDate = new Date(endDateStr);
  const weeks = [];

  // 시작 날짜를 해당 주의 첫 번째 날짜로 조정
  startDate.setDate(startDate.getDate() - startDate.getDay());

  // 주 단위로 반복하여 주의 시작 날짜와 종료 날짜를 배열에 추가
  while (startDate <= endDate) {
    const weekStart = formatDate(startDate);
    const weekEnd = formatDate(
      new Date(startDate.getTime() + 6 * 24 * 60 * 60 * 1000)
    );
    weeks.push({ start: weekStart, end: weekEnd });
    startDate.setDate(startDate.getDate() + 7);
  }

  return weeks;
}

function getMonthsInRange(startDateStr: string, endDateStr: string) {
  const startDate = new Date(startDateStr);
  const endDate = new Date(endDateStr);
  const months = [];

  // 시작 날짜를 해당 월의 첫 번째 날짜로 조정
  startDate.setDate(1);

  // 월 단위로 반복하여 월의 시작 날짜와 종료 날짜를 배열에 추가
  while (startDate <= endDate) {
    const year = startDate.getFullYear();
    const month = String(startDate.getMonth() + 1).padStart(2, "0");
    const monthStart = `${year}-${month}-01`;
    const monthEnd = `${year}-${month}-${getDaysInMonth(
      year,
      startDate.getMonth() + 1
    )}`;
    months.push({ start: monthStart, end: monthEnd });
    startDate.setMonth(startDate.getMonth() + 1);
  }

  return months;
}

function isDateInRange(
  dateStr: string,
  startDateStr: string,
  endDateStr: string
) {
  const date = new Date(dateStr);
  const startDate = new Date(startDateStr);
  const endDate = new Date(endDateStr);

  return date >= startDate && date <= endDate;
}
