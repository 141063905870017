export const AccountIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <path
        d="M5.848 13.55a26.922 26.922 0 0 1 4.326 0 17.639 17.639 0 0 1 2.336.294c1.67.338 2.76.89 3.227 1.779a2.463 2.463 0 0 1 0 2.215c-.467.89-1.514 1.477-3.244 1.779a16.781 16.781 0 0 1-2.336.3A19.92 19.92 0 0 1 7.968 20H6.644a6.311 6.311 0 0 0-.8-.053 16.006 16.006 0 0 1-2.344-.294c-1.67-.32-2.76-.89-3.227-1.779A2.44 2.44 0 0 1 0 16.753a2.413 2.413 0 0 1 .268-1.13c.459-.89 1.549-1.468 3.236-1.779a17.067 17.067 0 0 1 2.344-.294zM8 0a5.327 5.327 0 0 1 5.251 5.4A5.327 5.327 0 0 1 8 10.8a5.327 5.327 0 0 1-5.249-5.4A5.327 5.327 0 0 1 8 0z"
        transform="translate(7 5)"
        stroke="#fff"
        strokeWidth={2}
        fill="none"
      />
      <path data-name="사각형 3573" fill="none" d="M0 0h30v30H0z" />
    </svg>
  );
};
