interface Props {
  setAccountValue: React.Dispatch<React.SetStateAction<string>>;
  accountValue: string;
  accountPrice: number;
}

export const PlanAccount = ({
  accountValue,
  setAccountValue,
  accountPrice,
}: Props) => {
  const selectAccount = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setAccountValue(e.target.value);
  };

  return (
    <div className="d-flex justify-content-between align-items-center">
      <div className="widthFull d-flex gap-3 align-items-center">
        <p className="weight600 text14 margin0">계정</p>
        <div className="width40">
          <select
            className="allttam-input"
            onChange={selectAccount}
            value={accountValue}
          >
            <option value="1">1개</option>
            <option value="2">2개</option>
            <option value="3">3개</option>
            <option value="4">4개</option>
            <option value="5">5개</option>
            <option value="6">6개</option>
            <option value="7">7개</option>
            <option value="8">8개</option>
            <option value="9">9개</option>
            <option value="10">10개</option>
          </select>
        </div>
      </div>
      {accountPrice === 0 || !accountPrice ? (
        <p className="margin0 weight600 textYellow text14">FREE</p>
      ) : (
        <p className="margin0 weight600 grey oneLine text14">{`\\ ${accountPrice.toLocaleString(
          "KR"
        )}`}</p>
      )}
    </div>
  );
};
