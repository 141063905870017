import dots from "../../../../../assets/images/landing/earth/threeWhiteDots.svg";
import svg9 from "../../../../../assets/images/landing/earth/svg/svg9.svg";
import svg12 from "../../../../../assets/images/landing/earth/svg/svg12.svg";

interface Props {
  style?: any;
}

export const EarthMobileInfluentWindow = ({ style }: Props) => {
  return (
    <div className="landingMobileEarthBoard mt-5" style={style}>
      <div className="d-flex justify-content-end widthFull">
        <img src={dots} className="mb-2" />
      </div>
      <div className="landingMobileEarthBoardInner">
        <div className="d-flex justify-content-center">
          <p className="mobileEarthMainTitle">유입수 분석</p>
        </div>
        <ul className="moblieEarthTextBox">
          <li className="d-flex align-items-start gap-3">
            <img src={svg12} />
            <div>
              <p className="moblieEarthTextBoxMain">유입수 조회</p>
              <p className="moblieEarthTextBoxSub">
                상세페이지의{" "}
                <span className="moblieEarthTextBoxSubBold">고객 유입수</span>를{" "}
                <span className="moblieEarthTextBoxSubBold">조회</span>하는 기능
              </p>
              <p className="moblieEarthTextBoxSub">
                마켓별, 기간별, 그룹별, 메모 등 필터 제공
              </p>
            </div>
          </li>
          <li className="d-flex align-items-start gap-3">
            <img src={svg9} />
            <div>
              <p className="moblieEarthTextBoxMain">유입수 그래프</p>
              <p className="moblieEarthTextBoxSub">
                필터를 기반으로 고객{" "}
                <span className="moblieEarthTextBoxSubBold">
                  유입수 그래프 제공
                </span>
              </p>
            </div>
          </li>
        </ul>
        <p className="moblieEarthTextBoxSub">
          모든 오픈마켓의 유입수를 조회·관리할 수 있습니다.
        </p>
        <p className="moblieEarthTextBoxSub">
          유입수가{" "}
          <span className="moblieEarthTextBoxSubBold">저조한 상품을 확인</span>
          하여 수정·삭제할 수 있습니다.
        </p>
      </div>
    </div>
  );
};
