import ReactDOM from "react-dom/client";

import "bootstrap/dist/css/bootstrap.css";
import "./assets/css/style.css";
import "./assets/css/responsive-style.css";
import "./assets/css/allttam.style.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import { RecoilRoot } from "recoil";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { Router } from "./Router";
import ErrorBoundary from "@components/ErrorBoundary";
import useChannelTalk from "@hooks/useChannelTalk";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

root.render(
  <QueryClientProvider client={queryClient}>
    <RecoilRoot>
      <BrowserRouter>
        <ErrorBoundary>
          <Router />
        </ErrorBoundary>
      </BrowserRouter>
    </RecoilRoot>
  </QueryClientProvider>
);
