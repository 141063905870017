import { GreenMiddleButton } from "@components/Button/allttamButton/GreenMiddleButton";
import { GreyMiddleButton } from "@components/Button/allttamButton/GreyMiddleButton";
import ModalContent from "@components/shared/modal/ModalContent";
import useFetch from "@hooks/useFetch";
import { useInfoWindow } from "@hooks/useInfoWindow";
import useModal from "@hooks/useModal";
import useUser from "@hooks/useUser";
import axiosCenter from "@utils/axiosCenter";
import { AxiosError } from "axios";
import { ChangeEvent, useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { createUserReference, getReference } from "src/apis/referenceApi";

const ReferenceCodeSettingModal = () => {
  const openWindow = useInfoWindow();
  const queryClient = useQueryClient();
  const [isEdit, setIsEdit] = useState(false);
  const userInfo = useUser();

  const { data: referenceCode, isLoading } = useQuery(
    "referenceCode",
    () => getReference(userInfo?.username),
    {}
  );

  const [editReferenceCodeValue, setEditReferenceCodeValue] = useState("");

  const { closeModal } = useModal();

  const inputReferenceCode = (e: ChangeEvent<HTMLInputElement>) => {
    setEditReferenceCodeValue(e.target.value);
  };

  useEffect(() => {
    if (!referenceCode) return;
    setEditReferenceCodeValue(referenceCode.referenceCode);
  }, [referenceCode]);

  const submitForm = async () => {
    try {
      const userCode = await createUserReference({
        referenceCode: editReferenceCodeValue,
        userId: userInfo.username,
        username: userInfo.originalUserName,
        userContact: userInfo.userTelNo,
        userEmail: userInfo.userEmail,
      });
      queryClient.invalidateQueries("referenceCode");
      openWindow("추천인 코드가 등록되었습니다.");
      setIsEdit(false);
    } catch (err: any) {
      if (err.response.data.message) {
        return openWindow(err.response.data.message);
      }
    }
  };

  return (
    <ModalContent width={400}>
      <ModalContent.ModalHead>추천인 코드 입력</ModalContent.ModalHead>
      <ModalContent.ModalBody>
        <p className="weight500 grey text14 margin0 mb-2">추천인코드</p>
        {referenceCode === "" || isEdit ? (
          <div className="d-flex gap-3">
            <input
              className="allttam-input input-border-2 mb-4"
              value={editReferenceCodeValue}
              onChange={inputReferenceCode}
            />
            <GreenMiddleButton onClick={submitForm}>저장</GreenMiddleButton>
          </div>
        ) : (
          <div className="d-flex justify-content-between items-center my-3">
            <p className="weight700 text20">{referenceCode?.referenceCode}</p>
            <GreenMiddleButton onClick={() => setIsEdit(true)}>
              수정
            </GreenMiddleButton>
          </div>
        )}

        <div className="d-flex gap-3 justify-content-center">
          <GreyMiddleButton onClick={closeModal}>닫기</GreyMiddleButton>
        </div>
      </ModalContent.ModalBody>
    </ModalContent>
  );
};

export default ReferenceCodeSettingModal;
