import { useState } from "react";
import kakaoLogo from "@assets/images/landing/community/kakao_logo.svg";
import navercafe from "@assets/images/landing/community/navercafe_logo.svg";
import youtubeLogo from "@assets/images/landing/community/yutub_logo.svg";
import kakaoHover from "@assets/images/landing/community/kakaoHover.svg";
import navercafeHover from "@assets/images/landing/community/navercafeHover.svg";
import youtubeHover from "@assets/images/landing/community/youtubeHover.svg";

interface Props {
  communityRef: any;
}

export const LandingCoummunity = ({ communityRef }: Props) => {
  const [naver, setNaver] = useState(navercafe);
  const [youtube, setYoutube] = useState(youtubeLogo);
  const [kakao, setKakao] = useState(kakaoLogo);

  const onMouseEnterNaver = () => {
    setNaver(navercafeHover);
  };
  const onMouseLeaveNaver = () => {
    setNaver(navercafe);
  };

  const onMouseEnterYoutube = () => {
    setYoutube(youtubeHover);
  };
  const onMouseLeaveYoutube = () => {
    setYoutube(youtubeLogo);
  };
  const onMouseEnterKakao = () => {
    setKakao(kakaoHover);
  };
  const onMouseLeaveKakao = () => {
    setKakao(kakaoLogo);
  };

  return (
    <div
      ref={communityRef}
      className="landingEarth d-flex flex-column justify-content-start align-items-center"
    >
      <div className="communityWrapper">
        <p className="landingMainSubText marin0 oneLine mt-5">
          소통하는 글로벌셀러의{" "}
          <span className="landingTextYellow">즐겨찾기</span>
        </p>
        <h4 className="mainText oneLine margin0 mt-2">올땀 커뮤니티 채널</h4>
        <div className="landingMainSmallText oneLine mt-5">
          구매대행 트렌드ㆍ이슈를 <span className="landingTextGreen">공유</span>
          하는 <span className="landingTextYellow">인사이트 </span>커뮤니티
          <div className="stretchbarMain" />
        </div>
      </div>
      <div className="communityLogoWrapper mt-5">
        <a
          href="https://www.youtube.com/@allttam"
          target="_blank"
          className="madebutton logoButton"
          onMouseEnter={onMouseEnterYoutube}
          onMouseLeave={onMouseLeaveYoutube}
        >
          <img src={youtube} className="communityLogo" />
        </a>
        <a
          href="https://cafe.naver.com/mandeunydle"
          target="_blank"
          className="madebutton logoButton"
          onMouseEnter={onMouseEnterNaver}
          onMouseLeave={onMouseLeaveNaver}
        >
          <img src={naver} className="communityLogo" />
        </a>
        <a
          href="https://open.kakao.com/o/gS1VNoOe"
          target="_blank"
          className="madebutton logoButton"
          onMouseEnter={onMouseEnterKakao}
          onMouseLeave={onMouseLeaveKakao}
        >
          <img src={kakao} className="communityLogoKako" />
        </a>
      </div>
    </div>
  );
};
