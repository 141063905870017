import { ReactCropperElement } from "react-cropper";
import { useTooltip } from "../../../../../hooks/useTooltip";
import square from "@assets/images/imgToolSquare.svg";
import squareSelect from "@assets/images/squareSelect.svg";
import { UploadTooltip } from "../../../Tooltip/UploadTooltip";

interface Props {
  handleSquareCrop: () => void;
  isSquare: boolean;
  mouseHover: (string: string) => void;
  mouseLeave: () => void;
}

export const SquareCropButton = ({
  handleSquareCrop,
  isSquare,
  mouseHover,
  mouseLeave,
}: Props) => {
  return (
    <button
      className={`tooltipRel madebutton thumbIcon ${isSquare && "squareMode"}`}
      onClick={handleSquareCrop}
      onMouseLeave={mouseLeave}
      onMouseEnter={() => mouseHover("정사각형 윤곽 고정하기")}
    >
      <img src={isSquare ? squareSelect : square} />
    </button>
  );
};
