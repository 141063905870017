import { useCallback, useState } from "react";

type StateType = boolean | null;

const useToggle = (initialState: StateType = false) => {
  const [state, setState] = useState<boolean>(
    initialState ? initialState : false
  );

  const handleToggle = useCallback((): void => {
    setState((prev) => !prev);
  }, [setState]);

  return { state, handleToggle };
};

export default useToggle;
