export const copyFunction = async (text: string) => {
  try {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text);
      return true;
    } else return;
  } catch {
    return;
  }
};
