import { NoticeSearchValues, NoticeValues } from "@interface/notice";
import axiosCenter from "@utils/axiosCenter";

const notice = "/allttam/notice";
const category = "/allttam/notice/category";

export const noticeApi = {
  getHome: async () => {
    const [pinned, top5] = await Promise.all([
      axiosCenter.get(`${notice}?isPinned=true`),
      axiosCenter.get(`${notice}?limit=5&page=1`),
    ]);

    return {
      pinned: pinned.data.notice,
      top5: top5.data.notice,
    };
  },

  createNotice: async (noticeValues: NoticeValues) => {
    const res = await axiosCenter.post(notice, noticeValues);

    return res.data;
  },

  getNoticeCategory: async () => {
    const res = await axiosCenter.get(category);

    return res.data;
  },

  createNoticeCategory: async (title: string) => {
    const res = await axiosCenter.post(category, {
      title,
    });

    return res.data;
  },

  deleteNoticeCagtegory: async (id: string) => {
    const res = await axiosCenter.delete(`${category}/${id}`);

    return res.data;
  },

  getNotice: async (searchValues: NoticeSearchValues) => {
    const res = await axiosCenter.get(notice, {
      params: searchValues,
    });
    return res.data;
  },

  deleteNotice: async (id: string) => {
    const res = await axiosCenter.delete(`${notice}/${id}`);

    return res.data;
  },

  updateNotice: async (id: string, noticeValues: NoticeValues) => {
    const res = await axiosCenter.patch(`${notice}/${id}`, noticeValues);

    return res.data;
  },
};
