import { GlobalTooltip } from "@components/GlobalTooltip/GlobalTooltip";
import { useTooltip } from "@hooks/useTooltip";

interface Props {
  setCheckedValue: React.Dispatch<
    React.SetStateAction<{
      isFillNumber: boolean;
      isDuplicateRemove: boolean;
      isProhibit: boolean;
      isReplace: boolean;
    }>
  >;
  checkedValue: {
    isFillNumber: boolean;
    isDuplicateRemove: boolean;
    isProhibit: boolean;
    isReplace: boolean;
  };
}

export const WordOptionSelect = ({ setCheckedValue, checkedValue }: Props) => {
  const [isProhibitTooltip, enterProhibit, leaveProhibit] = useTooltip();
  const [isReplaceTooltip, enterReplace, leaveReplace] = useTooltip();
  const [isNumberTooltip, enterNumber, leaveNumber] = useTooltip();
  const [isDuplicateTooltip, enterDuplicate, leaveDuplicate] = useTooltip();

  const clickCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = e.target;

    setCheckedValue({
      ...checkedValue,
      [name]: checked,
    });
  };
  return (
    <>
      <div className="d-flex align-items-center gap-3 attributes1">
        <div className="form-group relative">
          <GlobalTooltip
            isVisible={isNumberTooltip as boolean}
            style={{
              zIndex: "5",
            }}
          >
            상품명 맨뒤에 일련의 숫자를 추가합니다.
          </GlobalTooltip>
          <input
            onChange={clickCheck}
            type="checkbox"
            id="word-number"
            name="isFillNumber"
            checked={checkedValue.isFillNumber}
          />
          <label htmlFor="word-number"></label>
        </div>
        <p
          className=""
          onMouseEnter={enterNumber as () => void}
          onMouseLeave={leaveNumber as () => void}
        >
          숫자+
        </p>
      </div>
      <div className="d-flex align-items-center gap-3 attributes1">
        <div className="form-group relative">
          <GlobalTooltip
            isVisible={isDuplicateTooltip as boolean}
            style={{
              zIndex: "5",
            }}
          >
            상품명 내 중복되는 단어들을 제거합니다.
          </GlobalTooltip>
          <input
            onChange={clickCheck}
            checked={checkedValue.isDuplicateRemove}
            type="checkbox"
            name="isDuplicateRemove"
            id="word-duplicate"
          />
          <label htmlFor="word-duplicate"></label>
        </div>
        <p
          className=""
          onMouseEnter={enterDuplicate as () => void}
          onMouseLeave={leaveDuplicate as () => void}
        >
          중복단어 -
        </p>
      </div>
      <div className="d-flex align-items-center gap-3 attributes1">
        <div className="form-group relative">
          <GlobalTooltip
            isVisible={isProhibitTooltip}
            style={{
              right: "-100px",
              zIndex: "5",
            }}
          >
            상품명 내 제거할 단어를 금지단어로 설정 및 적용합니다.
          </GlobalTooltip>
          <input
            onChange={clickCheck}
            checked={checkedValue.isProhibit}
            name="isProhibit"
            type="checkbox"
            id="word-prohibit"
          />
          <label htmlFor="word-prohibit"></label>
        </div>
        <p
          className=""
          onMouseEnter={enterProhibit}
          onMouseLeave={leaveProhibit}
        >
          금지단어 -
        </p>
      </div>
      <div className="d-flex align-items-center gap-3 attributes1">
        <div className="form-group relative">
          <GlobalTooltip
            isVisible={isReplaceTooltip}
            style={{
              right: "-100px",
              zIndex: "5",
            }}
          >
            상품명 내 치환할 단어를 치환단어로 설정 및 적용합니다.
          </GlobalTooltip>
          <input
            onChange={clickCheck}
            checked={checkedValue.isReplace}
            name="isReplace"
            type="checkbox"
            id="word-replace"
          />
          <label htmlFor="word-replace"></label>
        </div>
        <p className="" onMouseEnter={enterReplace} onMouseLeave={leaveReplace}>
          치환단어
        </p>
      </div>
    </>
  );
};
