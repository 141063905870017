import earthBg from "@assets/images/landing/earth/Group.png";
import { useEffect, useState } from "react";
import { YellowPin } from "./component/YellowPin";
import { FirstBlackPin } from "./component/FirstBlackPin";
import { SecondBlackPin } from "./component/SecondBlackPin";
import { ThirdBlackPin } from "./component/ThirdBlackPin";
import { EarthMobileOrderWindow } from "./component/EarthMobileOrderWindow";
import { EarthMobileCsWindow } from "./component/EarthMobileCsWindow";
import { EarthMobileProfitWindow } from "./component/EarthMobileProfitWindow";
import { EarthMobileInfluentWindow } from "./component/EarthMobileInfluentWindow";

export const LandingEarth = () => {
  const [selectedState, setSelectedState] = useState<string[]>([
    "order",
    "cs",
    "profit",
    "influent",
  ]);
  const clickSelectButton = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (e.currentTarget.value === "order") {
      setSelectedState(["order", "cs", "profit", "influent"]);
    }
    if (e.currentTarget.value === "cs") {
      setSelectedState(["cs", "profit", "influent", "order"]);
    }
    if (e.currentTarget.value === "profit") {
      setSelectedState(["profit", "influent", "order", "cs"]);
    }
    if (e.currentTarget.value === "influent") {
      setSelectedState(["influent", "order", "cs", "profit"]);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setSelectedState((prev) => [prev[1], prev[2], prev[3], prev[0]]);
    }, 4000);

    return () => clearInterval(interval);
  }, [selectedState]);

  return (
    <div className="landingEarth relative">
      <h4 className="seoMainText oneLine mt-4">
        <span className="textGreen">관리 </span>와
        <span className="textGreen"> 분석</span>으로
        <span className="textYellow"> 스마트 경영</span>
      </h4>
      <img className="earthBg" src={earthBg} />
      <YellowPin selectedState={selectedState} />
      <FirstBlackPin selectedState={selectedState} />
      <SecondBlackPin selectedState={selectedState} />
      <ThirdBlackPin selectedState={selectedState} />
      <div style={WINDOW_INDEX[selectedState[0] as EarthState].style as any}>
        {WINDOW_INDEX[selectedState[0] as EarthState].component}
      </div>
      <div className="earth-button-box">
        <button
          onClick={clickSelectButton}
          value="order"
          className={`madebutton ${
            selectedState[0] === "order" && "seleced-earth-button"
          }`}
        >
          주문 관리
        </button>
        <button
          onClick={clickSelectButton}
          value="cs"
          className={`madebutton ${
            selectedState[0] === "cs" && "seleced-earth-button"
          }`}
        >
          CS 관리
        </button>
        <button
          onClick={clickSelectButton}
          value="profit"
          className={`madebutton ${
            selectedState[0] === "profit" && "seleced-earth-button"
          }`}
        >
          순수익 계산
        </button>
        <button
          onClick={clickSelectButton}
          value="influent"
          className={`madebutton ${
            selectedState[0] === "influent" && "seleced-earth-button"
          }`}
        >
          유입수 분석
        </button>
      </div>
    </div>
  );
};

type EarthState = "order" | "cs" | "profit" | "influent";

const WINDOW_INDEX = {
  order: {
    component: <EarthMobileOrderWindow />,
    style: {
      width: "550px",
      top: "24%",
      left: "33%",
      position: "absolute",
      transition: "all 1s ease",
      transform: `scale(${window.innerWidth / 1920}`,
    },
  },
  cs: {
    component: <EarthMobileCsWindow />,
    style: {
      width: "550px",
      top: "27%",
      left: "63%",
      position: "absolute",
      transition: "all 1s ease",
      transform: `scale(${window.innerWidth / 1920}`,
    },
  },
  profit: {
    component: <EarthMobileProfitWindow />,
    style: {
      width: "550px",
      top: "31%",
      left: "15%",
      position: "absolute",
      transition: "all 1s ease",
      transform: `scale(${window.innerWidth / 1920}`,
    },
  },

  influent: {
    component: <EarthMobileInfluentWindow />,
    style: {
      width: "550px",
      top: "24%",
      left: "33%",
      position: "absolute",
      transition: "all 1s ease",
      transform: `scale(${window.innerWidth / 1920}`,
    },
  },
};
