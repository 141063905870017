import { CollectValue } from "@interface/collectInterface";

interface Props {
  collectValue: CollectValue;
  inputCollectValue: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => void;
}

export const CostcoSearchingMemo = ({
  collectValue,
  inputCollectValue,
}: Props) => {
  return (
    <div className="product-label product-label-1 col-md-6 col-12">
      <label>메모</label>
      <input
        onChange={inputCollectValue}
        value={collectValue.memo}
        type="text"
        name="memo"
        className="form-control"
        placeholder="메모를 입력해주세요"
      />
    </div>
  );
};
