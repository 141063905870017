export const SaleDeleteIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <g data-name="그룹 9820">
        <path
          data-name="패스 10357"
          d="m1020.028 1893.339 12 12"
          transform="translate(-1011.028 -1884.339)"
          stroke="#d62828"
          strokeLinecap="round"
          strokeWidth="3px"
          fill="none"
        />
        <path
          data-name="패스 10358"
          d="m1032.028 1893.339-12 12"
          transform="translate(-1011.028 -1884.339)"
          stroke="#d62828"
          strokeLinecap="round"
          strokeWidth="3px"
          fill="none"
        />
      </g>
      <path
        data-name="사각형 3654"
        fill="none"
        className="svg-stroke2"
        d="M0 0h30v30H0z"
      />
    </svg>
  );
};
