import care from "@assets/images/logo/consultingIcon.svg";
import careHover from "@assets/images/logo/consultingIconHover.svg";
import { useTooltip } from "@hooks/useTooltip";
import { CareContent } from "./component/CareContent";
import { useEffect, useRef, useState } from "react";
import "./allttamcare.css";
import { allttamCare } from "src/atom/atom";
import { useRecoilState } from "recoil";

export const AllttamCare = () => {
  const [isOpen, setIsOpen] = useRecoilState(allttamCare);
  // const [isOpen, setIsOpen] = useState(false);
  const [isHover, enterLogo, leaveLogo] = useTooltip();

  const clickOpen = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <div className="allttam-care">
      <img
        onClick={clickOpen}
        className="pointer"
        src={isHover ? careHover : care}
        onMouseEnter={enterLogo}
        onMouseLeave={leaveLogo}
      />
      {isOpen && <CareContent close={clickOpen} />}
    </div>
  );
};
