import innopay from "../../utils/functions/innopay";
import { madeOrderNumber } from "./madeOrderNumber";

export const payment = async (data: {
  GoodsName: string;
  Amt: string;
  BuyerName: string;
  BuyerTel: string;
  BuyerEmail: string;
  mallReserved: string;
}) => {
  await innopay.goPay({
    PayMethod: "CARD",
    MID: "pgallttamm",
    MerchantKey:
      "Os7fbQ6r/n4DxBHaCQ4B5YkmqsYi4bLHvReANHwSBbz/I5yw538xYgT6FTPxOF5d2l3PCFQHAPtzhM6z2dhNMw==",
    GoodsName: data.GoodsName,
    Amt: data.Amt,
    BuyerName: data.BuyerName,
    BuyerTel: data.BuyerTel,
    BuyerEmail: data.BuyerEmail,
    ResultYN: "Y",
    Moid: data.mallReserved,
    ReturnURL: "https://allttam.com/payment/result",
    MallReserved: data.mallReserved.toString(),
  });
};
