import { Spinner } from "@components/Spinner/Spinner";
import { PagingButton } from "../PagingButton/PagingButton";
import styles from "./MultiSelectedCollect.module.css";

interface Props {
  resetSelect: () => void;
  clickCollectMulti: () => Promise<void>;
  length: number;
  currentPage: number;
  totalPages: number;
  clickPage: (e: React.MouseEvent<HTMLButtonElement>) => Promise<void>;
  clickNextPage: () => Promise<void>;
  clickPrevPage: () => Promise<void>;
  isTaobaoTimeout?: boolean;
}

export const MultiSelectedCollect = ({
  resetSelect,
  clickCollectMulti,
  length,
  currentPage,
  totalPages,
  clickPage,
  clickNextPage,
  clickPrevPage,
  isTaobaoTimeout,
}: Props) => {
  return (
    <div
      className={`newCollectSelect selected-num collectSticky ${styles["clip"]}`}
    >
      <div className="">
        <div>
          <h4>선택된 상품 수 {length}</h4>
        </div>
      </div>
      <PagingButton
        disabled={isTaobaoTimeout}
        currentPage={currentPage}
        totalPages={totalPages}
        onClick={clickPage}
        clickNextPage={clickNextPage}
        clickPrevPage={clickPrevPage}
      />
      <div className="">
        <div className="search-btn d-flex justify-content-end gap-3 align-items-center">
          <button
            onClick={resetSelect}
            className="cancel-btn save-btn reset-btn"
          >
            선택 상품 초기화
          </button>
          <button onClick={clickCollectMulti} className="cancel-btn save-btn">
            수집
          </button>
        </div>
      </div>
      {isTaobaoTimeout && (
        <div className={`${styles["taobao-timeout-loading"]}`}>
          <Spinner />
        </div>
      )}
    </div>
  );
};
