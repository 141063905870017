interface Props {
  onClick?: () => void;
  children: string | React.ReactNode;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

export const WhiteBigButton = ({
  onClick,
  children,
  onMouseEnter,
  onMouseLeave,
}: Props) => {
  return (
    <button
      onClick={onClick}
      className="allttam-button button-big white-border-button "
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
    >
      {children}
    </button>
  );
};
