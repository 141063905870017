import { RightIcon } from "@components/svg/RightIcon";
import { useState } from "react";
import { OptionWordApplyBtn } from "./OptionWordApplyBtn";
import { WordSettingType } from "./OptionNameSetting";
import { OptionGroupType } from "@interface/uploadinterface";
import { OptionValue } from "./OptionValue";

interface Props {
  wordSettingValue: WordSettingType;
  optionGroups: OptionGroupType[];
  setOptionGroups: React.Dispatch<React.SetStateAction<OptionGroupType[]>>;
  groupOptionIndex: number;
}

export const OptionValueSetting = ({
  wordSettingValue,
  optionGroups,
  setOptionGroups,
  groupOptionIndex,
}: Props) => {
  const [replaceWord, setRePlaceWord] = useState({
    prevWord: "",
    nextWord: "",
  });

  const [optionWordValue, setOptionWordValue] = useState<OptionValue>({
    front: "alp",
    back: "none",
  });

  const inputReplaceWord = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setRePlaceWord({
      ...replaceWord,
      [name]: value,
    });
  };

  const selectOptionWordValue = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setOptionWordValue({
      ...optionWordValue,
      [name]: value,
    });
  };
  return (
    <div className="widthFull mt-3 d-flex align-items-end gap-3 justify-content-between">
      <div className="width40 d-flex flex-col align-items-start gap-2">
        <p className="weight500 text14 grey margin0">옵션값 치환</p>
        <div className="d-flex widthFull align-items-center">
          <input
            value={replaceWord.prevWord}
            onChange={inputReplaceWord}
            name="prevWord"
            className="width45 allttam-input input-border-2"
          />
          <RightIcon />
          <input
            value={replaceWord.nextWord}
            onChange={inputReplaceWord}
            name="nextWord"
            className="width45 allttam-input input-border-2"
          />
        </div>
      </div>
      <SelectOptionWord
        optionWordValue={optionWordValue}
        title="옵션명 앞글자"
        name="front"
        onChange={selectOptionWordValue}
      />
      <SelectOptionWord
        optionWordValue={optionWordValue}
        title="옵션명 뒷글자"
        name="back"
        onChange={selectOptionWordValue}
      />
      <OptionWordApplyBtn
        groupOptionIndex={groupOptionIndex}
        optionWordValue={optionWordValue}
        optionGroups={optionGroups}
        setOptionGroups={setOptionGroups}
        wordSettingValue={wordSettingValue}
        replaceWord={replaceWord}
      />
    </div>
  );
};

const SelectOptionWord = ({
  onChange,
  name,
  title,
  optionWordValue,
}: SelectProps) => {
  return (
    <div className="width15 d-flex flex-col align-items-start gap-2">
      <p className="weight500 text14 grey margin0">{title}</p>
      <select
        onChange={onChange}
        name={name}
        value={optionWordValue[name]}
        className="allttam-input input-border-2"
      >
        <option value="none">없음</option>
        <option value="alp">알파벳+</option>
        <option value="num">숫자+</option>
      </select>
    </div>
  );
};

interface SelectProps {
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  name: "front" | "back";
  title: string;
  optionWordValue: OptionValue;
}

type OptionValueType = "none" | "num" | "alp";

export interface OptionValue {
  front: OptionValueType;
  back: OptionValueType;
}
