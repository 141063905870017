import { ReactCropperElement } from "react-cropper";
import rightIcon from "@assets/images/imgToolRight.svg";
import { useTooltip } from "@hooks/useTooltip";
import { UploadTooltip } from "../../../Tooltip/UploadTooltip";

interface Props {
  cropperRef: React.RefObject<ReactCropperElement>;
  mouseHover: (string: string) => void;
  mouseLeave: () => void;
}

export const MoveRightButton = ({
  cropperRef,
  mouseHover,
  mouseLeave,
}: Props) => {
  const handleMoveRight = () => {
    cropperRef.current?.cropper.move(10, 0);
  };

  return (
    <button
      onClick={handleMoveRight}
      className="madebutton thumbIcon tooltipRel"
      onMouseLeave={mouseLeave}
      onMouseEnter={() => mouseHover("우로 이동")}
    >
      <img src={rightIcon} />
    </button>
  );
};
