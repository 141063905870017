import { useState } from "react";

export const useInput = (initialState: any) => {
  const [state, setState] = useState(initialState);

  const inputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  return [state, inputValue];
};
