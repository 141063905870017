import { DeliveryInfo } from "../OrderInfoModal";

interface Props {
  deliveryInfo: DeliveryInfo;
  inputDeliveryInfo: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const OrderInfoMemoRequest = ({
  deliveryInfo,
  inputDeliveryInfo,
}: Props) => {
  return (
    <>
      <div className="textStart mt-4">
        <h5>발송메세지</h5>
        <input
          className="orderInfoModalInput"
          defaultValue={deliveryInfo?.request}
          readOnly
        />
      </div>
      <div className="textStart mt-4">
        <h5>주문메모</h5>
        <input
          className="orderInfoModalInput"
          value={deliveryInfo?.orderMemo}
          onChange={inputDeliveryInfo}
          name="orderMemo"
        />
      </div>
    </>
  );
};


