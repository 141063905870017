import { useEffect, useState } from "react";
import { OptionListItem } from "./OptionListItem";
import { DetailOptionType, OptionGroupType } from "@interface/uploadinterface";
import { SearchinGreenCircleIcon } from "@components/svg/SearchingGreenCircleIcon";

interface Props {
  setDetailOptionList: React.Dispatch<React.SetStateAction<DetailOptionType[]>>;
  optionGroups: OptionGroupType[];
  detailOptionList: DetailOptionType[];
  setSelectedDetailOption: React.Dispatch<
    React.SetStateAction<DetailOptionType[]>
  >;
  selectedDetailOption: DetailOptionType[];
}

export const OptionListTable = ({
  setDetailOptionList,
  detailOptionList,
  optionGroups,
  setSelectedDetailOption,
  selectedDetailOption,
}: Props) => {
  const [optionTitle, setOptionTitle] = useState<string[]>([]);

  // const clickSetSoldOutButton = () => {
  //   const newDetailList = [...detailOptionList];
  //   const updatedDetailList = newDetailList.map((item) => {
  //     const selected = selectedDetailOption.find(
  //       (select) => select.id === item.id
  //     );
  //     if (selected) {
  //       return {
  //         ...item,
  //         isSoldOut: settingValue.soldOut,
  //       };
  //     }
  //     return item;
  //   });
  //   setDetailOptionList(updatedDetailList);
  // };

  useEffect(() => {
    if (detailOptionList) {
      setOptionTitle(
        detailOptionList[0]?.optionGroupName
          .filter((item) => item.language === "KR")[0]
          .text.split("//")
      );
    }
  }, [detailOptionList]);

  const clickSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setSelectedDetailOption(detailOptionList);
    } else {
      setSelectedDetailOption([]);
    }
  };

  return (
    <table width="100%">
      <thead className="head-one ">
        <tr className="option-list-table-head">
          <th className="width5">
            <div className="form-group">
              <input
                onChange={clickSelectAll}
                checked={
                  detailOptionList.length === selectedDetailOption.length
                }
                type="checkbox"
                id="htmlaxs11x"
              />
              <label htmlFor="htmlaxs11x"></label>
            </div>
          </th>
          <th className="width5">
            <div className="optionImg oneLine  weight600 text14 d-flex align-items-center gap-2">
              <span>이미지</span>
              <SearchinGreenCircleIcon />
            </div>
          </th>
          {optionTitle?.map((item, idx) => (
            <th
              key={idx}
              className={optionTitle?.length > 1 ? "width20" : "width40"}
            >
              <div className="optionImg weight600 text14 oneLine text-center">
                {item}
              </div>
            </th>
          ))}
          <th className="width15">
            <div>옵션가</div>
          </th>
          <th className="width15">
            <div className=" weight600 text14">재고</div>
          </th>
          <th className="width10">
            <div className=" weight600 text14">상태</div>
          </th>
        </tr>
      </thead>
      <tbody className="">
        {detailOptionList?.map((option, idx) => (
          <OptionListItem
            optionGroups={optionGroups}
            optionIndex={idx}
            detailOptionList={detailOptionList}
            setDetailOptionList={setDetailOptionList}
            key={idx}
            option={option}
            setSelectedDetailOption={setSelectedDetailOption}
            selectedDetailOption={selectedDetailOption}
          />
        ))}
      </tbody>
    </table>
  );
};
