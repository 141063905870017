export const ImageTranslateIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <path
        data-name="패스 10502"
        d="M7.471 13.471a2.59 2.59 0 1 1 2.59-2.59 2.59 2.59 0 0 1-2.59 2.59zm0-3.731a1.141 1.141 0 1 0 1.141 1.14 1.141 1.141 0 0 0-1.141-1.14zM2.139 21.99a.725.725 0 0 1-.539-1.209l3.407-3.8a1.906 1.906 0 0 1 2.64-.2L9.111 18a.446.446 0 0 0 .348.1.46.46 0 0 0 .315-.174l3.852-4.977a1.9 1.9 0 0 1 1.507-.742h.007a1.9 1.9 0 0 1 1.5.727l3.694 4.7a.725.725 0 0 1-1.14.9L15.5 13.83a.455.455 0 0 0-.364-.174.5.5 0 0 0-.366.179l-3.855 4.974a1.913 1.913 0 0 1-2.729.3l-1.464-1.204a.46.46 0 0 0-.638.048l-3.4 3.793a.722.722 0 0 1-.545.244z"
        transform="translate(4.26 1.37)"
        fill="#fff"
      />
      <path
        data-name="패스 10501"
        d="M17.189 20.252H3.311A3.066 3.066 0 0 1 .25 17.19V3.313A3.065 3.065 0 0 1 3.311.252h13.878a3.065 3.065 0 0 1 3.061 3.061V17.19a3.066 3.066 0 0 1-3.061 3.062zM3.311 1.7A1.613 1.613 0 0 0 1.7 3.313V17.19a1.614 1.614 0 0 0 1.611 1.61h13.878a1.614 1.614 0 0 0 1.611-1.61V3.313A1.613 1.613 0 0 0 17.189 1.7z"
        transform="translate(4.75 4.748)"
        fill="#fff"
      />
      <path data-name="사각형 3777" fill="none" d="M0 0h30v30H0z" />
    </svg>
  );
};
