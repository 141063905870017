interface Props {
  isReverse?: boolean;
}

export const RightArrowIcon = ({ isReverse }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="51"
      height="51"
      viewBox="0 0 51 51"
      style={
        isReverse
          ? {
              transform: "scaleX(-1)",
            }
          : {}
      }
    >
      <defs>
        <filter
          id="no4pvsjgja"
          x="0"
          y="0"
          width="51"
          height="51"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="3.5" result="blur" />
          <feFlood floodOpacity=".102" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g data-name="그룹 9740">
        <g filter="url(#no4pvsjgja)">
          <circle
            data-name="타원 132"
            cx="15"
            cy="15"
            r="15"
            transform="rotate(90 16.5 24)"
            fill="#fff"
          />
        </g>
        <g data-name="그룹 3">
          <path
            data-name="사각형 74"
            fill="none"
            d="M0 0h24v24H0z"
            transform="translate(13.5 10.5)"
          />
          <path
            data-name="선 2"
            transform="rotate(45 -7.814 38.894)"
            stroke="#a7b0c7"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5px"
            fill="none"
            d="M7.895 0 0 7.895"
          />
          <path
            data-name="패스 1"
            d="M6.815 6.815V0H0"
            transform="rotate(45 -8.068 40.89)"
            stroke="#a7b0c7"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5px"
            fill="none"
          />
        </g>
      </g>
    </svg>
  );
};
