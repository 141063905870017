import { TranslateIcon } from "@components/svg/TrnaslateIcon";
import styles from "./translate.module.css";
import { useRecoilState } from "recoil";
import { translateWindowState } from "src/atom/atom";
import { TranslateAndExchange } from "./TranslateAndExchange";

export const TranslateButton = () => {
  const [isTranslate, setIsTranslate] = useRecoilState(translateWindowState);

  const clickToggleTranslate = () => {
    setIsTranslate((prev) => !prev);
  };

  return (
    <>
      <button
        onClick={clickToggleTranslate}
        className={`d-flex align-items-center justify-content-center madebutton ${styles["translate-button"]}`}
      >
        <TranslateIcon />
      </button>
      {isTranslate && (
        <TranslateAndExchange handleTranslate={clickToggleTranslate} />
      )}
    </>
  );
};
