import backIcon from "@assets/images/payment/backIcon.svg";

interface Props {
  setComponent: React.Dispatch<React.SetStateAction<"sub" | "main">>;
}

export const StarterSubContents = ({ setComponent }: Props) => {
  const getBack = () => {
    setComponent("main");
  };

  return (
    <div className="d-flex gap-3 widthFull align-items-start">
      <button className="madebutton" onClick={getBack}>
        <img src={backIcon} />
      </button>
      <div className="d-flex flex-col gap-2 widthFull">
        <div className="d-flex justify-content-between widthFull">
          <p className="weight500 text20 navy margin0 oneLine">
            # 오픈마켓 등 기본 셋팅
          </p>
          <p className="weight500 text20 navy margin0 oneLine">
            # 오픈마켓 출하지/반품지 셋팅
          </p>
          <p className="weight500 text20 navy margin0 oneLine">
            # 하단이미지 기본 셋팅
          </p>
          <p className="weight500 text20 navy margin0 oneLine">
            # 단일/대량수집 시현
          </p>
        </div>
        <div className="d-flex justify-content-between widthFull">
          <p className="weight500 text20 navy margin0 oneLine">
            # 가격설정 / 태그설정 시현
          </p>
          <p className="weight500 text20 navy margin0 oneLine">
            # 단일/일괄 상품 판매등록 설명
          </p>
          <p className="weight500 text20 navy margin0 oneLine">
            # 카테고리 오토매핑 설명
          </p>
          <p className="weight500 text20 navy margin0 oneLine"># 질의응답</p>
        </div>
        <div className="d-flex justify-content-between widthFull">
          <p className="weight500 text20 none-select margin0 oneLine">
            # 이미지번역
          </p>
          <p className="weight500 text20 none-select margin0 oneLine">
            # 상품명/옵션설정
          </p>
          <p className="weight500 text20 none-select margin0 oneLine">
            # 메모/그룹기능
          </p>
          <p className="weight500 text20 none-select margin0 oneLine">
            # 금지/치환기능
          </p>
          <p className="weight500 text20 none-select margin0 oneLine">
            # 유입수관리
          </p>
          <p className="weight500 text20 none-select margin0 oneLine">
            # 상품관리
          </p>
        </div>
        <div className="d-flex justify-content-between widthFull">
          <p className="weight500 text20 none-select margin0 oneLine">
            # 판매상품수정
          </p>
          <p className="weight500 text20 none-select margin0 oneLine">
            # 주문관리
          </p>
          <p className="weight500 text20 none-select margin0 oneLine">
            # 송장전송
          </p>
          <p className="weight500 text20 none-select margin0 oneLine">
            # 수익계산기
          </p>
          <p className="weight500 text20 none-select margin0 oneLine">
            # 주문엑셀추출
          </p>
          <p className="weight500 text20 none-select margin0 oneLine">
            # CS관리
          </p>
        </div>
        <p className="weight500 text16 none-select margin0">
          # 이미지번역 ~ # CS관리는 올땀케어의 심화 강의에서 수강 부탁드립니다.
        </p>
      </div>
    </div>
  );
};
