import { useTooltip } from "@hooks/useTooltip";
import allttama from "@assets/images/login/a.svg";
import allttamwhite from "@assets/images/login/allttamWhite.svg";
import visible from "@assets/images/login/visible.svg";
import invisible from "@assets/images/login/invisible.svg";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import { rootapi } from "../../../config/config";
import Cookies from "js-cookie";
import { useAlert } from "@hooks/useAlert";
import { AlertOnlyClose } from "@components/Alert/AlertOnlyClose";
import { LeftIcon } from "@components/svg/LeftIcon";
import useToggle from "@hooks/useToggle";
import { CheckEmail } from "./CheckEmail";
import { login } from "@apis/authApis";

interface Props {
  clickChangeToInput: () => void;
}

export const LoginInput = ({ clickChangeToInput }: Props) => {
  const [isTooltipVisible, handleMouseEnter, handleMouseLeave] = useTooltip();
  const { state: isCheckEmail, handleToggle: handleEmailModal } = useToggle();
  const [isPasswordVisible, setisPasswordVisible] = useState(false);
  const { isAlert, alertMessage, handleAlert } = useAlert();
  const navigate = useNavigate();
  const [loginValue, setLoginValue] = useState({
    username: "",
    password: "",
  });

  const inputLoginValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setLoginValue({
      ...loginValue,
      [name]: value,
    });
  };

  const submitLoginForm = async () => {
    try {
      const user = await login(loginValue);
      if (user) {
        if (user.role === "BasicUser") {
          handleEmailModal();
        } else {
          navigate("/home");
        }
      }
    } catch (err) {
      handleAlert((err as any).response.data);
    }
  };

  const clickVisibleButton = () => {
    setisPasswordVisible((prev) => !prev);
  };

  return (
    <div className="widthFull d-flex flex-column gap-3">
      <div className="width Full">
        <h5>아이디</h5>
        <input
          name="username"
          value={loginValue.username}
          type="texts"
          className="loginInput"
          onChange={inputLoginValue}
        />
      </div>
      <div className="widthFull">
        <h5>비밀번호</h5>
        <div className="widthFull relative d-flex align-items-center">
          <input
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                submitLoginForm();
              }
            }}
            name="password"
            type={`${isPasswordVisible ? "text" : "password"}`}
            className="loginInput"
            onChange={inputLoginValue}
            value={loginValue.password}
          />
          <button
            onClick={clickVisibleButton}
            className="madebutton passwordInvisible paddingZero"
          >
            <img src={isPasswordVisible ? invisible : visible} />
          </button>
        </div>
      </div>
      <div className="d-flex justify-content-between">
        <button
          onClick={clickChangeToInput}
          className="login-input-button madebutton d-flex justify-content-center gap-2 align-items-center"
        >
          <LeftIcon />
          <span>뒤로가기</span>
        </button>
        <button
          onClick={submitLoginForm}
          onMouseEnter={handleMouseEnter as () => void}
          onMouseLeave={handleMouseLeave as () => void}
          className="login-input-button loginButton madebutton d-flex justify-content-center gap-2 align-items-center"
        >
          <img src={isTooltipVisible ? allttamwhite : allttama} />
          <span>로그인</span>
        </button>
      </div>
      {isAlert && (
        <AlertOnlyClose
          message={alertMessage}
          closeAlert={handleAlert as () => void}
        />
      )}
      {isCheckEmail && <CheckEmail closeModal={handleEmailModal} />}
    </div>
  );
};
