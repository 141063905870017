import greyChecked from "@assets/images/payment/greyCheck.svg";

interface Props {
  code: string;
  setCode: React.Dispatch<React.SetStateAction<string>>;
}

export const PlanFreeText = ({ code, setCode }: Props) => {
  const inputCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCode(e.target.value);
  };
  return (
    <>
      <div className="d-flex gap-3 align-items-center">
        <p className="weight600 text14 margin0">코인</p>
        <div className="text12 grey margin0 weight500">
          <img src={greyChecked} /> 차감 없음
        </div>
      </div>
      <div className="d-flex gap-3 align-items-center">
        <p className="weight600 text14 margin0 oneLine">추천인 코드</p>
        <input
          value={code}
          onChange={inputCode}
          className="allttam-input"
          placeholder="추천인코드 입력필수"
        />
      </div>
    </>
  );
};
