import { FreeButton } from "@components/Button/LandingFreeButton/FreeButton";
import lectureVideo from "@assets/video/LectureVideo.mp4";
import { useTooltip } from "@hooks/useTooltip";

export const LandingLecture = () => {
  const [isHover, mouseEnter, mouseLeave] = useTooltip();

  return (
    <div className="landingLecture">
      <video
        playsInline
        muted
        autoPlay
        loop
        src={lectureVideo}
        className="lectureVideo"
      />
      <div className="d-flex justify-content-end align-items-center lectureMainContent">
        <div className="mainTextBox">
          <p className="landingMainSubText marin0 oneLine">
            기초에서 실전까지{" "}
            <span className="landingTextYellow">밀착 올케어 클래스</span>
          </p>
          <h4 className="mainText oneLine mt-3">올케어 일타 강의 솔루션</h4>
          <div className="landingMainSmallText oneLine mt-5">
            올땀 <span className="textGreen">200% 활용</span>에서 글로벌셀러{" "}
            <span className="textYellow">꿀팁</span>까지 전수
            <div className="stretchbarMain" />
          </div>
          <FreeButton
            onMouseEnter={mouseEnter as () => void}
            onMouseLeave={mouseLeave as () => void}
          >
            {isHover ? "준비중" : "커리큘럼"}
          </FreeButton>
        </div>
      </div>
    </div>
  );
};
