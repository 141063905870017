import { InquiryCategory } from "@components/Admin/AdminInquiry/modal/InquiryCategoryModal";
import { GreenBigButton } from "@components/Button/allttamButton/GreenBigButton";
import { GreyBigButton } from "@components/Button/allttamButton/GreyBigButton";
import { ModalBg } from "@components/ModalLayout/ModalBg";
import { ModalBody } from "@components/ModalLayout/ModalBody";
import { ModalHead } from "@components/ModalLayout/ModalHead";
import { ModalInnerTop } from "@components/ModalLayout/ModalInnerTop";
import { FroalaEditor } from "@components/shared/Froala/Froala";
import useFetch from "@hooks/useFetch";
import { useInfoWindow } from "@hooks/useInfoWindow";
import axios from "axios";
import { useState } from "react";
import { useQueryClient } from "react-query";
import styles from "../Inquiries.module.css";

interface Props {
  handleWriteModal: () => void;
  userId: string;
  userName: string;
}

export const InquiryWrite = ({ handleWriteModal, userId, userName }: Props) => {
  const queryClient = useQueryClient();
  const openWindow = useInfoWindow();
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState("");
  const [model, setModel] = useState("");

  const [categoryList] = useFetch(
    "inquiryCategory",
    "https://center.allttam.com/api/allttam/qna/category"
  );

  const inputTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };

  const submitInquiry = async () => {
    const res = await axios.post(
      "https://center.allttam.com/api/allttam/qna",
      // "http://localhost:8000/allttam/qna",
      {
        userId: userId,
        name: title,
        content: model,
        categoryId: category,
        userName: userName,
      }
    );

    if (res.status === 201) {
      openWindow("문의사항이 작성되었습니다.");
      handleWriteModal();
      queryClient.invalidateQueries(["inquiries", userId]);
    }
  };

  const selectCategory = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setCategory(e.target.value);
  };

  return (
    <ModalBg onClick={() => {}}>
      <ModalInnerTop>
        <ModalHead clickClose={handleWriteModal}>문의글 작성</ModalHead>
        <ModalBody>
          <div className={`${styles["write-body"]} mt-3 d-flex flex-col gap-3`}>
            <input
              value={title}
              onChange={inputTitle}
              className="allttam-input input-border-2"
              placeholder="제목을 입력해주세요"
            />
            <select
              value={category}
              onChange={selectCategory}
              className="allttam-input input-border-2"
            >
              <option>카테고리를 선택해주세요</option>
              {categoryList?.map((item: InquiryCategory) => (
                <option key={item._id} value={item._id}>
                  {item.title}
                </option>
              ))}
            </select>
            <FroalaEditor
              model={model}
              setModel={setModel}
              placeholder=""
              height={500}
            />
          </div>
          <div className="d-flex mt-3 gap-3 justify-content-center">
            <GreyBigButton onClick={handleWriteModal}>닫기</GreyBigButton>
            <GreenBigButton onClick={submitInquiry}>저장</GreenBigButton>
          </div>
        </ModalBody>
      </ModalInnerTop>
    </ModalBg>
  );
};
