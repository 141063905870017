interface Props {
  setNavState: React.Dispatch<React.SetStateAction<"order" | "cs">>;
  navState: "order" | "cs";
}

export const NavManageOrder = ({ setNavState, navState }: Props) => {
  const selectComponent = (e: React.MouseEvent<HTMLButtonElement>) => {
    setNavState(e.currentTarget.value as "order" | "cs");
  };

  return (
    <div className="heading-one payment-sec detail-sec product-collect-nav">
      <ul className="d-flex align-items-center">
        <li className="nav-item">
          <button
            onClick={selectComponent}
            value="order"
            className={`nav-link madebutton ${
              navState === "order" && "selectedNavItem"
            }`}
          >
            주문관리
          </button>
          {navState !== "order" && <span />}
        </li>
        <li className="nav-item">
          <button
            onClick={selectComponent}
            value="cs"
            className={`nav-link madebutton ${
              navState === "cs" && "selectedNavItem"
            }`}
          >
            cs관리
          </button>
          {navState !== "cs" && <span />}
        </li>
      </ul>
    </div>
  );
};
