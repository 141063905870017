import NavBar from "@components/NavBar/NavBar";
import { NavUpload } from "@components/NavBar/component/NavUpload";
import { usePost } from "@hooks/usePost";
import { SearchValue, UploadSummary } from "@interface/uploadinterface";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UploadSearchingBoard } from "./Uploadsearching/UploadSearchingBoard";
import { UploadModalButtonBox } from "./Uploadmodalbutton/UploadModalButtonBox";
import { UploadResultTable } from "./Uploadresulttable/UploadResultTable";
import { PagingButton } from "@components/PagingButton/PagingButton";
import chromeAPI from "@utils/functions/chromeAPI.js";
import "./Upload.css";

export const Upload = () => {
  const [page, setPage] = useState(1);
  const [isViewWayDetail, setIsViewWayDetail] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<UploadSummary[]>([]);
  const [searchValue, setSearchValue] =
    useState<SearchValue>(DEFAULT_SEARCHVALUE);
  const collectProductSummary = usePost(
    "/Product/SearchCollectProductSummary",
    {
      ...searchValue,
      nonUpload: {
        ...searchValue.nonUpload,
        market: searchValue.nonUpload.market.toLocaleUpperCase(),
      },
      pageNum: page,
      uploadWorkNumber:
        searchValue.uploadWorkNumber === ""
          ? null
          : searchValue.uploadWorkNumber,
      workNumber: searchValue.workNumber === "" ? null : searchValue.workNumber,
    }
  );
  const navigate = useNavigate();
  const checkExtension = async () => {
    const res = await chromeAPI.exCheck();

    return res.data;
  };

  useEffect(() => {
    checkExtension()
      .then((res) => {})
      .catch(() => {
        navigate("/home");
      });
  }, []);

  const clickNextPage = async () => {
    await setPage((prev) => prev + 1);
    collectProductSummary.mutate();
  };

  const clickPrevPage = async () => {
    await setPage((prev) => prev - 1);
    collectProductSummary.mutate();
  };

  const onClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    await setPage(parseInt(e.currentTarget.value));
    collectProductSummary.mutate();
  };

  const clickSearch = () => {
    collectProductSummary.mutate();
    setSelectedItem([]);
  };

  return (
    <>
      <NavBar>
        <NavUpload />
      </NavBar>
      <UploadSearchingBoard
        collectProductSummary={collectProductSummary}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        defaultSearch={DEFAULT_SEARCHVALUE}
        clickSearch={clickSearch}
      />
      <UploadModalButtonBox
        setPage={setPage}
        setSelectedItem={setSelectedItem}
        isViewWayDetail={isViewWayDetail}
        setIsViewWayDetail={setIsViewWayDetail}
        selectedItem={selectedItem}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        collectProductSummary={collectProductSummary}
      />

      <UploadResultTable
        isViewWayDetail={isViewWayDetail}
        setSelectedItem={setSelectedItem}
        selectedItem={selectedItem}
        collectProductSummary={collectProductSummary}
      />
      {collectProductSummary?.data && (
        <PagingButton
          totalPages={collectProductSummary?.data?.totalPage}
          currentPage={page}
          onClick={onClick}
          clickNextPage={clickNextPage}
          clickPrevPage={clickPrevPage}
        />
      )}
    </>
  );
};

const DEFAULT_SEARCHVALUE: SearchValue = {
  code: "",
  groupName: "",
  memo: "",
  productName: "",
  uploadWorkNumber: null,
  workNumber: null,
  startDate: new Date(new Date().setMonth(new Date().getMonth() - 1))
    .toISOString()
    .slice(0, 10),
  endDate: new Date().toISOString().slice(0, 10),
  pageNum: 1,
  pageLimit: 30,
  perPage: 30,
  nonUpload: {
    market: "",
    marketAccount: "",
  },
};
