const convertToNumber = (value: any): number => {
  if (typeof value === "number") {
    return value;
  } else if (typeof value === "string") {
    const numberValue = Number(value.replace(/[^0-9.-]+/g, ""));
    return isNaN(numberValue) ? -Infinity : numberValue;
  } else {
    return -Infinity;
  }
};

export const compareValues = (
  a: any,
  b: any,
  keyword: string,
  sortWay: "desc" | "asc"
) => {
  const aValue = convertToNumber(a[keyword]);
  const bValue = convertToNumber(b[keyword]);
  if (isNaN(aValue) && isNaN(bValue)) {
    return sortWay === "desc"
      ? b[keyword].localeCompare(a[keyword])
      : a[keyword].localeCompare(b[keyword]);
  } else {
    return sortWay === "desc" ? bValue - aValue : aValue - bValue;
  }
};