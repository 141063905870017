import { useMemo, useState } from "react";
import { StarterContents } from "./StarterContents";
import { StarterContentsMobile } from "./StarterContentsMobile";
import useToggle from "@hooks/useToggle";
import { FreeModalMobile } from "../modal/FreeModalMobile";
import { useNavigate } from "react-router-dom";
import ribon from "@assets/images/payment/best.png";

export const StarterMobile = () => {
  const [isOffline, setIsOffline] = useState(false);
  const { state: isFreeModal, handleToggle: toggleIsFreeModal } = useToggle();
  const navigate = useNavigate();

  const toggleIsOffline = () => {
    setIsOffline((prev) => !prev);
  };

  const starterPrice = useMemo(() => {
    return isOffline ? 140000 : 120000;
  }, [isOffline]);

  return (
    <div className="widthFull starter-mobile-container">
      <p className="weight600 text10 navy margin0 alignCenter">
        아직도 <span className="textRed">월요금제</span> 내면서 프로그램 배우고
        계세요? <br />
        <span className="textYellow">단기 속성</span>으로{" "}
        <span className="textGreen">단시간</span>에 셋팅하고 매출에 집중하세요
      </p>
      <div className="widthFull d-flex justify-content-end mt-4 gap-2">
        <p
          onClick={() => {
            setIsOffline(false);
          }}
          className={`pointer weight600 text12 navy margin0 oneLine ${
            isOffline && "none-select"
          }`}
        >
          <span className={`${isOffline ? "none-select" : "textYellow"}`}>
            원격
          </span>
          셋팅
        </p>
        <input
          onClick={toggleIsOffline}
          checked={isOffline}
          className="payment-starter-mobile"
          type="checkbox"
          readOnly
        />
        <p
          onClick={() => {
            setIsOffline(true);
          }}
          className={`pointer weight600 text12 navy margin0 offline-button oneLine ${
            !isOffline && "none-select"
          }`}
        >
          <span className={`${!isOffline ? "none-select" : "textYellow"}`}>
            대면
          </span>
          셋팅
        </p>
      </div>
      <div className="starter-mobile">
        <img src={ribon} className="absolute mobileribon" />
        <div className="starter-mobile-inner">
          <p className="weight700 text16  margin0">
            <span className="textYellow text20">스타트</span> 패키지
          </p>
          <p className="weight600  margin0 text20 navy">
            {"\\"}
            {starterPrice.toLocaleString()}
          </p>
        </div>
      </div>
      <div className="starter-button-container">
        <button
          onClick={() => {
            navigate("/payment?tab=starter");
          }}
          className="starter-button-mobile-left madebutton weight600 text10"
        >
          결제하기
        </button>
        <button
          onClick={() => {
            toggleIsFreeModal();
          }}
          className="starter-button-mobile-right madebutton weight600 text10"
        >
          무료사용
        </button>
      </div>
      <StarterContentsMobile isOffline={isOffline} />
      {isFreeModal && <FreeModalMobile closeModal={toggleIsFreeModal} />}
    </div>
  );
};
