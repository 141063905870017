import { getKoreanTiemFromUTC } from "../function/getKoreanTimeFromUTC";
import { CommentType } from "./PayEducation";

interface Props {
  item: CommentType;
}

export const Comment = ({ item }: Props) => {
  return (
    <div className="d-flex justify-content-between careText12 careWeight5 commentColor">
      <div className="d-flex gap-3">
        <p className="margin0 commentName">{item.name}</p>
        <p className="margin0 commentContent">: {item.content}</p>
      </div>
      <p className="margin0">{getKoreanTiemFromUTC(item.createdAt)}</p>
    </div>
  );
};
