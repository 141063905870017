
import { ViewProductInfo } from "./ViewCount/ViewProductInfo";
import { ViewCountChart } from "./ViewCount/ViewCountChart";
import { ModalBg } from "@components/ModalLayout/ModalBg";
import { ModalInnerTop } from "@components/ModalLayout/ModalInnerTop";

interface Props {
  closeModal: () => void;
  site: string;
  code: string;
  market: string;
}

export const ViewCountModal = ({ closeModal, code, site, market }: Props) => {
  return (
    <ModalBg onClick={closeModal}>
      <ModalInnerTop width={1400}>
        <>
          <div className="modal-header modal-sticky">
            <h5 className="modal-title d-flex align-items-center">
              유입수 그래프
            </h5>
            <button
              type="button"
              className="btn-close"
              onClick={closeModal}
            ></button>
          </div>
          <div className="modal-body viewModal d-flex">
            <ViewProductInfo site={site} code={code} />
            <ViewCountChart code={code} market={market} />
          </div>
        </>
      </ModalInnerTop>
    </ModalBg>
  );
};
