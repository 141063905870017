import { QuestionMarkIcon } from "@components/svg/QuestionMarkIcon";
import { SearchingIconGreen } from "@components/svg/SearchingIconGreen";
import axios from "axios";
import { useState } from "react";
import { CrawledKeyword } from "../../TagSettingModal";
import { getCrawledItemList } from "../../function/getCrawledItemLIst";
import { Keyword } from "@interface/uploadinterface";
import { upload } from "@utils/functions/postApi";

interface Props {
  setCrawledKeyword: React.Dispatch<React.SetStateAction<CrawledKeyword>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
  setSearchAdKeywordList: React.Dispatch<React.SetStateAction<Keyword[]>>;
}

export const KeywordSearching = ({
  setCrawledKeyword,
  isLoading,
  setIsLoading,
  setSearchAdKeywordList,
}: Props) => {
  const [keyword, setKeyword] = useState("");

  const inputKeyword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(e.target.value);
  };

  // const searchKeyword = () => {
  //   if (keywordValue.split(",").length < 5) {
  //     getSearchKeyword.mutate();
  //     setKeyWordValue("");
  //   } else {
  //     getSearchKeyword.mutate();
  //     setKeyWordValue(keywordValue.split(",").slice(5).join(", "));
  //   }
  // };

  const submitSearch = async () => {
    setIsLoading(true);

    if (keyword.split(",").length < 5) {
      setKeyword("");
    } else {
      setKeyword(keyword.split(",").slice(5).join(", "));
    }

    const keywordList = await upload.getNaverKeyword(keyword);
    setSearchAdKeywordList(keywordList.keywordList);

    const { rel, auto } = await getCrawledItemList(keyword.split(",")[0]);
    setCrawledKeyword({
      rel,
      auto,
    });
    setIsLoading(false);
  };

  return (
    <div className="mt-3">
      <div className="d-flex align-items-center gap-3">
        <p className="margin0 text18 weight500">키워드 검색</p>
        <QuestionMarkIcon />
      </div>
      <div className="col-12 mt-3">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            submitSearch();
          }}
          className="relative"
        >
          <input
            value={keyword}
            onChange={inputKeyword}
            type="text"
            className="allttam-input input-pr-3 tag-placeholder"
            placeholder="키워드를 입력해주세요. 쉼표(,)를 통해 입력한 키워드에서 순차적으로 5개씩 검색됩니다."
          />
          <button
            disabled={isLoading}
            className="madebutton input-searching-icon"
          >
            <SearchingIconGreen />
          </button>
        </form>
      </div>
    </div>
  );
};

export interface CrawlKeywordType {
  id: number;
  keyword: string;
}
