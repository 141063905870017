import useUser from "@hooks/useUser";
import useFetch from "../../../hooks/useFetch";

export const NavBarDefaultComponent = () => {
  const userInfo = useUser();
  return (
    <h4 className="subTitle">
      안녕하세요, {(userInfo as any)?.originalUserName}님!
    </h4>
  );
};
