import { WhiteMiddleButton } from "@components/Button/allttamButton/WhiteMiddleButton";
import { GlobalTooltip } from "@components/GlobalTooltip/GlobalTooltip";
import { LeftIcon } from "@components/svg/LeftIcon";
import { RightIcon } from "@components/svg/RightIcon";
import { useTooltip } from "@hooks/useTooltip";
import { ProhibitionWordModal } from "../../ProhibitionWordModal";
import { ReplaceWordModal } from "../../ReplaceWordModal";
import useToggle from "@hooks/useToggle";
import { ProhibitWordButton } from "@components/Button/WordButton/ProhibitWordButton";
import { ReplaceWordButton } from "@components/Button/WordButton/ReplaceWordButton";

interface Props {
  setWordLengthLimit: React.Dispatch<React.SetStateAction<number>>;
  wordLengthLimit: number;
}

export const WordOptionButton = ({
  setWordLengthLimit,
  wordLengthLimit,
}: Props) => {
  const [isLengthTooltip, enterLength, leaveLength] = useTooltip();

  const clickPlusWordLengthLimit = () => {
    setWordLengthLimit((prev) => prev + 1);
  };

  const clickMinusWordLengthLimit = () => {
    setWordLengthLimit((prev) => prev - 1);
  };

  const inputLengthLimit = (e: React.ChangeEvent<HTMLInputElement>) => {
    setWordLengthLimit(parseInt(e.target.value));
  };

  return (
    <div className="width35 d-flex gap-3">
      <ProhibitWordButton />
      <ReplaceWordButton />
      <div className="width30 d-flex flex-col relative gap-2">
        <GlobalTooltip isVisible={isLengthTooltip as boolean}>
          상품명 글자수의 최대값을 설정합니다.
        </GlobalTooltip>
        <p
          onMouseEnter={enterLength as () => void}
          onMouseLeave={leaveLength as () => void}
          className="margin0 weight500 text14 grey oneLine"
        >
          최대 글자 수
        </p>
        <input
          onChange={inputLengthLimit}
          type="number"
          className="allttam-input button-middle border-grey"
          placeholder={wordLengthLimit.toString()}
          value={wordLengthLimit}
        />
        <div className="next-prev-svg">
          <button className="madebutton" onClick={clickMinusWordLengthLimit}>
            <LeftIcon />
          </button>
          <button className="madebutton" onClick={clickPlusWordLengthLimit}>
            <RightIcon />
          </button>
        </div>
      </div>
    </div>
  );
};
