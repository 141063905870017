import { getKoreanTiemFromUTC } from "@components/AllttamCare/function/getKoreanTimeFromUTC";
import { GreenBigButton } from "@components/Button/allttamButton/GreenBigButton";
import { FroalaEditor } from "@components/shared/Froala/Froala";
import { ModalBg } from "@components/ModalLayout/ModalBg";
import { ModalBody } from "@components/ModalLayout/ModalBody";
import { ModalHead } from "@components/ModalLayout/ModalHead";
import { ModalInnerTop } from "@components/ModalLayout/ModalInnerTop";
import { useInfoWindow } from "@hooks/useInfoWindow";
import axios from "axios";
import HTMLReactParser from "html-react-parser";
import { useState } from "react";
import { useQueryClient } from "react-query";

interface Props {
  id: string;
  name: string;
  createdAt: string;
  content: string;
  answer: string;
  closeModal: () => void;
  adminCategoryId: string;
  adminHasAnswerd: string;
}

export const InquiryAnswer = ({
  id,
  name,
  createdAt,
  content,
  answer,
  closeModal,
  adminHasAnswerd,
  adminCategoryId,
}: Props) => {
  const queryClient = useQueryClient();
  const [model, setModel] = useState(answer);
  const openWindow = useInfoWindow();

  const submitAnswer = async () => {
    const res = await axios.post(
      `https://center.allttam.com/api/allttam/qna/answer/${id}`,
      {
        answer: model,
      }
    );

    if (res.status === 201) {
      openWindow("답변이 작성되었습니다.");
      closeModal();
      queryClient.invalidateQueries([
        "inquiries",
        adminCategoryId,
        adminHasAnswerd,
      ]);
    }
  };

  return (
    <ModalBg onClick={() => {}}>
      <ModalInnerTop width={1400}>
        <ModalHead clickClose={closeModal}>문의글 답변</ModalHead>
        <ModalBody>
          <div className="d-flex gap-4">
            <div className="widthHalf">
              <p className="textStart cursor text16">{name}</p>
              <p className="grey textEnd">{getKoreanTiemFromUTC(createdAt)}</p>
              <div className="textStart">{HTMLReactParser(content)}</div>
            </div>
            <div className="widthHalf">
              <FroalaEditor
                model={model}
                setModel={setModel}
                placeholder="placeHolder"
              />
              <GreenBigButton onClick={submitAnswer}>답변하기</GreenBigButton>
            </div>
          </div>
        </ModalBody>
      </ModalInnerTop>
    </ModalBg>
  );
};
