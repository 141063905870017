interface Props {
  payPrice: number;
}

export const PlanTotalPrice = ({ payPrice }: Props) => {
  return (
    <div className="d-flex justify-content-between">
      <div className="d-flex align-items-end gap-2">
        <p className="text14 weight600 navy margin0">결제 금액</p>
        <p className="margin0 vat">(vat 별도)</p>
      </div>
      <p className="margin0 weight600 text14 textGreen">
        {`\\ ${payPrice ? payPrice.toLocaleString() : 0}`}
      </p>
    </div>
  );
};
