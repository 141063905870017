interface Props {
  colSpan: number;
}

export const NoneTableItem = ({ colSpan }: Props) => {
  return (
    <tr>
      <td colSpan={colSpan}>
        <p className="textCenter mt-5">기록이 없습니다.</p>
      </td>
    </tr>
  );
};
