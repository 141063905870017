import HTMLReactParser from "html-react-parser";
import { useEffect, useMemo, useState } from "react";
import { AliItem } from "../../../../interface/collectInterface";

interface Props {
  item: AliItem;
  selectedCollectItem: string[];
  setSelectedCollectItem: React.Dispatch<React.SetStateAction<string[]>>;
}

export const AliCollectItem = ({
  item,
  selectedCollectItem,
  setSelectedCollectItem,
}: Props) => {
  const [isSelect, setIsSelect] = useState<boolean>(false);

  useEffect(() => {
    if (selectedCollectItem.includes(item.productId)) {
      setIsSelect(true);
    } else {
      setIsSelect(false);
    }
  }, [selectedCollectItem]);

  const selectCollectItem = () => {
    if (isSelect) {
      setSelectedCollectItem((prev) =>
        prev.filter((select) => select !== item.productId)
      );
    } else {
      setSelectedCollectItem((prev) => [...prev, item.productId]);
    }
  };
  // const isPromotion = useMemo(() => {
  //   return Boolean(
  //     item.sellingPoints?.filter(
  //       (item) =>
  //         item.sellingPointTagId === "m0000040" ||
  //         item.sellingPointTagId === "885603746"
  //     ).length
  //   );
  // }, [item]);

  return (
    <div className={`custom-col-product`} onClick={selectCollectItem}>
      {/* {isPromotion && <span className="ali-promo-item">행사상품 주의</span>} */}
      <div
        className={`product-col  collectItem ${
          isSelect && "selectedCollectItem"
        }`}
      >
        <div className="d-flex justify-content-center">
          <img className="collectItemImg" src={item.image.imgUrl} />
        </div>
        <div className="itemTitleContainer">
          <h4>{item.title.displayTitle}</h4>
        </div>
        <div className="product-col-inner">
          <h6>판매가</h6>
          <h5>{item?.prices?.salePrice.formattedPrice}</h5>
        </div>
        <a
          href={`https://aliexpress.com/item/${item.productId}.html?`}
          target="_blank"
        >
          <button className="cancel-btn short-btn">바로가기</button>
        </a>
      </div>
    </div>
  );
};
