import { DownArrowIcon } from "@components/svg/DownArrowIcon";
import { CollectValue } from "@interface/collectInterface";
import { GroupNameSelect } from "../../GroupNameSelect/GroupNameSelect";
import useToggle from "@hooks/useToggle";
import useSelectHelperComponent from "@hooks/useSelectHelperComponent";
import { QuestionMarkIcon } from "@components/svg/QuestionMarkIcon";

interface Props {
  collectValue: CollectValue;
  inputCollectValue: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => void;
  setCollectValue: React.Dispatch<React.SetStateAction<CollectValue>>;
}

export const VvicSearchingGroupName = ({
  setCollectValue,
  collectValue,
  inputCollectValue,
}: Props) => {
  const { state: isSelect, handleToggle: handleSelect } = useToggle();
  const openHelper = useSelectHelperComponent();

  return (
    <div className="product-label col-md-3 col-12">
      <div className="d-flex align-items-center gap-3 mb-2">
        <label htmlFor="product-name" className="paddingZero">
          상품 그룹명
        </label>
        <button
          onClick={() => {
            // openHelper("detail", "64e5d5c28081d5317ca779aa")
          }}
          className="madebutton d-flex align-items-center paddingZero"
        >
          <QuestionMarkIcon color="#39415c" />
        </button>
      </div>
      <div className="relative">
        <input
          value={collectValue.groupName}
          onChange={inputCollectValue}
          name="groupName"
          type="text"
          className="form-control"
          placeholder="상품 그룹명을 입력해주세요."
        />
        <button
          className="madebutton collect-group-select-btn"
          onClick={handleSelect}
        >
          <DownArrowIcon />
        </button>
        {isSelect && (
          <GroupNameSelect
            setCollectValue={setCollectValue}
            closeMenu={handleSelect}
          />
        )}
      </div>
    </div>
  );
};
