import styles from "./Spacing.module.css";

interface Props {
  height?: "" | "height8" | "height16" | "height32" | "height40";
  width?: "" | "width8" | "width16" | "width32" | "width40";
}

const Spacing = () => {};

const Height = ({
  height,
}: {
  height: "height8" | "height16" | "height32" | "height40";
}) => {
  return <div className={styles[`${height}`]} />;
};

const Width = ({
  width,
}: {
  width: "width8" | "width16" | "width32" | "width40";
}) => {
  return <div className={styles[`${width}`]} />;
};

Spacing.Height = Height;
Spacing.Width = Width;

export default Spacing;
