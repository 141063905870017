interface Props {
  checkEdit: (name: string) => void;
  isEdit: boolean;
}

export const SelectDeliveryPrice = ({ checkEdit, isEdit }: Props) => {
  return (
    <div className="row mt-4">
      <div className="col-12">
        <div className="d-flex align-items-center gap-3 attributes1 m-0">
          <div
            className="d-flex gap-3 align-items-center pointer"
            onClick={() => checkEdit("deliverPrice")}
          >
            <div className="form-group">
              <input
                name="deliverPrice"
                checked={isEdit}
                type="checkbox"
                id="deliverPrice"
                readOnly
              />
              <label onClick={() => checkEdit("deliverPrice")}></label>
            </div>
            <p>배송비 수정</p>
          </div>
        </div>
      </div>
    </div>
  );
};
