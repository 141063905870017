import { Revenue } from "../../../../interface/orderManageInterface";

interface Props {
  inputRevenueValue: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => void;
  revenueValue: Revenue;
}

export const RevenuePurchasePrice = ({
  inputRevenueValue,
  revenueValue,
}: Props) => {
  return (
    <div className="d-flex align-items-center widthFull gap-3 textStart mt-2">
      <div className="d-flex align-items-center widthFull">
        <p className="ordererInfoTitle revenueModalLeft">구입가</p>
        <div className="d-flex align-items-center revenueModalRight gap-5">
          <div className="d-flex align-items-center widthFull">
            <input
              value={revenueValue.purchasePriceWon}
              name="purchasePriceWon"
              className="orderInfoModalInput"
              disabled
            />
            <p className="margin0 pl-1">원</p>
          </div>
          <div className="d-flex align-items-center widthFull">
            <input
              onChange={inputRevenueValue}
              value={revenueValue.purchasePriceOrigin}
              name="purchasePriceOrigin"
              className="orderInfoModalInput"
            />
            <p className="margin0 pl-1">{revenueValue.currency}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
