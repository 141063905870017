import { checkLoginAllOnce } from "./checkLoginAll";

export const checkLoginFromAccountList = async (accountList: Account[]) => {
  let result = [];
  for (let i in accountList) {
    for (let j in accountList[i].children) {
      const res = await checkLoginAllOnce(
        accountList[i].children[j].label.split("] ")[1],
        accountList[i].id
      );
      result.push({
        market: accountList[i].id,
        account: accountList[i].children[j].label.split("] ")[1],
        result: res ? true : false,
      });
    }
  }

  return result;
};

interface Account {
  id: string;
  label: string;
  children: Children[];
}
interface Children {
  id: string;
  label: string;
}
