import madeFullIcon from "@assets/images/madeFull.svg";
import { ReactCropperElement } from "react-cropper";

interface Props {
  cropperRef: React.RefObject<ReactCropperElement>;
  mouseHover: (string: string) => void;
  mouseLeave: () => void;
}

export const MadeCropBoxFullButton = ({
  cropperRef,
  mouseHover,
  mouseLeave,
}: Props) => {
  const clickEnlarge = () => {
    cropperRef.current!.cropper.setCropBoxData({
      left: 0,
      top: 0,
      height: 350, // 크롭 박스의 높이를 원하는 값으로 설정
      width: 350, // 크롭 박스의 너비를 원하는 값으로 설정
    });
  };

  return (
    <button
      onClick={clickEnlarge}
      className="madebutton thumbIcon tooltipRel"
      onMouseLeave={mouseLeave}
      onMouseEnter={() => mouseHover("에디터 이미지 전체 선택하기")}
    >
      <img src={madeFullIcon} />
    </button>
  );
};
