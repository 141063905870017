import { createBillKey, deleteBillKey, getBillKey } from "@apis/payApis";
import { GreenMiddleButton } from "@components/Button/allttamButton/GreenMiddleButton";
import { ChangeEvent, FormEvent, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import styles from "@styles/UserInfo.module.css";
import { TrashCanIcon } from "@components/svg/TrashCanIcon";
import { RedSmallButton } from "@components/Button/allttamButton/RedSmallButton";
import { useInfoWindow } from "@hooks/useInfoWindow";

export const UserInfoPayInfo = () => {
  const { data, isLoading } = useQuery("billingInfo", getBillKey);

  const openWindow = useInfoWindow();
  const queryClient = useQueryClient();

  const [cardInfo, setCardInfo] = useState(DEFAULT_CARDINFO);

  const inputCardInfo = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setCardInfo((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const submitForm = async () => {
    const res = await createBillKey(cardInfo);
    if (res.status === "Success") {
      queryClient.invalidateQueries("billingInfo");
      openWindow("카드정보가 등록되었습니다.");
      setCardInfo(DEFAULT_CARDINFO);
    } else {
      openWindow(res.message);
    }
  };

  const deleteCard = async () => {
    const res = await deleteBillKey();

    if (res.status === "Success") {
      queryClient.invalidateQueries("billingInfo");
      openWindow("카드정보가 삭제되었습니다.");
    }
  };

  if (isLoading) return null;

  return (
    <>
      {data.data ? (
        <div className={styles["billing-card"]}>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <p className="weight600 text18 margin0">등록된 카드</p>
            {/* <button className="madebutton paddingZero" onClick={deleteCard}>
              <TrashCanIcon />
            </button> */}
          </div>
          <p className="margin0 weight500 text14 gray">
            카드번호 : {data.data}
          </p>
          <div className="d-flex mt-3 justify-content-center">
            <RedSmallButton onClick={deleteCard}>삭제하기</RedSmallButton>
          </div>
        </div>
      ) : (
        <div className="row form-row-one form-row-two">
          <div className="col-12">
            <h6 className="form-h6 form-h6-one">
              결제 정보 등록<span>저장되어 있는 카드로 결제</span>
            </h6>
          </div>
          <div className="col-md-5 col-12 p-0">
            <div className="form-inner form-inner-1">
              <label>카드 번호</label>
              <input
                onScroll={(e) => e.preventDefault()}
                type="text"
                pattern="\d{4}-\d{4}-\d{4}-\d{4}"
                className="form-control"
                placeholder="카드 번호 입력"
                name="cardNum"
                value={cardInfo.cardNum}
                onChange={(e) =>
                  setCardInfo((prev) => ({
                    ...prev,
                    cardNum: e.target.value
                      .replace(/\D/g, "")
                      .replace(/(\d{4})(?=\d)/g, "$1-")
                      .slice(0, 19),
                  }))
                }
              />
            </div>
          </div>

          <div className="col-md-2 col-12 p-0">
            <div className="form-inner">
              <label className="form-label">만료일</label>
              <input
                type="text"
                className="form-control"
                placeholder="YYMM"
                name="cardExpire"
                maxLength={4}
                value={cardInfo.cardExpire}
                onChange={inputCardInfo}
              />
            </div>
          </div>
          <div className="col-md-2 col-12 p-0">
            <div className="form-inner">
              <label className="form-label">카드비밀번호</label>
              <input
                type="password"
                className="form-control"
                placeholder="카드 비밀번호 앞2자리"
                name="cardPwd"
                maxLength={2}
                value={cardInfo.cardPwd}
                onChange={inputCardInfo}
              />
            </div>
          </div>

          <div className="col-md-2 col-12 p-0">
            <div className="form-inner">
              <label className="form-label">CVC</label>
              <input
                type="password"
                className="form-control"
                placeholder="CVC 3자리 입력"
                name="idNum"
                maxLength={6}
                value={cardInfo.idNum}
                onChange={inputCardInfo}
              />
            </div>
          </div>
          <div className="d-flex col-12 mt-3 justify-content-end">
            <GreenMiddleButton onClick={submitForm}>저장</GreenMiddleButton>
          </div>
        </div>
      )}
    </>
  );
};

const DEFAULT_CARDINFO = {
  cardNum: "",
  cardExpire: "",
  cardPwd: "",
  idNum: "",
};
