import { AlertOnlyClose } from "@components/Alert/AlertOnlyClose";
import { GreenBigButton } from "@components/Button/allttamButton/GreenBigButton";
import { useAlert } from "@hooks/useAlert";
import useFetch from "@hooks/useFetch";
import useUser from "@hooks/useUser";
import { PlanOptionInfoType, PlanType } from "@interface/payment";
import axiosInstance from "@utils/axiosInstance";
import { payment } from "@utils/functions/paymentFunction";
import { useCallback } from "react";

interface Props {
  item: PlanType;
  accountValue: string;
  selectedPlanInfo: PlanOptionInfoType;
  payPrice: number;
  code: string;
  plan: string;
}

export const PlanSubscribeButton = ({
  plan,
  code,
  item,
  accountValue,
  selectedPlanInfo,
  payPrice,
}: Props) => {
  const userInfo = useUser();
  const { isAlert, handleAlert, alertMessage } = useAlert();

  const checkIsPossibleToPay = () => {
    if (!item.isCanPayment) {
      handleAlert(
        `플랜 하향시 관리자 문의가 필요합니다. 채널톡을 이용해주세요.`
      );
      return false;
    }
    if (!selectedPlanInfo) {
      handleAlert(`플랜의 구독 개월수를 선택해주세요`);
      return false;
    }
    if (selectedPlanInfo.openmarketInfo[0].Value.now > parseInt(accountValue)) {
      handleAlert("현재 구독 계정수 보다 낮추려면 관리자 문의가 필요합니다.");
      return false;
    } else return true;
  };

  const checkPaymentId = async () => {
    const res = await axiosInstance.post("/Ipay/CreateAllddamPaymentId", {
      type: "PLAN",
      level: 3,
      grade: selectedPlanInfo.grade,
      smartStore: parseInt(accountValue),
      coupang: parseInt(accountValue),
      auction: parseInt(accountValue),
      gmarket: parseInt(accountValue),
      elevenst: parseInt(accountValue),
      originalPrice: selectedPlanInfo.originalPrice,
      salePrice: payPrice,
    });

    return res.data;
  };

  const checkCode = useCallback(async () => {
    const res = await axiosInstance.post(
      "https://center.allttam.com/api/user/checkcode",
      {
        code,
      }
    );

    return res.data;
  }, []);

  const clickSubscribe = async () => {
    const isPossible = checkIsPossibleToPay();
    if (isPossible) {
      if (plan === "Unlimited") {
        const isCheckCode = await checkCode();
        if (!isCheckCode)
          return handleAlert("추천인 코드가 유효하지 않습니다.");
      }

      const res = await checkPaymentId();
      if (!res.data) {
        handleAlert(res.message);
        return;
      }
      if (payPrice) {
        payment({
          GoodsName: `${item.planNameEn} ${selectedPlanInfo.grade}개월 ${accountValue}계정`,
          Amt: (payPrice + payPrice * 0.1).toString(),
          BuyerName: userInfo.originalUserName,
          BuyerTel: userInfo.userTelNo,
          BuyerEmail: userInfo.userEmail,
          mallReserved: res.data,
        });
      }
    }
  };

  return (
    <div className="d-flex justify-content-center mt-3">
      <GreenBigButton onClick={clickSubscribe}>구독하기</GreenBigButton>
      {isAlert && (
        <AlertOnlyClose
          closeAlert={handleAlert as () => void}
          message={alertMessage}
        />
      )}
    </div>
  );
};
