import helper from "@assets/images/helper/allttamHelper.svg";
import "./allttamhelper.css";
import { HelperContent } from "./component/HelperContent";
import useToggle from "@hooks/useToggle";
import useToggleHelper from "@hooks/useToggleHelper";

export const AllttamHelper = () => {
  const [isHelperOpen, handleHelper] = useToggleHelper();
  return (
    <div className="allttam-helper">
      <img src={helper} className="pointer" onClick={handleHelper} />
      {isHelperOpen && <HelperContent closeModal={handleHelper} />}
      {isHelperOpen && <div className="care-bg" onClick={handleHelper}></div>}
    </div>
  );
};
