import { useCallback, useEffect, useMemo, useState } from "react";
import { DefaultHeader } from "./DefaultHeader";
import { DefaultBody } from "./DefaultBody";
import { CategoryBody } from "./CategoryBody";
import { ComponentType, IndexType } from "@interface/fqa.interface";
import { DetailBody } from "./DetailBody";
import { LeftIcon } from "@components/svg/LeftIcon";
import { HelperSearchResult } from "./HelperSearchResult";
import { Inquiry } from "./Inquiry";
import useSelectHelperComponent from "@hooks/useSelectHelperComponent";
import { helperComponent, helperPrevComponentList } from "src/atom/atom";
import { useRecoilState } from "recoil";

interface Props {
  closeModal: () => void;
}

export const HelperContent = ({ closeModal }: Props) => {
  const [isWide, setIsWide] = useState(false);
  const [isViewAll, setIsViewAll] = useState(false);
  const [component, setComponent] =
    useRecoilState<ComponentType>(helperComponent);
  const [prevList, setPrevList] = useRecoilState(helperPrevComponentList);

  const selectComponent = useSelectHelperComponent();

  useEffect(() => {
    return () => {
      setPrevList([]);
      setComponent({
        id: "",
        type: "default",
      });
    };
  }, []);

  const bodyIndex = useMemo(() => {
    return {
      default: (
        <DefaultBody
          selectComponent={selectComponent}
          isViewAll={isViewAll}
          setIsViewAll={setIsViewAll}
        />
      ),
      category: (
        <CategoryBody id={component.id} selectComponent={selectComponent} />
      ),
      detail: <DetailBody id={component.id} />,
      search: (
        <HelperSearchResult
          id={component.id}
          selectComponent={selectComponent}
        />
      ),
      inquiry: <Inquiry />,
    };
  }, [component, isViewAll]);

  const clickPrev = () => {
    const newArr = [...prevList];
    const lastComponent = newArr.pop()!;

    selectComponent(lastComponent.type, lastComponent?.id);
    setPrevList(newArr);
  };

  return (
    <div className={isWide ? "helper-wide helper-content" : "helper-content"}>
      <div className="helper-header">
        <DefaultHeader
          selectComponent={selectComponent}
          setIsWide={setIsWide}
          isWide={isWide}
          closeModal={closeModal}
        />
      </div>
      <div className="helper-body">
        <div className="">
          {!(component.type === "default") && (
            <button
              onClick={clickPrev}
              className="madebutton helper-back-icon weight500 text14 d-flex align-items-center"
            >
              <LeftIcon width="30" height="30" color="#000" />
              <p className="margin0">뒤로가기</p>
            </button>
          )}
        </div>
        {bodyIndex[component.type]}
      </div>
    </div>
  );
};
