import mapping from "@assets/images/landing/automapping.svg";
import yellowArrow from "@assets/images/landing/automappingArrow.png";

export const SeoCategoryMapping = () => {
  return (
    <div className="seoMainContentRightSide component-transition">
      <div className="textEnd">
        <p className="textContentMain">카테고리 오토 매핑</p>
        <p className="seoSubText oneLine">하나의 오픈마켓 카테고리 선택 시</p>
        <p className="seoSubText oneLine">모든 오픈마켓 카테고리가 자동 완성</p>
      </div>
      <div className="seoMainSmallText mt-5">
        <div className="seoIconBox">
          <img src={mapping} />
          <img src={yellowArrow} />
        </div>
        <div className="seoContentText mt-2 mb-2">
          <p className="margin0 oneLine">
            모든 오픈마켓 카테고리를 하나하나 검색하시나요?
          </p>
          <p className="margin0 oneLine">
            카테고리를 일일히 선택하는 데 시간을 쏟으시나요?
          </p>
          <p className="margin0 oneLine">
            상품을 <span className="seoContentTextBold">빠르게</span>,{" "}
            <span className="seoContentTextBold">많이</span> 업로드할수록{" "}
            <span className="seoContentTextBold">매출</span>에{" "}
            <span className="seoContentTextBold">직결</span>
            됩니다.
          </p>
          <p className="margin0 oneLine">
            카테고리 오토매핑으로{" "}
            <span className="seoContentTextBold">업무효율</span>과
            <span className="seoContentTextBold">매출</span>을 높이세요.
          </p>
        </div>
      </div>
    </div>
  );
};
