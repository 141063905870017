import { CoinSideIcon } from "@components/svg/CoinSideIcon";
import { HomeIcon } from "@components/svg/HomeIcon";
import { OrderManageIcon } from "@components/svg/OrderManageIcon";
import { ProductCollectIcon } from "@components/svg/ProductCollectIcon";
import { ProductManageIcon } from "@components/svg/ProductManageIcon";
import { ProductUploadIcon } from "@components/svg/ProductUploadIcon";
import { SettingIcon } from "@components/svg/SettingIcon";
import { useTooltip } from "@hooks/useTooltip";
import { memo } from "react";
import { Link, useLocation } from "react-router-dom";
import { CollectProductIcon } from "@components/Home/HomeUserInfo/svg/CollectProductIcon";

interface Props {
  isNav: boolean;
}

const SideBarItemTop = ({ isNav }: Props) => {
  const [ishomeToolTip, enterHome, leaveHome] = useTooltip();
  const [isCollectTooltip, enterCollect, leaveCollect] = useTooltip();
  const [isUploadTooltip, enterUpload, leaveUpload] = useTooltip();
  const [isProductTooltip, enterProduct, leaveProduct] = useTooltip();
  const [isOrderTooltip, enterOrder, leaveOrder] = useTooltip();
  const [isSettingTooltip, enterSetting, leaveSetting] = useTooltip();
  const location = useLocation();

  return (
    <ul>
      <li className={`${location.pathname === "/home" && "side-nav-top-bg"}`}>
        <div
          className={`${
            isNav ? "side-bar-none-div" : "side-bar-none-div-small"
          } ${location.pathname === "/home" && "side-nav-top-radius"}`}
        ></div>
      </li>
      <li
        onMouseEnter={enterHome as () => void}
        onMouseLeave={leaveHome as () => void}
        className={location.pathname === "/home" ? "activeItem" : ""}
      >
        {!isNav && ishomeToolTip && <div className="nav-tooltip">홈</div>}
        <Link to={"/home"}>
          <HomeIcon />
          <span className="navItemListText">홈</span>
        </Link>
      </li>
      <li
        className={`${
          (location.pathname === "/home" || location.pathname === "/collect") &&
          "side-nav-top-bg"
        }`}
      >
        <div
          className={`${
            isNav ? "side-bar-none-div" : "side-bar-none-div-small"
          } ${location.pathname === "/home" && "side-nav-bottom-radius"} ${
            location.pathname === "/collect" && "side-nav-top-radius"
          }`}
        ></div>
      </li>
      <li
        onMouseEnter={enterCollect as () => void}
        onMouseLeave={leaveCollect as () => void}
        className={location.pathname === "/collect" ? "activeItem" : ""}
      >
        {!isNav && isCollectTooltip && (
          <div className="nav-tooltip">상품 수집</div>
        )}
        <Link to={"/collect"}>
          <CollectProductIcon isSelect={location.pathname === "/collect"} />
          <span className="navItemListText">상품 수집</span>
        </Link>
      </li>
      <li
        className={`${
          (location.pathname === "/collect" ||
            location.pathname === "/upload") &&
          "side-nav-top-bg"
        }`}
      >
        <div
          className={`${
            isNav ? "side-bar-none-div" : "side-bar-none-div-small"
          } ${location.pathname === "/collect" && "side-nav-bottom-radius"} ${
            location.pathname === "/upload" && "side-nav-top-radius"
          }`}
        ></div>
      </li>
      <li
        onMouseEnter={enterUpload as () => void}
        onMouseLeave={leaveUpload as () => void}
        className={location.pathname === "/upload" ? "activeItem" : ""}
      >
        {!isNav && isUploadTooltip && (
          <div className="nav-tooltip">상품 등록</div>
        )}
        <Link to={"/upload"}>
          <ProductUploadIcon />
          <span className="navItemListText">상품 등록</span>
        </Link>
      </li>
      <li
        className={`${
          (location.pathname === "/upload" ||
            location.pathname === "/productmanage") &&
          "side-nav-top-bg"
        }`}
      >
        <div
          className={`${
            isNav ? "side-bar-none-div" : "side-bar-none-div-small"
          } ${location.pathname === "/upload" && "side-nav-bottom-radius"} ${
            location.pathname === "/productmanage" && "side-nav-top-radius"
          }`}
        ></div>
      </li>
      <li
        onMouseEnter={enterProduct as () => void}
        onMouseLeave={leaveProduct as () => void}
        className={location.pathname === "/productmanage" ? "activeItem" : ""}
      >
        {!isNav && isProductTooltip && (
          <div className="nav-tooltip">상품 관리</div>
        )}
        <Link to={"/productmanage"}>
          <ProductManageIcon />
          <span className="navItemListText">상품 관리</span>
        </Link>
      </li>
      <li
        className={`${
          (location.pathname === "/productmanage" ||
            location.pathname === "/ordermanage") &&
          "side-nav-top-bg"
        }`}
      >
        <div
          className={`${
            isNav ? "side-bar-none-div" : "side-bar-none-div-small"
          } ${
            location.pathname === "/productmanage" && "side-nav-bottom-radius"
          } ${location.pathname === "/ordermanage" && "side-nav-top-radius"}`}
        ></div>
      </li>
      <li
        onMouseEnter={enterOrder as () => void}
        onMouseLeave={leaveOrder as () => void}
        className={location.pathname === "/ordermanage" ? "activeItem" : ""}
      >
        {!isNav && isOrderTooltip && (
          <div className="nav-tooltip">주문 관리</div>
        )}
        <Link to={"/ordermanage"}>
          <OrderManageIcon />
          <span className="navItemListText">주문 관리</span>
        </Link>
      </li>
      <li
        className={`${
          (location.pathname === "/ordermanage" ||
            location.pathname === "/setting") &&
          "side-nav-top-bg"
        }`}
      >
        <div
          className={`${
            isNav ? "side-bar-none-div" : "side-bar-none-div-small"
          } ${
            location.pathname === "/ordermanage" && "side-nav-bottom-radius"
          } ${location.pathname === "/setting" && "side-nav-top-radius"}`}
        ></div>
      </li>
      <li
        onMouseEnter={enterSetting as () => void}
        onMouseLeave={leaveSetting as () => void}
        className={location.pathname === "/setting" ? "activeItem" : ""}
      >
        <Link to={"/setting"}>
          {!isNav && isSettingTooltip && (
            <div className="nav-tooltip">설정</div>
          )}
          <SettingIcon />
          <span className="navItemListText">기본 설정</span>
        </Link>
      </li>
      <li
        className={`${
          (location.pathname === "/setting" ||
            location.pathname === "/payment") &&
          "side-nav-top-bg"
        }`}
      >
        <div
          className={`${
            isNav ? "side-bar-none-div" : "side-bar-none-div-small"
          } ${location.pathname === "/setting" && "side-nav-bottom-radius"} ${
            location.pathname === "/payment" && "side-nav-top-radius"
          }`}
        ></div>
      </li>
      <li className={location.pathname === "/payment" ? "activeItem" : ""}>
        <Link to={"/payment"}>
          <CoinSideIcon isSelect={location.pathname === "/payment"} />
          <span className="navItemListText">코인 충전소</span>
        </Link>
      </li>
      <li
        className={`${location.pathname === "/payment" && "side-nav-top-bg"}`}
      >
        <div
          className={`${
            isNav ? "side-bar-none-div" : "side-bar-none-div-small"
          } ${location.pathname === "/payment" && "side-nav-bottom-radius"}`}
        ></div>
      </li>
    </ul>
  );
};

export default SideBarItemTop;
