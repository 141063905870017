import rightArrow from "@assets/images/landing/Vector.png";

interface Props {
  children: string;
  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

export const FreeButton = ({
  children,
  onClick,
  onMouseEnter,
  onMouseLeave,
}: Props) => {
  return (
    <button
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className="mt-5 d-flex align-items-center gap-3 justify-content-center madebutton contentGreenButton"
    >
      {children}
      <img src={rightArrow} />
    </button>
  );
};
