export let ALPHABET_INDEX: string[] = [];
let letters = "ABCDEFGHIJKLMNOPQRSTUVWSYZ";

for (let i = 0; i <= 100; i++) {
  if (i < 26) {
    ALPHABET_INDEX.push(letters[i]);
  } else {
    let firstLetter = letters[Math.floor((i - 26) / 26)];
    let secondLetter = letters[(i - 26) % 26];
    ALPHABET_INDEX.push(firstLetter + secondLetter);
  }
}

export let ALPHABET_INDEX_LOWER: string[] = [];
let letters_lower = "abcdefghijklmnopqrstuvwsyz";

for (let i = 0; i <= 100; i++) {
  if (i < 26) {
    ALPHABET_INDEX_LOWER.push(letters[i]);
  } else {
    let firstLetter = letters_lower[Math.floor((i - 26) / 26)];
    let secondLetter = letters_lower[(i - 26) % 26];
    ALPHABET_INDEX_LOWER.push(firstLetter + secondLetter);
  }
}
