import { getKoreanTiemFromUTC } from "@components/AllttamCare/function/getKoreanTimeFromUTC";
import { GreySmallButton } from "@components/Button/allttamButton/GreySmallButton";
import { RedSmallButton } from "@components/Button/allttamButton/RedSmallButton";
import useModal from "@hooks/useModal";
import { Notice as INotice } from "@interface/notice";
import { NoticeModal } from "@components/Admin/RenewalNotice/components/noticeModal";
import NoticeWriteModal from "@components/Admin/RenewalNotice/components/noticeWriteModal";

interface Props {
  onDelete?: (id: string) => void;
  notice: INotice;
  isAdmin: boolean;
}

export const NoticeItem = ({ notice, onDelete = () => {}, isAdmin }: Props) => {
  const { openModal } = useModal();

  return (
    <tr
      className="allttam-table-item-border pointer"
      onClick={() => openModal(<NoticeModal notice={notice} />)}
    >
      <td className="width10">
        <div>{notice.idx}</div>
      </td>
      <td className="width20">
        <div>{notice.category}</div>
      </td>
      <td className="width40">
        <div className="d-flex gap-3 justify-content-center">
          {notice.isPinned && <span className="pinnedTag weight500">중요</span>}
          {notice.title}
        </div>
      </td>
      <td className="width15">
        <div>{notice.writer}</div>
      </td>
      <td className="width15">
        <div>{getKoreanTiemFromUTC(notice.createdAt)}</div>
      </td>
      {isAdmin && (
        <td className="width20" onClick={(e) => e.stopPropagation()}>
          <div className="d-flex align-items-center gap-3">
            <RedSmallButton onClick={() => onDelete(notice._id)}>
              삭제
            </RedSmallButton>
            <GreySmallButton
              onClick={() =>
                openModal(
                  <NoticeWriteModal queryKey={[]} notice={notice} isEdit />
                )
              }
            >
              수정
            </GreySmallButton>
          </div>
        </td>
      )}
    </tr>
  );
};
