function formatTimeWithLeadingZero(num: number) {
  return num.toString().padStart(2, "0");
}

export const madeSBunid = () => {
  const now = new Date();

  const year = now.getFullYear();
  const month = formatTimeWithLeadingZero(now.getMonth() + 1);
  const day = formatTimeWithLeadingZero(now.getDate());
  const hour = formatTimeWithLeadingZero(now.getHours());
  const minute = formatTimeWithLeadingZero(now.getMinutes());
  const second = formatTimeWithLeadingZero(now.getSeconds());

  return `010590${year}${month}${day}${hour}${minute}${second}`;
};
