import { ReactCropperElement } from "react-cropper";
import downIcon from "@assets/images/imgTooldown.svg";
import { UploadTooltip } from "../../../Tooltip/UploadTooltip";
import { useTooltip } from "@hooks/useTooltip";

interface Props {
  cropperRef: React.RefObject<ReactCropperElement>;
  mouseHover: (string: string) => void;
  mouseLeave: () => void;
}

export const MoveDownButton = ({
  cropperRef,
  mouseHover,
  mouseLeave,
}: Props) => {
  const handleMoveDown = () => {
    cropperRef.current?.cropper.move(0, 10);
  };

  return (
    <button
      onClick={handleMoveDown}
      className="madebutton thumbIcon tooltipRel"
      onMouseLeave={mouseLeave}
      onMouseEnter={() => mouseHover("아래로 이동")}
    >
      <img src={downIcon} />
    </button>
  );
};
