import { ReactCropperElement } from "react-cropper";
import lotateRightIcon from "@assets/images/lotateRight.svg";

interface Props {
  cropperRef: React.RefObject<ReactCropperElement>;
  mouseHover: (string: string) => void;
  mouseLeave: () => void;
}

export const RotateRightButton = ({
  cropperRef,
  mouseHover,
  mouseLeave,
}: Props) => {
  const handleRotateRight = (): void => {
    cropperRef.current?.cropper?.rotate(90);
  };

  return (
    <button
      onClick={handleRotateRight}
      className="madebutton thumbIcon tooltipRel"
      onMouseLeave={mouseLeave}
      onMouseEnter={() => mouseHover("우로 회전")}
    >
      <img src={lotateRightIcon} />
    </button>
  );
};
