import useToggle from "../../../../hooks/useToggle";
import { CollectLog } from "../../../../interface/defaultinterface";
import { CollectDetailLogModal } from "../../modal/CollectDetailLogModal";

interface Props {
  log: CollectLog;
}

export const CollectComponent = ({ log }: Props) => {
  const { state: isDetailModal, handleToggle: handleDetailModal } = useToggle();
  return (
    <tr>
      <td>
        <div>{log.workNumber}</div>
      </td>
      <td>
        <div className="d-flex align-items-center">
          <span></span>
          {log.site}
        </div>
      </td>
      <td>
        <div>{log.groupName}</div>
      </td>
      <td>
        <div>{log.collectRequestCount}</div>
      </td>
      <td>
        <div>{log.collectSuccessCount}</div>
      </td>
      <td>
        <div>
          <button
            onClick={handleDetailModal}
            className="madebutton collectLogDetailButton"
          >
            상세보기
          </button>
          {isDetailModal && (
            <CollectDetailLogModal closeModal={handleDetailModal} workNumber={log.workNumber} />
          )}
        </div>
      </td>
    </tr>
  );
};
