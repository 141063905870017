import { ModalBg } from "@components/ModalLayout/ModalBg";
import { Spinner } from "@components/Spinner/Spinner";
import ModalContent from "@components/shared/modal/ModalContent";
import useModal from "@hooks/useModal";
import { SendInvoiceRes } from "@interface/orderManageInterface";

interface Props {
  sendInvoiceRes: SendInvoiceRes[];
}

export const SendInvoiceResultModal = ({
  // isLoading,
  sendInvoiceRes,
}: Props) => {
  const { closeModal } = useModal();
  return (
    <ModalContent width={1000}>
      <ModalContent.ModalHead>
        <h5
          className="modal-title d-flex align-items-center"
          id="exampleModalLabel"
        >
          전송결과
        </h5>
      </ModalContent.ModalHead>

      <div className="modal-body">
        {sendInvoiceRes.map((res, idx) => (
          <div key={idx}>
            {res.isSuccess ? (
              <p className="textGreen">성공</p>
            ) : (
              <p className="textRed">실패</p>
            )}
            {res.summary.length > 70 ? (
              <>
                {" "}
                <h6 className="oneLine">{res.summary.slice(0, 80)}</h6>
                <h6 className="oneLine">{res.summary.slice(80)}</h6>
              </>
            ) : (
              <h6 className="oneLine">{res.summary}</h6>
            )}
            <p>{res.message}</p>
          </div>
        ))}
        <div className="row mt-4">
          <div className="col-12">
            <div className="d-flex align-items-center justify-content-center gap-3 mt-4">
              <button
                className="cancel-btn save-btn"
                onClick={() => {
                  closeModal();
                }}
              >
                확인
              </button>
            </div>
          </div>
        </div>
      </div>
    </ModalContent>
  );
};
