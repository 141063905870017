import { GetBackIcon } from "@components/svg/GetBackIcon";
import { UseMutationResult } from "react-query";

interface Props {
  submitProductEdit: UseMutationResult<any, unknown, void, unknown>;
  clickGetBack: () => void;
  setFunction: () => void;
}
export const SubmitButtonComponent = ({
  submitProductEdit,
  clickGetBack,
  setFunction,
}: Props) => {
  return (
    <div className="row">
      <div className="col-12">
        <div className="d-flex align-items-center justify-content-center gap-3 mt-4">
          <button className="cancel-btn" onClick={clickGetBack}>
            <GetBackIcon />
            되돌리기
          </button>
          <button className="cancel-btn save-btn" onClick={setFunction}>
            저장
          </button>
        </div>
      </div>
    </div>
  );
};
