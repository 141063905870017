import { useCallback, useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import NavBar from "../../components/NavBar/NavBar";
import { NavBarDefaultComponent } from "../../components/NavBar/component/NavBarDefaultComponent";
import { HomeIcon } from "../../components/svg/HomeIcon";
import { ProductManageIcon } from "@components/svg/ProductManageIcon";
import { useAlert } from "@hooks/useAlert";
import { AlertOnlyClose } from "@components/Alert/AlertOnlyClose";
import useToggle from "@hooks/useToggle";
import { ReferenceCodeModal } from "./modal/ReferenceCodeModal";
import { useInfoWindow } from "@hooks/useInfoWindow";
import { PayResult } from "@interface/payment";
import axiosInstance from "@utils/axiosInstance";
import useFetch from "@hooks/useFetch";
import useUser from "@hooks/useUser";

export const PaymentResult = () => {
  const { state, handleToggle } = useToggle();
  const [isSendCode, setIsSendCode] = useState(false);
  const openInfo = useInfoWindow();
  const [params, setParams] = useState<PayResult>();
  const { isAlert, handleAlert, alertMessage } = useAlert();

  const userInfo = useUser();

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const paramsObj = {};
    for (const [key, value] of searchParams.entries()) {
      (paramsObj as any)[key] = value;
    }
    setParams(paramsObj as any);
  }, []);

  useEffect(() => {
    if (isSendCode) {
      openInfo("추천인 코드가 입력되었습니다.");
    }
  }, [isSendCode]);

  useEffect(() => {
    if (!params) return;
    if (!userInfo) return;
    createPayLog();
  }, [params, userInfo]);

  const createPayLog = async () => {
    const res = await axiosInstance.post(
      "https://center.allttam.com/api/allttam/paylog",
      {
        orderNumber: params?.MOID,
        userId: userInfo?.username,
        userName: userInfo?.originalUserName,
        amount: params?.Amt,
        productName: params?.GoodsName,
      }
    );
  };

  return (
    <>
      <NavBar>
        <NavBarDefaultComponent />
      </NavBar>
      <div className="payResultTable d-flex flex-column">
        <div className="d-flex justify-content-center resultTitle">
          {params?.AuthDate.substring(0, 2)}.{params?.AuthDate.substring(2, 4)}.
          {params?.AuthDate.substring(4, 6)} {params?.AuthDate.substring(6, 8)}:
          {params?.AuthDate.substring(8, 10)}에 {params?.Amt} 원을 카드 결제
          성공한 내역은 다음과 같습니다.
        </div>
        <div className="d-flex mt-4">
          <div className="widthHalf mt-2">
            <div className="d-flex">
              <span className="widthHalf">결제일시</span>
              <span>
                {params?.AuthDate.substring(0, 2)}.
                {params?.AuthDate.substring(2, 4)}.
                {params?.AuthDate.substring(4, 6)}{" "}
                {params?.AuthDate.substring(6, 8)}:
                {params?.AuthDate.substring(8, 10)}
              </span>
            </div>
            <div className="d-flex mt-2">
              <span className="widthHalf">상품명</span>
              <span>{params?.GoodsName}</span>
            </div>
            <div className="d-flex mt-2">
              <span className="widthHalf">신용카드</span>
              <span>{params?.fn_name}</span>
            </div>
            <div className="d-flex mt-2">
              <span className="widthHalf">할부</span>
              <span>
                {params?.CardQuota === "00" ? "일시불" : params?.CardQuota}
              </span>
            </div>
            <div className="d-flex mt-2">
              <span className="widthHalf">구매자</span>
              <span>{params?.name}</span>
            </div>
            <div className="d-flex mt-2">
              <span className="widthHalf">주문번호</span>
              <span>{params?.OID}</span>
            </div>
          </div>
          <div className="widthHalf">
            <p>구매상점 정보</p>
            <div className="d-flex mt-2">
              <span className="widthHalf">구매상점명</span> <span>이들</span>
            </div>
            <div className="d-flex mt-2">
              <span className="widthHalf">사이트주소</span>{" "}
              <span>http://allttam.com</span>
            </div>
            <p className="mt-3 textRed">
              * 결제 취소 및 배송문의는 상점에 문의해 주세요.
            </p>
          </div>
        </div>
        <div className="d-flex  align-items-center justify-content-center gap-5 mt-5">
          <a
            href={`https://pg.innopay.co.kr/pay/issue/CardIssue.jsp?TID=${params?.TID}`}
            target="_blank"
          >
            <button className="receiptButton madebutton d-flex align-items-center justify-content-center gap-2">
              <ProductManageIcon />
              <p className="oneLine margin0">매출전표(영수증) 보기</p>
            </button>
          </a>
          <Link
            className="toHomeButton madebutton d-flex align-items-center justify-content-center gap-2"
            to={"/home"}
          >
            <HomeIcon />
            <p className="oneLine margin0">홈으로</p>
          </Link>
        </div>
        {isAlert && (
          <AlertOnlyClose
            closeAlert={handleAlert as () => void}
            message={alertMessage}
          />
        )}
      </div>
      <div className="d-flex justify-content-center mt-5">
        <button
          onClick={handleToggle}
          className="codeButton madebutton textCenter"
        >
          <p className="margin0">추천인 코드 입력하고</p>
          <p className="margin0">500코인 받기</p>
        </button>
        {state && (
          <ReferenceCodeModal
            payInfo={params!}
            closeModal={handleToggle}
            setIsSendCode={setIsSendCode}
          />
        )}
      </div>
    </>
  );
};
