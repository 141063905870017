export const UserInfoSpeed = ({ planName }: { planName: string }) => {
  return (
    <div className="col-12 col-md-6">
      <div className="s1-inner d-flex align-items-center justify-content-between acc-info">
        <h6>속도</h6>
        <p>{planName}</p>
      </div>
    </div>
  );
};
