import { useState } from "react";

import { LoginInput } from "./LoginInput";
import { LoginDefault } from "./LoginDefault";

export const LoginComponent = () => {
  const [isLoginDefault, setIsLoginDefault] = useState(true);

  const clickChangeToInput = () => {
    setIsLoginDefault((prev) => !prev);
  };

  return (
    <>
      {isLoginDefault ? (
        <LoginDefault clickChangeToInput={clickChangeToInput} />
      ) : (
        <LoginInput clickChangeToInput={clickChangeToInput} />
      )}
    </>
  );
};
