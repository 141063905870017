import { changeTimeFormat } from "../../../../utils/functions/changeTimeFormat";


export const UserInfoExpireDate = ({ expireDate }: { expireDate: string }) => {
  return (
    <div className="col-12 col-md-6 mt-3">
      <div className="s1-inner d-flex align-items-center justify-content-between">
        <h6>만료 날짜</h6>
        {expireDate && <p>{changeTimeFormat(expireDate)}</p>}
      </div>
    </div>
  );
};
